var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    [
      _c(
        "v-flex",
        { attrs: { id: "hideMobile" } },
        [
          _c(
            "v-dialog",
            {
              ref: "startMenu",
              attrs: {
                "close-on-content-click": true,
                "nudge-right": 40,
                lazy: "",
                transition: "scale-transition",
                "offset-y": "",
                "full-width": "",
                "min-width": "290px"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  slot: "activator",
                  label: _vm.label,
                  "prepend-icon": "event",
                  readonly: ""
                },
                slot: "activator",
                model: {
                  value: _vm.selectedText,
                  callback: function($$v) {
                    _vm.selectedText = $$v
                  },
                  expression: "selectedText"
                }
              }),
              _c("v-date-picker", {
                attrs: {
                  locale: "pt-br",
                  value: _vm.value,
                  "no-title": "",
                  type: _vm.type,
                  scrollable: ""
                },
                on: {
                  change: function($event) {
                    return _vm.$emit("input", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { id: "hideDesk" } },
        [
          _c(
            "v-menu",
            {
              ref: "startMenu",
              attrs: {
                "close-on-content-click": true,
                "nudge-right": 40,
                lazy: "",
                transition: "scale-transition",
                "offset-y": "",
                "full-width": "",
                "min-width": "290px"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  slot: "activator",
                  label: _vm.label,
                  "error-messages": _vm.errorMessage,
                  "prepend-icon": "event",
                  readonly: ""
                },
                slot: "activator",
                model: {
                  value: _vm.selectedText,
                  callback: function($$v) {
                    _vm.selectedText = $$v
                  },
                  expression: "selectedText"
                }
              }),
              _c("v-date-picker", {
                attrs: {
                  locale: "pt-br",
                  value: _vm.value,
                  "no-title": "",
                  type: _vm.type,
                  scrollable: ""
                },
                on: {
                  change: function($event) {
                    return _vm.$emit("input", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }