<template>
  <v-card>
    <v-layout class="grey lighten-3" justify-center>
      <v-card-title>
        <h3>Fazer sugestão</h3>
      </v-card-title>
    </v-layout>
    <div v-if="iaSuggestion.length > 0 && (isAdmin || isCoordinador)">
      <a @click="addIaSuggestedProducts()" class="ml-2">aceitar sugestão automática</a>
    </div>
    <v-layout pa-0 row wrap>
      <v-flex md12 lg6>
        <ProductDescriptionSelect
          @selected="
            (description) => {
              selectedProduct = description;
            }
          "
          v-model="selectedProductId"
          :availableProductsIdList="!isShowAllProductsSelected && availableProducts"
          id="selectProduct"
          class="suggestionProducts"
        ></ProductDescriptionSelect>
      </v-flex>
      <v-flex xs7 lg3>
        <v-text-field
          class="amount"
          label="Quantidade"
          v-model="analyzeProductAmount"
          title="Informe a quantidade a ser aplicada."
          type="number"
          min="0"
          id="amount"
        />
      </v-flex>
      <v-flex class="suggestion-box-ppm" xs5 lg3
        >&nbsp;&nbsp;{{
          selectedProduct &&
            {
              amount:
                selectedProduct.name === 'Pastilha tricloro'
                  ? analyzeProductAmount * 0.2
                  : analyzeProductAmount,
              volume: poolVolume,
              purity: selectedProduct.chemicalPurity,
            } | ppm
        }}</v-flex
      >
      <v-flex xs12 class="suggestionButton">
        <v-btn
          color="primary"
          @click="addSuggestedProduct()"
          :disabled="!isEnabledButtonSuggestion()"
          id="addSuggestedProduct"
          >Sugerir</v-btn
        >
      </v-flex>
    </v-layout>
    <div class="ml-2">
      <input id="checkbox" type="checkbox" v-model="isShowAllProductsSelected" />
      <label for="checkbox"> Mostrar todos os produtos</label>
    </div>
  </v-card>
</template>

<script>
import { isRole } from '@/services/UtilsService';
import ProductDescriptionSelect from '@/components/selects/ProductDescriptionSelect.vue';

export default {
  data: () => ({
    selectedProductId: '',
    value: [],
    isShowAllProductsSelected: false,
    analyzeProductAmount: '',
    selectedProduct: '',
    isAdmin: isRole('ADMIN'),
    isCoordinador: isRole('COORDINADOR'),
    suggestedProducts: [],
  }),
  computed: {},
  methods: {
    isEnabledButtonSuggestion() {
      return Boolean(this.analyzeProductAmount && this.selectedProduct);
    },
    addSuggestedProduct() {
      this.analyzeProductAmount = Number(this.analyzeProductAmount);
      const suggestedProduct = this.suggestedProducts.find(
        (product) => product.description.id === this.selectedProduct.value,
      );
      if (suggestedProduct) {
        suggestedProduct.amount += this.analyzeProductAmount;
      } else {
        this.suggestedProducts.push({
          description: {
            id: this.selectedProduct.value,
            name: this.selectedProduct.name,
            unit: this.selectedProduct.unit,
            chemicalPurity: this.selectedProduct.chemicalPurity,
          },
          amount: this.analyzeProductAmount,
        });
      }
      this.$emit('suggestedProducts', this.suggestedProducts);
      this.selectedProduct = '';
      this.analyzeProductAmount = '';
      this.selectedProductId = '';
    },
    addIaSuggestedProducts() {
      this.suggestedProducts = this.iaSuggestion.slice();
      this.$emit('suggestedProducts', this.suggestedProducts);
    },
  },
  created() {},
  filters: {
    ppm(data) {
      if (!data.amount) {
        return '';
      }
      const purity = data.purity ? data.purity : 100;
      const ppm = (data.amount * 1000 * (purity / 100)) / data.volume;
      return data.volume ? `(${ppm.toFixed(1)} ppm)` : '';
    },
  },
  components: { ProductDescriptionSelect },
  props: ['iaSuggestion', 'availableProducts', 'poolVolume'],
};
</script>

<style scoped>
.amount {
  margin-left: 15px;
}
.suggestionButton {
  text-align: center;
  margin-bottom: 15px;
}
.suggestion-box-ppm {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  margin: auto;
}
.suggestionProducts {
  margin-left: 4%;
  margin-right: 4%;
}
</style>
