var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mt-2",
      staticStyle: { width: "100%" },
      attrs: { id: "listVisitorsAnalyzes", row: "" }
    },
    [
      _c(
        "v-toolbar",
        {
          attrs: {
            "clipped-left": _vm.$vuetify.breakpoint.lgAndUp,
            color: "#0D1824",
            dark: "",
            app: "",
            fixed: ""
          }
        },
        [
          _c(
            "v-layout",
            [
              _c("v-flex", { attrs: { xs6: "" } }, [
                _c("a", { attrs: { href: "https://piscinapp.com.br/" } }, [
                  _c("img", {
                    staticStyle: { "margin-top": "6px" },
                    attrs: { src: "/assets/img/logo-piscinapp-35px.png" }
                  })
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pool-board" },
        [
          _c(
            "div",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    flat: "",
                    large: "",
                    disabled:
                      _vm.currentIndex === _vm.listAnalyzes.length - 1 ||
                      !_vm.currentAnalyze.id
                  },
                  on: {
                    click: function($event) {
                      _vm.currentIndex += 1
                    }
                  }
                },
                [
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-icon",
                            { staticClass: "material-icons v-icon" },
                            [_vm._v("arrow_back")]
                          ),
                          _c("br"),
                          _c("span", { staticClass: "caption" }, [
                            _vm._v("Anterior")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticStyle: { float: "right" },
                  attrs: {
                    flat: "",
                    large: "",
                    disabled: !_vm.currentIndex || !_vm.currentAnalyze.id
                  },
                  on: {
                    click: function($event) {
                      _vm.currentIndex -= 1
                    }
                  }
                },
                [
                  _c(
                    "v-flex",
                    [
                      _c("v-icon", { staticClass: "material-icons v-icon" }, [
                        _vm._v("arrow_forward")
                      ]),
                      _c("br"),
                      _c("span", { staticClass: "caption" }, [
                        _vm._v("Próxima")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.isLoading
            ? _c(
                "v-card",
                {
                  staticClass: "ma-4 text-xs-center",
                  attrs: { height: "250" }
                },
                [
                  _c("br"),
                  _c("v-progress-circular", {
                    staticClass: "loader",
                    attrs: { indeterminate: "", color: "primary" }
                  })
                ],
                1
              )
            : !_vm.currentAnalyze.id
            ? _c("v-card", { staticClass: "ma-4 text-xs-center" }, [
                _c("p", { staticClass: "headline pt-5 pb-5" }, [
                  _vm._v("Ainda não há análises"),
                  _c("br"),
                  _vm._v("para esta piscina")
                ])
              ])
            : _c(
                "v-card",
                { staticClass: "mx-4" },
                [
                  _c("v-img", {
                    attrs: { src: _vm._f("photoUrl")(_vm.currentAnalyze) },
                    on: {
                      click: function($event) {
                        return _vm.openBetterPhoto()
                      }
                    }
                  }),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "info-header" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  this.currentAnalyze.pool.local?.localName
                                ) +
                                "\n            "
                            ),
                            _c("h2", [
                              _vm._v(_vm._s(this.currentAnalyze.pool.name))
                            ])
                          ]),
                          _vm.currentAnalyze.pool.local?.franchisee
                            ?.myBusinessLink
                            ? _c("v-img", {
                                staticClass: "google-business-card",
                                attrs: { src: "/assets/img/google.png" },
                                on: {
                                  click: function($event) {
                                    return _vm.openMyBusiness()
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "analyze-data mb-3" },
                        [
                          _c("div", { staticClass: "report-section" }, [
                            _c("h4", [_vm._v("Última análise")])
                          ]),
                          _vm.currentAnalyze.employee
                            ? _c(
                                "div",
                                { staticClass: "report-section py-0" },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v("person")
                                  ]),
                                  _c("div", [
                                    _c("b", [_vm._v("Nome do piscineiro: ")]),
                                    _c("br"),
                                    _c(
                                      "span",
                                      { staticClass: "text--primary" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.currentAnalyze.employee.name
                                          )
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.currentAnalyze.createDate
                            ? _c("v-divider")
                            : _vm._e(),
                          _vm.currentAnalyze.createDate
                            ? _c(
                                "div",
                                { staticClass: "report-section py-0" },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v("date_range")
                                  ]),
                                  _c("div", [
                                    _c("b", [_vm._v("Data: ")]),
                                    _c("br"),
                                    _c(
                                      "span",
                                      { staticClass: "text--primary" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.currentAnalyze.createDate)
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.currentAnalyze.createHour
                            ? _c("v-divider")
                            : _vm._e(),
                          _vm.currentAnalyze.createHour
                            ? _c(
                                "div",
                                { staticClass: "report-section py-0" },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v("schedule")
                                  ]),
                                  _c("div", [
                                    _c("b", [_vm._v("Hora: ")]),
                                    _c("br"),
                                    _c(
                                      "span",
                                      { staticClass: "text--primary" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.currentAnalyze.createHour)
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.currentAnalyze.visit
                            ? _c("div", { staticClass: "report-section" }, [
                                _c("h4", [_vm._v("Visita")])
                              ])
                            : _vm._e(),
                          _vm.currentAnalyze.startVisitDateTime
                            ? _c(
                                "div",
                                { staticClass: "report-section py-0" },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v("date_range")
                                  ]),
                                  _c("div", [
                                    _c("b", [_vm._v("Início: ")]),
                                    _c("br"),
                                    _c(
                                      "span",
                                      { staticClass: "text--primary" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.currentAnalyze
                                              .startVisitDateTime
                                          )
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.currentAnalyze.endVisitDateTime
                            ? _c("v-divider")
                            : _vm._e(),
                          _vm.currentAnalyze.endVisitDateTime
                            ? _c(
                                "div",
                                { staticClass: "report-section py-0" },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v("date_range")
                                  ]),
                                  _c("div", [
                                    _c("b", [_vm._v("Fim: ")]),
                                    _c("br"),
                                    _c(
                                      "span",
                                      { staticClass: "text--primary" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.currentAnalyze.endVisitDateTime
                                          )
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "report-section" },
                        [
                          _c("v-icon", [_vm._v("colorize")]),
                          _c("h4", [_vm._v("Parâmetros físicos e químicos")])
                        ],
                        1
                      ),
                      _c("div", { staticClass: "report-section-item" }, [
                        _c("b", [_vm._v("Volume: ")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("brNumber")(
                                _vm.currentAnalyze.pool.volume * 1000
                              )
                            )
                          )
                        ]),
                        _vm._v(" Litros\n        ")
                      ]),
                      _c("v-divider"),
                      _vm._l(_vm.parameters, function(parameter, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c("div", { staticClass: "report-section-item" }, [
                              _c("b", [_vm._v(_vm._s(parameter.label) + ": ")]),
                              _c("span", { staticClass: "text--primary" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("brNumber")(
                                      _vm.currentAnalyze[parameter.key]
                                    )
                                  ) + _vm._s(parameter.textAfter)
                                )
                              ])
                            ]),
                            _c("v-divider")
                          ],
                          1
                        )
                      }),
                      _vm.currentAnalyze.suggestion &&
                      _vm.currentAnalyze.suggestion.aplication &&
                      _vm.currentAnalyze.suggestion.aplication.products.length
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                { staticClass: "report-section" },
                                [
                                  _c("v-icon", [_vm._v("format_color_fill")]),
                                  _c("h4", [_vm._v("Produtos Aplicados")])
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.currentAnalyze.suggestion.aplication
                                  .products,
                                function(product, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "report-section-item" },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(product.description.name) +
                                                ": "
                                            )
                                          ]),
                                          _c(
                                            "span",
                                            { staticClass: "text--primary" },
                                            [
                                              _vm._v(
                                                _vm._s(product.amount) +
                                                  " " +
                                                  _vm._s(
                                                    _vm._f("unitOfMeasurement")(
                                                      product.description.unit
                                                    )
                                                  )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c("v-divider")
                                    ],
                                    1
                                  )
                                }
                              )
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.currentAnalyze.servicesPerformed &&
                      _vm.currentAnalyze.servicesPerformed.length
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                { staticClass: "report-section" },
                                [
                                  _c("v-icon", [_vm._v("checklist")]),
                                  _c("h4", [_vm._v("Serviços Realizados")])
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.currentAnalyze.servicesPerformed,
                                function(service, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "report-section-item" },
                                        [_c("b", [_vm._v(_vm._s(service))])]
                                      ),
                                      _c("v-divider")
                                    ],
                                    1
                                  )
                                }
                              )
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.currentAnalyze.problemReported
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                { staticClass: "report-section" },
                                [
                                  _c("v-icon", [_vm._v("warning")]),
                                  _c("h4", [_vm._v("Relato de problemas")])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "report-section-item" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.currentAnalyze.problemReported)
                                  )
                                ]
                              ),
                              _vm._l(
                                _vm.currentAnalyze.problemAudioUrls,
                                function(audioUrl, index) {
                                  return _c(
                                    "audio",
                                    {
                                      key: index,
                                      staticClass: "attached-media",
                                      attrs: { controls: "controls" }
                                    },
                                    [_c("source", { attrs: { src: audioUrl } })]
                                  )
                                }
                              ),
                              _vm._l(
                                _vm.currentAnalyze.problemVideoUrls,
                                function(videoUrl, index) {
                                  return _c(
                                    "video",
                                    {
                                      key: index,
                                      staticClass: "attached-media",
                                      attrs: { controls: "controls" }
                                    },
                                    [_c("source", { attrs: { src: videoUrl } })]
                                  )
                                }
                              ),
                              _vm._l(
                                _vm.currentAnalyze.problemPhotoUrls,
                                function(photoUrl, index) {
                                  return _c("img", {
                                    key: index,
                                    staticClass: "attached-media",
                                    attrs: { src: photoUrl }
                                  })
                                }
                              )
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }