var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-layout",
        { staticClass: "grey lighten-3", attrs: { "justify-center": "" } },
        [_c("v-card-title", [_c("h3", [_vm._v(_vm._s(_vm.title))])])],
        1
      ),
      _c("v-card-text", [
        _c("table", { staticClass: "table" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v("Produto")]),
              _c("th", [_vm._v("Pureza")]),
              _c("th", [_vm._v("Quantidade")])
            ])
          ]),
          _c(
            "tbody",
            _vm._l(_vm.products, function(product, index) {
              return _c("tr", { key: product.id, attrs: { width: "100%" } }, [
                _c("td", { staticStyle: { "text-align": "left" } }, [
                  _vm._v(_vm._s(product.description.name))
                ]),
                _c("td", [
                  _vm._v(_vm._s(product.description.chemicalPurity) + "%")
                ]),
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(product.amount) +
                      "\n            " +
                      _vm._s(_vm._f("units")(product.description.unit)) +
                      "\n              " +
                      _vm._s(
                        _vm._f("ppm")({
                          amount:
                            product.description.name === "Pastilha tricloro"
                              ? product.amount * 0.2
                              : product.amount,
                          volume: _vm.poolVolume,
                          purity: product.description.chemicalPurity
                        })
                      ) +
                      "\n          "
                  )
                ]),
                _vm.hasRemoveBtn
                  ? _c(
                      "td",
                      { attrs: { width: "36px" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticStyle: { margin: "0px", float: "right" },
                            attrs: { icon: "", flat: "" },
                            on: {
                              click: function($event) {
                                return _vm.removeProduct(index)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v(_vm._s("close"))])],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            }),
            0
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }