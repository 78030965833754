<template>
  <v-card>
    <v-snackbar
      id="snackbar"
      v-model="showSnack"
      :right="true"
      :timeout="value.timeout || 2000"
      :color="value.isError ? 'red' : 'grey darken-4'"
    >
      {{ value.text }}
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit('input', {});
    },
  },
  computed: {
    showSnack: {
      get() {
        return Boolean(this.value.text);
      },
      set(newValue) {
        this.close();
        return newValue;
      },
    },
  },
  watch: {
    value() {
      if (this.value.text === 'errorDefault') {
        this.value.text = 'Erro. Tente novamente.';
        this.value.isError = true;
      } else if (this.value.text === 'saveDefault') {
        this.value.text = 'Salvo com sucesso.';
      }
    },
  },
  props: ['value'],
  created() {},
};
</script>
