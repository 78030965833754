<template id="selectPool">
  <v-autocomplete
    autocomplete="off"
    :items="pools"
    name="locais"
    label="Selecione uma piscina"
    @change="$emit('input', $event)"
    item-text="name"
    item-value="id"
    :value="value.id"
    return-object
    :disabled="disabled || !pools.length"
    :rules="rules"
  />
</template>
<script>
import { getPoolsByLocalId } from '@/services/PlacesService';
import { alertSnack } from '@/services/UtilsService';

export default {
  data: () => ({ pools: [] }),
  watch: {
    async localId() {
      await this.getPools();
      if (!this.pools.length) {
        alertSnack('O local selecionado não possui nenhuma piscina cadastrada');
      }
    },
  },
  methods: {
    async getPools() {
      const allLocalPools = await getPoolsByLocalId(this.$apollo, {
        localId: this.localId,
      }).then((response) => response.local.pools);

      this.pools = this.poolsToRemove
        ? allLocalPools.filter((element) => element.id !== this.poolsToRemove)
        : allLocalPools;
    },
  },
  created() {
    if (this.localId) {
      this.getPools();
    }
  },
  props: ['value', 'localId', 'rules', 'poolsToRemove', 'disabled'],
};
</script>
