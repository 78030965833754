<template>
  <v-card :width="windowWidth < 960 ? `${windowWidth}px` : '100%'">
    <v-toolbar color="#0d1824" dark flat>
      <v-toolbar-title>Nova movimentação</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-layout wrap>
        <v-flex xs12>
          <v-select
            menu-props="auto"
            :items="types"
            v-model="form.type"
            label="Selecione o tipo de movimentação"
            item-text="name"
            item-value="value"
        /></v-flex>
        <v-flex xs12><placeSelect v-model="form.local.from" label="Selecione o local" /> </v-flex>
        <v-flex xs12
          ><placeSelect
            :removePlaceId="form.local.from.id"
            v-if="form.type === 'TRANSFERENCIA'"
            v-model="form.local.to"
            label="Selecione o destino"
          />
        </v-flex>
        <v-flex xs12
          ><productDescriptionSelect
            @selected="
              (description) => {
                form.product.unit = description.unit;
                form.product.name = description.name;
              }
            "
            v-model="form.product.id"
        /></v-flex>
        <v-flex v-if="form.product.id" xs12>
          <v-text-field
            :placeholder="`Quantidade em ${longUnit} (${shortUnit})`"
            v-model="form.product.amount"
            type="number"
          />
        </v-flex>
      </v-layout>
      <v-flex xs12 justify-center offset-md4 md4>
        <v-btn
          :disabled="!form.product.amount"
          style="width: 100%;"
          color="primary"
          @click="addProduct()"
        >
          Adicionar produto
        </v-btn>
      </v-flex>
      <h2 class="mb-2">Produtos</h2>
      <v-layout wrap>
        <h3 v-if="!selectedProductList.length">Nenhum produto adicionado</h3>
        <v-flex v-for="(product, index) in selectedProductList" :key="index" xs6 md2>
          <v-layout>
            <div>
              <h3>{{ product.name }}</h3>
              <p>{{ product.amount }}{{ product.unit | shortUnit }}</p>
            </div>
            <v-btn icon @click="removeProduct(index)">
              <v-icon title="Sair" id="buttonClose">close</v-icon>
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>

    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" flat @click="closeModal()">Fechar</v-btn>
      <v-btn
        :loading="isLoading"
        color="primary"
        @click="submit()"
        :disabled="
          !selectedProductList.length ||
            !form.local.from ||
            (form.type === 'TRANSFERENCIA' && !form.local.to)
        "
      >
        Confirmar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import productDescriptionSelect from '@/components/selects/ProductDescriptionSelect.vue';
import { returnUnitsLongFilter, returnUnitsShortFilter, alertSnack } from '@/services/UtilsService';
import placeSelect from '@/components/selects/PlaceSelect.vue';
import { createTransference } from '@/services/TransferenceService';

export default {
  data: () => ({
    windowWidth: window.innerWidth,
    isLoading: false,
    selectedProductList: [],
    form: {
      type: '',
      local: { from: '', to: '' },
      product: { id: '', name: '', unit: '', amount: '' },
    },
    types: [
      { name: 'Entrada', value: 'ENTRADA' },
      { name: 'Saída', value: 'SAIDA' },
      { name: 'Transferência', value: 'TRANSFERENCIA' },
      { name: 'Contagem Total', value: 'CONTAGEM' },
      { name: 'Contagem parcial', value: 'CONTAGEM_PARCIAL' },
    ],
  }),
  computed: {
    longUnit() {
      return returnUnitsLongFilter(this.form.product.unit);
    },
    shortUnit() {
      return returnUnitsShortFilter(this.form.product.unit);
    },
  },
  methods: {
    submit() {
      this.isLoading = true;
      const variables = {
        day: new Date().toISOString(),
        type: this.form.type,
        from: { connect: { id: this.form.local.from.id } },
        to: this.form.local.to ? { connect: { id: this.form.local.to.id } } : null,
        employee: { connect: { id: localStorage.employeeId } },
        products: {
          create: this.selectedProductList.map((product) => ({
            amount: Number(product.amount),
            description: { connect: { id: product.id } },
          })),
        },
      };
      return createTransference(this.$apollo, variables)
        .then(() => {
          this.form = this.$options.data().form;
          this.selectedProductList = [];
          alertSnack('Transferência realizada com sucesso!');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    removeProduct(index) {
      this.selectedProductList.splice(index, 1);
    },
    closeModal() {
      this.$emit('showModal', false);
    },
    addProduct() {
      this.selectedProductList.push(this.form.product);
      this.form.product = this.$options.data().form.product;
    },
  },
  watch: {},
  filters: {
    shortUnit(value) {
      return returnUnitsShortFilter(value);
    },
  },
  components: {
    placeSelect,
    productDescriptionSelect,
  },
};
</script>

<style scoped>
</style>
