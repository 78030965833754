<template>
  <v-container grid-list-xl id="containerTabEquipment">
    <v-layout wrap>
      <v-flex xs12 md7>
        <h2>Equipamentos deste local</h2>
      </v-flex>
      <v-flex xs12 md5>
        <v-btn
          v-if="!isClient"
          style="width:100%;"
          class="ma-0"
          large
          color="primary"
          @click="showModalNewRegister = true"
        >
          Cadastrar equipamento
        </v-btn>
      </v-flex>
      <v-flex xs12>
        <DataTable
          :tableContent="tableEquipments"
          @selectedItemIndex="selectedEquipmentIndex = $event"
        />
      </v-flex>

      <CardModal v-model="showModalRegister" width="50%">
        <RegisterEquipmentCardWithTabs
          :selectedEquipmentId="selectedEquipmentId"
          :idPlaceEdit="idPlaceEdit"
          @showModal="showModalRegister = $event"
        />
      </CardModal>
    </v-layout>
    <CardModal v-model="showModalNewRegister" width="40%">
      <RegisterEquipmentCard
        :idPlaceEdit="idPlaceEdit"
        @showModalNewRegister="showModalNewRegister = $event"
      />
    </CardModal>
    <v-flex xs12>
      <v-card-actions>
        <v-spacer />
        <v-btn v-if="!isClient" flat color="primary" @click="$emit('showModal', false)">
          fechar
        </v-btn>
      </v-card-actions>
    </v-flex>
  </v-container>
</template>

<script>
import DataTable from '@/components/utils/DataTable.vue';
import CardModal from '@/components/modal/CardModal.vue';
import RegisterEquipmentCard from '@/components/places/RegisterEquipmentCard.vue';
import { isRole, formatDate } from '@/services/UtilsService';
import { getEquipmentList } from '@/services/EquipmentService';
import RegisterEquipmentCardWithTabs from '@/components/places/RegisterEquipmentCardWithTabs.vue';
import * as moment from 'moment';

export default {
  data: () => ({
    isClient: isRole('CLIENT'),
    showModalNewRegister: false,
    responseEquipment: '',
    showModalRegister: '',
    tableEquipments: {
      isLoading: true,
      headerList: [
        { text: 'Tipo', align: 'left', value: 'type' },
        { text: 'Marca', align: 'center', value: 'brand' },
        { text: 'Modelo', align: 'center', value: 'model' },
        { text: 'Descrição', align: 'center', value: 'description' },
        { text: 'Observação', align: 'center', value: 'note' },
        { text: 'Status', align: 'center', value: 'status' },
        { text: 'Última manutenção', align: 'center', value: 'lastMaintenance' },
      ],
      contentList: [],
      noResult: 'Não há equipamentos neste local',
      itensPerPage: [-1],
      textPerPage: 'Equipamentos por página',
      linkMovesAndNewRegister: true,
    },
    selectedEquipmentId: '',
    selectedEquipment: null,
    selectedEquipmentIndex: null,
  }),
  computed: {
    selectedEquipmentLastRegister() {
      if (!this.selectedEquipment) {
        return undefined;
      }
      return this.selectedEquipment.equipmentRegisters
        ? this.selectedEquipment.equipmentRegisters[
            this.selectedEquipment.equipmentRegisters.length - 1
          ]
        : undefined;
    },
  },
  methods: {
    formatEquipmentStatus(statusValue) {
      return statusValue
        ? [
            { name: 'Funcionando', value: 'WORKING' },
            { name: 'Funcionando com defeito', value: 'WORKING_WITH_DEFECT' },
            { name: 'Funcionando com defeito crítico', value: 'WORKING_WITH_CRITICAL_DEFECT' },
            { name: 'Em manutenção', value: 'UNDER_MAINTENCE' },
            { name: 'Aguardando substituição', value: 'WAITING_FOR_REPLACEMENT' },
            { name: 'Desativado', value: 'DISABLED' },
          ].find((status) => status.value === statusValue).name
        : '';
    },
    async getEquipments() {
      this.responseEquipment = await getEquipmentList(this.$apollo, this.idPlaceEdit);
      this.tableEquipments.contentList = this.formatTableEquipments(
        this.responseEquipment,
      ).reverse();
      this.tableEquipments.isLoading = false;
    },
    formatTableEquipments(equipments) {
      return equipments.map((equipment) => ({
        type: equipment.details.type,
        brand: equipment.details.brand,
        model: equipment.details.model,
        description: equipment.equipmentRegisters.length
          ? equipment.equipmentRegisters[equipment.equipmentRegisters.length - 1].description
          : '',
        note: equipment.equipmentRegisters.length
          ? equipment.equipmentRegisters[equipment.equipmentRegisters.length - 1].observation
          : '',
        status: equipment.equipmentRegisters.length
          ? this.formatEquipmentStatus(
              equipment.equipmentRegisters[equipment.equipmentRegisters.length - 1].status,
            )
          : '',
        lastMaintenance: equipment.equipmentRegisters.length
          ? formatDate(
              equipment.equipmentRegisters[equipment.equipmentRegisters.length - 1].lastMaintenance,
            )
          : '',
      }));
    },
  },
  watch: {
    // selectedEquipmentId
    showModalRegister(value) {
      if (!value) {
        this.selectedEquipmentId = '';
        this.selectedEquipmentIndex = null;
        this.getEquipments();
      }
    },
    showModalNewRegister(value) {
      if (!value) this.getEquipments();
    },
    idPlaceEdit() {
      this.getEquipments();
    },
    selectedEquipmentIndex(index) {
      if (!Number.isInteger(index)) return;
      this.selectedEquipmentId = this.responseEquipment[index].id;
      this.showModalRegister = true;
    },
  },
  created() {
    this.getEquipments();
  },
  filters: {
    formatDate(date) {
      if (date) return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
      return '';
    },
  },
  components: {
    DataTable,
    CardModal,
    RegisterEquipmentCardWithTabs,
    RegisterEquipmentCard,
  },
  props: ['idPlaceEdit'],
};
</script>

<style scoped>
tr:hover {
  background: #e7e3e3;
}
</style>
