<template>
  <v-card>
    <v-layout class="grey lighten-3" justify-center>
      <v-card-title>
        <h3>Parâmetros</h3>
      </v-card-title>
    </v-layout>
    <v-layout wrap ma-3>
      <v-flex sm12 md10>
        <v-layout wrap>
          <v-flex class="parameter" sm12 md3 xl2>
            <v-layout>
              <v-text-field
                label="Volume"
                :value="analyze.pool.volume + ' m³'"
                disabled
              ></v-text-field>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl2>
            <v-layout>
              <ParameterSelect
                label="Alcalinidade"
                parameter="alkalinity"
                parameterFixed="fixedAlkalinity"
                :value="analyze.alkalinity"
                :analyzeId="analyze.id"
                :aiValue="analyze.aiAlkalinity"
                :fixedValue="analyze.fixedAlkalinity"
              ></ParameterSelect>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl2>
            <v-layout>
              <ParameterSelect
                label="Cloro total"
                parameter="totalChlorine"
                parameterFixed="fixedTotalChlorine"
                :value="analyze.totalChlorine"
                :analyzeId="analyze.id"
                :aiValue="analyze.aiTotalChlorine"
                :fixedValue="analyze.fixedTotalChlorine"
              ></ParameterSelect>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl2>
            <v-layout>
              <ParameterSelect
                label="Cianúrico"
                parameter="cyanuric"
                parameterFixed="fixedCyanuric"
                :value="analyze.cyanuric"
                :aiValue="analyze.aiCyanuric"
                :fixedValue="analyze.fixedCyanuric"
                :analyzeId="analyze.id"
              ></ParameterSelect>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl3>
            <v-layout>
              <ParameterSelect
                label="Água"
                parameter="water"
                parameterFixed="fixedWater"
                :value="analyze.water"
                :aiValue="analyze.aiWater"
                :fixedValue="analyze.fixedWater"
                :analyzeId="analyze.id"
              ></ParameterSelect>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl2>
            <v-layout>
              <ParameterSelect
                label="Cloro livre"
                parameter="freeChlorine"
                parameterFixed="fixedFreeChlorine"
                :value="analyze.freeChlorine"
                :analyzeId="analyze.id"
                :aiValue="analyze.aiFreeChlorine"
                :fixedValue="analyze.fixedFreeChlorine"
              ></ParameterSelect>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl2>
            <v-layout>
              <ParameterSelect
                label="Dureza"
                parameter="hardness"
                parameterFixed="fixedHardness"
                :value="analyze.hardness"
                :aiValue="analyze.aiHardness"
                :fixedValue="analyze.fixedHardness"
                :analyzeId="analyze.id"
              ></ParameterSelect>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl2>
            <v-layout>
              <ParameterSelect
                label="PH"
                parameter="ph"
                parameterFixed="fixedPh"
                :value="analyze.ph"
                :aiValue="analyze.aiPh"
                :fixedValue="analyze.fixedPh"
                :analyzeId="analyze.id"
              ></ParameterSelect>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl2>
            <v-layout>
              <v-text-field
                label="Temperatura"
                :value="analyze.temperature != '99' ? analyze.temperature + ' °C' : 'Indisponível'"
                disabled
              ></v-text-field>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl3>
            <v-layout>
              <v-text-field
                label="Superfície"
                :value="getPhysicistChemicalParam(analyze.surface)"
                disabled
              ></v-text-field>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl2>
            <v-layout>
              <ParameterSelect
                label="gH"
                parameter="gh"
                parameterFixed="fixedGh"
                :value="analyze.gh"
                :analyzeId="analyze.id"
                :aiValue="analyze.aiGh"
                :fixedValue="analyze.fixedGh"
              ></ParameterSelect>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl2>
            <v-layout>
              <ParameterSelect
                label="kH"
                parameter="kh"
                parameterFixed="fixedKh"
                :value="analyze.kh"
                :analyzeId="analyze.id"
                :aiValue="analyze.aiKh"
                :fixedValue="analyze.fixedKh"
              ></ParameterSelect>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl2>
            <v-layout>
              <ParameterSelect
                label="Nitrito"
                parameter="nitrite"
                parameterFixed="fixedNitrite"
                :value="analyze.nitrite"
                :analyzeId="analyze.id"
                :aiValue="analyze.aiNitrite"
                :fixedValue="analyze.fixedNitrite"
              ></ParameterSelect>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl2>
            <v-layout>
              <ParameterSelect
                label="Nitrato"
                parameter="nitrate"
                parameterFixed="fixedNitrate"
                :value="analyze.nitrate"
                :analyzeId="analyze.id"
                :aiValue="analyze.aiNitrate"
                :fixedValue="analyze.fixedNitrate"
              ></ParameterSelect>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl3>
            <v-layout>
              <ParameterSelect
                label="Amônia Total"
                parameter="totalAmmonia"
                parameterFixed="fixedTotalAmmonia"
                :value="analyze.totalAmmonia"
                :analyzeId="analyze.id"
                :aiValue="analyze.aiTotalAmmonia"
                :fixedValue="analyze.fixedTotalAmmonia"
              ></ParameterSelect>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl2>
            <v-layout>
              <v-text-field
                label="Fundo"
                :value="getPhysicistChemicalParam(analyze.bottom)"
                disabled
              ></v-text-field>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl2>
            <v-layout>
              <v-text-field
                label="Borda"
                :value="getPhysicistChemicalParam(analyze.edge)"
                disabled
              ></v-text-field>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl2>
            <v-layout>
              <v-text-field
                label="Calçada"
                :value="getPhysicistChemicalParam(analyze.sidewalk)"
                disabled
              ></v-text-field>
            </v-layout>
          </v-flex>
          <v-flex class="parameter" sm12 md3 xl2>
            <v-layout>
              <ParameterSelect
                label="Sal"
                parameter="salt"
                parameterFixed="fixedSalt"
                :value="analyze.salt"
                :analyzeId="analyze.id"
                :aiValue="analyze.aiSalt"
                :fixedValue="analyze.fixedSalt"
              ></ParameterSelect>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex class="text-no-wrap" sm12 md12 xl2 v-if="isAdmin || isCoordinador">
        <div class="toolbar-switch"><b>Usar no treinamento:</b></div>
        <div class="toolbar-switch">
          <PiscinappSwitch @input="saveIsRevised" v-model="isRevised"> </PiscinappSwitch>
        </div>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { isRole, alertSnack } from '@/services/UtilsService';
import ParameterSelect from '@/components/analyzes/ParameterSelect.vue';
import PiscinappSwitch from '@/components/piscinappSwitch/PiscinappSwitch.vue';
import { updateAnalyze } from '@/services/AnalyzeService';

export default {
  data: () => ({
    isAdmin: isRole('ADMIN'),
    isCoordinador: isRole('COORDINADOR'),
    isRevised: false,
  }),
  computed: {},
  methods: {
    saveIsRevised() {
      updateAnalyze(this.$apollo, {
        id: this.analyze.id,
        data: { isRevised: this.isRevised },
      })
        .then(() => {
          alertSnack('Atualizado com sucesso');
        })
        .catch(() => {
          alertSnack('Erro ao atualizar', true);
        });
    },
    getPhysicistChemicalParam(param) {
      return (
        {
          INDISPONIVEL: 'Indisponível',
          MUITO_LIMPO: 'Muito limpo',
          LIMPO: 'Limpo',
          SUJO: 'Sujo',
          MUITO_SUJO: 'Muito sujo',
        }[param] || 'Não definido'
      );
    },
  },
  created() {
    this.isRevised = this.analyze.isRevised;
  },
  filters: {
    physicistChemicalParam(param) {
      return (
        {
          INDISPONIVEL: 'Indisponível',
          MUITO_LIMPO: 'Muito limpo',
          LIMPO: 'Limpo',
          SUJO: 'Sujo',
          MUITO_SUJO: 'Muito sujo',
        }[param] || 'Indisponível'
      );
    },
    waterType(type) {
      return (
        {
          CRISTALINA_BRILHOSA: 'Cristalina brilhosa',
          TRANSPARENTE_OPACA: 'Transparente opaca',
          LEVEMENTE_TURVA: 'Levemente turva',
          TURVA: 'Turva',
          ESVERDEADA: 'Esverdeada',
          VERDE: 'Verde',
          OUTRA_COLORACAO: 'Outra coloração',
        }[type] || 'Indisponível'
      );
    },
  },
  components: {
    ParameterSelect,
    PiscinappSwitch,
  },
  props: ['analyze'],
};
</script>

<style scoped>
.toolbar-switch {
  margin: auto;
  width: fit-content;
}
</style>
