<template>
  <v-select
    menu-props="auto"
    :items="types"
    @change="$emit('input', $event)"
    :value="value"
    :label="rules ? 'Tipo de local *' : 'Tipo de local'"
    :disabled="disabled"
    :rules="rules"
    item-text="name"
    item-value="value"
  />
</template>

<script>
export default {
  data: () => ({
    allTypes: [
      { name: 'Cliente', value: 'CLIENT' },
      { name: 'Fornecedor', value: 'PROVIDER' },
      { name: 'Residência', value: 'RESIDENTIAL' },
      { name: 'Sede', value: 'HEADQUARTERS' },
      { name: 'Todos', value: 'ALL' },
    ],
  }),
  computed: {
    types() {
      return this.allTypes.filter((type) => this.includeOptionAll || type.value !== 'ALL');
    },
  },
  props: ['value', 'disabled', 'rules', 'inclincludeOptionAlludeAll'],
};
</script>
