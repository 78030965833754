<template>
  <v-card class="elevation-12">
    <v-toolbar dark color="secondary">
      <v-toolbar-title>Entrar</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form>
        <v-text-field
          id="email"
          prepend-icon="mail_outline"
          name="login"
          label="Email"
          type="text"
          v-model="email"
          @keyup.enter="login()"
        ></v-text-field>
        <v-text-field
          id="password"
          prepend-icon="lock"
          name="password"
          label="Senha"
          type="password"
          v-model="password"
          @keyup.enter="login()"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        :loading="isLoading"
        color="primary"
        @click="login()"
        id="buttonLogin"
        block
        class="mx-2 buttonFull"
      >
        Entrar
      </v-btn>
    </v-card-actions>
    <v-card-actions class="text-center">
      <v-spacer />
      <a
        @click="$emit('component-change', 'RequestPasswordReset')"
        id="forgotPassword"
        class="mr-2"
      >
        Esqueceu sua senha?
      </a>
    </v-card-actions>
  </v-card>
</template>
<script>
import loginMutation from '@/graphql/login/login.gql';
import { rewriteUrl } from '@/services/UtilsService';
import { onLogin } from '@/vue-apollo';

export default {
  data: () => ({
    isLoading: false,
    email: '',
    password: '',
    textSnackbar: '',
  }),
  methods: {
    async login() {
      this.isLoading = true;
      const mutation = loginMutation;
      const variables = { email: this.email, password: this.password };

      this.$root.$apolloProvider.defaultClient
        .mutate({
          mutation,
          variables,
        })
        .then(async (resp) => {
          if (resp.data.authenticateUser === null || !resp.data) {
            return;
          }
          if (resp.data.authenticateUser.roles[0] === 'EMPLOYEE') {
            this.textSnackbar = 'Você não tem acesso ao sistema.';
            this.$emit('textSnackbar', this.textSnackbar);
            this.isLoading = false;
            return;
          }
          localStorage.userId = resp.data.authenticateUser.userId;
          localStorage.userRole = resp.data.authenticateUser.roles[0];
          localStorage.userEmail = this.email;
          localStorage.employeeId = resp.data.authenticateUser.employeeId;
          localStorage.franchiseeId = resp.data.authenticateUser.franchiseeId;
          localStorage.clientId = resp.data.authenticateUser.clientId;

          await onLogin(this.$root.$apolloProvider.defaultClient, resp.data.authenticateUser.token);
          this.isLoading = false;
          rewriteUrl(this.$router, this.$route, 'analises');
        })
        .catch((error) => {
          this.isLoading = false;
          this.textSnackbar =
            {
              'GraphQL error: Error: Unauthorized, wrong email or password!':
                'E-mail ou senha Inválidos, tente novamente.',
              'GraphQL error: Error: Unauthorized, this user is disabled.':
                'Usuário desativado. Entre em contato conosco.',
              'Network error: Failed to fetch': 'Verifique sua conexão com a internet.',
              'GraphQL error: Error: Too many consecutive attempts.':
                'Muitas tentativas de login mal-sucedidas, por favor, aguarde alguns minutos antes de tentar novamente.',
            }[error.message] || 'Não foi possivel efetuar o login.';

          this.$emit('textSnackbar', this.textSnackbar);
        });
    },
  },
};
</script>
