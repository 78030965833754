<template>
  <v-container>
    <FilterCard
      :resetButton="true"
      :searchButton="true"
      @clickOnReset="resetFilters()"
      @clickOnSearch="loadNotifications()"
    >
      <v-flex xs12 md12 lg6 xl2>
        <v-text-field
          class="mx-2"
          label="Pesquise pelo colaborador"
          v-model="filter.employeeName"
        />
      </v-flex>
      <v-flex xs12 md12 lg6 xl2>
        <v-text-field class="mx-2" label="Pesquise pelo assunto" v-model="filter.subject" />
      </v-flex>
      <v-flex xs12 md6 lg3 xl2 id="searchByType">
        <v-select
          class="mx-2"
          menu-props="auto"
          :items="notificationTypes"
          v-model="filter.type"
          item-value="value"
          item-text="name"
          label="Pesquise pelo tipo"
        />
      </v-flex>
      <v-flex xs12 md6 lg3 xl2 id="searchByStatus">
        <v-select
          class="mx-2"
          menu-props="auto"
          :items="statusTypes"
          v-model="filter.status"
          item-value="value"
          item-text="name"
          label="Pesquise pelo status"
        />
      </v-flex>
      <v-flex xs12 md6 lg3 xl2
        ><datePicker class="mx-2" label="Data inicial" v-model="filter.startDate"></datePicker
      ></v-flex>
      <v-flex xs12 md6 lg3 xl2
        ><datePicker class="mx-2" label="Data final" v-model="filter.endDate"></datePicker
      ></v-flex>
    </FilterCard>
    <cardList
      @click="(notification) => openModal(notification.id)"
      :isDescending="true"
      :sortBy="'date'"
      :isLoading="isLoading"
      :contentList="filteredNotificationTable"
    />
    <cardModal v-model="showModal" width="65%">
      <NotificationCard :notificationId="selectedNotificationId" @showModal="showModal = $event" />
    </cardModal>
  </v-container>
</template>

<script>
import * as moment from 'moment';

import FilterCard from '@/components/filters/FilterCard.vue';
import datePicker from '@/components/date-time/DatePicker.vue';
import cardList from '@/components/lists/CardList.vue';
import { getAllNotifications } from '@/services/NotificationService';
import { normalizeString } from '@/services/UtilsService';
import cardModal from '@/components/modal/CardModal.vue';
import NotificationCard from '@/components/notifications/NotificationCard.vue';

export default {
  data: () => ({
    isLoading: false,
    showModal: false,
    selectedNotificationId: '',
    notificationTableData: [],
    notificationTableColumns: [
      { text: 'Data', value: 'date', type: 'text' },
      { text: 'Assunto', value: 'subject', type: 'text' },
      { text: 'Tipo', value: 'type', type: 'text' },
      { text: 'Status', value: 'status', type: 'text' },
    ],
    filter: {
      employeeName: '',
      subject: '',
      type: '',
      startDate: moment()
        .subtract(1, 'day')
        .format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      status: 'ABERTA',
    },
    notificationTypes: [
      { name: 'Todas', value: null },
      { name: 'Estoque', value: 'ESTOQUE' },
      { name: 'Geolocalização', value: 'GEOLOCALIZACAO' },
      { name: 'Visita', value: 'VISITA' },
      { name: 'Ponto', value: 'PONTO' },
      { name: 'Problemas', value: 'LOCAL' },
    ],
    statusTypes: [
      { name: 'Todas', value: null },
      { name: 'Aberta', value: 'ABERTA' },
      { name: 'Fechada', value: 'FECHADA' },
    ],
  }),
  components: {
    cardList,
    FilterCard,
    datePicker,
    cardModal,
    NotificationCard,
  },
  methods: {
    openModal(id) {
      this.selectedNotificationId = id;
      this.showModal = true;
    },
    resetFilters() {
      this.filter = this.$options.data().filter;
    },
    setNotificationTableData(transferences) {
      this.notificationTableData = transferences.map((notification) => ({
        date: moment(notification.day).format('DD/MM/YY HH:mm'),
        subject: notification.title,
        type: this.formatNotificationType(notification.type),
        status: this.formatNotificationStatus(notification.status),
        employeeName: notification.users[0] ? notification.users[0].employee.name : '',
        id: notification.id,
      }));
    },
    formatNotificationType(type) {
      return {
        ESTOQUE: 'Estoque',
        GEOLOCALIZACAO: 'Geolocalização',
        VISITA: 'Visita',
        PONTO: 'Ponto',
        LOCAL: 'Problemas',
      }[type];
    },
    formatNotificationStatus(status) {
      return {
        ABERTA: 'Aberta',
        FECHADA: 'Fechada',
      }[status];
    },
    loadNotifications() {
      this.isLoading = true;
      const variables = {
        data: {
          ...(this.filter.type ? { type: this.filter.type } : {}),
          ...(this.filter.status ? { status: this.filter.status } : {}),
          day_gte: moment(this.filter.startDate)
            .startOf('day')
            .toISOString(),
          day_lte: moment(this.filter.endDate)
            .endOf('day')
            .toISOString(),
        },
      };
      getAllNotifications(this.$apollo, variables)
        .then((resp) => this.setNotificationTableData(resp))
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    filteredNotificationTable() {
      return {
        columns: this.notificationTableColumns,
        data: this.notificationTableData
          .filter((data) =>
            normalizeString(data.employeeName).includes(normalizeString(this.filter.employeeName)),
          )
          .filter((data) =>
            normalizeString(data.subject).includes(normalizeString(this.filter.subject)),
          ),
      };
    },
  },
  created() {
    this.loadNotifications();
  },
};
</script>

<style></style>
