<template>
  <v-dialog :fullscreen="isMobile" :width="isMobile ? '' : width" v-model="showModal">
    <slot></slot>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({ isMobile: window.innerWidth < 960 }),
  computed: {
    showModal: {
      get() {
        return this.value;
      },
      set(newValue) {
        if (this.removeURLParams && !newValue) {
          window.history.replaceState(null, null, window.location.pathname);
        }
        this.$emit('input', newValue);
        return newValue;
      },
    },
  },
  mounted() {
    document.onkeydown = (event) => {
      if (event.key === 'Escape') {
        this.showModal = false;
      }
    };
  },
  props: ['value', 'width', 'removeURLParams'],
};
</script>
