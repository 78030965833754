<template id="selectPlace">
  <v-autocomplete
    autocomplete="off"
    v-if="places.length"
    :items="places"
    name="locais"
    label="Selecione o local"
    @change="$emit('input', $event)"
    item-text="localName"
    item-value="id"
    :value="value.id"
    return-object
    :disabled="disabled"
    :rules="rules"
  />
  <p v-else>Não há outro local vinculado a esta franquia.</p>
</template>
<script>
import { getPlacesToSelectByFranchisee } from '@/services/PlacesService';

export default {
  data: () => ({ places: [] }),
  watch: {
    franchiseeId() {
      this.getPlaces();
    },
  },
  methods: {
    async getPlaces() {
      const allPlacesByFranchisee = await getPlacesToSelectByFranchisee(this.$apollo, {
        franchiseeId: this.franchiseeId,
      }).then((response) => response.locals);

      this.places = this.removePlaceId
        ? allPlacesByFranchisee.filter((element) => element.id !== this.removePlaceId)
        : allPlacesByFranchisee;
    },
  },
  created() {
    this.getPlaces();
  },
  props: ['value', 'franchiseeId', 'rules', 'removePlaceId', 'disabled'],
};
</script>
