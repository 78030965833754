import { mutation } from '@/services/GraphqlService';
import createSuggestionMutation from '@/graphql/analyze/createSuggestion.gql';

export const createSuggestion = (
  apollo,
  variables,
) => mutation(
  apollo,
  createSuggestionMutation,
  variables,
).then((resp) => resp.data);
