<template>
  <v-container grid-list-xl id="containerStockTab">
    <CardModal v-model="showModalSecondStock" width="30%">
      <v-card>
        <v-card-title class="headline">Estoque externo:</v-card-title>
        <v-card-text>
          <v-form ref="form" v-if="!isClient">
            <v-layout wrap>
              <v-flex xs12>
                <SelectPlaces
                  id="selectPlaces"
                  v-model="localSelectedStock"
                  :franchiseeId="formStock.franchiseeId"
                  :removePlaceId="formStock.id"
                  :rules="[validationRequired]"
                ></SelectPlaces>
              </v-flex>
              <v-flex xs12>
                <p class="subtitle-1">
                  As próximas aplicações neste local serão feitas por padrão no estoque selecionado.
                </p>
                <v-radio-group :mandatory="true" v-model="isUsingAnotherStock">
                  <v-radio
                    :label="`Estoque principal: ${formStock.localName}`"
                    :value="false"
                  ></v-radio>
                  <v-radio
                    id="alternativeStock"
                    :label="`Estoque secundário: ${localSelectedStock.localName}`"
                    :value="true"
                    :disabled="!localSelectedStock.id"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
          </v-form>
          <v-card-actions>
            <v-spacer />
            <v-btn flat color="primary" @click="showModalSecondStock = !showModalSecondStock"
              >Fechar</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </CardModal>

    <v-layout wrap>
      <v-flex xs12 md7>
        <h2>Estoque deste local</h2>
      </v-flex>
      <v-flex xs12 md5>
        <v-btn
          class="ma-0"
          v-if="!isClient"
          style="width: 100%;"
          large
          color="primary"
          @click="showModalSecondStock = !showModalSecondStock"
          >Estoque externo</v-btn
        >
      </v-flex>
    </v-layout>

    <v-layout wrap>
      <v-flex xs12>
        <v-expansion-panel>
          <v-expansion-panel-content>
            <template v-slot:header>
              <div>
                <v-layout>
                  <v-flex>
                    <h1><v-icon :color="!isUsingAnotherStock ? 'primary' : ''">layers</v-icon></h1>
                  </v-flex>
                  <v-flex xs12>
                    <h3>Estoque Principal</h3>
                    <span>{{ formStock.localName }}</span>
                  </v-flex>
                </v-layout>
              </div>
            </template>
            <v-card>
              <v-card-text>
                <DataTable
                  :tableContent="tableStockDefault"
                  :hideColumnValue="isClient ? 'minimumQuantity' : null"
                  @inputedMinimumQuantity="formatMinimumQuantity($event)"
                />
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
          <v-expansion-panel-content v-if="isUsingAnotherStock && localSelectedStock.id">
            <template v-slot:header>
              <div>
                <v-layout>
                  <v-flex>
                    <h1><v-icon color="primary">layers</v-icon></h1>
                  </v-flex>
                  <v-flex xs12>
                    <h3>Estoque Alternativo</h3>
                    <span>{{ localSelectedStock.localName }}</span>
                  </v-flex>
                </v-layout>
              </div>
            </template>
            <v-card>
              <v-card-text>
                <DataTable
                  :tableContent="tableSecondStock"
                  hideColumnValue="minimumQuantity"
                  @inputedMinimumQuantity="formatMinimumQuantity($event)"
                />
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>

      <v-flex xs12>
        <v-expansion-panel>
          <v-expansion-panel-content>
            <template v-slot:header>
              <div>
                <v-layout>
                  <v-flex>
                    <h1><v-icon>swap_horiz</v-icon></h1>
                  </v-flex>
                  <v-flex xs12>
                    <h3>Últimas movimentações</h3>
                    <span>Estoque principal: {{ formStock.localName }}</span>
                  </v-flex>
                </v-layout>
              </div>
            </template>
            <v-card>
              <v-card-text>
                <DataTable :tableContent="tableLastMove" />
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
    </v-layout>
    <v-card-actions>
      <v-spacer />
      <v-btn flat color="primary" @click="showModal = false">Fechar</v-btn>
      <v-btn color="primary" @click="submitStock()" id="saveStock">Salvar</v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import {
  isRole,
  returnUnitsShortFilter,
  returnUnitsLongFilter,
  typesFilter,
  formatDate,
  validationRequired,
  alertSnack,
} from '@/services/UtilsService';
import { createMinimumQuantity, updatePlace, getPlace } from '@/services/PlacesService';
import { getStockTabTransferences } from '@/services/TransferenceService';
import moment from 'moment';

import SelectPlaces from '@/components/places/LocalSelect.vue';
import DataTable from '@/components/utils/DataTable.vue';
import CardModal from '@/components/modal/CardModal.vue';

export default {
  data: () => ({
    showModalSecondStock: false,
    validationRequired,
    isLoading: false,
    showModal: true,
    isClient: isRole('CLIENT'),
    formStock: {
      id: '',
      franchiseeId: '',
      localName: '',
    },
    isUsingAnotherStock: false,
    localSelectedStock: { id: '', localName: '' },
    inputedMinimumQuantity: [],
    tableStockDefault: {
      isLoading: true,
      headerList: [
        { text: 'Produto', align: 'left', value: 'product' },
        { text: 'Quantidade', align: 'center', value: 'amount' },
        { text: 'Pureza (%)', align: 'center', value: 'purity' },
        { text: 'Medida', align: 'center', value: 'measure' },
        { text: 'Qtd mínima', align: 'center', value: 'minimumQuantity' },
      ],
      contentList: [],
      noResult: 'Não há produtos no estoque deste local',
      itensPerPage: [5, 10, 15, 20],
      textPerPage: 'Produtos por página',
    },
    tableSecondStock: {
      isLoading: true,
      headerList: [],
      contentList: [],
      noResult: '',
      itensPerPage: [''],
      textPerPage: '',
    },
    tableLastMove: {
      isLoading: true,
      headerList: [
        { text: 'Data', align: 'left', value: 'date', sortable: false },
        { text: 'Tipo de transferência', align: 'center', value: 'type' },
        { text: 'Produto', align: 'center', value: 'product' },
        { text: 'Quantidade', align: 'center', value: 'amount' },
        { text: 'Medida', align: 'center', value: 'measure' },
      ],
      contentList: [],
      noResult: 'Não foram realizadas movimentações neste estoque.',
      itensPerPage: [5, 10, 20, 50],
      textPerPage: 'Movimentações por página',
    },
  }),
  methods: {
    formatMinimumQuantity(event) {
      const index = this.inputedMinimumQuantity.findIndex(
        (element) => element.idProduct === event.idProduct,
      );
      if (index >= 0) {
        this.inputedMinimumQuantity[index] = {
          idProduct: event.idProduct,
          minimumQuantity: event.minimumQuantity,
        };
      } else {
        this.inputedMinimumQuantity.push(event);
      }
    },
    formatTableStockList(responseStock) {
      const contentTable = responseStock.map((element) => ({
        product: element.description.name,
        amount: element.amount,
        chemicalPurity: element.description.chemicalPurity,
        unit: returnUnitsLongFilter(element.description.unit),
        minimumQuantity: {
          idProduct: element.id,
          value: element.minimumQuantity,
        },
      }));

      return {
        isLoading: false,
        headerList: this.tableStockDefault.headerList,
        contentList: contentTable,
        noResult: this.tableStockDefault.noResult,
        itensPerPage: this.tableStockDefault.itensPerPage,
        textPerPage: this.tableStockDefault.textPerPage,
      };
    },
    async getStocksAndLastMoves() {
      this.isLoading = true;
      await this.getDefaultStock();
      await this.getSecondStock();
      await this.getMoveList();
      this.isLoading = false;
    },
    submitStock() {
      this.saveStockMinimumQuantity();
      const variables = {
        id: this.idPlaceEdit,
        data: {
          localServingStock: this.localSelectedStock.id
            ? { connect: { id: this.localSelectedStock.id } }
            : null,
          isUsingAnotherStock: this.isUsingAnotherStock,
        },
      };
      updatePlace(this.$apollo, variables)
        .then(() => {
          alertSnack('saveDefault');
          this.showModal = false;
        })
        .catch(() => {
          alertSnack('errorDefault');
        });
    },
    saveStockMinimumQuantity() {
      this.inputedMinimumQuantity.forEach((product) => {
        const variables = {
          id: product.idProduct,
          minimumQuantity: Number(product.minimumQuantity),
        };
        createMinimumQuantity(this.$apollo, variables)
          .then(() => {
            alertSnack('saveDefault');
            this.showModal = false;
          })
          .catch(() => {
            alertSnack('errorDefault');
          });
      });
    },
    async getDefaultStock() {
      const variables = {
        idUser: localStorage.userId,
        idPlace: this.idPlaceEdit,
      };
      const response = await getPlace(this.$apollo, variables).then((resp) => resp.local);
      this.formStock = {
        id: response.id,
        franchiseeId: response.franchisee.id,
        localName: response.localName,
      };

      this.isUsingAnotherStock = Boolean(response.isUsingAnotherStock);
      this.localSelectedStock = response.localServingStock
        ? response.localServingStock
        : { id: '', localName: '' };
      this.tableStockDefault = this.formatTableStockList(response.stock);
    },
    async getSecondStock() {
      if (!this.isUsingAnotherStock || !this.localSelectedStock.id) {
        return;
      }
      const variables = { idPlace: this.localSelectedStock.id };
      const alternativeStock = await getPlace(this.$apollo, variables).then(
        (resp) => resp.local.stock,
      );
      this.tableSecondStock = this.formatTableStockList(alternativeStock);
    },
    async getMoveList() {
      const variables = {
        data: { OR: [{ from: { id: this.idPlaceEdit } }, { to: { id: this.idPlaceEdit } }] },
        skip: 0,
        first: 15,
      };
      const lastMoves = await getStockTabTransferences(this.$apollo, variables).then((resp) =>
        resp.transferences
          .filter((transference) => transference.type !== 'TRANSFERENCIA_PARA_APLICACAO')
          .sort((a, b) => moment(b.day).diff(a.day)),
      );
      const contentTable = lastMoves
        .filter((move) => move.products.length)
        .map((move) => ({
          date: formatDate(move.day),
          type: typesFilter(move.type),
          product: move.products.map((product) => product.description.name),
          amount: move.products.map((product) => product.amount.toFixed(2)),
          unit: move.products.map((product) => returnUnitsShortFilter(product.description.unit)),
        }));

      this.tableLastMove = {
        isLoading: false,
        headerList: this.tableLastMove.headerList,
        contentList: contentTable,
        noResult: this.tableLastMove.noResult,
        itensPerPage: this.tableLastMove.itensPerPage,
        textPerPage: this.tableLastMove.textPerPage,
      };
    },
  },
  watch: {
    async localSelectedStock(value) {
      if (!value.id) return;
      await this.getSecondStock();
    },
    showModal(showModal) {
      this.$emit('showModal', showModal);
    },
  },
  components: {
    SelectPlaces,
    DataTable,
    CardModal,
  },
  created() {
    this.getStocksAndLastMoves();
  },
  props: ['idPlaceEdit'],
};
</script>
