<template>
  <v-layout>
    <v-flex>
      <LoadingCircle v-if="tableContent.isLoading" />
      <div v-else>
        <v-data-table
          :headers="tableContent.headerList"
          :items="tableContent.contentList"
          class="elevation-1"
          :rows-per-page-items="tableContent.itensPerPage"
          :rows-per-page-text="tableContent.textPerPage"
          disable-initial-sort
          :no-data-text="tableContent.noResult"
        >
          <template v-slot:items="props">
            <td v-for="(item, name, index) in props.item" :key="index">
              <v-tooltip v-if="name === 'minimumQuantity'" top>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    label="Definir quantidade mínima."
                    :value="item.value"
                    @input="
                      $emit('inputedMinimumQuantity', {
                        idProduct: item.idProduct,
                        minimumQuantity: $event,
                      })
                    "
                    class="suggestionProducts"
                    type="number"
                  ></v-text-field>
                </template>
                <span>{{ toolTipDetails.text }}</span>
              </v-tooltip>
              <p
                v-else-if="Array.isArray(item)"
                v-for="(subItem, subName, subKey) in item"
                :key="subKey"
              >
                {{ subItem }}
              </p>
              <p v-else-if="name === 'qrcode'">
                <i
                  :style="`cursor: pointer; font-size: 25pt;`"
                  @click="$emit('urlForQrCode', item.url)"
                  class="material-icons"
                >
                  {{ item.icon }}
                </i>
              </p>
              <p v-else-if="name === 'edit'">
                <i
                  :style="`cursor: pointer; font-size: 25pt;`"
                  @click="$emit('edit', item.index)"
                  class="material-icons"
                >
                  edit
                </i>
              </p>
              <!-- <p v-else-if="props.item.find((urlLink) => name)">{{ item }}</p> -->
              <p v-else @click="$emit('selectedItemIndex', props.index)">
                {{ item }}
              </p>
              <!-- <p v-else @click="$emit('selectedEquipmentId', props.item.id)">
                {{ item }}
              </p> -->
            </td>
          </template>
        </v-data-table>
      </div>
    </v-flex>
  </v-layout>
</template>
<script>
import LoadingCircle from '@/components/animations/LoadingCircle.vue';

export default {
  data: () => ({
    toolTipDetails: {
      icon: 'info',
      text: 'Você receberá uma notificação quando a quantidade mínima definida for atingida.',
      class: 'forTable',
      show: false,
    },
    minimumQuantity: '',
  }),
  methods: {
    hideColumn() {
      if (!this.hideColumnValue) {
        return;
      }
      this.tableContent.headerList = this.tableContent.headerList.filter(
        (element) => element.value !== this.hideColumnValue,
      );
      this.tableContent.contentList = this.tableContent.contentList.map((element) => {
        const cloneElement = Object.assign({}, element);
        delete cloneElement[this.hideColumnValue];
        return cloneElement;
      });
    },
  },
  components: {
    LoadingCircle,
  },
  watch: {
    tableContent() {
      this.hideColumn();
    },
  },
  created() {
    this.hideColumn();
    this.toolTipDetails.show = Boolean(
      this.tableContent.headerList.find((element) => element.value === 'minimumQuantity'),
    );
  },
  props: ['tableContent', 'hideColumnValue'],
};
</script>
