<template>
  <v-container>
    <FilterCard @clickOnReset="resetFilter()" :searchButton="false" :resetButton="true">
      <v-flex xs12 sm12 md6 xl6>
        <v-text-field
          class="mx-2"
          v-model="filterForm.type"
          padding
          label="Pesquise pelo tipo"
          id="searchType"
        />
      </v-flex>
      <v-flex xs12 sm12 md3 xl4>
        <v-text-field
          class="mx-2"
          v-model="filterForm.brand"
          id="brandSearchTerm"
          label="Pesquise pela marca"
        />
      </v-flex>
      <v-flex xs12 sm12 md3 xl2>
        <StatusSelect class="mx-2" id="searchStatus" v-model="filterForm.status" />
      </v-flex>
    </FilterCard>
    <CardList
      id="dataEquipments"
      @click="(element) => openModal(element)"
      :contentList="formatedListEquipments"
      :isLoading="isLoading"
    />

    <CardModal v-model="showModal" width="65%">
      <CreateEquipament :equipmentData="equipmentData" @showModal="showModal = $event" />
    </CardModal>
    <v-btn
      fab
      bottom
      right
      color="#A6CE39"
      dark
      fixed
      @click="openModal()"
      title="Novo"
      id="create"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import { getAllEquipmentDetails } from '@/services/EquipmentService';
import { filter, isStatusVisible } from '@/services/UtilsService';

import eventBus from '@/eventBus';

import CardList from '@/components/lists/CardList.vue';
import FilterCard from '@/components/filters/FilterCard.vue';
import StatusSelect from '@/components/selects/StatusSelect.vue';
import CreateEquipament from '@/components/equipments/EquipmentCreation.vue';
import CardModal from '@/components/modal/CardModal.vue';

export default {
  data: () => ({
    equipmentData: '',
    showModal: false,
    isLoading: false,
    filterForm: {
      type: '',
      brand: '',
      status: 'ACTIVE',
    },
    listEquipments: [],
    selectedStatus: 'ACTIVE',
  }),
  computed: {
    filteredEquipments() {
      const filteredByType = filter(this.listEquipments, 'type', this.filterForm.type);
      const filteredByBrand = filter(filteredByType, 'brand', this.filterForm.brand);
      return filteredByBrand.filter((element) =>
        isStatusVisible(this.filterForm.status, element.isDisabled),
      );
    },
    formatedListEquipments() {
      return {
        columns: [
          { text: 'Tipo', value: 'type', type: 'text' },
          { text: 'Marca', value: 'brand', type: 'text' },
          { text: 'Modelo', value: 'model', type: 'text' },
          { text: 'Pontência', value: 'power', type: 'text' },
          { text: 'Corrente', value: 'current', type: 'text' },
          { text: 'Tensão', value: 'tension', type: 'text' },
          { text: 'Diâmetro (cm)', value: 'diameter', type: 'text' },
          { text: 'Área (m²)', value: 'area', type: 'text' },
          { text: 'Carga (kg)', value: 'load', type: 'text' },
          { text: '', value: 'isDisabled', type: 'icon' },
        ],
        data: this.filteredEquipments.map((equipment) => ({
          type: equipment.type,
          brand: equipment.brand,
          model: equipment.model,
          power: equipment.power,
          current: equipment.current,
          tension: equipment.tension,
          diameter: equipment.diameter,
          area: equipment.area,
          load: equipment.load,
          isDisabled: equipment.isDisabled ? 'block' : 'done',
          id: equipment.id,
        })),
      };
    },
  },
  methods: {
    resetFilter() {
      this.filterForm = this.$options.data().filterForm;
    },
    openModal(equipmentData) {
      this.equipmentData = equipmentData;
      this.showModal = true;
    },
    async getListEquipments() {
      if (!this.listEquipments.length) {
        this.isLoading = true;
      }
      getAllEquipmentDetails(this.$apollo)
        .then((equipments) => {
          this.listEquipments = equipments;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getListEquipments();
    eventBus.onCloseModal(() => {
      this.getListEquipments();
    });
  },
  filters: {},
  components: {
    CardModal,
    CreateEquipament,
    StatusSelect,
    FilterCard,
    CardList,
  },
  props: [],
};
</script>
<style scoped>
</style>
