var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "title" }, [
        _vm._v("Cadastrar Equipamento no local")
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("equipmentSelect", {
                    staticStyle: { "margin-right": "25px" },
                    attrs: { id: "equipmentSelect" },
                    model: {
                      value: _vm.newEquipment.detailsId,
                      callback: function($$v) {
                        _vm.$set(_vm.newEquipment, "detailsId", $$v)
                      },
                      expression: "newEquipment.detailsId"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-text-field", {
                    staticStyle: { "margin-right": "25px" },
                    attrs: {
                      type: "number",
                      label: "ID",
                      id: "codeIdentifier"
                    },
                    model: {
                      value: _vm.newEquipment.codeIdentifier,
                      callback: function($$v) {
                        _vm.$set(_vm.newEquipment, "codeIdentifier", $$v)
                      },
                      expression: "newEquipment.codeIdentifier"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-text-field", {
                    staticStyle: { "margin-right": "25px" },
                    attrs: { label: "Descrição", id: "equipmentDescription" },
                    model: {
                      value: _vm.newEquipmentRegister.description,
                      callback: function($$v) {
                        _vm.$set(_vm.newEquipmentRegister, "description", $$v)
                      },
                      expression: "newEquipmentRegister.description"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", id: "selectStatus" } },
                [
                  _c("v-autocomplete", {
                    staticStyle: { "margin-right": "25px" },
                    attrs: {
                      autocomplete: "off",
                      "menu-props": "auto",
                      items: _vm.statusList,
                      "item-value": "value",
                      "item-text": "name",
                      label: "Status do equipamento"
                    },
                    model: {
                      value: _vm.newEquipmentRegister.status,
                      callback: function($$v) {
                        _vm.$set(_vm.newEquipmentRegister, "status", $$v)
                      },
                      expression: "newEquipmentRegister.status"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", md6: "" } },
                [
                  _c("datePicker", {
                    attrs: {
                      label: "Última manutenção",
                      id: "lastMaintenance"
                    },
                    model: {
                      value: _vm.newEquipmentRegister.lastMaintenance,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.newEquipmentRegister,
                          "lastMaintenance",
                          $$v
                        )
                      },
                      expression: "newEquipmentRegister.lastMaintenance"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", md6: "" } },
                [
                  _c("datePicker", {
                    attrs: {
                      label: "Próxima manutenção",
                      id: "nextMaintenance"
                    },
                    model: {
                      value: _vm.newEquipmentRegister.nextMaintenance,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.newEquipmentRegister,
                          "nextMaintenance",
                          $$v
                        )
                      },
                      expression: "newEquipmentRegister.nextMaintenance"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-textarea", {
                attrs: { label: "Observação", id: "observation" },
                model: {
                  value: _vm.newEquipmentRegister.observation,
                  callback: function($$v) {
                    _vm.$set(_vm.newEquipmentRegister, "observation", $$v)
                  },
                  expression: "newEquipmentRegister.observation"
                }
              })
            ],
            1
          ),
          !_vm.isClient
            ? _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("showModalNewRegister", false)
                        }
                      }
                    },
                    [_vm._v("\n        Fechar\n      ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled: !this.newEquipment.detailsId,
                        id: "buttonSaveEquipment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.saveEquipment()
                        }
                      }
                    },
                    [_vm._v("\n        Salvar\n      ")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }