import { query, mutation } from '@/services/GraphqlService';
import saveRatingMutation from '@/graphql/visitors/saveRatingMutation.gql';
import updateRatingMutation from '@/graphql/visitors/updateRatingMutation.gql';
import getRatingByPoolIdQuery from '@/graphql/visitors/getRatingByPoolIdQuery.gql';

export const saveRating = (apollo, variables) => mutation(apollo, saveRatingMutation, variables, { client: 'visitorApolloClient' });
export const updateRating = (apollo, variables) =>
  mutation(apollo, updateRatingMutation, variables, { client: 'visitorApolloClient' });
export const getRatingByPoolId = (apollo, variables) =>
  query(apollo, getRatingByPoolIdQuery, variables, { client: 'visitorApolloClient' });
