var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "pt-0" }, [
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "text-xs-center" },
          [
            _c("v-progress-circular", {
              attrs: { size: "35", indeterminate: "", color: "primary" }
            }),
            _c("br")
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "v-layout",
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _vm.isAdmin || _vm.isCoordinador || _vm.isFranchisee
                      ? _c("v-switch", {
                          staticStyle: { float: "right" },
                          attrs: {
                            label: _vm.isCheckListEnabled
                              ? "CheckList ativo"
                              : "CheckList inativo"
                          },
                          model: {
                            value: _vm.isCheckListEnabled,
                            callback: function($$v) {
                              _vm.isCheckListEnabled = $$v
                            },
                            expression: "isCheckListEnabled"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-layout",
              { attrs: { wrap: "" } },
              [
                _c("v-flex", { attrs: { xs12: "" } }, [
                  _c("h2", [_vm._v("Checklist de tarefas deste local")])
                ]),
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-toolbar",
                          { attrs: { flat: "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", md6: "" } },
                                  [
                                    _vm.isAdmin || _vm.isCoordinador
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "mx-0 mb-2",
                                            staticStyle: {
                                              float: "right",
                                              width: "100%"
                                            },
                                            attrs: {
                                              color: "primary",
                                              disabled: !_vm.isCheckListEnabled
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.showModalNewTask = true
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [_vm._v("add")]),
                                            _vm._v(
                                              "Adicionar tarefa\n                "
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-toolbar",
                          { attrs: { flat: "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("p", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      "\n                  Selecione tarefas que devem ser cumpridas pelo piscineiro ao finalizar uma\n                  visita.\n                "
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-list",
                          {
                            staticStyle: { overflow: "auto", height: "300px" },
                            attrs: { subheader: "", "two-line": "" }
                          },
                          [
                            _c("v-divider"),
                            _vm._l(_vm.optionList, function(content, index) {
                              return _c(
                                "v-list-tile",
                                {
                                  key: index,
                                  on: {
                                    click: function($event) {
                                      _vm.isCheckListEnabled
                                        ? (content.check = !content.check)
                                        : ""
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-tile-action",
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          disabled: !_vm.isCheckListEnabled,
                                          "input-value": content.check
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c("v-list-tile-title", [
                                        _vm._v(_vm._s(content.name))
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-tile-action",
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { "mx-3": "" } },
                                            [
                                              _vm.isAdmin || _vm.isCoordinador
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        disabled: !_vm.isCheckListEnabled,
                                                        icon: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.editOption(
                                                            index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("edit")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { "mx-3": "" } },
                                            [
                                              _vm.isAdmin || _vm.isCoordinador
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        disabled: !_vm.isCheckListEnabled
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.confirmRemoveOption(
                                                            index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("delete")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        ),
                        _c("v-divider"),
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-tile",
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      loading: _vm.isSaveLoading,
                                      color: "primary",
                                      disabled: !_vm.isCheckListEnabled
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.submitAllCheck()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Salvar\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "CardModal",
                  {
                    model: {
                      value: _vm.showModalNewTask,
                      callback: function($$v) {
                        _vm.showModalNewTask = $$v
                      },
                      expression: "showModalNewTask"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", { staticClass: "headline" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                this.formOption.id
                                  ? "Editar tarefa"
                                  : "Nova tarefa"
                              ) +
                              "\n          "
                          )
                        ]),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-form",
                              { ref: "form" },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: { label: "Tarefa" },
                                      model: {
                                        value: _vm.formOption.name,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formOption, "name", $$v)
                                        },
                                        expression: "formOption.name"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        label:
                                          "Adicionar à checklist do piscineiro"
                                      },
                                      model: {
                                        value: _vm.formOption.check,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formOption, "check", $$v)
                                        },
                                        expression: "formOption.check"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("v-flex", { attrs: { xs12: "" } })
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary darken-1",
                                  flat: "flat"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.showModalNewTask = false
                                  }
                                }
                              },
                              [_vm._v("\n              Fechar\n            ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary" },
                                on: {
                                  click: function($event) {
                                    _vm.formOption.id
                                      ? _vm.submitEditOption(_vm.formOption)
                                      : _vm.submitCreateOption(_vm.formOption)
                                  }
                                }
                              },
                              [_vm._v("\n              Salvar\n            ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "CardModal",
                  {
                    model: {
                      value: _vm.showModalConfirmRemove,
                      callback: function($$v) {
                        _vm.showModalConfirmRemove = $$v
                      },
                      expression: "showModalConfirmRemove"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", { staticClass: "title" }, [
                          _vm._v("Confirmar exclusão")
                        ]),
                        _c("v-card-text", [
                          _vm._v(
                            "\n            Você tem certeza que deseja excluir permanentemente esta opção?"
                          ),
                          _c("br"),
                          this.indexOptionRemove !== ""
                            ? _c("b", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      this.optionList[this.indexOptionRemove]
                                        .name
                                    ) +
                                    "\n            "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary", flat: "" },
                                on: {
                                  click: function($event) {
                                    _vm.showModalConfirmRemove = false
                                  }
                                }
                              },
                              [_vm._v("\n              Cancelar\n            ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeOption(
                                      _vm.indexOptionRemove
                                    )
                                  }
                                }
                              },
                              [_vm._v("\n              Excluir\n            ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-layout",
              [
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary", flat: "" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("showModal", false)
                      }
                    }
                  },
                  [_vm._v("\n        Fechar\n      ")]
                )
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }