<template>
  <v-card class="elevation-12">
    <v-toolbar dark color="secondary">
      <v-toolbar-title>Recuperar senha</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form>
        <v-text-field
          id="email"
          prepend-icon="mail_outline"
          name="login"
          label="Email"
          type="text"
          v-model="email"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        :loading="isLoading"
        color="primary"
        @click="requestPasswordReset(email)"
        id="buttonLogin"
        block
        class="mx-2 buttonFull"
      >
        Enviar email
      </v-btn>
    </v-card-actions>
    <v-card-actions>
      <a @click="$emit('component-change', 'Login')" id="forgotPassword" class="ml-2">Voltar</a>
    </v-card-actions>
  </v-card>
</template>
<script>
import requestPasswordResetMutation from '@/graphql/login/requestPasswordReset.gql';

export default {
  data: () => ({
    isLoading: false,
    email: '',
  }),
  methods: {
    requestPasswordReset(email) {
      this.isLoading = true;
      const mutation = requestPasswordResetMutation;
      const variables = { email };

      this.$root.$apolloProvider.defaultClient
        .mutate({
          mutation,
          variables,
        })
        .then(() => this.finish('Confira sua caixa de e-mail.'))
        .catch(() => this.finish('Erro. Tente novamente.'));
    },
    finish(message) {
      this.isLoading = false;
      this.$emit('textSnackbar', message);
    },
  },
};
</script>
