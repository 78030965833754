<template>
  <v-container>
    <FilterCard
      @clickOnReset="resetFilter()"
      :searchButton="true"
      @clickOnSearch="loadAnalyzes()"
      :resetButton="true"
    >
      <v-flex xs12 md6 lg5>
        <v-text-field class="mx-2" label="Nome do colaborador" v-model="filter.employeeName" />
      </v-flex>
      <v-flex xs12 md6 lg5>
        <v-text-field class="mx-2" label="Nome do Local" v-model="filter.localName" />
      </v-flex>
      <v-flex v-if="!isClient" xs12 md4 lg2 id="searchByStatus">
        <v-select
          class="mx-2"
          menu-props="auto"
          :items="statusList"
          v-model="status"
          label="Status"
          item-text="name"
          item-value="value"
        />
      </v-flex>
      <v-flex v-if="isAdmOrCoord" xs12 md4 lg4>
        <v-select
          class="mx-2"
          menu-props="auto"
          :items="isRevisedItems"
          v-model="filter.isRevised"
          label="utilizado no treinamento"
          item-text="name"
          item-value="value"
        />
      </v-flex>
      <v-flex xs12 md4 lg4>
        <datePicker class="mx-2" label="Data inicial" v-model="filter.startDate"></datePicker>
      </v-flex>
      <v-flex xs12 md4 lg4>
        <datePicker class="mx-2" label="Data final" v-model="filter.endDate"></datePicker>
      </v-flex>
    </FilterCard>
    <CardList
      :hasClickedPainting="true"
      :isDescending="true"
      sortBy="isoDate"
      :isLoading="isLoading"
      @click="(analyze) => openModal(analyze.id)"
      :contentList="filteredAnalyzeTable"
    />
    <CardModal :removeURLParams="true" v-model="showModal" width="65%">
      <AnalyzeCreation
        @reloadList="() => loadAnalyzes()"
        :analyzeId="selectedAnalyzeId"
        @showModal="showModal = $event"
      />
    </CardModal>
  </v-container>
</template>

<script>
import * as moment from 'moment';
import FilterCard from '@/components/filters/FilterCard.vue';
import datePicker from '@/components/date-time/DatePicker.vue';
import { getAnalyzes, getLastAnalyze } from '@/services/AnalyzeService';
import CardList from '@/components/lists/CardList.vue';
import CardModal from '@/components/modal/CardModal.vue';
import { normalizeString, isRole } from '@/services/UtilsService';
import AnalyzeCreation from '@/components/analyzes/AnalyzeCreation.vue';

export default {
  data: () => ({
    lastAnalyzeId: '',
    isClient: isRole('CLIENT'),
    selectedAnalyzeId: '',
    audio: new Audio('/assets/sounds/alert.mp3'),
    showModal: false,
    isLoading: false,
    analyzeTableData: [],
    status: 'OPEN',
    interval: '',
    statusList: [
      { name: 'Todas', value: 'ALL' },
      { name: 'Aplicada', value: 'APPLIED' },
      { name: 'Aberta', value: 'OPEN' },
      { name: 'Sugerida', value: 'SUGGESTED' },
      { name: 'Sem aplicação', value: 'WITHOUT_SUGGESTED' },
    ],
    analyzeTableColumns: [
      { text: 'Data', value: 'date', type: 'text' },
      { text: 'Colaborador', value: 'employeeName', type: 'text' },
      { text: 'Local', value: 'local', type: 'text' },
      { text: 'Piscina', value: 'pool', type: 'text' },
      { text: 'Status', value: 'status', type: 'text' },
      { text: 'Foto início', value: 'photoUrl', type: 'photo' },
      { text: 'Foto fita', value: 'urlTestStripPhotos', type: 'photo' },
    ],
    isRevisedItems: [
      { name: 'Todas', value: 'ALL' },
      { name: 'Sim', value: true },
      { name: 'Não', value: false },
    ],
    filter: {
      employeeName: '',
      localName: '',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      type: null,
      isRevised: 'ALL',
    },
  }),
  components: {
    AnalyzeCreation,
    CardModal,
    datePicker,
    CardList,
    FilterCard,
  },
  methods: {
    playSound() {
      if (this.isAdmOrCoord()) {
        this.audio.muted = false;
        this.audio.load();
        this.audio.play();
        setTimeout(() => {
          this.audio.load();
          this.audio.play();
        }, 1500);
      }
    },
    isAdmOrCoord() {
      return isRole('ADMIN') || isRole('COORDINADOR');
    },
    verifyNewAnalysis() {
      getLastAnalyze(this.$apollo).then((resp) => {
        if (
          this.lastAnalyzeId &&
          resp.analyzes.length &&
          this.lastAnalyzeId !== resp.analyzes[0].id
        ) {
          this.lastAnalyzeId = resp.analyzes[0].id;
          this.playSound();
          this.loadAnalyzes();
        } else if (resp.analyzes.length && !this.lastAnalyzeId) {
          this.lastAnalyzeId = resp.analyzes[0].id;
        }
      });
    },
    openModal(id) {
      this.selectedAnalyzeId = id;
      this.showModal = true;
    },
    setAnalyzeTableData(analyzes) {
      this.analyzeTableData = analyzes.map((analyze) => ({
        date: moment(analyze.createDay).format('DD/MM/YY HH:mm'),
        isoDate: analyze.createDay,
        employeeName: analyze.employee ? analyze.employee.name : '',
        local: analyze.pool.local ? analyze.pool.local.localName : '',
        pool: analyze.pool.name,
        status: this.statusList.find((status) => status.value === analyze.status).name,
        id: analyze.id,
        photoUrl: analyze.urlPhotos[0],
        urlTestStripPhotos: analyze.urlTestStripPhotos[0],
      }));
    },
    resetFilter() {
      this.filter = this.$options.data().filter;
    },
    async loadAnalyzes() {
      this.setAnalyzeTableData([]);
      this.isLoading = true;
      const variables = {
        data: {
          ...(this.status !== 'ALL' ? { status: this.status } : {}),
          createDay_gte: moment(this.filter.startDate)
            .startOf('day')
            .toISOString(),
          createDay_lte: moment(this.filter.endDate)
            .endOf('day')
            .toISOString(),
          ...{ isRevised: this.filter.isRevised === 'ALL' ? undefined : this.filter.isRevised },
        },
      };
      getAnalyzes(this.$apollo, variables, this.isClient && localStorage.userId)
        .then((resp) => this.setAnalyzeTableData(resp))
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    filteredAnalyzeTable() {
      return {
        columns: this.analyzeTableColumns,
        data: this.analyzeTableData
          .filter((data) =>
            normalizeString(data.employeeName).includes(normalizeString(this.filter.employeeName)),
          )
          .filter((data) =>
            normalizeString(data.local).includes(normalizeString(this.filter.localName)),
          ),
      };
    },
  },
  created() {
    this.interval = setInterval(this.verifyNewAnalysis, 30000);
    this.loadAnalyzes();
  },
  mounted() {
    if (window.location.href.includes('?id=')) {
      const analyzeId = this.$router.app.$route.fullPath.split('?id=')[1];
      this.openModal(analyzeId);
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style></style>
