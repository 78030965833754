import { render, staticRenderFns } from "./MonitoringDashboard.vue?vue&type=template&id=848cfb56&slot-scope=%7B%20result%3A%20%7B%20loading%2C%20error%2C%20data%20%7D%20%7D&"
import script from "./MonitoringDashboard.vue?vue&type=script&lang=js&"
export * from "./MonitoringDashboard.vue?vue&type=script&lang=js&"
import style0 from "./MonitoringDashboard.vue?vue&type=style&index=0&id=848cfb56&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/piscinaFacilDev/piscinapp-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('848cfb56')) {
      api.createRecord('848cfb56', component.options)
    } else {
      api.reload('848cfb56', component.options)
    }
    module.hot.accept("./MonitoringDashboard.vue?vue&type=template&id=848cfb56&slot-scope=%7B%20result%3A%20%7B%20loading%2C%20error%2C%20data%20%7D%20%7D&", function () {
      api.rerender('848cfb56', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/monitoring/MonitoringDashboard.vue"
export default component.exports