var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.selectList.length
    ? _c("v-autocomplete", {
        attrs: {
          autocomplete: "off",
          "menu-props": "auto",
          disabled: _vm.disabled || _vm.selectList.length === 1,
          loading: _vm.isLoading,
          items: _vm.selectList,
          value: _vm.value,
          label: "Selecione a franquia *",
          "item-text": "name",
          "item-value": "value",
          rules: _vm.rules
        },
        on: {
          change: function($event) {
            return _vm.$emit("input", $event)
          }
        }
      })
    : _c("p", [_vm._v("Não foi encontrada nenhuma franquia.")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }