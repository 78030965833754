<template>
  <v-select
    id="searchStatus"
    menu-props="auto"
    :items="statusList"
    @change="$emit('input', $event)"
    :value="value"
    label="Pesquise pelo status"
    item-text="name"
    item-value="value"
  />
</template>

<script>
export default {
  data: () => ({
    statusList: [
      { name: 'Ativo', value: 'ACTIVE' },
      { name: 'Inativo', value: 'INACTIVE' },
      { name: 'Todos', value: 'ALL' },
    ],
  }),
  created() {
    this.$emit('input', this.default ? this.default : 'ACTIVE');
  },
  props: ['value'],
};
</script>
