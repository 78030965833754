<template>
  <div>
    <v-card>
      <v-layout wrap>
        <v-flex xs12 md4>
          <v-img
            :style="`cursor: pointer;`"
            min-width="100px"
            class="ma-2"
            :lazy-src="analyze.urlPhotos[0]"
            :src="analyze.urlPhotos[1] || analyze.urlPhotos[0]"
            @click="
              () => {
                if (!isMobile) {
                  clickedPhotos = analyze.urlPhotos;
                  dialog = !dialog;
                }
              }
            "
          />
        </v-flex>
        <v-flex xs12 md4>
          <v-img
            v-if="analyze.completionUrlPhotos.length"
            :style="`cursor: pointer;`"
            min-width="100px"
            class="ma-2"
            :lazy-src="analyze.completionUrlPhotos[0]"
            :src="analyze.completionUrlPhotos[1] || analyze.completionUrlPhotos[0]"
            @click="
              () => {
                if (!isMobile) {
                  clickedPhotos = analyze.completionUrlPhotos;
                  dialog = !dialog;
                }
              }
            "
          />
        </v-flex>
        <v-flex xs12 md4>
          <v-img v-if="analyze.urlTestStripPhotos.length"
            :style="`cursor: pointer;`"
            min-width="100px"
            class="ma-2"
            :lazy-src="analyze.urlTestStripPhotos[0]"
            :src="analyze.urlTestStripPhotos[0]"
            @click="
              () => {
                if (!isMobile) {
                  clickedPhotos = analyze.urlTestStripPhotos;
                  dialog = !dialog;
                }
              }
            "
          />
        </v-flex> </v-layout
    ></v-card>
    <v-dialog v-model="dialog">
      <v-card :width="windowHeight * 0.8">
        <v-img
          v-if="dialog"
          :lazy-src="clickedPhotos[1] || clickedPhotos[0]"
          :src="clickedPhotos[2] || clickedPhotos[1] || clickedPhotos[0]"
          id="bigImage"
        />
        <v-card-actions>
          <v-btn @click="dialog = !dialog" color="primary">
            Voltar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    clickedPhotos: [],
    dialog: false,
    isMobile: window.innerWidth < 960,
    windowHeight: window.innerHeight,
  }),
  computed: {},
  methods: {},
  created() {},
  filters: {},
  components: {},
  props: ['analyze'],
};
</script>

<style scoped>
#bigImage {
  width: 100%;
}
</style>
