<template>
  <div class="delete-user-container">
    <v-card class="elevation-12">
      <v-toolbar dark color="secondary">
        <v-toolbar-title><v-icon left>delete</v-icon>Excluir Conta</v-toolbar-title>
      </v-toolbar>
      <div class="delete-user-message">
        <p>Para excluir sua conta, digite seu email e senha.</p>
        <p>Essa ação não pode ser desfeita.</p>
      </div>
      <v-card-text>
        <v-form>
          <v-text-field
            id="email"
            prepend-icon="mail_outline"
            name="login"
            label="Email"
            type="text"
            v-model="email"
            @keyup.enter="login()"
          ></v-text-field>
          <v-text-field
            id="password"
            prepend-icon="lock"
            name="password"
            label="Senha"
            type="password"
            v-model="password"
            @keyup.enter="login()"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :loading="isLoading"
          @click="login()"
          id="deleteUserButton"
          block
          class="mx-2 buttonFull"
          >Excluir</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-snackbar id="snackbar" v-model="snackbar" :right="true" :timeout="timeout">
      {{ textSnackbar }}
      <v-btn color="blue" flat @click="snackbar = false">Fechar</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import loginMutation from '@/graphql/login/login.gql';
import deleteUserMutation from '@/graphql/user/deleteUser.gql';

export default {
  data: () => ({
    isLoading: false,
    email: '',
    password: '',
    textSnackbar: '',
    deletUserMutation: '',
    timeout: 18000,
    snackbar: false,
    isFormShown: false,
  }),
  methods: {
    async login() {
      this.isLoading = true;
      const variables = { email: this.email, password: this.password };

      this.$root.$apolloProvider.defaultClient
        .mutate({
          mutation: loginMutation,
          variables,
        })
        .then(async (resp) => {
          if (resp.data.authenticateUser === null || !resp.data) {
            return;
          }

          const token = resp.data.authenticateUser.token;
          localStorage.setItem('token', token);
          const id = resp.data.authenticateUser.userId;
          this.$root.$apolloProvider.defaultClient
            .mutate({
              mutation: deleteUserMutation,
              variables: { id },
            })
            .then(() => {
              this.isLoading = false;
              this.textSnackbar = 'Conta excluída com sucesso.';
              this.snackbar = true;
              this.email = '';
              this.password = '';
              localStorage.removeItem('token');
            })
            .catch((error) => {
              this.isLoading = false;
              this.textSnackbar =
                {
                  'GraphQL error: Error: Unauthorized, wrong email or password!':
                    'E-mail ou senha Inválidos, tente novamente.',
                  'GraphQL error: Error: Unauthorized, this user is disabled.':
                    'Usuário desativado. Entre em contato conosco.',
                  'Network error: Failed to fetch': 'Verifique sua conexão com a internet.',
                  'GraphQL error: Error: Too many consecutive attempts.':
                    'Muitas tentativas de login mal-sucedidas, por favor, aguarde alguns minutos antes de tentar novamente.',
                }[error.message] || 'Não foi possivel efetuar o login.';
              this.snackbar = true;
            });
        })
        .catch((error) => {
          this.isLoading = false;
          this.textSnackbar =
            {
              'GraphQL error: Error: Unauthorized, wrong email or password!':
                'E-mail ou senha Inválidos, tente novamente.',
              'GraphQL error: Error: Unauthorized, this user is disabled.':
                'Usuário desativado. Entre em contato conosco.',
              'Network error: Failed to fetch': 'Verifique sua conexão com a internet.',
              'GraphQL error: Error: Too many consecutive attempts.':
                'Muitas tentativas de login mal-sucedidas, por favor, aguarde alguns minutos antes de tentar novamente.',
            }[error.message] || 'Não foi possivel efetuar o login.';
          this.snackbar = true;
        });
    },
  },
};
</script>

<style scoped>
.delete-user-message {
  padding: 20px;
  text-align: center;
  color: #f44336;
  font-size: 16px;
  font-weight: 500;
}

.delete-user-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1482c4;
  width: 100%;
  height: 100vh;
}

#deleteUserButton {
  background-color: #f44336;
  color: white;
  margin: 10px 0;
}
</style>
