import { query, mutation } from '@/services/GraphqlService';

import chatMessagesQuery from '@/graphql/chatMessages/chatMessagesQuery.gql';
import chatMessageCreationMutation from '@/graphql/chatMessages/chatMessageCreationMutation.gql';
import markAsReadMutation from '@/graphql/chatMessages/markAsReadMutation.gql';
import supportChatsQuery from '@/graphql/chatMessages/supportChatsQuery.gql';
import supportChatsCountQuery from '@/graphql/chatMessages/supportChatsCountQuery.gql';

export const getChatMessages = (apollo, variables) => query(apollo, chatMessagesQuery, variables);

export const createChatMessage = (apollo, variables) =>
  mutation(apollo, chatMessageCreationMutation, variables);

export const markAsRead = (apollo, variables) => mutation(apollo, markAsReadMutation, variables);

export const getSupportChats = (apollo) => query(apollo, supportChatsQuery);

export const getSupportChatsCount = (apollo) =>
  query(apollo, supportChatsCountQuery).then((res) => res.supportChatsConnection.aggregate);
