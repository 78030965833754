<template>
  <div>
    <v-flex xs12>
      <v-toolbar :clipped-left="$vuetify.breakpoint.lgAndUp" color="#0D1824" dark app fixed>
        <v-layout>
          <v-flex xs6>
            <a href="https://piscinapp.com.br/">
              <img src="/assets/img/logo-piscinapp-35px.png" style="margin-top: 6px" />
            </a>
          </v-flex>
        </v-layout>
      </v-toolbar>
    </v-flex>
    <v-container fluid>
      <v-layout v-if="errorMessage"
        ><v-spacer /><v-card max-width="500" class="ma-4 pa-3">
          <v-layout
            ><b>{{ errorMessage }}</b
            ><v-icon color="red" class="ml-2" large>error</v-icon></v-layout
          > </v-card
        ><v-spacer
      /></v-layout>
      <v-layout v-if="pools.length" row wrap>
        <v-flex xl2 sm6 md4 v-for="(pool, index) in pools" :key="index">
          <v-card class="ma-1 v-flex" width="300">
            <v-img class="center-cropped" height="150px" :src="pool.analyze[0] | photoUrl"></v-img>
            <v-card-text class="pt-2 pl-2 pb-0">
              <b>Descrição: {{ pool.name }}</b>
              <div>Volume: {{ pool.volume }}</div>
            </v-card-text>

            <v-card-actions>
              <v-btn :href="pool.id | formatUrl" block text color="blue">
                ver o resumo da limpeza
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
      <LoadingCircle class="mt-3" v-else-if="!errorMessage" />
    </v-container>
  </div>
</template>

<script>
import { getPoolByLocalId } from '@/services/PlacesService';
import LoadingCircle from '@/components/animations/LoadingCircle.vue';
import { setVisitorToken } from '../../vue-apollo';

export default {
  data: () => ({
    errorMessage: '',
    isLoading: true,
    localId: '',
    pools: [],
  }),
  computed: {},
  methods: {
    async queryPoolsByPlaceId() {
      const resp = await getPoolByLocalId(this.$apollo, { id: this.localId }).catch((error) => {
        if (error.message === 'Network error: Failed to fetch') {
          this.errorMessage =
            'Não foi possível conectar ao servidor, verifique sua conexão com a internet.';
        }
      });
      this.pools = (resp && resp.local.pools) || [];
      this.isLoading = false;
    },
  },
  async created() {
    const VISITOR_TOKEN = process.env.VUE_APP_VISITOR_TOKEN;
    await setVisitorToken(VISITOR_TOKEN);
    this.localId = window.location.pathname.split('/')[3];
    this.queryPoolsByPlaceId();
  },
  filters: {
    photoUrl(analyze) {
      if (!analyze) return '';
      return (
        analyze.completionUrlPhotos[2] ||
        analyze.completionUrlPhotos[1] ||
        analyze.completionUrlPhotos[0] ||
        analyze.urlPhotos[2] ||
        analyze.urlPhotos[1] ||
        analyze.urlPhotos[0]
      );
    },
    formatUrl(poolId) {
      let integerUrl = window.location.protocol;
      integerUrl += '//';
      integerUrl += window.location.host;
      integerUrl += '/visitante/piscina/';
      integerUrl += poolId;

      return integerUrl;
    },
  },
  components: {
    LoadingCircle,
  },
  props: [],
};
</script>

<style scoped>
.center-cropped {
  width: 300px;
  height: 150px;
  background-position: center center;
  background-repeat: no-repeat;
}
</style>
