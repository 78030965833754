var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "chatsContainer", staticClass: "container" },
    _vm._l(_vm.chats, function(chat) {
      return _c(
        "div",
        {
          key: chat.id,
          staticClass: "chat-info-wrapper",
          class: {
            "current-chat": chat.isShown
          },
          on: {
            click: function($event) {
              return _vm.onChatClick(chat.originId)
            }
          }
        },
        [
          _c("div", { staticClass: "chat-info" }, [
            _c("div", { staticClass: "sent-at" }, [
              _vm._v(_vm._s(_vm._f("formatDate")(chat.lastMessage.sentIn)))
            ]),
            _c("div", { staticClass: "chat-last-message" }, [
              _c("div", { staticClass: "last-message-text" }, [
                _vm._v(
                  _vm._s(_vm._f("chatMessagePreview")(chat.lastMessage.body))
                )
              ]),
              !chat.lastMessage.isRead && chat.lastMessage.isFromUser
                ? _c("div", { staticClass: "unread-message-dot" })
                : _vm._e()
            ])
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }