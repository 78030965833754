<template>
  <v-container>
    <v-card>
      <v-toolbar class="toolbar" color="#0d1824" dark flat>
        <v-toolbar-title class="toolbar-title">Ofertas</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon outline @click="isModalShown = true">
          <v-icon>add</v-icon>
        </v-btn>
      </v-toolbar>
      <v-spacer></v-spacer>
      <v-card-text>
        <v-data-table
          :headers="[
            { text: 'Produto', value: 'classification' },
            { text: 'Link', value: 'link' },
            { text: 'Criação', value: 'createdAt' },
            { text: '', value: 'actions', sortable: false, align: 'right', width: '20px' },
          ]"
          :items="offers"
          :items-per-page="5"
          class="elevation-0"
          :no-data-text="isLoading ? 'Carregando...' : 'Não há ofertas a serem exibidas'"
          rows-per-page-text="Itens por página"
          :rows-per-page-items="[10, 20, 50]"
          :loading="isLoading"
        >
          <template v-slot:items="props">
            <td>{{ props.item.classification | classificationText }}</td>
            <td @click="openOfferPage(props.item)" class="product-link">{{ props.item.link }}</td>
            <td>{{ props.item.createdAt | formatDate }}</td>
            <td>
              <v-btn @click="deleteOffer(props.item.id)" icon small>
                <v-icon color="red" size="20px">delete</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <CardModal width="480" style="position: fixed;" v-model="isModalShown">
      <v-card>
        <v-toolbar color="#0d1824" dark flat>
          <v-toolbar-title>Adicionar oferta</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="padding-modal">
          <v-flex>
            <v-select
              full-width
              v-model="form.classification"
              :items="productClassifications"
              label="Produto"
            ></v-select>
            <v-text-field v-model="form.link" label="Link" type="text"></v-text-field>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary darken-1" flat="flat" @click="isModalShown = false">
            Fechar
          </v-btn>
          <v-btn
            :loading="isLoading"
            @click="createOffer()"
            color="primary"
            :disabled="!form.classification || !isLinkValid"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </CardModal>
  </v-container>
</template>

<script>
import { createOffer, getOffers, deleteOffer } from '@/services/ConfigsService';
import CardModal from '@/components/modal/CardModal.vue';

export default {
  name: 'Configs',
  components: {
    CardModal,
  },
  data() {
    return {
      productClassifications: [
        { text: 'Elevador de alcalinidade', value: 'ELEVADOR_DE_ALCALINIDADE' },
        { text: 'Cloro', value: 'CLORO' },
        { text: 'Pastilha de tricloro', value: 'PASTILHA_DE_TRICLORO' },
        { text: 'Decantador', value: 'DECANTADOR' },
        { text: 'Algicida de choque', value: 'ALGICIDA_DE_CHOQUE' },
        { text: 'Algicida de manutenção', value: 'ALGICIDA_DE_MANUTENCAO' },
        { text: 'Ácido redutor de pH', value: 'ACIDO_REDUTOR_DE_PH' },
        { text: 'Barrilha', value: 'BARRILHA' },
      ],
      offers: [],
      form: {
        classification: '',
        link: '',
      },
      isModalShown: false,
      isLoading: true,
    };
  },
  computed: {
    isLinkValid() {
      return this.form.link && this.form.link.match(/^https:\/\/.+/);
    },
  },
  methods: {
    async createOffer() {
      this.isLoading = true;
      const resp = await createOffer(this.$apollo, this.form);
      this.offers.push(resp.createOffer);
      this.form = {
        classification: '',
        link: '',
      };
      this.isModalShown = false;
      this.isLoading = false;
    },
    async deleteOffer(id) {
      this.isLoading = true;
      this.offers.splice(
        this.offers.findIndex((offer) => offer.id === id),
        1,
      );
      await deleteOffer(this.$apollo, { id });
      this.isLoading = false;
    },
    async getOffers() {
      this.resp = await getOffers(this.$apollo);
      this.offers = this.resp.offers;
      this.isLoading = false;
    },
    openOfferPage(item) {
      window.open(item.link, '_blank');
    },
  },
  mounted() {
    this.getOffers();
  },
  filters: {
    formatDate(value) {
      if (!value) return '';
      const date = new Date(value);
      return date.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    },
    classificationText(value) {
      if (!value) return '';
      const productClassifications = {
        ELEVADOR_DE_ALCALINIDADE: 'Elevador de alcalinidade',
        CLORO: 'Cloro',
        PASTILHA_DE_TRICLORO: 'Pastilha de tricloro',
        DECANTADOR: 'Decantador',
        ALGICIDA_DE_CHOQUE: 'Algicida de choque',
        ALGICIDA_DE_MANUTENCAO: 'Algicida de manutenção',
        ACIDO_REDUTOR_DE_PH: 'Ácido redutor de pH',
        BARRILHA: 'Barrilha',
      };

      return productClassifications[value];
    },
  },
};
</script>

<style scoped>
.product-link {
  cursor: pointer;
  text-decoration: underline;
  color: #0d1824;
}
</style>
