var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading
    ? _c("LoadingCircle", { staticClass: "ma-5" })
    : _c(
        "v-card",
        { staticClass: "mt-4", attrs: { "justify-center": "" } },
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              pagination: _vm.pagination,
              "hide-actions": "",
              headers: _vm.contentList.columns,
              items: _vm.contentList.data,
              "no-data-text": "Não há dados a serem exibidos"
            },
            on: {
              "update:pagination": function($event) {
                _vm.pagination = $event
              }
            },
            scopedSlots: _vm._u(
              [
                _vm.isTemplateVisible
                  ? {
                      key: "items",
                      fn: function(props) {
                        return _vm._l(_vm.contentList.columns, function(
                          column
                        ) {
                          return _c(
                            "td",
                            {
                              key: column.value,
                              class: _vm.itemClasses[props.item.id],
                              style: `cursor: pointer;`,
                              on: {
                                click: () => {
                                  if (_vm.hasClickedPainting) {
                                    _vm.paintItem(props.item)
                                  }
                                  _vm.$emit("click", props.item)
                                }
                              }
                            },
                            [
                              column.type === "text"
                                ? _c("p", [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(props.item[column.value]) +
                                        "\n        "
                                    )
                                  ])
                                : _vm._e(),
                              column.type === "icon"
                                ? _c("v-icon", [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(props.item[column.value]) +
                                        "\n        "
                                    )
                                  ])
                                : _vm._e(),
                              column.type === "photo"
                                ? _c(
                                    "div",
                                    [
                                      props.item[column.value]
                                        ? _c("v-img", {
                                            staticStyle: {
                                              "margin-top": "5px"
                                            },
                                            attrs: {
                                              "aspect-ratio": "2",
                                              src: props.item[column.value],
                                              width: "50",
                                              height: "50"
                                            }
                                          })
                                        : _c(
                                            "i",
                                            {
                                              staticClass: "material-icons",
                                              staticStyle: {
                                                "margin-left": "12px"
                                              }
                                            },
                                            [_vm._v("visibility_off")]
                                          )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        })
                      }
                    }
                  : null
              ],
              null,
              true
            )
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }