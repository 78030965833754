<template>
  <v-app id="login">
    <v-content class="back">
      <v-container grid-list-sm fluid fill-height>
        <v-layout wrap align-center justify-center>
          <v-flex xs12 sm8 md4>
            <component
              :is="showComponent"
              @component-change="showComponent = $event"
              @textSnackbar="
                (event) => {
                  textSnackbar = event;
                  snackbar = true;
                }
              "
              :idPasswordReset="idPasswordReset"
            />
          </v-flex>
        </v-layout>
      </v-container>
      <v-snackbar id="snackbar" v-model="snackbar" :right="true" :timeout="timeout">
        {{ textSnackbar }}
        <v-btn color="blue" flat @click="snackbar = false">Fechar</v-btn>
      </v-snackbar>
    </v-content>
    <v-btn
      fab
      large
      fixed
      bottom
      right
      href="https://play.google.com/store/apps/details?id=com.trycode.piscinapp"
      target="_blank"
    >
      <div class="playstore-icon">
        <v-img src="/assets/img/playstore.svg" />
      </div>
    </v-btn>
  </v-app>
</template>

<script>
import { AUTH_TOKEN } from '@/vue-apollo';
import { rewriteUrl } from '@/services/UtilsService';

import Login from '@/components/authetication/Login.vue';
import LimitedUserCreation from '@/components/user/LimitedUserCreation.vue';
import ClientUserCreation from '@/components/user/ClientUserCreation.vue';
import RequestPasswordReset from '@/components/authetication/RequestPasswordReset.vue';
import ChangePassword from '@/components/authetication/ChangePassword.vue';

export default {
  data: () => ({
    showComponent: 'Login',
    drawer: null,
    snackbar: false,
    textSnackbar: '',
    timeout: 6000,
    userName: '',
    email: '',
    password: '',
    container: 'login',
    idPasswordReset: '',
  }),
  methods: {},
  components: {
    Login,
    LimitedUserCreation,
    RequestPasswordReset,
    ChangePassword,
  },
  created() {
    if (localStorage.getItem(AUTH_TOKEN)) {
      rewriteUrl(this.$router, this.$route, 'analises');
    }

    const urlPart = this.$router.app.$route.path.split('/');
    if (urlPart[1] === 'changePassword' && urlPart[2]) {
      this.idPasswordReset = urlPart[2];
      this.showComponent = ChangePassword;
    } else if (urlPart[1] === 'cadastro') {
      this.showComponent = ClientUserCreation;
    }
  },
  props: {
    source: String,
  },
};
</script>

<style>
.back {
  background-image: url(/assets/img/background/bg2.jpg);
  background-color: darkturquoise;
  background-size: cover;
}
#inspire {
  background: none;
}
.buttonFull {
  height: 50px;
  font-size: 20px;
  border-radius: 5px;
}
.playstore-icon {
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
