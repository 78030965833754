import MonitoringList from '@/components/monitoring/MonitoringList.vue';
import { log } from '@/services/LogService';
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import App from './App.vue';
import './plugins/vuetify';
import router from './router';
import { createProvider } from './vue-apollo';

Vue.config.productionTip = false;
const apolloProvider = createProvider();
Vue.config.errorHandler = (error) => {
  log(apolloProvider.defaultClient, error, 'error');
};

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBsu7zLSMrEB7tc377iC4D60Okod1yF1U0',
    libraries: 'places',
  },
});
GmapCluster.props.styles.default = () => ([
  {
    textColor: 'balck',
    url: '/assets/icons/m1.png',
    height: 52,
    width: 53,
  },
  {
    textColor: 'balck',
    url: '/assets/icons/m2.png',
    height: 55,
    width: 56,
  },
  {
    textColor: 'balck',
    url: '/assets/icons/m3.png',
    height: 65,
    width: 66,
  },
  {
    textColor: 'balck',
    url: '/assets/icons/m4.png',
    height: 77,
    width: 78,
  },
  {
    textColor: 'balck',
    url: '/assets/icons/m5.png',
    height: 89,
    width: 90,
  },
]);

Vue.component('GmapCluster', GmapCluster);
Vue.component('clock-check', MonitoringList);

new Vue({
  router,
  apolloProvider,
  render: h => h(App),
}).$mount('#app');
