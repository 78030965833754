var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "listReports" } },
    [
      _c(
        "FilterCard",
        {
          attrs: { resetButton: true },
          on: {
            clickOnReset: function($event) {
              return _vm.resetFilter()
            }
          }
        },
        [
          _c(
            "v-flex",
            { attrs: { id: "selectType", xs12: "", md6: "", xl3: "" } },
            [
              _c("v-autocomplete", {
                staticClass: "mx-2",
                attrs: {
                  autocomplete: "off",
                  "menu-props": "auto",
                  items: _vm.isClient ? _vm.typesForClients : _vm.types,
                  label: "Selecione o tipo",
                  "item-text": "name",
                  "item-value": "value"
                },
                on: {
                  change: function($event) {
                    _vm.fileFormat = "CSV"
                  }
                },
                model: {
                  value: _vm.type,
                  callback: function($$v) {
                    _vm.type = $$v
                  },
                  expression: "type"
                }
              })
            ],
            1
          ),
          _vm.type === "ANALYZE"
            ? _c(
                "v-flex",
                { attrs: { xs12: "", md6: "", xl3: "" } },
                [
                  _c("v-autocomplete", {
                    staticClass: "mx-2",
                    attrs: {
                      autocomplete: "off",
                      "menu-props": "auto",
                      items: _vm.formats,
                      label: "Selecione formato",
                      "item-text": "name",
                      "item-value": "value"
                    },
                    model: {
                      value: _vm.fileFormat,
                      callback: function($$v) {
                        _vm.fileFormat = $$v
                      },
                      expression: "fileFormat"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          (_vm.isAdmin || _vm.isCoodinador) && _vm.fileFormat !== "PDF"
            ? _c(
                "v-flex",
                { attrs: { xs12: "", md6: "", xl3: "" } },
                [
                  _c("franchiseeSelect", {
                    staticClass: "mx-2",
                    attrs: {
                      id: "selectFranchisee",
                      allDataOption:
                        _vm.type !== "DAILY_TRATAMENT" &&
                        _vm.type !== "POOL_CONDITIONS"
                    },
                    model: {
                      value: _vm.selectedFranchisee,
                      callback: function($$v) {
                        _vm.selectedFranchisee = $$v
                      },
                      expression: "selectedFranchisee"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.type === "DAILY_TRATAMENT" || _vm.type === "POOL_CONDITIONS"
            ? _c(
                "v-flex",
                { attrs: { xs12: "", md6: "", xl3: "" } },
                [
                  _c("placeSelect", {
                    staticClass: "mx-2",
                    attrs: {
                      franchisee: _vm.selectedFranchisee,
                      client: _vm.selectedClient,
                      id: "selectPlace"
                    },
                    model: {
                      value: _vm.selectedPlace,
                      callback: function($$v) {
                        _vm.selectedPlace = $$v
                      },
                      expression: "selectedPlace"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.type === "DAILY_TRATAMENT" || _vm.type === "POOL_CONDITIONS"
            ? _c(
                "v-flex",
                { attrs: { xs12: "", md6: "", xl3: "" } },
                [
                  _c("poolSelect", {
                    staticClass: "mx-2",
                    attrs: { localId: _vm.selectedPlace.id, id: "selectPool" },
                    model: {
                      value: _vm.selectedPool,
                      callback: function($$v) {
                        _vm.selectedPool = $$v
                      },
                      expression: "selectedPool"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.isClient &&
          (_vm.fileFormat === "PDF" || _vm.type === "ANALYZE_WITH_PHOTO")
            ? _c(
                "v-flex",
                { attrs: { xs12: "", md6: "", xl3: "" } },
                [
                  _c("clientSelect", {
                    staticClass: "mx-2",
                    model: {
                      value: _vm.selectedClient,
                      callback: function($$v) {
                        _vm.selectedClient = $$v
                      },
                      expression: "selectedClient"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.type !== "DAILY_TRATAMENT" && _vm.type !== "POOL_CONDITIONS"
            ? _c(
                "v-flex",
                { attrs: { xs12: "", md6: "", xl3: "" } },
                [
                  _c("datePicker", {
                    staticClass: "datePicker mx-2",
                    attrs: { label: "Data inicial" },
                    model: {
                      value: _vm.startDate,
                      callback: function($$v) {
                        _vm.startDate = $$v
                      },
                      expression: "startDate"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.type !== "DAILY_TRATAMENT" && _vm.type !== "POOL_CONDITIONS"
            ? _c(
                "v-flex",
                { attrs: { xs12: "", md6: "", xl3: "" } },
                [
                  _c("datePicker", {
                    staticClass: "datePicker mx-2",
                    attrs: { label: "Data final" },
                    model: {
                      value: _vm.endDate,
                      callback: function($$v) {
                        _vm.endDate = $$v
                      },
                      expression: "endDate"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.type === "DAILY_TRATAMENT" || _vm.type === "POOL_CONDITIONS"
            ? _c(
                "v-flex",
                { attrs: { xs12: "", md6: "", xl3: "" } },
                [
                  _c("datePicker", {
                    staticClass: "datePicker mx-2",
                    attrs: { type: "month", label: "Mês e ano de referência" },
                    model: {
                      value: _vm.selectedMonth,
                      callback: function($$v) {
                        _vm.selectedMonth = $$v
                      },
                      expression: "selectedMonth"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            { attrs: { xs12: "", id: "toolbarButton" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: {
                    outline: "",
                    disabled: !_vm.type,
                    loading: _vm.isLoading,
                    id: "generateReport"
                  },
                  on: {
                    click: function($event) {
                      return _vm.downloadReport()
                    }
                  }
                },
                [_vm._v("Gerar relatório")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            bottom: true,
            color: this.color,
            right: true,
            timeout: _vm.timeout,
            id: "snackbar"
          },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.snackbarText) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "White", flat: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v(" Fechar ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }