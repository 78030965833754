var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    attrs: {
      autocomplete: "off",
      "menu-props": "auto",
      disabled: _vm.selectList.length === 0,
      items: _vm.selectList,
      value: _vm.value,
      rules: _vm.rules,
      label: `${_vm.label || "Selecione um local"}${_vm.rules ? " *" : ""}`,
      "item-text": "optionText",
      "item-value": "value"
    },
    on: {
      change: function($event) {
        return _vm.$emit("input", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }