import analyzeAfterQuery from '@/graphql/analyze/analyzeAfterQuery.gql';
import analyzeBeforeQuery from '@/graphql/analyze/analyzeBeforeQuery.gql';
import listAnalyzesWhere from '@/graphql/analyze/analyzesWhere.gql';
import getAnalyzesQuery from '@/graphql/analyze/getAnalyzes.gql';
import lastAnalyzeQuery from '@/graphql/analyze/lastAnalyze.gql';
import listAnalyzesForClientQuery from '@/graphql/analyze/listAnalyzesForClient.gql';
import ratingsPlaceByPoolId from '@/graphql/analyze/ratingsPlaceByPoolId.gql';
import analyzeQuery from '@/graphql/analyze/analyzeQuery.gql';
import analyzePoolQuery from '@/graphql/analyze/analyzePoolQuery.gql';
import updateAnalyzeMutation from '@/graphql/analyze/updateAnalyze.gql';
import visitorAnalyzeQuery from '@/graphql/analyze/visitorAnalyze.gql';
import analyzesCreateDay from '@/graphql/analyze/analyzesCreateDay.gql';
import { mutation, query } from '@/services/GraphqlService';
import * as moment from 'moment';

export const getAnalyzes = (apollo, variables, clientId) => {
  if (clientId) {
    variables.id = clientId;
    delete variables.data.status;
    return query(apollo, listAnalyzesForClientQuery, variables).then((resp) =>
      resp.user.places
        .reduce((pools, local) => pools.concat(local.pools), [])
        .reduce((analyzes, pool) => analyzes.concat(pool.analyze), []),
    );
  }
  return query(apollo, getAnalyzesQuery, variables).then((resp) => resp.analyzes);
};

export const getListAnalyzesWhere = (apollo, variables) =>
  query(apollo, listAnalyzesWhere, variables, { client: 'visitorApolloClient' });

export const getLastAnalyze = (apollo) => query(apollo, lastAnalyzeQuery, {});

export const getAnalyze = (apollo, variables) => query(apollo, analyzeQuery, variables);

export const getAnalyzePool = (apollo, variables) =>
  query(apollo, analyzePoolQuery, variables).then((resp) => ({ pool: resp.analyze.pool }));

export const getVisitorAnalyze = (apollo, variables) =>
  query(apollo, visitorAnalyzeQuery, variables, { client: 'visitorApolloClient' });

export const getRatingsPlaceByPoolId = (apollo, variables) =>
  query(apollo, ratingsPlaceByPoolId, variables);

export const formatedDateTime = (dateTime) => ({
  date: moment(dateTime).format('DD/MM'),
  time: moment(dateTime).format('HH:mm'),
});

export const getStatusAnalyzesIcon = (status) => {
  if (!status) {
    return '';
  }
  return [
    { status: 'OPEN', icon: 'radio_button_unchecked' },
    { status: 'APPLIED', icon: 'check_circle_outline' },
    { status: 'SUGGESTED', icon: 'radio_button_checked' },
    { status: 'WITHOUT_SUGGESTED', icon: 'check' },
  ].find((option) => option.status === status).icon;
};

export const getStatusAnalyzesText = (status) => {
  if (!status) {
    return '';
  }
  return [
    { status: 'OPEN', text: 'Aberta' },
    { status: 'APPLIED', text: 'Aplicada' },
    { status: 'SUGGESTED', text: 'Sugerida' },
    { status: 'WITHOUT_SUGGESTED', text: 'Sem sugestão' },
  ].find((option) => option.status === status).text;
};

export const updateAnalyze = (apollo, variables) =>
  mutation(apollo, updateAnalyzeMutation, variables);

export const getAnalyzeBefore = (apollo, analyzeId) =>
  query(apollo, analyzeBeforeQuery, { id: analyzeId }).then((resp) => resp.analyzes[0]);

export const getAnalyzeAfter = (apollo, analyzeId) =>
  query(apollo, analyzeAfterQuery, { id: analyzeId }).then((resp) => resp.analyzes[0]);

export const getAnalyzesCreateDay = (apollo, variables) =>
  query(apollo, analyzesCreateDay, variables).then((resp) => resp.analyzes);
