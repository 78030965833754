<template>
  <v-app app>
    <v-content style="background-color: #bfc7d1;">
      <router-view />
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      //
    };
  },
};
</script>
