import JsPDF from 'jspdf';

const pdfDocumentAttributes = [
  'Data',
  'Piscina',
  'Volume (m³)',
  'Alcalinidade',
  'PH',
  'Cloro',
  'Água',
];

const convertArrayOfObjectsToCSV = (args) => {
  let ctr;
  const data = args.data || null;
  if (data == null || !data.length) {
    return null;
  }
  const columnDelimiter = args.columnDelimiter || ';';
  const lineDelimiter = args.lineDelimiter || '\n';
  const keys = Object.keys(data[0]);
  let result;
  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;
  data.forEach((item) => {
    ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) {
        result += columnDelimiter;
      }
      result += item[key];
      ctr += 1;
    });
    result += lineDelimiter;
  });
  return result;
};

export const downloadCSV = (filename, info) => {
  let data = '';
  let link = HTMLAnchorElement;
  let csv;

  if (info.length) {
    csv = convertArrayOfObjectsToCSV({
      data: info,
    });
  } else {
    csv = info.csv;
  }

  if (!csv) {
    throw new Error('No data to report.');
  }

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  data = encodeURI(csv);

  link = document.createElement('a');
  link.setAttribute('href', data);
  link.setAttribute('download', filename);
  return link.click();
};

const sliceArrayIntoGroups = (font, groupSize) =>
  font.reduce((arrayGroup, element) => {
    let arrayFromGroup = arrayGroup.find((array) => array.length < groupSize);
    if (arrayFromGroup) {
      arrayFromGroup.push(element);
    } else {
      arrayFromGroup = [element];
      arrayGroup.push(arrayFromGroup);
    }
    return arrayGroup;
  }, []);

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index += 1) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
}

const loadImage = (url) =>
  new Promise((resolve) => {
    const image = new Image();
    image.crossOrigin = '*';
    image.src = url;
    image.onload = () => {
      resolve(image);
    };
  });

function fillPDFDataAndPhotos(pageStart, pdf, analyzes) {
  const addAnalyzeDataToPDF = (label, data, dataIndex, lineIndex) => {
    const yPosition = pageStart + lineIndex * 5 + dataIndex * 60;
    pdf.text(`${label}: ${data[lineIndex]}`, 30, yPosition);
  };
  pdf.setFont('arial', '');
  const fillPromises = [];

  analyzes.forEach((data, dataIndex) => {
    pdfDocumentAttributes.forEach((label, index) =>
      addAnalyzeDataToPDF(label, data, dataIndex, index));
    const fillPromise = data[pdfDocumentAttributes.length]
      ? loadImage(data[pdfDocumentAttributes.length]).then((photo) =>
        pdf.addImage(photo, 'JPG', 135, pageStart + dataIndex * 60, 45, 45),
      )
      : Promise.resolve();
    fillPromises.push(fillPromise);
  });
  return Promise.all(fillPromises);
}

async function fillPage(pdf, tableHead, analyzes, headerInfo) {
  if (headerInfo) {
    const logoPiscinapp = await loadImage('/assets/img/logo-piscinapp-positivo.png');
    pdf.addImage(logoPiscinapp, 'PNG', 56, 10, 93, 21);
    pdf.setFont('arial', 'bold');
    pdf.setFontSize(18);
    pdf.setFont('arial', '');
    pdf.setFontSize(12);
    pdf.text(`Colaborador: ${headerInfo.employee}`, 30, 42);
    pdf.text(`Cliente: ${headerInfo.client}`, 30, 47);
    pdf.text(`Unidade: ${headerInfo.local}`, 30, 52);
    pdf.text(`Endereço: ${headerInfo.publicPlace}, ${headerInfo.number}`, 30, 57);
    pdf.text(`${headerInfo.neighborhood}, ${headerInfo.city}, ${headerInfo.state}`, 30, 62);
    pdf.setFont('arial', 'bold');
    pdf.text('Controle Físico-Químico da Qualidade de Água de Piscina', 49, 70);
  }

  if (analyzes[0][pdfDocumentAttributes.length]) {
    const pageStart = !headerInfo ? 20 : 79;
    await fillPDFDataAndPhotos(pageStart, pdf, analyzes);
  } else {
    pdf.autoTable({
      styles: { halign: 'center', fontSize: 8 },
      startY: !headerInfo ? 15 : 74,
      headStyles: { fillColor: [0, 0, 0] },
      head: tableHead,
      body: analyzes,
    });
  }
}

async function createPDFPages(pdf, tableHead, headerInfo, pages) {
  if (!pdf.isEmpty) {
    pdf.addPage({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });
  }

  await fillPage(pdf, tableHead, pages[0], headerInfo);
  if (pages.length > 1) {
    pages.splice(0, 1);
    await asyncForEach(pages, async (lines) => {
      pdf.addPage({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
      });
      await fillPage(pdf, tableHead, lines);
    });
  }
}

const fillPDF = async (pdf, data, localGroup, analyzesPerPage) => {
  let pages = [];
  pages.push(localGroup.analyzes.splice(0, analyzesPerPage < 5 ? 3 : 28));
  pages = pages.concat(sliceArrayIntoGroups(localGroup.analyzes, analyzesPerPage));
  const { headerInfo } = data;
  headerInfo.employee = localGroup.docHeader.employee;
  headerInfo.local = localGroup.docHeader.local;

  return createPDFPages(pdf, data.tableHead, headerInfo, pages);
};

export const downloadAnalyzesPDF = async (filename, data, analyzesPerPage) => {
  const pdf = new JsPDF({
    orientation: 'p',
    unit: 'mm',
    format: 'a4',
  });
  pdf.isEmpty = true;
  await asyncForEach(data.groupsByLocal, async (group) => {
    await fillPDF(pdf, data, group, analyzesPerPage);
    pdf.isEmpty = false;
  });

  pdf.save(filename);
};
