<template>
  <v-container grid-list-xl id="containerStockTab">
    <v-layout wrap>
      <v-flex xs12 md7>
        <h2>Piscinas deste local</h2>
      </v-flex>
      <v-flex xs12 md5>
        <v-btn
          class="ma-0"
          style="width: 100%"
          v-if="!isClient"
          large
          color="primary"
          @click="showNewPoolCard = !showNewPoolCard"
          >Cadastrar piscina</v-btn
        >
      </v-flex>
      <v-flex xs12>
        <DataTable
          :tableContent="tablePool"
          @edit="(index) => editPool(index)"
          @urlForQrCode="(url) => setUrlForQrCode(url)"
        />
      </v-flex>
    </v-layout>
    <v-card-actions v-if="!isClient">
      <v-spacer />
      <v-btn flat color="primary" @click="closeModal()" id="closePool">Fechar</v-btn>
    </v-card-actions>
    <CardModal v-model="showNewPoolCard" width="30%">
      <NewPoolCard
        :contentList="tablePool.contentList"
        :idPlaceEdit="idPlaceEdit"
        @showNewPoolCard="showNewPoolCard = $event"
        :id="poolToEdit.id"
        :volume="poolToEdit.volume"
        :name="poolToEdit.name"
        :hasTabletSupport="poolToEdit.hasTabletSupport"
        :hasAutomaticSuggestion="poolToEdit.hasAutomaticSuggestion"
      />
    </CardModal>
    <v-dialog v-model="showQrCode">
      <v-card>
        <v-container>
          <qrcode cls="qrcode" :value="urlForQrCode" :size="qrCodeSize"></qrcode>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import * as moment from 'moment';
import Qrcode from 'v-qrcode';
import { getPlace, getPlaceForClient } from '@/services/PlacesService';
import { isRole, alertSnack } from '@/services/UtilsService';

import DataTable from '@/components/utils/DataTable.vue';
import CardModal from '@/components/modal/CardModal.vue';
import NewPoolCard from '@/components/places/NewPoolCard.vue';

export default {
  data: () => ({
    pools: '',
    poolToEdit: {},
    qrCodeSize:
      (window.innerWidth < window.innerHeight ? window.innerWidth : window.innerHeight) / 1.5,
    urlForQrCode: '',
    showQrCode: '',
    showNewPoolCard: false,
    isClient: isRole('CLIENT'),
    dataPools: [
      {
        poolName: null,
        volume: null,
        analyze: null,
      },
    ],
    tablePool: {
      isLoading: true,
      headerList: [
        { text: 'Nome da piscina', align: 'center', value: 'name' },
        { text: 'Volume (L)', align: 'center', value: 'volume' },
        { text: 'Última análise', align: 'center', value: 'analyze' },
        { text: 'QR Code', align: 'center', value: 'qrcode' },
        { text: 'Editar', align: 'center', value: 'edit' },
      ],
      contentList: [],
      noResult: 'Não há piscinas cadastradas para este local',
      itensPerPage: [10],
      textPerPage: 'Piscinas por página',
    },
  }),
  computed: {
    markers() {
      return [
        {
          position: { lat: Number(this.latitude), lng: Number(this.longitude) },
          title: 'Local principal',
        },
      ];
    },
  },
  methods: {
    editPool(index) {
      const pool = this.pools[index];
      this.poolToEdit = {
        id: pool.id,
        name: pool.name,
        volume: pool.volume,
        hasTabletSupport: pool.hasTabletApplicationSupport,
        hasAutomaticSuggestion: pool.isEnabledAutomaticSuggestion,
      };
      this.showNewPoolCard = true;
    },
    closeModal() {
      this.$emit('showModal', false);
    },
    setUrlForQrCode(urlForQrCode) {
      this.urlForQrCode = urlForQrCode;
      this.showQrCode = true;
    },
    formatUrlQrCode(poolId) {
      let integerUrl = window.location.protocol;
      integerUrl += '//';
      integerUrl += window.location.host;
      integerUrl += '/visitante/piscina/';
      integerUrl += poolId;

      return integerUrl;
    },
    async getPools() {
      if (!this.idPlaceEdit) {
        return;
      }
      const variables = {
        idUser: localStorage.userId,
        idPlace: this.idPlaceEdit,
      };
      const pools = await (this.isClient
        ? getPlaceForClient(this.$apollo, variables)
        : getPlace(this.$apollo, variables)
      )
        .then((resp) => (this.isClient ? resp.user.places[0].pools : resp.local.pools))
        .catch(() => {
          alertSnack('Erro ao carregar piscinas deste local. Tente novamente.', true);
        });

      this.pools = pools;

      this.tablePool.contentList = pools.map((pool, index) => ({
        name: pool.name,
        volume: pool.volume * 1000,
        analyze: pool.analyze[pool.analyze.length - 1]
          ? [
              `Data: ${moment(pool.analyze[pool.analyze.length - 1].createDay).format('DD/MM/YY')}`,
              `Hora: ${moment(pool.analyze[pool.analyze.length - 1].createDay).format('HH:mm')}`,
              pool.analyze[pool.analyze.length - 1].employee
                ? `Colaborador: ${pool.analyze[pool.analyze.length - 1].employee.name}`
                : undefined,
            ].filter((item) => item)
          : 'Ainda não foi realizado nenhuma análise.',
        qrcode: {
          icon: 'select_all',
          url: this.formatUrlQrCode(pool.id),
        },
        edit: { index },
      }));

      this.tablePool.isLoading = false;
      this.isLoading = false;
    },
  },
  watch: {
    idPlaceEdit() {
      this.getPools();
    },
    showNewPoolCard() {
      if (!this.showNewPoolCard) {
        this.poolToEdit = {};
        this.getPools();
      }
    },
  },
  components: {
    DataTable,
    CardModal,
    Qrcode,
    NewPoolCard,
  },
  props: ['idPlaceEdit'],
  async created() {
    this.getPools();
  },
};
</script>
