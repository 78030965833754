import { query, mutation } from '@/services/GraphqlService';

import listEquipmentDetails from '@/graphql/equipment/listEquipmentDetails.gql';
import listEquipment from '@/graphql/equipment/listEquipment.gql';
import saveEquipmentDetailsMutation from '@/graphql/equipment/saveEquipmentDetails.gql';
import updateEquipmentDetailsMutation from '@/graphql/equipment/updateEquipmentDetails.gql';
import updateEquipmentDetailsStatusMutation from '@/graphql/equipment/updateEquipmentDetailsStatus.gql';
import listEquipmentDetailsToSelectMutation from '@/graphql/equipment/listEquipmentDetailsToSelect.gql';
import saveEquipmentMutation from '@/graphql/equipment/saveEquipment.gql';
import saveEquipmentRegisterMutation from '@/graphql/equipment/saveEquipmentRegister.gql';
import equipmentTransferenceMutation from '@/graphql/transference/equipmentTransference.gql';
import listEquipmentRegisters from '@/graphql/equipment/listEquipmentRegisters.gql';
import updateEquipmentStateMutation from '@/graphql/equipment/updateEquipmentState.gql';

export const getAllEquipmentDetails = (apollo) =>
  query(apollo, listEquipmentDetails, {}).then((resp) => resp.equipmentDetailses);

export const getEquipmentAllRegisters = (apollo, variables) =>
  query(apollo, listEquipmentRegisters, variables);

export const getAllEquipmentDetailsToSelect = (apollo) =>
  query(apollo, listEquipmentDetailsToSelectMutation, {}).then((resp) => resp.equipmentDetailses);

export const getEquipmentList = (apollo, localId) =>
  query(apollo, listEquipment, { localId }).then((resp) => resp.local.equipments);

export const saveEquipmentDetails = (apollo, variables) =>
  mutation(apollo, saveEquipmentDetailsMutation, variables);

export const saveEquipment = (apollo, variables) =>
  mutation(apollo, saveEquipmentMutation, variables);

export const updateEquipmentDetails = (apollo, variables) =>
  mutation(apollo, updateEquipmentDetailsMutation, variables);

export const updateEquipmentDetailsStatus = (apollo, variables) =>
  mutation(apollo, updateEquipmentDetailsStatusMutation, variables);

export const saveEquipmentRegister = (apollo, variables) =>
  mutation(apollo, saveEquipmentRegisterMutation, variables);

export const saveEquipmentTransference = (apollo, variables) =>
  mutation(apollo, equipmentTransferenceMutation, variables);

export const updateEquipmentState = (apollo, variables) =>
  mutation(apollo, updateEquipmentStateMutation, variables);
