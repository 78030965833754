var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "pt-5",
          staticStyle: { "background-color": "#484857" },
          attrs: {
            clipped: _vm.$vuetify.breakpoint.lgAndUp,
            fixed: "",
            app: ""
          },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "v-list",
            {
              staticStyle: { "background-color": "#484857" },
              attrs: { dense: "" }
            },
            [
              _c("div", { staticStyle: { "margin-top": "20px" } }),
              _vm._l(_vm.userMenuItens, function(item) {
                return _c(
                  "v-list-tile",
                  {
                    key: item.text,
                    class: _vm.styleForMenu(item.name),
                    staticStyle: { height: "40px", "margin-top": "15px" },
                    on: {
                      click: function($event) {
                        return _vm.redirect(item.name)
                      }
                    }
                  },
                  [
                    _c(
                      "v-list-tile-action",
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "26px", color: "#A6CE39" } },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.icon) +
                                "\n            "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-list-tile-content",
                      [
                        _c(
                          "v-list-tile-title",
                          {
                            staticClass: "titleMenu",
                            attrs: { id: item.text }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.text) +
                                "\n            "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    item.name === "chat"
                      ? _c(
                          "div",
                          { staticClass: "alert-count-circle-wrapper" },
                          [
                            _vm.unreadChatsCount &&
                            (_vm.isAdmin() || _vm.isCoordinador())
                              ? _c(
                                  "div",
                                  { staticClass: "alert-count-circle" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.unreadChatsCount) +
                                        "\n            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "app-toolbar" },
        [
          _c(
            "v-icon",
            {
              staticClass: "mx-2",
              attrs: { color: "#ffffff" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  _vm.drawer = !_vm.drawer
                }
              }
            },
            [_vm._v("menu")]
          ),
          _c("a", { attrs: { href: "/" } }, [
            _c("img", {
              staticClass: "logo",
              attrs: {
                src: "/assets/img/logo-piscinapp-35px.png",
                title: "PiscinApp"
              }
            })
          ]),
          _c("v-spacer"),
          _vm.screenSize != "xs" && _vm.screenSize != "sm"
            ? _c("span", { attrs: { id: "userEmail" } }, [
                _vm._v(_vm._s(_vm.userEmail))
              ])
            : _vm._e(),
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.logout } },
            [
              _c(
                "v-icon",
                {
                  attrs: { title: "Sair", color: "#ffffff", id: "buttonClose" }
                },
                [_vm._v("logout")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-content",
        { staticClass: "px-1 pt-5" },
        [
          _vm.pageComponent
            ? _c(_vm.pageComponent, { tag: "component" })
            : _vm._e()
        ],
        1
      ),
      _c("snackAlert", {
        model: {
          value: _vm.snackBar,
          callback: function($$v) {
            _vm.snackBar = $$v
          },
          expression: "snackBar"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }