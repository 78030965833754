'<template>
  <v-card :width="windowWidth < 960 ? `${windowWidth}px` : '100%'">
    <v-toolbar color="#0d1824" dark flat><v-toolbar-title>Notificação</v-toolbar-title></v-toolbar>
    <v-container v-if="!isLoading">
      <v-layout row wrap>
        <v-flex xs12>
          <strong>Tipo:</strong>
          {{ dataNotification.type | notificationType }}
        </v-flex>
        <v-flex xs12>
          <strong>Status:</strong>
          {{ dataNotification.status | notificationStatus }}
        </v-flex>
        <v-flex xs12>
          <strong>Data:</strong>
          {{ dataNotification.day | formatedDay }}
        </v-flex>
        <v-flex xs12 class="my-2">
          <strong>Título:</strong>
          {{ dataNotification.title }}
        </v-flex>
      </v-layout>
      <v-layout class="mb-2" row>
        <strong>Mensagem:</strong>
        <div v-if="!messageData">{{ dataNotification.message }}</div>
        <div v-if="messageData && !messageData.local">{{ messageData.message }}</div>
      </v-layout>
      <v-layout v-if="dataNotification.type === 'GEOLOCALIZACAO'">
        <googleMaps
          :center="markersCenter()"
          :defaultMarkers="markers()"
          :localMarkers="markerPlace"
          xs4
          class="map"
        ></googleMaps>
      </v-layout>
      <v-layout>
        <div v-if="messageData && messageData.differences">
          <p>{{ messageData.header }}</p>
          <v-list>
            <v-list-tile>
              <v-flex xs4>
                <v-list-tile-content>
                  <b>Produto</b>
                </v-list-tile-content>
              </v-flex>
              <v-flex xs4>
                <v-list-tile-content>
                  <b>Unidade</b>
                </v-list-tile-content>
              </v-flex>
              <v-flex xs4>
                <v-list-tile-content>
                  <b>Contagem</b>
                </v-list-tile-content>
              </v-flex>
              <v-flex xs4>
                <v-list-tile-content>
                  <b>Esperado</b>
                </v-list-tile-content>
              </v-flex>
              <v-flex xs4>
                <v-list-tile-content>
                  <b>Diferença</b>
                </v-list-tile-content>
              </v-flex>
              <v-flex xs4>
                <v-list-tile-content>
                  <b>%</b>
                </v-list-tile-content>
              </v-flex>
            </v-list-tile>
            <v-list-tile v-for="(difference, index) in messageData.differences" :key="index">
              <v-flex xs2>
                <v-list-tile-content>
                  {{ difference.name }}
                </v-list-tile-content>
              </v-flex>
              <v-flex xs2>
                <v-list-tile-content>
                  {{ difference.unit | units }}
                </v-list-tile-content>
              </v-flex>
              <v-flex xs2>
                <v-list-tile-content>
                  {{ difference.tranferenceAmount.toFixed(2) }}
                </v-list-tile-content>
              </v-flex>
              <v-flex xs2>
                <v-list-tile-content>
                  {{ difference.localAmount.toFixed(2) }}
                </v-list-tile-content>
              </v-flex>
              <v-flex xs2>
                <v-list-tile-content>
                  {{ difference.difference.toFixed(2) }}
                </v-list-tile-content>
              </v-flex>
              <v-flex xs2>
                <v-list-tile-content>
                  {{ difference.differencePercentage }}
                </v-list-tile-content>
              </v-flex>
            </v-list-tile>
          </v-list>
        </div>
        <div v-if="messageData && messageData.negativeProducts">
          <p>{{ messageData.header }}</p>
          <v-list>
            <v-list-tile>
              <v-flex xs4>
                <v-list-tile-content>
                  <b>Produto</b>
                </v-list-tile-content>
              </v-flex>

              <v-flex xs4>
                <v-list-tile-content>
                  <b>Unidade</b>
                </v-list-tile-content>
              </v-flex>
              <v-flex xs4>
                <v-list-tile-content>
                  <b>Quantidade anterior</b>
                </v-list-tile-content>
              </v-flex>
              <v-flex xs4>
                <v-list-tile-content>
                  <b>Após contagem</b>
                </v-list-tile-content>
              </v-flex>
            </v-list-tile>
            <v-list-tile
              v-for="(negativeProduct, index) in messageData.negativeProducts"
              :key="index"
            >
              <v-flex xs4>
                <v-list-tile-content>{{ negativeProduct.name }}</v-list-tile-content>
              </v-flex>
              <v-flex xs4>
                <v-list-tile-content>{{ negativeProduct.unit | units }}</v-list-tile-content>
              </v-flex>
              <v-flex xs4>
                <v-list-tile-content>
                  {{ negativeProduct.amountBeforeTranference.toFixed(2) }}
                </v-list-tile-content>
              </v-flex>
              <v-flex xs4>
                <v-list-tile-content>
                  {{ negativeProduct.amountAfterTranference.toFixed(2) }}
                </v-list-tile-content>
              </v-flex>
            </v-list-tile>
          </v-list>
        </div>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12 style="margin-top: 20px">
          <strong>Observação:</strong>
          {{ dataNotification.observations ? dataNotification.observations[0] : '' }}
        </v-flex>
        <textarea
          xs12
          id="observation"
          v-if="dataNotification.status === 'ABERTA'"
          style="width:700px; height:50px; margin-top: 20px"
          placeholder="Escreva sua observação aqui."
          type="text"
          v-model="observations"
        />
      </v-layout>

      <v-card-actions>
        <v-layout wrap>
          <v-flex xs12 md4 lg3
            ><v-btn
              class="mx-0 mb-0 mt-2"
              style="width:100%;"
              v-if="dataNotification.status === 'ABERTA'"
              color="primary"
              title="Salva a observação e muda seu status para fechada"
              @click="submit(true)"
              id="submitClose"
              >Fechar notificação</v-btn
            ></v-flex
          >
          <v-flex xs12 md4 lg3 offset-lg6 offset-md4 justify-end>
            <v-btn
              style="width:50%"
              class="mx-0 mb-0 mt-2"
              flat
              color="primary"
              @click="closeModal()"
              id="closeModal"
              >Fechar</v-btn
            >
            <v-btn
              style="width:50%"
              class="mx-0 mb-0 mt-2"
              v-if="dataNotification.status === 'ABERTA'"
              flat
              color="primary"
              title="Salva a observação"
              @click="submit(false)"
              id="submit"
              >Salvar</v-btn
            ></v-flex
          >
        </v-layout>
      </v-card-actions>
    </v-container>
    <div v-else class="text-xs-center">
      <v-progress-circular indeterminate color="primary" class="loader ma-5"></v-progress-circular>
    </div>
  </v-card>
</template>

<script>
import { getNotification, updateNotification } from '@/services/NotificationService';
import * as moment from 'moment';
import googleMaps from '@/components/maps/GoogleMaps.vue';
import { returnUnitsShortFilter, alertSnack } from '@/services/UtilsService';

export default {
  data: () => ({
    isLoading: false,
    isSaveLoading: false,
    windowWidth: window.innerWidth,
    dataNotification: '',
    idNotification: '',
    observations: '',
    dataList: '',
    lat: '',
    lng: '',
  }),
  computed: {
    messageData() {
      if (this.dataNotification && this.dataNotification.message.indexOf('{') === 0) {
        const data = JSON.parse(this.dataNotification.message);
        return data;
      }
      return '';
    },

    markerPlace() {
      return [
        {
          position: {
            lat: Number(this.dataNotification.local.address.latitude),
            lng: Number(this.dataNotification.local.address.longitude),
          },
          title: this.dataNotification.local.localName,
        },
      ];
    },
  },
  methods: {
    markers() {
      this.datalist = this.dataNotification.geographicPositions;

      if (this.datalist) {
        return this.datalist.reduce((markersList, geographicPositions) => {
          markersList.push({
            position: {
              lat: Number(geographicPositions.latitude),
              lng: Number(geographicPositions.longitude),
            },
            title: moment(geographicPositions.dateTime).format('DD/MM/YYYY HH:mm'),
          });
          return markersList;
        }, []);
      }
      return [];
    },
    markersCenter() {
      this.lat = Number(this.dataNotification.geographicPositions[0].latitude);
      this.lng = Number(this.dataNotification.geographicPositions[0].longitude);
      return { lat: this.lat, lng: this.lng };
    },
    closeModal() {
      this.$emit('showModal', false);
    },
    loadNotification() {
      this.isLoading = true;
      getNotification(this.$apollo, { id: this.idNotification })
        .then((resp) => {
          this.dataNotification = resp.notification;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetSingleNotification() {
      this.dataNotification = '';
      this.idNotification = '';
      this.observations = '';
      this.dataList = '';
      this.lat = '';
      this.lng = '';
    },
    submit(isClose) {
      this.isSaveLoading = true;
      const variables = {
        data: {
          observations: { set: [this.observations] },
          ...(isClose ? { status: 'FECHADA' } : {}),
        },
        id: this.idNotification,
      };
      updateNotification(this.$apollo, variables)
        .then(() => {
          alertSnack('saveDefault');
          this.closeModal();
        })
        .catch(() => alertSnack('errorDefault'))
        .finally(() => {
          this.isSaveLoading = false;
        });
    },
  },
  filters: {
    formatedDay(day) {
      return day ? moment(day).format('DD/MM/YY HH:mm') : '';
    },
    notificationType(type) {
      return {
        VISITA: 'Visita',
        PONTO: 'Ponto',
        JORNADA: 'Jornada',
        ESTOQUE: 'Estoque',
        GEOLOCALIZACAO: 'Geolocalização',
        LOCAL: 'Local',
      }[type];
    },
    notificationStatus(status) {
      return { ABERTA: 'Aberta', FECHADA: 'Fechada' }[status];
    },
    units(unit) {
      return returnUnitsShortFilter(unit);
    },
  },
  watch: {
    notificationId(id) {
      this.idNotification = id;
      this.loadNotification();
    },
  },
  props: ['notificationId'],
  components: {
    googleMaps,
  },
};
</script>
<style>
#createNotification .map {
  width: 710px;
  height: 400px;
}
</style>
