<template>
  <v-card>
    <v-card-title class="title">Cadastrar Equipamento no local</v-card-title>
    <v-card-text>
      <v-layout wrap>
        <v-flex xs12>
          <equipmentSelect
            v-model="newEquipment.detailsId"
            style="margin-right: 25px"
            id="equipmentSelect"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="number"
            label="ID"
            style="margin-right: 25px"
            v-model="newEquipment.codeIdentifier"
            id="codeIdentifier"
          ></v-text-field
        ></v-flex>
        <v-flex xs12>
          <v-text-field
            label="Descrição"
            style="margin-right: 25px"
            v-model="newEquipmentRegister.description"
            id="equipmentDescription"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 id="selectStatus">
          <v-autocomplete
            autocomplete="off"
            style="margin-right: 25px"
            menu-props="auto"
            :items="statusList"
            v-model="newEquipmentRegister.status"
            item-value="value"
            item-text="name"
            label="Status do equipamento"
          />
        </v-flex>
        <v-flex xs12 md6>
          <datePicker
            v-model="newEquipmentRegister.lastMaintenance"
            label="Última manutenção"
            id="lastMaintenance"
          ></datePicker
        ></v-flex>
        <v-flex xs12 md6>
          <datePicker
            v-model="newEquipmentRegister.nextMaintenance"
            label="Próxima manutenção"
            id="nextMaintenance"
          ></datePicker>
        </v-flex>
      </v-layout>
      <v-flex xs12>
        <v-textarea
          v-model="newEquipmentRegister.observation"
          label="Observação"
          id="observation"
        ></v-textarea>
      </v-flex>
      <v-card-actions v-if="!isClient">
        <v-spacer />
        <v-btn color="primary" flat @click="$emit('showModalNewRegister', false)">
          Fechar
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!this.newEquipment.detailsId"
          @click="saveEquipment()"
          id="buttonSaveEquipment"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import datePicker from '@/components/date-time/DatePicker.vue';
import equipmentSelect from '@/components/selects/EquipmentSelect.vue';

import { isRole, alertSnack } from '@/services/UtilsService';
import { saveEquipment } from '@/services/EquipmentService';

export default {
  data: () => ({
    isClient: isRole('CLIENT'),
    newEquipment: {
      detailsId: '',
      codeIdentifier: null,
    },
    newEquipmentRegister: {
      status: 'WORKING',
      lastMaintenance: null,
      nextMaintenance: null,
      description: '',
      observation: '',
      equipmentId: '',
    },
    statusList: [
      { name: 'Funcionando', value: 'WORKING' },
      { name: 'Funcionando com defeito', value: 'WORKING_WITH_DEFECT' },
      { name: 'Funcionando com defeito crítico', value: 'WORKING_WITH_CRITICAL_DEFECT' },
      { name: 'Em manutenção', value: 'UNDER_MAINTENCE' },
      { name: 'Aguardando substituição', value: 'WAITING_FOR_REPLACEMENT' },
      { name: 'Desativado', value: 'DISABLED' },
    ],
  }),
  methods: {
    async saveEquipment() {
      const variables = {
        localId: this.idPlaceEdit,
        detailsId: this.newEquipment.detailsId,
        codeIdentifier: this.newEquipment.codeIdentifier,
        status: this.newEquipmentRegister.status,
        observation: this.newEquipmentRegister.observation,
        lastMaintenance: this.newEquipmentRegister.lastMaintenance
          ? this.newEquipmentRegister.lastMaintenance
          : null,
        nextMaintenance: this.newEquipmentRegister.nextMaintenance
          ? this.newEquipmentRegister.nextMaintenance
          : null,
        dateTime: new Date().toISOString(),
        description: this.newEquipmentRegister.description,
      };
      this.newEquipment = this.$options.data().newEquipment;
      this.newEquipmentRegister = this.$options.data().newEquipmentRegister;
      saveEquipment(this.$apollo, variables)
        .then(() => {
          alertSnack('saveDefault');
          this.$emit('showModalNewRegister', false);
        })
        .catch(() => {
          alertSnack('errorDefault');
        });
    },
  },
  components: {
    datePicker,
    equipmentSelect,
  },
  props: ['idPlaceEdit'],
};
</script>
