var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "FilterCard",
        {
          attrs: { searchButton: false, resetButton: true },
          on: {
            clickOnReset: function($event) {
              return _vm.resetFilter()
            }
          }
        },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm12: "", md9: "" } },
            [
              _c("v-text-field", {
                staticClass: "mx-2",
                attrs: {
                  label: "Pesquise pelo nome do produto",
                  id: "searchName"
                },
                model: {
                  value: _vm.searchName,
                  callback: function($$v) {
                    _vm.searchName = $$v
                  },
                  expression: "searchName"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm12: "", md3: "" } },
            [
              _c("StatusSelect", {
                staticClass: "mx-2",
                attrs: { id: "searchStatus" },
                model: {
                  value: _vm.selectedStatus,
                  callback: function($$v) {
                    _vm.selectedStatus = $$v
                  },
                  expression: "selectedStatus"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("CardList", {
        attrs: {
          isLoading: _vm.isLoading,
          id: "dataProducts",
          contentList: _vm.formatedProductList
        },
        on: { click: elementData => _vm.openModal(elementData.id) }
      }),
      _c(
        "CardModal",
        {
          attrs: { width: "65%" },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("ProductCreation", {
            attrs: { idProductEdit: _vm.idProductEdit },
            model: {
              value: _vm.showModal,
              callback: function($$v) {
                _vm.showModal = $$v
              },
              expression: "showModal"
            }
          })
        ],
        1
      ),
      _vm.isAdmin || _vm.isCoordinador
        ? _c(
            "v-btn",
            {
              attrs: {
                fab: "",
                bottom: "",
                right: "",
                color: "#A6CE39",
                dark: "",
                fixed: "",
                title: "Novo",
                id: "create"
              },
              on: {
                click: function($event) {
                  return _vm.openModal()
                }
              }
            },
            [_c("v-icon", [_vm._v("add")])],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }