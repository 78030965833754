<template>
  <v-card class="elevation-12">
    <v-toolbar dark color="secondary">
      <v-toolbar-title>Nova senha</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form>
        <v-text-field
          id="password"
          prepend-icon="lock"
          name="password"
          label="Insira nova senha"
          type="password"
          v-model="newPassword"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        :loading="isLoading"
        color="primary"
        @click="changePassword()"
        id="buttonLogin"
        block
        class="mx-2 buttonFull"
      >
        Alterar senha
      </v-btn>
    </v-card-actions>
    <v-card-actions>
      <a @click="$emit('component-change', 'Login')" id="forgotPassword" class="ml-2">Login</a>
    </v-card-actions>
  </v-card>
</template>
<script>
import changePasswordMutation from '@/graphql/login/changePassword.gql';

export default {
  data: () => ({
    isLoading: false,
    newPassword: '',
  }),
  methods: {
    changePassword() {
      this.isLoading = true;
      const mutation = changePasswordMutation;
      const variables = {
        passwordResetId: this.idPasswordReset,
        newPassword: this.newPassword,
      };

      this.$root.$apolloProvider.defaultClient
        .mutate({
          mutation,
          variables,
        })
        .then(() => this.finish('Senha alterada com sucesso!'))
        .catch(() => this.finish('Erro. Tente novamente.'));
    },
    finish(message) {
      this.isLoading = false;
      this.$emit('textSnackbar', message);
    },
  },
  props: ['idPasswordReset'],
};
</script>
