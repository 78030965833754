var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    [
      _c(
        "v-flex",
        [
          _vm.tableContent.isLoading
            ? _c("LoadingCircle")
            : _c(
                "div",
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.tableContent.headerList,
                      items: _vm.tableContent.contentList,
                      "rows-per-page-items": _vm.tableContent.itensPerPage,
                      "rows-per-page-text": _vm.tableContent.textPerPage,
                      "disable-initial-sort": "",
                      "no-data-text": _vm.tableContent.noResult
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return _vm._l(props.item, function(
                            item,
                            name,
                            index
                          ) {
                            return _c(
                              "td",
                              { key: index },
                              [
                                name === "minimumQuantity"
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function({ on }) {
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "suggestionProducts",
                                                        attrs: {
                                                          label:
                                                            "Definir quantidade mínima.",
                                                          value: item.value,
                                                          type: "number"
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            return _vm.$emit(
                                                              "inputedMinimumQuantity",
                                                              {
                                                                idProduct:
                                                                  item.idProduct,
                                                                minimumQuantity: $event
                                                              }
                                                            )
                                                          }
                                                        }
                                                      },
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.toolTipDetails.text)
                                          )
                                        ])
                                      ]
                                    )
                                  : Array.isArray(item)
                                  ? _vm._l(item, function(
                                      subItem,
                                      subName,
                                      subKey
                                    ) {
                                      return _c("p", { key: subKey }, [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(subItem) +
                                            "\n            "
                                        )
                                      ])
                                    })
                                  : name === "qrcode"
                                  ? _c("p", [
                                      _c(
                                        "i",
                                        {
                                          staticClass: "material-icons",
                                          style: `cursor: pointer; font-size: 25pt;`,
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "urlForQrCode",
                                                item.url
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.icon) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ])
                                  : name === "edit"
                                  ? _c("p", [
                                      _c(
                                        "i",
                                        {
                                          staticClass: "material-icons",
                                          style: `cursor: pointer; font-size: 25pt;`,
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "edit",
                                                item.index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                edit\n              "
                                          )
                                        ]
                                      )
                                    ])
                                  : _c(
                                      "p",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit(
                                              "selectedItemIndex",
                                              props.index
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item) +
                                            "\n            "
                                        )
                                      ]
                                    )
                              ],
                              2
                            )
                          })
                        }
                      }
                    ])
                  })
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }