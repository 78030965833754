<template>
  <v-tooltip top left :class="this.detail.class">
    <v-btn icon slot="activator">
      <v-icon :color="this.detail.color || 'indigo'">{{ this.detail.icon }}</v-icon>
    </v-btn>
    <span>{{ this.detail.text }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: ['detail'],
};
</script>
<style>
.forTable {
  position: absolute;
  top: 20px;
  right: 20px;
}
.forInput {
  float: right;
}
</style>
