var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      attrs: { width: _vm.windowWidth < 960 ? `${_vm.windowWidth}px` : "100%" }
    },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "#0d1824", dark: "", flat: "" } },
        [_c("v-toolbar-title", [_vm._v("Nova movimentação")])],
        1
      ),
      _c(
        "v-container",
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-select", {
                    attrs: {
                      "menu-props": "auto",
                      items: _vm.types,
                      label: "Selecione o tipo de movimentação",
                      "item-text": "name",
                      "item-value": "value"
                    },
                    model: {
                      value: _vm.form.type,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "type", $$v)
                      },
                      expression: "form.type"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("placeSelect", {
                    attrs: { label: "Selecione o local" },
                    model: {
                      value: _vm.form.local.from,
                      callback: function($$v) {
                        _vm.$set(_vm.form.local, "from", $$v)
                      },
                      expression: "form.local.from"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _vm.form.type === "TRANSFERENCIA"
                    ? _c("placeSelect", {
                        attrs: {
                          removePlaceId: _vm.form.local.from.id,
                          label: "Selecione o destino"
                        },
                        model: {
                          value: _vm.form.local.to,
                          callback: function($$v) {
                            _vm.$set(_vm.form.local, "to", $$v)
                          },
                          expression: "form.local.to"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("productDescriptionSelect", {
                    on: {
                      selected: description => {
                        _vm.form.product.unit = description.unit
                        _vm.form.product.name = description.name
                      }
                    },
                    model: {
                      value: _vm.form.product.id,
                      callback: function($$v) {
                        _vm.$set(_vm.form.product, "id", $$v)
                      },
                      expression: "form.product.id"
                    }
                  })
                ],
                1
              ),
              _vm.form.product.id
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          placeholder: `Quantidade em ${_vm.longUnit} (${_vm.shortUnit})`,
                          type: "number"
                        },
                        model: {
                          value: _vm.form.product.amount,
                          callback: function($$v) {
                            _vm.$set(_vm.form.product, "amount", $$v)
                          },
                          expression: "form.product.amount"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-flex",
            {
              attrs: {
                xs12: "",
                "justify-center": "",
                "offset-md4": "",
                md4: ""
              }
            },
            [
              _c(
                "v-btn",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled: !_vm.form.product.amount,
                    color: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.addProduct()
                    }
                  }
                },
                [_vm._v("\n        Adicionar produto\n      ")]
              )
            ],
            1
          ),
          _c("h2", { staticClass: "mb-2" }, [_vm._v("Produtos")]),
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              !_vm.selectedProductList.length
                ? _c("h3", [_vm._v("Nenhum produto adicionado")])
                : _vm._e(),
              _vm._l(_vm.selectedProductList, function(product, index) {
                return _c(
                  "v-flex",
                  { key: index, attrs: { xs6: "", md2: "" } },
                  [
                    _c(
                      "v-layout",
                      [
                        _c("div", [
                          _c("h3", [_vm._v(_vm._s(product.name))]),
                          _c("p", [
                            _vm._v(
                              _vm._s(product.amount) +
                                _vm._s(_vm._f("shortUnit")(product.unit))
                            )
                          ])
                        ]),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function($event) {
                                return _vm.removeProduct(index)
                              }
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              { attrs: { title: "Sair", id: "buttonClose" } },
                              [_vm._v("close")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", flat: "" },
              on: {
                click: function($event) {
                  return _vm.closeModal()
                }
              }
            },
            [_vm._v("Fechar")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                loading: _vm.isLoading,
                color: "primary",
                disabled:
                  !_vm.selectedProductList.length ||
                  !_vm.form.local.from ||
                  (_vm.form.type === "TRANSFERENCIA" && !_vm.form.local.to)
              },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("\n      Confirmar\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }