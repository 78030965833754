var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      attrs: { width: _vm.windowWidth < 960 ? `${_vm.windowWidth}px` : "100%" }
    },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "#0d1824", dark: "", flat: "" } },
        [
          _c("v-toolbar-title", [_vm._v("Cadastro de Local")]),
          _c("div", { staticClass: "flex-grow-1" })
        ],
        1
      ),
      _vm.idPlaceEdit
        ? _c(
            "v-tabs",
            {
              attrs: { color: "#0d1824", dark: "" },
              model: {
                value: _vm.activeTab,
                callback: function($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab"
              }
            },
            _vm._l(_vm.tabButtons, function(tab) {
              return _c(
                "v-tab",
                {
                  key: tab.name,
                  on: {
                    click: function($event) {
                      _vm.selectedTab = tab.value
                    }
                  }
                },
                [_vm._v("\n      " + _vm._s(tab.name) + "\n    ")]
              )
            }),
            1
          )
        : _vm._e(),
      _vm.idPlaceEdit
        ? _c(_vm.selectedTab, {
            tag: "component",
            attrs: { idPlaceEdit: _vm.idPlaceEdit },
            on: {
              showModal: function($event) {
                _vm.showModal = $event
              }
            }
          })
        : _c("CreationPlaceTab", {
            on: {
              showModal: function($event) {
                _vm.showModal = $event
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }