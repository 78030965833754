<template>
  <v-layout>
    <v-flex id="hideMobile">
      <v-dialog
        :close-on-content-click="true"
        :nudge-right="40"
        ref="startMenu"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="selectedText"
          :label="label"
          prepend-icon="event"
          readonly
        />
        <v-date-picker
          locale="pt-br"
          @change="$emit('input', $event)"
          :value="value"
          no-title
          :type="type"
          scrollable
        ></v-date-picker>
      </v-dialog>
    </v-flex>
    <v-flex id="hideDesk">
      <v-menu
        :close-on-content-click="true"
        :nudge-right="40"
        ref="startMenu"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="selectedText"
          :label="label"
          :error-messages="errorMessage"
          prepend-icon="event"
          readonly
        />
        <v-date-picker
          locale="pt-br"
          @change="$emit('input', $event)"
          :value="value"
          no-title
          :type="type"
          scrollable
        ></v-date-picker>
      </v-menu>
    </v-flex>
  </v-layout>
</template>

<script>
import * as moment from 'moment';
import { validationRequired } from '@/services/UtilsService';

export default {
  data: () => ({
    validationRequired,
  }),
  computed: {
    selectedText() {
      return this.value
        ? moment(this.value).format(`${this.type === 'month' ? '' : 'DD/'}MM/YYYY`)
        : '';
    },
    errorMessage() {
      return this.rules && this.rules.length && !this.selectedText
        ? this.rules.reduce(
            (accumulator, rule) =>
              rule(this.selectedText) !== true ? rule(this.selectedText) : '',
            '',
          )
        : '';
    },
  },
  props: ['label', 'value', 'rules', 'type'],
};
</script>

<style>
#hideMobile {
  display: none;
}

@media screen and (max-width: 960px) {
  #hideDesk {
    display: none;
  }
  #hideMobile {
    display: block;
  }
}
</style>
