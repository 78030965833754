<template>
  <div>
    <v-btn icon @click="openModal">
      <v-icon color="#ffffff">assessment</v-icon>
    </v-btn>
    <v-dialog v-model="isModalOpen" max-width="600px">
      <v-toolbar color="#0d1824" dark flat>
        <v-toolbar-title>Análises</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="isModalOpen = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <div class="container">
          <LoadingCircle v-if="isLoading" />
          <div class="list" v-else>
            <div class="no-data" v-if="!analyzes.length && !isLoading">
              Sem análises
            </div>
            <div
              class="list-item"
              v-for="analyze in analyzes"
              :key="analyze.id"
              @click="handleAnalyseClick(analyze)"
            >
              <div class="list-item-images">
                <v-img
                  min-width="100px"
                  :src="analyze.urlPhotos[0]"
                  v-if="analyze.urlPhotos?.length"
                ></v-img>
                <div class="image-placeholder" v-else></div>
                <v-img
                  min-width="100px"
                  :src="analyze.completionUrlPhotos[0]"
                  v-if="analyze.completionUrlPhotos?.length"
                ></v-img>
                <div class="image-placeholder" v-else></div>
                <v-img
                  min-width="100px"
                  :src="analyze.urlTestStripPhotos[0]"
                  v-if="analyze.urlTestStripPhotos?.length"
                ></v-img>
                <div class="image-placeholder" v-else></div>
              </div>
              <div class="list-item-content">
                <div class="list-item-title" v-if="analyze.employee">
                  {{ analyze.employee.name }}
                </div>
                <div class="list-item-subtitle" v-if="analyze?.pool?.local">
                  {{ analyze.pool.local.localName }}
                </div>
                <div class="list-item-subtitle">{{ analyze.createDay | formatDate }}</div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getAnalyzesCreateDay } from '@/services/AnalyzeService';
import LoadingCircle from '@/components/animations/LoadingCircle.vue';

export default {
  name: 'ShowAnalyzesButton',
  components: {
    LoadingCircle,
  },
  data() {
    return {
      isLoading: false,
      analyzes: [],
      isModalOpen: false,
    };
  },
  methods: {
    async openModal() {
      this.isLoading = true;
      this.isModalOpen = true;
      this.analyzes = await getAnalyzesCreateDay(this.$apollo, {
        where:
          this.originId.length > 30
            ? { deviceId: this.originId }
            : {
                employee: {
                  user: { id: this.originId },
                },
              },
      });
      this.isLoading = false;
    },
    handleAnalyseClick(analyze) {
      const page = `/dashboard/analises?id=${analyze.id}`;
      window.open(page, '_blank');
    },
  },
  watch: {
    isModalOpen(val) {
      if (!val) {
        setTimeout(() => {
          this.analyzes = [];
        }, 300);
      }
    },
  },
  filters: {
    formatDate(value) {
      const date = new Date(value);
      return date.toLocaleString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    },
  },
  props: {
    originId: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
}

.list-item-content {
  display: flex;
  flex-direction: column;
}

.list-item-title {
  font-size: 16px;
  font-weight: 500;
}

.list-item-subtitle {
  font-size: 14px;
  color: #9e9e9e;
}

.no-data {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  color: #9e9e9e;
}

.container {
  min-height: 450px;
  min-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-item-images {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  height: 100px;
}

.image-placeholder {
  width: 100px;
  height: 100px;
}
</style>
