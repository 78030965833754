var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "px-3 py-1",
      attrs: { color: "#0D1824", dark: "", id: "cardFilter" }
    },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-layout", { attrs: { wrap: "" } }, [_vm._t("default")], 2),
          _vm.searchButton
            ? _c(
                "v-btn",
                {
                  attrs: { icon: "", outline: "", id: "submitFilter" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("clickOnSearch")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { title: "Buscar" } }, [
                    _vm._v("search")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.resetButton
            ? _c(
                "v-btn",
                {
                  attrs: { icon: "", outline: "", id: "resetFields" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("clickOnReset")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { title: "Limpar filtro" } }, [
                    _vm._v("undo")
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }