<template>
  <div style="width: 100%" id="listVisitorsAnalyzes" row class="mt-2">
    <v-toolbar :clipped-left="$vuetify.breakpoint.lgAndUp" color="#0D1824" dark app fixed>
      <v-layout>
        <v-flex xs6>
          <a href="https://piscinapp.com.br/">
            <img src="/assets/img/logo-piscinapp-35px.png" style="margin-top: 6px" />
          </a>
        </v-flex>
      </v-layout>
    </v-toolbar>
    <div class="pool-board">
      <div style="width: 100%">
        <v-btn
          flat
          large
          :disabled="currentIndex === listAnalyzes.length - 1 || !currentAnalyze.id"
          @click="currentIndex += 1"
        >
          <v-layout>
            <v-flex>
              <v-icon class="material-icons v-icon">arrow_back</v-icon><br />
              <span class="caption">Anterior</span>
            </v-flex>
          </v-layout>
        </v-btn>
        <v-btn
          flat
          large
          style="float: right"
          @click="currentIndex -= 1"
          :disabled="!currentIndex || !currentAnalyze.id"
        >
          <v-flex>
            <v-icon class="material-icons v-icon">arrow_forward</v-icon>
            <br />
            <span class="caption">Próxima</span>
          </v-flex>
        </v-btn>
      </div>
      <v-card height="250" class="ma-4 text-xs-center" v-if="isLoading">
        <br />
        <v-progress-circular indeterminate color="primary" class="loader"></v-progress-circular>
      </v-card>
      <v-card class="ma-4 text-xs-center" v-else-if="!currentAnalyze.id">
        <p class="headline pt-5 pb-5">Ainda não há análises<br />para esta piscina</p>
      </v-card>
      <v-card class="mx-4" v-else>
        <v-img @click="openBetterPhoto()" :src="currentAnalyze | photoUrl" />
        <v-card-text>
          <div>
            <div class="info-header">
              {{ this.currentAnalyze.pool.local?.localName }}
              <h2>{{ this.currentAnalyze.pool.name }}</h2>
            </div>
            <v-img
              v-if="currentAnalyze.pool.local?.franchisee?.myBusinessLink"
              @click="openMyBusiness()"
              class="google-business-card"
              src="/assets/img/google.png"
            ></v-img>
          </div>
          <div class="analyze-data mb-3">
            <div class="report-section"><h4>Última análise</h4></div>
            <div class="report-section py-0" v-if="currentAnalyze.employee">
              <v-icon class="mr-2">person</v-icon>
              <div>
                <b>Nome do piscineiro: </b><br />
                <span class="text--primary">{{ currentAnalyze.employee.name }}</span>
              </div>
            </div>
            <v-divider v-if="currentAnalyze.createDate"></v-divider>
            <div class="report-section py-0" v-if="currentAnalyze.createDate">
              <v-icon class="mr-2">date_range</v-icon>
              <div>
                <b>Data: </b><br /><span class="text--primary">{{
                  currentAnalyze.createDate
                }}</span>
              </div>
            </div>
            <v-divider v-if="currentAnalyze.createHour"></v-divider>
            <div class="report-section py-0" v-if="currentAnalyze.createHour">
              <v-icon class="mr-2">schedule</v-icon>
              <div>
                <b>Hora: </b><br />
                <span class="text--primary">{{ currentAnalyze.createHour }}</span>
              </div>
            </div>
            <div v-if="currentAnalyze.visit" class="report-section"><h4>Visita</h4></div>
            <div class="report-section py-0" v-if="currentAnalyze.startVisitDateTime">
              <v-icon class="mr-2">date_range</v-icon>
              <div>
                <b>Início: </b><br />
                <span class="text--primary">{{ currentAnalyze.startVisitDateTime }}</span>
              </div>
            </div>
            <v-divider v-if="currentAnalyze.endVisitDateTime"></v-divider>
            <div class="report-section py-0" v-if="currentAnalyze.endVisitDateTime">
              <v-icon class="mr-2">date_range</v-icon>
              <div>
                <b>Fim: </b><br />
                <span class="text--primary">{{ currentAnalyze.endVisitDateTime }}</span>
              </div>
            </div>
          </div>
          <div class="report-section">
            <v-icon>colorize</v-icon>
            <h4>Parâmetros físicos e químicos</h4>
          </div>
          <div class="report-section-item">
            <b>Volume:&nbsp;</b><span>{{ (currentAnalyze.pool.volume * 1000) | brNumber }}</span
            >&nbsp;Litros
          </div>
          <v-divider></v-divider>
          <div v-for="(parameter, index) in parameters" :key="index">
            <div class="report-section-item">
              <b>{{ parameter.label }}: </b>
              <span class="text--primary"
                >{{ currentAnalyze[parameter.key] | brNumber }}{{ parameter.textAfter }}</span
              >
            </div>
            <v-divider></v-divider>
          </div>
          <div
            v-if="
              currentAnalyze.suggestion &&
                currentAnalyze.suggestion.aplication &&
                currentAnalyze.suggestion.aplication.products.length
            "
          >
            <div class="report-section">
              <v-icon>format_color_fill</v-icon>
              <h4>Produtos Aplicados</h4>
            </div>
            <div
              v-for="(product, index) in currentAnalyze.suggestion.aplication.products"
              :key="index"
            >
              <div class="report-section-item">
                <b>{{ product.description.name }}: </b>
                <span class="text--primary"
                  >{{ product.amount }} {{ product.description.unit | unitOfMeasurement }}</span
                >
              </div>
              <v-divider></v-divider>
            </div>
          </div>
          <div v-if="currentAnalyze.servicesPerformed && currentAnalyze.servicesPerformed.length">
            <div class="report-section">
              <v-icon>checklist</v-icon>
              <h4>Serviços Realizados</h4>
            </div>
            <div v-for="(service, index) in currentAnalyze.servicesPerformed" :key="index">
              <div class="report-section-item">
                <b>{{ service }}</b>
              </div>
              <v-divider></v-divider>
            </div>
          </div>
          <div v-if="currentAnalyze.problemReported">
            <div class="report-section">
              <v-icon>warning</v-icon>
              <h4>Relato de problemas</h4>
            </div>
            <div class="report-section-item">{{ currentAnalyze.problemReported }}</div>
            <audio
              class="attached-media"
              v-for="(audioUrl, index) in currentAnalyze.problemAudioUrls"
              :key="index"
              controls="controls"
            >
              <source :src="audioUrl" />
            </audio>
            <video
              class="attached-media"
              v-for="(videoUrl, index) in currentAnalyze.problemVideoUrls"
              :key="index"
              controls="controls"
            >
              <source :src="videoUrl" />
            </video>
            <img
              class="attached-media"
              v-for="(photoUrl, index) in currentAnalyze.problemPhotoUrls"
              :key="index"
              :src="photoUrl"
            />
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import {
  formatedDateTime,
  getStatusAnalyzesIcon,
  getStatusAnalyzesText,
  getVisitorAnalyze,
  getVisitorAnalyzesWhere,
} from '@/services/AnalyzeService';
import { calcActiveChlorineGrams, calcTabletActiveChlorineGrams } from '@/services/ProductsService';
import { getRatingByPoolId, saveRating, updateRating } from '@/services/VisitorService';
import * as moment from 'moment';
import { setVisitorToken } from '../../vue-apollo';

export default {
  data: () => ({
    imageUrl: [],
    parameters: [
      { label: 'Temperatura', key: 'temperature', textAfter: '°' },
      { label: 'Cloro estimado após tratamento', key: 'chlorineToShow' },
      { label: 'Cloro antes do tratamento', key: 'freeChlorine' },
      { label: 'PH', key: 'ph' },
      { label: 'Alcalinidade', key: 'alkalinity' },
      { label: 'Cianúrico', key: 'cyanuric' },
      { label: 'Dureza', key: 'hardness' },
      { label: 'gH', key: 'gh' },
      { label: 'kH', key: 'kh' },
      { label: 'Nitrito', key: 'nitrite' },
      { label: 'Nitrato', key: 'nitrate' },
      { label: 'Amônia Total', key: 'totalAmmonia' },
      { label: 'Sal', key: 'salt' },
    ],
    urlAnalyzeId: '',
    ratedPools: localStorage.ratedPools ? JSON.parse(localStorage.ratedPools) : [],
    evaluationId: '',
    isLoading: true,
    listAnalyzes: [],
    currentIndex: 0,
    currentAnalyze: {},
    scoreEvaluation: 0,
    formEvaluation: {
      email: '',
      comment: '',
    },
    averagePool: {
      rating: '',
      amountEvaluation: '',
    },
    averageRatingsPools: [],
    showModalEvaluation: '',
    showModalAnalyzes: '',
    localName: '',
    poolId: '',
    searchNameOrPlace: '',
    startDate: '',
    endDate: '',
    poolList: [],
    poolSelected: '',
    statusList: [
      { name: 'Todas', value: 'ALL' },
      { name: 'Aplicada', value: 'APPLIED' },
      { name: 'Aberta', value: 'OPEN' },
      { name: 'Sugerida', value: 'SUGGESTED' },
      { name: 'Sem aplicação', value: 'WITHOUT_SUGGESTED' },
    ],
    columns: [
      { text: 'Data', datakey: 'dateTime', size: 2 },
      { text: 'Colaborador', datakey: 'employeeName', size: 4 },
      { text: 'Piscina', datakey: 'poolName', size: 3 },
      { text: 'Status', datakey: 'status', size: 2 },
      { text: 'Fotos', datakey: 'photo', size: 1 },
    ],
  }),
  methods: {
    openBetterPhoto() {
      const betterPhotoUrl =
        this.currentAnalyze.completionUrlPhotos[2] ||
        this.currentAnalyze.completionUrlPhotos[1] ||
        this.currentAnalyze.completionUrlPhotos[0] ||
        this.currentAnalyze.urlPhotos[2] ||
        this.currentAnalyze.urlPhotos[1] ||
        this.currentAnalyze.urlPhotos[0];
      window.open(betterPhotoUrl);
    },
    openMyBusiness() {
      window.open(this.currentAnalyze.pool.local.franchisee.myBusinessLink);
    },
    isEvaluationAlreadySubmitted() {
      return this.ratedPools.some((pool) => pool === this.poolId);
    },
    filterScoreToString(score) {
      switch (score) {
        case 5:
          return 'Amei';
        case 4:
          return 'Gostei';
        case 3:
          return 'Razoável';
        case 2:
          return 'Não gostei';
        case 1:
          return 'Detestei';
        default:
          return '';
      }
    },
    async editRating() {
      const variables = {
        ratingId: this.evaluationId,
        score: this.scoreEvaluation,
        email: this.formEvaluation.email,
        comment: this.formEvaluation.comment,
      };

      return updateRating(this.$apollo, variables)
        .then(() => {
          this.showModalEvaluation = !this.showModalEvaluation;
        })
        .catch(() => {
          /* snackbar */
        });
    },
    async submitRating() {
      const variables = {
        poolId: this.currentAnalyze.pool.id,
        score: this.scoreEvaluation,
        email: this.formEvaluation.email,
        comment: this.formEvaluation.comment,
      };

      return saveRating(this.$apollo, variables)
        .then((resp) => resp.createRating.id)
        .catch(() => {
          /* snackbar */
        });
    },
    formatList(analyzes) {
      return analyzes.map((analyze) => ({
        id: analyze.id,
        dateTime: formatedDateTime(analyze.createDay),
        employeeName: analyze.employee.name,
        poolName: analyze.pool.name,
        poolId: analyze.pool.id,
        status: {
          text: getStatusAnalyzesText(analyze.status),
          icon: getStatusAnalyzesIcon(analyze.status),
        },
        photo: analyze.urlPhotos[0],
        local: analyze.pool.local,
      }));
    },
    async loadAnalyze() {
      this.isLoading = true;
      const currentAnalyze = (
        await getVisitorAnalyze(this.$apollo, {
          id: this.listAnalyzes[this.currentIndex].id,
        })
      ).analyze;

      if (!currentAnalyze.problemReportedFileUrls) {
        currentAnalyze.problemReportedFileUrls = [];
      }
      const videoReg = /[/.](mkv|mp4|avi|ogv|webm|mov|wmv)$/i;
      currentAnalyze.problemVideoUrls = currentAnalyze.problemReportedFileUrls.filter((url) =>
        url.split('?')[0].match(videoReg),
      );
      const imageReg = /[/.](gif|jpg|jpeg|tiff|png|webp)$/i;
      currentAnalyze.problemPhotoUrls = currentAnalyze.problemReportedFileUrls.filter((url) =>
        url.split('?')[0].match(imageReg),
      );
      const audioReg = /[/.](mp3|aac|wma|mp2|wav|ogg|flac)$/i;
      currentAnalyze.problemAudioUrls = currentAnalyze.problemReportedFileUrls.filter((url) =>
        url.split('?')[0].match(audioReg),
      );

      if (currentAnalyze.visit) {
        currentAnalyze.startVisitDateTime = moment(currentAnalyze.visit.start).format(
          'DD/MM/YY HH:mm',
        );
        currentAnalyze.endVisitDateTime = moment(currentAnalyze.visit.ends).format(
          'DD/MM/YY HH:mm',
        );
      } else {
        currentAnalyze.createDate = moment(currentAnalyze.createDay).format('DD/MM/YY');
        currentAnalyze.createHour = moment(currentAnalyze.createDay).format('HH:mm');
      }

      currentAnalyze.chlorineToShow = this.calcChlorineToShow(currentAnalyze);
      this.parameters = this.parameters.filter(
        (parameter) =>
          currentAnalyze[parameter.key] !== 99 && currentAnalyze[parameter.key] !== undefined,
      );
      return currentAnalyze;
    },
    calcChlorineToShow(currentAnalyze) {
      if (currentAnalyze.freeChlorine === 99) {
        return '';
      }
      let totalActiveChlorine = 0;
      if (currentAnalyze.suggestion && currentAnalyze.suggestion.aplication) {
        const activeChlorine = calcActiveChlorineGrams(
          currentAnalyze.suggestion.aplication.products,
        );
        const tabletActiveChlorineGrams = calcTabletActiveChlorineGrams(
          currentAnalyze.suggestion.aplication.products,
        );
        totalActiveChlorine = activeChlorine + tabletActiveChlorineGrams;
      }
      if (totalActiveChlorine) {
        const appliedPpm = totalActiveChlorine / currentAnalyze.pool.volume;
        const instantChlorine = appliedPpm * 0.6 + currentAnalyze.freeChlorine;
        return instantChlorine.toFixed(1);
      }
      return currentAnalyze.freeChlorine;
    },
    async getAnalyzes(path) {
      const variables = {
        piscina: { where: { pool: { id: this.poolId } } },
        analise: { where: { pool: { analyze_some: { id: this.urlAnalyzeId } } } },
      };
      return (await getVisitorAnalyzesWhere(this.$apollo, variables[path])).analyzes;
    },
    returnStartIcon(index, averageRating) {
      const countStar = averageRating - (index - 1);
      let starIcon;
      if (countStar >= 1) {
        starIcon = 'star';
      } else if (countStar > 0) {
        starIcon = 'star_half';
      } else {
        starIcon = 'star_border';
      }
      return starIcon;
    },
    async getAverageByPoolId(poolId, newScore) {
      const ratings = (await getRatingByPoolId(this.$apollo, { poolId })).ratings;
      if (newScore && ratings.some((rating) => rating.id === this.evaluationId)) {
        ratings[ratings.length - 1].score = newScore;
      } else if (newScore) {
        ratings.push({ id: this.evaluationId, score: newScore });
      }
      return {
        rating: this.calculateAverage(ratings),
        amountEvaluation: ratings.length,
      };
    },
    calculateAverage(ratings) {
      return !ratings
        ? ''
        : ratings.reduce((sumScore, rating, index, array) => {
            const average =
              index !== array.length - 1
                ? Number(sumScore + rating.score)
                : ((sumScore + rating.score) / array.length).toFixed(1);
            return average;
          }, '');
    },
  },
  filters: {
    unitOfMeasurement(value) {
      const units = {
        KILO: 'Kg',
        LITRO: 'L',
        METRO: 'm',
        UNIT: 'Un',
      };
      return units[value];
    },
    brNumber(number) {
      if (!number && number !== 0) {
        return '';
      }
      return Number(number).toLocaleString('pt-BR');
    },
    photoUrl(analyze) {
      if (!analyze) return '';
      return window.innerWidth < 960
        ? analyze.completionUrlPhotos[0] || analyze.urlPhotos[0]
        : analyze.completionUrlPhotos[2] ||
            analyze.completionUrlPhotos[1] ||
            analyze.completionUrlPhotos[0] ||
            analyze.urlPhotos[2] ||
            analyze.urlPhotos[1] ||
            analyze.urlPhotos[0];
    },
  },
  watch: {
    async currentIndex() {
      this.currentAnalyze = await this.loadAnalyze();
      this.isLoading = false;
    },
    async scoreEvaluation(score) {
      this.showModalEvaluation = true;
      if (!this.evaluationId) {
        this.evaluationId = await this.submitRating();
        this.ratedPools.push(this.poolId);
        localStorage.ratedPools = JSON.stringify(this.ratedPools);
      }
      this.averagePool = await this.getAverageByPoolId(this.poolId, score);
    },
  },
  async created() {
    const VISITOR_TOKEN = process.env.VUE_APP_VISITOR_TOKEN;
    await setVisitorToken(VISITOR_TOKEN);

    const urlParts = window.location.pathname.split('/');
    this.poolId = urlParts[2] === 'piscina' && urlParts[3];
    this.urlAnalyzeId = urlParts[2] === 'analise' && urlParts[3];
    this.listAnalyzes = await this.getAnalyzes(urlParts[2]);

    // this.averagePool = await this.getAverageByPoolId(this.poolId);
    if (this.listAnalyzes.length) {
      this.currentIndex = this.urlAnalyzeId
        ? this.listAnalyzes.findIndex((analyze) => analyze.id === this.urlAnalyzeId)
        : 0;
      this.currentAnalyze = await this.loadAnalyze();
    }

    this.isLoading = false;
  },
};
</script>

<style scoped>
.attached-media {
  width: 100%;
  padding-bottom: 15px;
}
.report-section {
  display: flex;
  align-items: center;
  padding: 20px 10px 10px 10px;
}
h4 {
  color: rgba(0, 0, 0, 0.54);
}
.report-section-item {
  padding: 10px;
}
.v-icon {
  padding-right: 15px;
}
.pool-board {
  width: 960px;
  margin: auto;
}
.google-business-card {
  cursor: pointer;
  border-radius: 10px;
  float: right;
  width: 190px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
}
.info-header {
  float: left;
}
.analyze-data {
  margin-top: 70px;
}

@media screen and (max-width: 960px) {
  .analyze-data {
    margin-top: 0px;
  }
  .info-header {
    float: none;
  }
  .google-business-card {
    float: none;
    margin: 10px auto;
  }
  #listVisitorsAnalyzes .hideMobile {
    display: none;
  }
  .pool-board {
    width: 100%;
    margin: auto;
  }
}
</style>
