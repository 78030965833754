<template>
  <v-card>
    <v-toolbar color="#0d1824" dark flat>
      <v-toolbar-title>Cadastro de Produto</v-toolbar-title>
      <div class="flex-grow-1"></div>
    </v-toolbar>
    <v-container v-if="!isLoading">
      <v-form ref="form">
        <v-layout wrap>
          <v-flex xs12>
            <v-switch
              style="float: right"
              v-if="idProductEdit"
              v-model="newProductDescription.isEnable"
              :label="newProductDescription.isEnable ? 'Produto ativo' : 'Produto inativo'"
            />
          </v-flex>
          <v-flex xs12>
            <v-text-field
              label="Nome *"
              v-model="newProductDescription.name"
              id="productName"
              :rules="[validationRequired]"
              :disabled="!isAdmin && !isCoordinador"
            />
          </v-flex>
          <v-flex xs12>
            <v-text-field
              label="Pureza química (%)"
              v-model="newProductDescription.chemicalPurity"
              id="chemicalPurity"
              type="number"
              :disabled="!isAdmin && !isCoordinador"
            />
          </v-flex>
          <v-flex xs12>
            <v-select
              menu-props="auto"
              :items="units"
              name="unit"
              label="Unidade de medida *"
              v-model="newProductDescription.unit"
              item-text="name"
              item-value="value"
              :rules="[validationRequired]"
              :disabled="!isAdmin && !isCoordinador"
            />
          </v-flex>
          <v-flex xs12>
            <v-select
              menu-props="auto"
              :items="types"
              name="type"
              label="Tipo"
              v-model="newProductDescription.type"
              item-text="name"
              item-value="value"
              :rules="[]"
              :disabled="!isAdmin && !isCoordinador"
            />
          </v-flex>
          <v-flex xs12>
            <v-select
              menu-props="auto"
              :items="categories"
              name="category"
              label="Categoria"
              v-model="newProductDescription.category"
              item-text="name"
              item-value="value"
              :rules="[]"
              :disabled="!isAdmin && !isCoordinador"
            />
          </v-flex>
          <v-flex xs12>
            <v-select
              menu-props="auto"
              :items="utilities"
              name="utility"
              label="Utilidade"
              v-model="newProductDescription.utility"
              item-text="name"
              item-value="value"
              :rules="[]"
              :disabled="!isAdmin && !isCoordinador"
            />
          </v-flex>
          <div
            class="uploaded-files"
            v-for="(document, index) in newProductDescription.documents"
            :key="index"
          >
            <v-icon>insert_drive_file</v-icon>
            <div class="uploaded-file" @click="downloadFile(document.url)">{{ document.name }}</div>
            <v-icon @click="removeDocument(index)" style="cursor: pointer; color: red">
              delete
            </v-icon>
          </div>
          <v-flex xs12>
            <div id="file-input-container" @dragover="dragOver" @dragleave="dragLeave">
              <div class="file-input-label-wrap">
                <span class="file-input-label" v-if="!isUploadLoading">
                  Adicionar documentos
                  <span class="file-input-label-small">
                    <br />
                    Arraste e solte o arquivo aqui ou clique para selecionar
                  </span>
                </span>
                <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
              </div>
              <input
                class="file-input"
                type="file"
                id="compressed-upload-input"
                @change="onFileChange"
              />
            </div>
          </v-flex>
        </v-layout>
      </v-form>
      <v-card-actions>
        <v-spacer />
        <v-btn flat color="primary" @click="closeModal()" id="closeModal">Fechar</v-btn>
        <v-btn
          :loading="isLoading"
          id="submitProduct"
          :disabled="!newProductDescription.name || !newProductDescription.unit"
          color="primary"
          @click="submit()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-container>
    <div v-else class="text-xs-center">
      <v-progress-circular indeterminate color="primary" class="loader ma-5"></v-progress-circular>
    </div>
  </v-card>
</template>

<script>
import { validationRequired, isRole, alertSnack } from '@/services/UtilsService';
import {
  getProductDescription,
  createProductDescription,
  updateProductDescription,
} from '@/services/ProductsService';
import { uploadFile } from '@/services/UploadService';

export default {
  data: () => ({
    isLoading: false,
    isUploadLoading: false,
    isAdmin: isRole('ADMIN'),
    isCoordinador: isRole('COORDINADOR'),
    validationRequired,
    newProductDescription: {
      name: '',
      chemicalPurity: '',
      unit: '',
      type: '',
      category: '',
      utility: '',
      isEnable: true,
      documents: [],
    },
    dataProduct: '',
    dialog: '',
    productName: '',
    chemicalPurity: null,
    unit: '',
    units: [
      { name: 'Quilograma (Kg)', value: 'KILO' },
      { name: 'Litro (L)', value: 'LITRO' },
      { name: 'Unidade (Un)', value: 'UNIT' },
      { name: 'Metro (m)', value: 'METRO' },
    ],
    categories: [
      { value: 'CLORO', name: 'Cloro' },
      { value: 'BASE', name: 'Base' },
      { value: 'DECANTADOR', name: 'Decantador' },
      { value: 'AUXILIAR_DE_FILTRACAO', name: 'Auxiliar de Filtração' },
      { value: 'ALGICIDA', name: 'Algicida' },
      { value: 'LIMPA_BORDAS', name: 'Limpa Bordas' },
      { value: 'ACIDO', name: 'Ácido' },
      { value: 'OXIDANTE', name: 'Oxidante' },
    ],
    utilities: [
      { value: 'ELEVADOR_DE_PH', name: 'Elevador de pH' },
      { value: 'ELEVADOR_DE_ALCALINIDADE', name: 'Elevador de Alcalinidade' },
      { value: 'MANUTENCAO', name: 'Manutenção' },
      { value: 'CHOQUE', name: 'Choque' },
      { value: 'REDUTOR_DE_PH', name: 'Redutor de pH' },
    ],
    types: [
      { value: 'TRICLORO', name: 'Tricloro' },
      { value: 'BARRILHA', name: 'Barrilha' },
      { value: 'PASTILHA_TRICLORO', name: 'Pastilha Tricloro' },
      { value: 'BICARBONATO_DE_SODIO', name: 'Bicarbonato de Sódio' },
      { value: 'PAC', name: 'PAC' },
      { value: 'POLIMERO', name: 'Polímero' },
      { value: 'HIPOCLORITO_DE_CALCIO', name: 'Hipoclorito de Cálcio' },
      { value: 'DICLORO', name: 'Dicloro' },
      { value: 'PQA', name: 'PQA' },
      { value: 'SULFATO_DE_COBRE', name: 'Sulfato de Cobre' },
      { value: 'CLARIFICANTE', name: 'Clarificante' },
      { value: 'SULFATO_DE_ALUMINIO', name: 'Sulfato de Alumínio' },
      { value: 'HIPOCLORITO_DE_SODIO', name: 'Hipoclorito de Sódio' },
    ],
  }),
  methods: {
    dragOver(e) {
      e.preventDefault();
      e.stopPropagation();
      const fileInputContainer = document.getElementById('file-input-container');
      fileInputContainer.style.background = '#d0d0d0';
    },
    dragLeave(e) {
      e.preventDefault();
      e.stopPropagation();
      const fileInputContainer = document.getElementById('file-input-container');
      fileInputContainer.style.background = '#f0f0f0';
    },
    downloadFile(url) {
      window.open(url, '_blank');
    },
    removeDocument(index) {
      this.newProductDescription.documents.splice(index, 1);
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;
      this.isUploadLoading = true;
      uploadFile(file)
        .then((url) => {
          this.newProductDescription.documents.push({
            name: file.name,
            url,
          });
          this.isUploadLoading = false;
        })
        .catch(() => {
          this.isUploadLoading = false;
          alertSnack('errorDefault');
        });
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      this.newProductDescription.isEnable = true;
    },
    closeModal() {
      this.$emit('input', false);
    },
    async getSingleProductDescription() {
      if (!this.idProductEdit) return;
      this.isLoading = true;
      const data = await getProductDescription(this.$apollo, { id: this.idProductEdit });
      this.newProductDescription = {
        name: data.name,
        chemicalPurity: data.chemicalPurity,
        unit: data.unit,
        category: data.category,
        utility: data.utility,
        type: data.type,
        isEnable: !data.isDisabled,
        documents: data.documentUrls.map((url) => ({
          url,
          name: url.substring(url.indexOf('%2') + 3, url.indexOf('?alt=media')),
        })),
      };
      this.isLoading = false;
    },
    submit() {
      const data = {
        name: this.newProductDescription.name,
        unit: this.newProductDescription.unit,
        chemicalPurity: this.newProductDescription.chemicalPurity,
        type: this.newProductDescription.type,
        category: this.newProductDescription.category,
        utility: this.newProductDescription.utility,
        isDisabled: !this.newProductDescription.isEnable,
        documentUrls: {
          set: this.newProductDescription.documents.map((doc) => doc.url),
        },
      };
      this.isLoading = true;
      if (!this.idProductEdit) {
        createProductDescription(this.$apollo, { data })
          .then(() => {
            alertSnack('saveDefault');
            this.closeModal();
          })
          .catch(() => {
            alertSnack('errorDefault');
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        updateProductDescription(this.$apollo, { id: this.idProductEdit, data })
          .then(() => {
            alertSnack('saveDefault');
            this.closeModal();
          })
          .catch(() => {
            alertSnack('errorDefault');
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
  watch: {
    idProductEdit(id) {
      if (id) this.getSingleProductDescription();
      else this.resetForm();
    },
  },
  props: ['idProductEdit', 'value'],
};
</script>

<style>
.uploaded-files {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
  justify-items: center;
  cursor: pointer;
}

.uploaded-file {
  flex: 1;
  margin: auto;
  margin-left: 8px;
  font-weight: 500;
}

#file-input-container {
  text-align: center;
  background-color: #e0e0e0;
  border-radius: 10px;
  border: 1px dashed #000;
  padding: 16px;
  cursor: pointer;
  width: 100%;
  color: #000;
  position: relative;
}

.file-input-label {
  font-size: 14pt;
}

.file-input-label-small {
  font-size: 10pt;
}

.file-input-label-wrap {
  text-align: center;
  display: inline-block;
}

.file-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
