<template>
  <v-autocomplete
    autocomplete="off"
    menu-props="auto"
    v-if="selectList.length"
    :disabled="disabled || selectList.length === 1"
    :loading="isLoading"
    :items="selectList"
    @change="$emit('input', $event)"
    :value="value"
    label="Selecione a franquia *"
    item-text="name"
    item-value="value"
    :rules="rules"
  />
  <p v-else>Não foi encontrada nenhuma franquia.</p>
</template>
<script>
import { getUsersFranchisee } from '@/services/UserService';

export default {
  data: () => ({
    franchisees: [],
    isLoading: false,
  }),
  computed: {
    selectList() {
      const formatedUserList = this.franchisees.map((franchisee) => ({
        name: franchisee.name,
        value: franchisee.id,
      }));
      formatedUserList.unshift({
        value: 'cjqs1fn49c2js0762hu91g5ti',
        name: 'Piscina Fácil',
      });
      if (this.allDataOption) {
        formatedUserList.unshift({ value: null, name: 'Todos os dados' });
      }
      return formatedUserList;
    },
  },
  methods: {
    async getUsers() {
      this.isLoading = true;
      const resp = await getUsersFranchisee(this.$apollo);
      this.isLoading = false;
      this.franchisees = resp.franchisees;
    },
  },
  created() {
    this.getUsers();
  },
  props: ['franchisee', 'rules', 'allDataOption', 'value', 'disabled'],
};
</script>
