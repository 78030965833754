var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "grid-list-xl": "" } },
    [
      _c(
        "v-expansion-panel",
        {
          attrs: { expand: "" },
          model: {
            value: _vm.panel,
            callback: function($$v) {
              _vm.panel = $$v
            },
            expression: "panel"
          }
        },
        [
          _c(
            "v-expansion-panel-content",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        [
                          _c(
                            "v-layout",
                            { staticClass: "justify-center align-center" },
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("account_circle")
                              ]),
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("h3", [
                                  _vm._v("Clientes vinculados a este local")
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      !_vm.isLoading
                        ? _c("CardList", {
                            attrs: {
                              sortBy: "name",
                              contentList: _vm.formatedUsers
                            },
                            on: {
                              click: element => {
                                _vm.panel = [0]
                                _vm.userShownId = element.id
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.isLoading ? _c("LoadingCircle") : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.userShownId
        ? _c("UserCreation", {
            staticClass: "mt-5",
            attrs: {
              idUserEdit: _vm.userShownId,
              showToolbar: false,
              showCloseButton: false
            },
            on: {
              showModal: function($event) {
                _vm.showModal = $event
              }
            }
          })
        : _vm._e(),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "primary" },
              on: {
                click: function($event) {
                  return _vm.closeModal()
                }
              }
            },
            [_vm._v("Fechar")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }