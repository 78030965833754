var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-layout",
        { staticClass: "grey lighten-3", attrs: { "justify-center": "" } },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "titleRegistration" }, [
              _vm._v(
                _vm._s(
                  _vm.localName ? `Estoque de ${_vm.localName}` : "Estoque"
                )
              )
            ])
          ])
        ],
        1
      ),
      _vm.filteredProducts < 1
        ? _c("v-card-text", [
            _c("h3", [_vm._v("Não há produtos no estoque deste local.")])
          ])
        : _c("v-card-text", [
            _c("table", { staticClass: "table", attrs: { lg5: "" } }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Produto")]),
                  _c("th", [_vm._v("Pureza")]),
                  !isNaN(_vm.filteredProducts[0].amount)
                    ? _c("th", [_vm._v("Quantidade")])
                    : _vm._e()
                ])
              ]),
              _c(
                "tbody",
                _vm._l(_vm.filteredProducts, function(product) {
                  return _c("tr", { key: product.id }, [
                    _c("td", { staticStyle: { "text-align": "left" } }, [
                      _vm._v(_vm._s(product.description.name))
                    ]),
                    _c("td", [
                      _vm._v(_vm._s(product.description.chemicalPurity) + " %")
                    ]),
                    !isNaN(product.amount)
                      ? _c("td", [
                          _vm._v(
                            "\n            " +
                              _vm._s(product.amount.toFixed(2)) +
                              "\n            " +
                              _vm._s(
                                _vm._f("units")(product.description.unit)
                              ) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ])
                }),
                0
              )
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }