import { query, mutation } from '@/services/GraphqlService';
import getTransferencesQuery from '@/graphql/transference/getTransferencesQuery.gql';
import getStockTabTransferencesQuery from '@/graphql/transference/getStockTabTransferencesQuery.gql';
import getTransferenceQuery from '@/graphql/transference/getTransferenceQuery.gql';
import createTransferenceMutation from '@/graphql/transference/createTransferenceMutation.gql';

export const getTransferences = (apollo, variables) =>
    query(apollo, getTransferencesQuery, variables);

export const getStockTabTransferences = (apollo, variables) =>
    query(apollo, getStockTabTransferencesQuery, variables);

export const getTransference = (apollo, id) =>
    query(apollo, getTransferenceQuery, { id });

export const createTransference = (apollo, variables) =>
    mutation(apollo, createTransferenceMutation, { data: variables });
