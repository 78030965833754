<template>
  <v-card v-if="!isLoading" class="elevation-12">
    <v-toolbar dark color="secondary">
      <v-toolbar-title>Cadastro de usuário</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form>
        <v-text-field
          prepend-icon="person"
          label="Nome"
          type="text"
          v-model="userName"
        ></v-text-field>
        <v-text-field
          prepend-icon="mail_outline"
          label="Email"
          type="text"
          v-model="email"
        ></v-text-field>
        <v-text-field
          prepend-icon="lock"
          label="Senha"
          type="password"
          v-model="password"
          :rules="[validationPassword]"
          validate-on-blur
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        :loading="isSavingUser"
        color="primary"
        @click="saveUser()"
        block
        class="mx-2 buttonFull"
      >
        Salvar
      </v-btn>
    </v-card-actions>
    <v-card-actions>
      <a @click="$emit('component-change', 'Login')" id="forgotPassword" class="ml-2">Voltar</a>
    </v-card-actions>
  </v-card>
</template>
<script>
import { createUser } from '@/services/UserService';
import { getPreRegistrationByLocal } from '@/services/PreRegistration';
import { validationPassword } from '@/services/UtilsService';

export default {
  data: () => ({
    validationPassword,
    isLoading: true,
    isSavingUser: false,
    localId: '',
    userName: '',
    email: '',
    password: '',
    telephoneId: '',
  }),
  methods: {
    saveUser() {
      this.isSavingUser = true;
      const variables = {
        data: {
          client: { create: { name: this.userName } },
          email: this.email,
          password: this.password,
          roles: { set: 'CLIENT' },
          places: { connect: [{ id: this.localId }] },
          localDefault: { connect: { id: this.localId } },
          telephones: { connect: { id: this.telephoneId } },
        },
      };
      createUser(this.$apollo, variables)
        .then(() => {
          this.$emit('textSnackbar', 'Usuário criado com sucesso.');
          this.$emit('component-change', 'Login');
        })
        .catch(() => {
          this.isSavingUser = false;
          this.$emit(
            'textSnackbar',
            'Sua senha deve ter pelo menos 1 letra minúscula, 1 letra maiúscula, 1 número e no minimo 8 caracteres.',
          );
        });
    },
  },
  async created() {
    this.localId = this.$router.app.$route.path.split('/')[2];
    const resp = await getPreRegistrationByLocal(this.$apollo, this.localId);
    this.isLoading = false;
    this.telephoneId = resp.preRegistrations[0].telephone.id;
    this.userName = resp.preRegistrations[0].name;
  },
};
</script>
