var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      attrs: { width: _vm.windowWidth < 960 ? `${_vm.windowWidth}px` : "100%" }
    },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "#0d1824", dark: "", flat: "" } },
        [_c("v-toolbar-title", [_vm._v("Movimentação")])],
        1
      ),
      _vm.transference
        ? _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "mr-2", attrs: { xs6: "", md2: "" } },
                    [
                      _c("h3", [_vm._v("Data")]),
                      _c("p", [
                        _vm._v(_vm._s(_vm._f("dateTime")(_vm.transference.day)))
                      ])
                    ]
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "mr-2", attrs: { xs6: "", md2: "" } },
                    [
                      _c("h3", [_vm._v("Tipo")]),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm._f("transferenceType")(_vm.transference.type)
                          )
                        )
                      ])
                    ]
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "mr-2", attrs: { xs6: "", md2: "" } },
                    [
                      _c("h3", [_vm._v("Colaborador")]),
                      _c("p", [_vm._v(_vm._s(_vm.transference.employee.name))])
                    ]
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "mr-2", attrs: { xs6: "", md2: "" } },
                    [
                      _c("h3", [_vm._v("Local")]),
                      _c("p", [_vm._v(_vm._s(_vm.transference.from.localName))])
                    ]
                  ),
                  _vm.transference.to
                    ? _c(
                        "v-flex",
                        { staticClass: "mr-2", attrs: { xs6: "", md2: "" } },
                        [
                          _c("h3", [_vm._v("Destino")]),
                          _c("p", [
                            _vm._v(_vm._s(_vm.transference.to.localName))
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("h2", { staticClass: "mb-2" }, [_vm._v("Produtos")]),
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                _vm._l(_vm.transference.products, function(product, index) {
                  return _c(
                    "v-flex",
                    {
                      key: index,
                      staticClass: "mr-2",
                      attrs: { xs6: "", md2: "" }
                    },
                    [
                      _c("h3", [_vm._v(_vm._s(product.description.name))]),
                      _c("p", [
                        _vm._v(
                          _vm._s(product.amount) +
                            _vm._s(
                              _vm._f("unitOfMeasurement")(
                                product.description.unit
                              )
                            )
                        )
                      ])
                    ]
                  )
                }),
                1
              ),
              _vm.transference.urlPhotos.length
                ? _c(
                    "v-carousel",
                    {
                      attrs: {
                        cycle: false,
                        "hide-delimiter-background": "",
                        "show-arrows-on-hover": ""
                      }
                    },
                    _vm._l(_vm.transference.urlPhotos, function(
                      urlPhoto,
                      index
                    ) {
                      return _c("v-carousel-item", {
                        key: index,
                        attrs: { src: urlPhoto }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "text-xs-center" },
            [
              _c("v-progress-circular", {
                staticClass: "loader ma-5",
                attrs: { indeterminate: "", color: "primary" }
              })
            ],
            1
          ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", flat: "" },
              on: {
                click: function($event) {
                  return _vm.closeModal()
                }
              }
            },
            [_vm._v("Fechar")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }