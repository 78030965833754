var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-toolbar",
            {
              attrs: {
                "clipped-left": _vm.$vuetify.breakpoint.lgAndUp,
                color: "#0D1824",
                dark: "",
                app: "",
                fixed: ""
              }
            },
            [
              _c(
                "v-layout",
                [
                  _c("v-flex", { attrs: { xs6: "" } }, [
                    _c("a", { attrs: { href: "https://piscinapp.com.br/" } }, [
                      _c("img", {
                        staticStyle: { "margin-top": "6px" },
                        attrs: { src: "/assets/img/logo-piscinapp-35px.png" }
                      })
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _vm.errorMessage
            ? _c(
                "v-layout",
                [
                  _c("v-spacer"),
                  _c(
                    "v-card",
                    { staticClass: "ma-4 pa-3", attrs: { "max-width": "500" } },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("b", [_vm._v(_vm._s(_vm.errorMessage))]),
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-2",
                              attrs: { color: "red", large: "" }
                            },
                            [_vm._v("error")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-spacer")
                ],
                1
              )
            : _vm._e(),
          _vm.pools.length
            ? _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                _vm._l(_vm.pools, function(pool, index) {
                  return _c(
                    "v-flex",
                    { key: index, attrs: { xl2: "", sm6: "", md4: "" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "ma-1 v-flex", attrs: { width: "300" } },
                        [
                          _c("v-img", {
                            staticClass: "center-cropped",
                            attrs: {
                              height: "150px",
                              src: _vm._f("photoUrl")(pool.analyze[0])
                            }
                          }),
                          _c("v-card-text", { staticClass: "pt-2 pl-2 pb-0" }, [
                            _c("b", [
                              _vm._v("Descrição: " + _vm._s(pool.name))
                            ]),
                            _c("div", [
                              _vm._v("Volume: " + _vm._s(pool.volume))
                            ])
                          ]),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    href: _vm._f("formatUrl")(pool.id),
                                    block: "",
                                    text: "",
                                    color: "blue"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              ver o resumo da limpeza\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : !_vm.errorMessage
            ? _c("LoadingCircle", { staticClass: "mt-3" })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }