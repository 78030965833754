<template>
  <v-card>
    <v-layout class="grey lighten-3" justify-center>
      <v-card-title>
        <h3 class="titleRegistration">{{ localName ? `Estoque de ${localName}` : 'Estoque' }}</h3>
      </v-card-title>
    </v-layout>
    <v-card-text v-if="filteredProducts < 1">
      <h3>Não há produtos no estoque deste local.</h3>
    </v-card-text>
    <v-card-text v-else>
      <table class="table" lg5>
        <thead>
          <tr>
            <th>Produto</th>
            <th>Pureza</th>
            <th v-if="!isNaN(filteredProducts[0].amount)">Quantidade</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in filteredProducts" :key="product.id">
            <td style="text-align: left">{{ product.description.name }}</td>
            <td>{{ product.description.chemicalPurity }} %</td>
            <td v-if="!isNaN(product.amount)">
              {{ product.amount.toFixed(2) }}
              {{ product.description.unit | units }}
            </td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({}),
  computed: {
    filteredProducts() {
      if (!this.products) {
        return [];
      }
      return this.products.filter((product) => product.amount > 0 || product.amount === undefined);
    },
  },
  methods: {},
  created() {},
  filters: {
    units(unit) {
      return (
        {
          LITRO: 'L',
          METRO: 'm',
          KILO: 'kg',
          UNIT: 'unid',
        }[unit] || 'Indisponível'
      );
    },
  },
  components: {},
  props: ['localName', 'products'],
};
</script>

<style scoped></style>
