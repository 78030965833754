<template>
  <v-container class="pt-0" grid-list-xl id="containerPlaceTab">
    <v-form ref="form" v-if="!isDataLoading">
      <v-layout v-if="!isClient">
        <v-flex xs12>
          <v-switch
            style="float: right"
            v-model="formPlace.isEnabled"
            :label="formPlace.isEnabled ? 'Local ativo' : 'Local inativo'"
          />
        </v-flex>
      </v-layout>

      <v-layout wrap>
        <v-flex xs12 md4 id="selectFranchisee" v-if="(isAdmin || isCoordinador) && !inUserCreation">
          <ToolTips :detail="toolTipDetails" v-if="toolTipDetails.show" />
          <FranchiseeSelect
            v-model="formPlace.franchiseeId"
            :rules="[validationRequired]"
            :disabled="Boolean(formPlace.connectedUsers && formPlace.connectedUsers.length)"
          />
        </v-flex>
        <v-flex xs12 md6 :class="inUserCreation || isFranchisee ? 'xs6' : 'xs4'">
          <v-text-field
            id="localName"
            label="Nome *"
            v-model="formPlace.localName"
            :rules="[validationRequired]"
            :disabled="isClient"
          />
        </v-flex>
        <v-flex xs12 md2 :class="inUserCreation || isFranchisee ? 'xs6' : 'xs4'" id="localType">
          <TypePlaceSelect
            v-model="formPlace.type"
            :rules="[validationRequired]"
            :disabled="isClient"
          />
        </v-flex>
      </v-layout>

      <v-layout wrap pb-4>
        <v-flex xs12>
          <v-card-title class="title">
            <v-icon>place</v-icon>
            Endereço
          </v-card-title>
        </v-flex>

        <v-flex xs12 md3 id="localState">
          <StatesSelect
            v-model="formPlace.state"
            :rules="[validationRequired]"
            :isDisabled="isClient"
          ></StatesSelect>
        </v-flex>
        <v-flex xs12 md3 id="localCity">
          <v-text-field
            v-model="formPlace.city"
            :rules="[validationRequired]"
            :disabled="!formPlace.state || isClient"
            menu-props="auto"
            name="city"
            label="Cidade *"
          />
        </v-flex>
        <v-flex xs12 md3>
          <v-text-field
            id="localNeighborhood"
            label="Bairro *"
            v-model="formPlace.neighborhood"
            :rules="[validationRequired]"
            :disabled="isClient"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md3>
          <v-text-field
            label="CEP"
            v-model="formPlace.cep"
            mask="#####-###"
            :disabled="isClient"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md10>
          <v-text-field
            id="localPublicPlace"
            label="Rua / Avenida *"
            v-model="formPlace.publicPlace"
            :rules="[validationRequired]"
            :disabled="isClient"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md2>
          <v-text-field
            id="localNumber"
            label="Número *"
            type="number"
            v-model="formPlace.number"
            :rules="[validationRequired]"
            :disabled="isClient"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md5>
          <v-text-field
            id="localComplement"
            label="Complemento"
            v-model="formPlace.complement"
            :disabled="isClient"
          />
        </v-flex>
        <v-flex xs12 md3>
          <v-text-field
            id="localAllowedDistance"
            label="Distância (m)"
            v-model="formPlace.allowedDistance"
            type="number"
            :disabled="isClient"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md2>
          <v-text-field
            id="localLatitude"
            label="Latitude *"
            v-model="formPlace.latitude"
            type="number"
            :rules="[validationLatAndLng]"
            :disabled="isClient"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md2>
          <v-text-field
            id="localLongitude"
            label="Longitude *"
            v-model="formPlace.longitude"
            type="number"
            :rules="[validationLatAndLng]"
            :disabled="isClient"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <gmap-autocomplete
        style="width: 100%; "
        class="v-label v-text-field"
        placeholder="Digite uma localização"
        @place_changed="setMapFocus"
      >
      </gmap-autocomplete>
      <googleMaps
        v-if="(formPlace.latitude && formPlace.longitude) || !this.idPlaceEdit"
        style="width: 100%; height: 500px;"
        :zoom="mapZoom"
        :center="mapCenter"
        @click="setLatLgn($event)"
        :defaultMarkers="markers"
        xs4
        title="Selecione o local no mapa para alterar sua latitude e longitude."
      ></googleMaps>
      <v-card-actions>
        <v-spacer />
        <v-btn flat color="primary" @click="closeModal()">Fechar</v-btn>
        <v-btn
          :loading="isSaveLoading"
          id="buttonSave"
          color="primary"
          v-if="!isClient"
          :disabled="isDisabledButtonSave()"
          @click="submitPlace()"
          >{{ this.inUserCreation ? 'Adicionar local' : 'Salvar' }}</v-btn
        >
      </v-card-actions>
    </v-form>
    <LoadingCircle v-if="isDataLoading" class="mt-3" />
  </v-container>
</template>

<script>
import {
  isRole,
  validationRequired,
  validationLatAndLng,
  alertSnack,
} from '@/services/UtilsService';
import { createPlace, updatePlace, getPlace, getPlaceForClient } from '@/services/PlacesService';

import FranchiseeSelect from '@/components/selects/FranchiseeSelect.vue';
import StatesSelect from '@/components/selects/StatesSelect.vue';
import TypePlaceSelect from '@/components/selects/TypePlaceSelect.vue';
import googleMaps from '@/components/maps/GoogleMaps.vue';
import ToolTips from '@/components/utils/ToolTips.vue';
import LoadingCircle from '@/components/animations/LoadingCircle.vue';

export default {
  data: () => ({
    isSaveLoading: false,
    isDataLoading: false,
    toolTipDetails: {
      icon: 'info',
      text: '',
      class: 'forInput',
      show: false,
      color: 'red',
    },
    isAdmin: isRole('ADMIN'),
    isClient: isRole('CLIENT'),
    isCoordinador: isRole('COORDINADOR'),
    isFranchisee: isRole('FRANCHISEE'),
    isLimited: isRole('LIMITED'),
    mapZoom: 2,
    mapCenter: '',
    formPlace: {
      localName: '',
      type: '',
      state: '',
      city: '',
      neighborhood: '',
      cep: '',
      publicPlace: '',
      number: '',
      complement: '',
      allowedDistance: '',
      latitude: '',
      longitude: '',
      isEnabled: true,
      franchiseeId: '',
      connectedUsers: [],
    },
    validationRequired,
    validationLatAndLng,
  }),
  computed: {
    markers() {
      return this.formPlace.latitude || this.formPlace.longitude
        ? [
            {
              position: {
                lat: Number(this.formPlace.latitude),
                lng: Number(this.formPlace.longitude),
              },
              title: 'Local principal',
            },
          ]
        : [];
    },
  },
  methods: {
    setMapFocus(place) {
      this.mapCenter = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.mapZoom = 17;
    },
    isRequiredFields(field) {
      return {
        localName: true,
        type: true,
        state: true,
        city: true,
        neighborhood: true,
        publicPlace: true,
        number: true,
        latitude: true,
        longitude: true,
        franchiseeId: !this.inUserCreation && !this.isFranchisee,
      }[field];
    },
    isDisabledButtonSave() {
      return Boolean(
        Object.keys(this.formPlace).find(
          (key) => this.isRequiredFields(key) && !this.formPlace[key],
        ),
      );
    },
    closeModal() {
      this.$emit('showModal', false);
      this.formPlace = this.$options.data().formPlace;
      this.$refs.form.resetValidation();
    },
    async getFormPlace() {
      if (this.isLimited) {
        this.formPlace.franchiseeId = localStorage.franchiseeId;
      }
      if (!this.idPlaceEdit) return;
      this.isDataLoading = true;
      const response = !this.isClient
        ? await getPlace(this.$apollo, {
            idPlace: this.idPlaceEdit,
          }).then((resp) => resp.local)
        : await getPlaceForClient(this.$apollo, {
            idUser: localStorage.userId,
            idPlace: this.idPlaceEdit,
          }).then((resp) => resp.user.places[0]);
      this.mapZoom = 16;
      this.mapCenter = {
        lat: Number(response.address.latitude),
        lng: Number(response.address.longitude),
      };
      this.formPlace = {
        localName: response.localName,
        type: response.type[0],
        state: response.address.state,
        city: response.address.city,
        neighborhood: response.address.neighborhood,
        cep: response.address.cep,
        publicPlace: response.address.publicPlace,
        number: response.address.number,
        complement: response.address.complement,
        allowedDistance: response.address.allowedDistance,
        latitude: response.address.latitude,
        longitude: response.address.longitude,
        isEnabled: !response.isDisabled,
        franchiseeId: response.franchisee.id,
        connectedUsers: response.user,
      };

      if (this.formPlace.connectedUsers && this.formPlace.connectedUsers.length) {
        this.toolTipDetails.text = 'Não é possível alterar a franquia deste local. ';
        this.toolTipDetails.text +=
          this.formPlace.connectedUsers.length === 1
            ? 'Há 1 usuário vinculado.'
            : `Há ${this.formPlace.connectedUsers.length} usuários vinculados.`;
        this.toolTipDetails.show = true;
      }
      this.isDataLoading = false;
    },
    setLatLgn(place) {
      this.formPlace.latitude = place.lat;
      this.formPlace.longitude = place.lng;
    },
    submitPlace() {
      const variables = this.normalizeData();
      if (this.inUserCreation) {
        this.$emit('variablesLocalCreation', variables.data);
        alertSnack('Inserido com sucesso.');
        this.closeModal();
        return;
      }
      this.isSaveLoading = true;
      const functionQuery = variables.id ? updatePlace : createPlace;
      functionQuery(this.$apollo, variables)
        .then(() => {
          alertSnack('Salvo com sucesso.');
          this.closeModal();
        })
        .catch(() => {
          alertSnack('errorDefault');
        })
        .finally(() => {
          this.isSaveLoading = false;
        });
    },
    normalizeData() {
      let normalizeFranchisee = null;
      if (this.isFranchisee) {
        normalizeFranchisee = { franchisee: { connect: { id: localStorage.franchiseeId } } };
      } else if (!this.inUserCreation) {
        normalizeFranchisee = { franchisee: { connect: { id: this.formPlace.franchiseeId } } };
      }
      return {
        ...(this.idPlaceEdit ? { id: this.idPlaceEdit } : null),
        data: {
          isDisabled: !this.formPlace.isEnabled,
          localName: this.formPlace.localName,
          type: { set: this.formPlace.type },
          address: {
            create: {
              number: this.formPlace.number,
              state: this.formPlace.state,
              city: this.formPlace.city,
              neighborhood: this.formPlace.neighborhood,
              cep: this.formPlace.cep,
              publicPlace: this.formPlace.publicPlace,
              complement: this.formPlace.complement,
              latitude: this.formPlace.latitude,
              longitude: this.formPlace.longitude,
            },
          },
          allowedDistance: this.formPlace.allowedDistance || 0,
          ...normalizeFranchisee,
        },
      };
    },
  },
  components: {
    LoadingCircle,
    FranchiseeSelect,
    StatesSelect,
    TypePlaceSelect,
    googleMaps,
    ToolTips,
  },
  watch: {
    idPlaceEdit() {
      if (this.idPlaceEdit && !this.isDataLoading) {
        this.getFormPlace();
      }
    },
  },
  created() {
    this.getFormPlace();
  },
  props: ['idPlaceEdit', 'inUserCreation'],
};
</script>
