import { query, mutation } from '@/services/GraphqlService';

import createProductDescriptionMutation from '@/graphql/product/createProductDescriptionMutation.gql';
import updateProductDescriptionMutation from '@/graphql/product/updateProductDescriptionMutation.gql';
import getListProductDescriptionsQuery from '@/graphql/product/getListProductDescriptionsQuery.gql';
import getProductDescriptionQuery from '@/graphql/product/getProductDescriptionQuery.gql';
import getProductDescriptionToSelectQuery from '@/graphql/product/getListSelectProductsQuery.gql';

export const createProductDescription = (apollo, variables) =>
  mutation(apollo, createProductDescriptionMutation, variables);

export const updateProductDescription = (apollo, variables) =>
  mutation(apollo, updateProductDescriptionMutation, variables);

export const getListProductDescriptions = (apollo) =>
  query(apollo, getListProductDescriptionsQuery).then((resp) => resp.productDescriptions);

export const getProductDescription = (apollo, variables) =>
  query(apollo, getProductDescriptionQuery, variables).then((resp) => resp.productDescription);

export const getProductDescriptionToSelect = (apollo) =>
  query(apollo, getProductDescriptionToSelectQuery).then((resp) => resp.productDescriptions);

const chlorineTypes = ['TRICLORO', 'HIPOCLORITO_DE_CALCIO', 'DICLORO', 'HIPOCLORITO_DE_SODIO'];

export const calcTabletActiveChlorineGrams = (suggestedProducts) => {
  const trichlorideTablet = suggestedProducts.find((product) => product.description.name === 'Pastilha tricloro');
  return trichlorideTablet ? trichlorideTablet.amount * 0.2 * 0.9 * 1000 : 0;
};

export const calcActiveChlorineGrams = (appliedProducts) => appliedProducts.reduce(
  (appliedActive, product) => (
    chlorineTypes.some((chlorineType) => product.description.type === chlorineType)
      ? ((product.description.chemicalPurity / 100) * product.amount) + appliedActive
      : appliedActive
  ), 0) * 1000;
