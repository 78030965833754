<template>
  <div class="piscinapp-switch">
    <div class="piscinapp-switch__container">
      <input
        type="checkbox"
        class="piscinapp-switch__input"
        :checked="value"
        @change="toggleValue"
      />
      <div class="piscinapp-switch__track"></div>
      <div class="piscinapp-switch__thumb"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
  },
  methods: {
    toggleValue(event) {
      this.$emit('input', event.target.checked);
    },
  },
};
</script>

<style>
.piscinapp-switch {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.piscinapp-switch__container {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 24px;
  border-radius: 12px;
  background-color: #e0e0e0;
}

.piscinapp-switch__input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}

.piscinapp-switch__track {
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  border-radius: 10px;
  background-color: #bdbdbd;
  transition: background-color 0.2s ease;
}

.piscinapp-switch__thumb {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4px;
  margin: auto;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
  transition: transform 0.2s ease;
}

.piscinapp-switch__input:checked + .piscinapp-switch__track {
  background-color: #92cd1d;
}

.piscinapp-switch__input:checked + .piscinapp-switch__track + .piscinapp-switch__thumb {
  transform: translateX(16px);
}
</style>
