var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    { class: this.detail.class, attrs: { top: "", left: "" } },
    [
      _c(
        "v-btn",
        { attrs: { slot: "activator", icon: "" }, slot: "activator" },
        [
          _c("v-icon", { attrs: { color: this.detail.color || "indigo" } }, [
            _vm._v(_vm._s(this.detail.icon))
          ])
        ],
        1
      ),
      _c("span", [_vm._v(_vm._s(this.detail.text))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }