<template>
  <v-card color="#0D1824" dark class="px-3 py-1" id="cardFilter">
    <v-layout wrap
      ><v-layout wrap>
        <slot></slot>
      </v-layout>
      <v-btn icon outline v-if="searchButton" @click="$emit('clickOnSearch')" id="submitFilter">
        <v-icon title="Buscar">search</v-icon>
      </v-btn>
      <v-btn icon outline v-if="resetButton" @click="$emit('clickOnReset')" id="resetFields">
        <v-icon title="Limpar filtro">undo</v-icon>
      </v-btn></v-layout
    >
  </v-card>
</template>
<script>
export default {
  props: ['resetButton', 'searchButton'],
};
</script>
