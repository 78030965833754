var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "delete-user-container" },
    [
      _c(
        "v-card",
        { staticClass: "elevation-12" },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "secondary" } },
            [
              _c(
                "v-toolbar-title",
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("delete")]),
                  _vm._v("Excluir Conta")
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "delete-user-message" }, [
            _c("p", [
              _vm._v("Para excluir sua conta, digite seu email e senha.")
            ]),
            _c("p", [_vm._v("Essa ação não pode ser desfeita.")])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                [
                  _c("v-text-field", {
                    attrs: {
                      id: "email",
                      "prepend-icon": "mail_outline",
                      name: "login",
                      label: "Email",
                      type: "text"
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.login()
                      }
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      id: "password",
                      "prepend-icon": "lock",
                      name: "password",
                      label: "Senha",
                      type: "password"
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.login()
                      }
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2 buttonFull",
                  attrs: {
                    loading: _vm.isLoading,
                    id: "deleteUserButton",
                    block: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.login()
                    }
                  }
                },
                [_vm._v("Excluir")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { id: "snackbar", right: true, timeout: _vm.timeout },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.textSnackbar) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "blue", flat: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v("Fechar")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }