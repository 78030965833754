var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "FilterCard",
        {
          attrs: { resetButton: true, searchButton: true },
          on: {
            clickOnReset: function($event) {
              return _vm.resetFilters()
            },
            clickOnSearch: function($event) {
              return _vm.loadNotifications()
            }
          }
        },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", md12: "", lg6: "", xl2: "" } },
            [
              _c("v-text-field", {
                staticClass: "mx-2",
                attrs: { label: "Pesquise pelo colaborador" },
                model: {
                  value: _vm.filter.employeeName,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "employeeName", $$v)
                  },
                  expression: "filter.employeeName"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", md12: "", lg6: "", xl2: "" } },
            [
              _c("v-text-field", {
                staticClass: "mx-2",
                attrs: { label: "Pesquise pelo assunto" },
                model: {
                  value: _vm.filter.subject,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "subject", $$v)
                  },
                  expression: "filter.subject"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            {
              attrs: { xs12: "", md6: "", lg3: "", xl2: "", id: "searchByType" }
            },
            [
              _c("v-select", {
                staticClass: "mx-2",
                attrs: {
                  "menu-props": "auto",
                  items: _vm.notificationTypes,
                  "item-value": "value",
                  "item-text": "name",
                  label: "Pesquise pelo tipo"
                },
                model: {
                  value: _vm.filter.type,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "type", $$v)
                  },
                  expression: "filter.type"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            {
              attrs: {
                xs12: "",
                md6: "",
                lg3: "",
                xl2: "",
                id: "searchByStatus"
              }
            },
            [
              _c("v-select", {
                staticClass: "mx-2",
                attrs: {
                  "menu-props": "auto",
                  items: _vm.statusTypes,
                  "item-value": "value",
                  "item-text": "name",
                  label: "Pesquise pelo status"
                },
                model: {
                  value: _vm.filter.status,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "status", $$v)
                  },
                  expression: "filter.status"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", md6: "", lg3: "", xl2: "" } },
            [
              _c("datePicker", {
                staticClass: "mx-2",
                attrs: { label: "Data inicial" },
                model: {
                  value: _vm.filter.startDate,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "startDate", $$v)
                  },
                  expression: "filter.startDate"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", md6: "", lg3: "", xl2: "" } },
            [
              _c("datePicker", {
                staticClass: "mx-2",
                attrs: { label: "Data final" },
                model: {
                  value: _vm.filter.endDate,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "endDate", $$v)
                  },
                  expression: "filter.endDate"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("cardList", {
        attrs: {
          isDescending: true,
          sortBy: "date",
          isLoading: _vm.isLoading,
          contentList: _vm.filteredNotificationTable
        },
        on: { click: notification => _vm.openModal(notification.id) }
      }),
      _c(
        "cardModal",
        {
          attrs: { width: "65%" },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("NotificationCard", {
            attrs: { notificationId: _vm.selectedNotificationId },
            on: {
              showModal: function($event) {
                _vm.showModal = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }