import Vue from 'vue';
import Router from 'vue-router';
import Menu from './components/menu/Menu.vue';
import LoggedOut from './components/authetication/LoggedOut.vue';
import Visitors from './components/visitors/VisitorsAnalyzesView.vue';
import VisitorsLocalSelect from './components/visitors/VisitorsLocalSelect.vue';
import DeleteUser from './components/authetication/DeleteUser.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Menu,
    },
    {
      path: '/dashboard/usuarios',
      name: 'usuarios',
      component: Menu,
    },
    {
      path: '/dashboard/locais',
      name: 'locais',
      component: Menu,
    },
    {
      path: '/dashboard/produtos',
      name: 'produtos',
      component: Menu,
    },
    {
      path: '/dashboard/equipamentos',
      name: 'equipamentos',
      component: Menu,
    },
    {
      path: '/dashboard/movimentacoes',
      name: 'movimentacoes',
      component: Menu,
    },
    {
      path: '/dashboard/analises',
      name: 'analises',
      component: Menu,
    },
    {
      path: '/dashboard/relatorios',
      name: 'relatorios',
      component: Menu,
    },
    {
      path: '/dashboard/notificacoes',
      name: 'notificacoes',
      component: Menu,
    },
    {
      path: '/dashboard/monitoramento',
      name: 'monitoramento',
      component: Menu,
    },
    {
      path: '/dashboard/chat',
      name: 'chat',
      component: Menu,
    },
    {
      path: '/visitante/piscina/*',
      name: '',
      component: Visitors,
    },
    {
      path: '/visitante/analise/*',
      name: '',
      component: Visitors,
    },
    {
      path: '/visitante/local/*',
      name: '',
      component: VisitorsLocalSelect,
    },
    {
      path: '*',
      name: 'login',
      component: LoggedOut,
    },
    {
      path: '/dashboard/machine-learning',
      name: 'machineLearning',
      component: Menu,
    },
    {
      path: '/dashboard/whatsapp',
      name: 'whatsapp',
      component: Menu,
    },
    {
      path: '/dashboard/configuracoes',
      name: 'configuracoes',
      component: Menu,
    },
    {
      path: '/excluir-sua-conta',
      name: 'deleteAccount',
      component: DeleteUser,
    },
  ],
});
