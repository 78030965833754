var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "login" } },
    [
      _c(
        "v-content",
        { staticClass: "back" },
        [
          _c(
            "v-container",
            { attrs: { "grid-list-sm": "", fluid: "", "fill-height": "" } },
            [
              _c(
                "v-layout",
                {
                  attrs: { wrap: "", "align-center": "", "justify-center": "" }
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm8: "", md4: "" } },
                    [
                      _c(_vm.showComponent, {
                        tag: "component",
                        attrs: { idPasswordReset: _vm.idPasswordReset },
                        on: {
                          "component-change": function($event) {
                            _vm.showComponent = $event
                          },
                          textSnackbar: event => {
                            _vm.textSnackbar = event
                            _vm.snackbar = true
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { id: "snackbar", right: true, timeout: _vm.timeout },
              model: {
                value: _vm.snackbar,
                callback: function($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar"
              }
            },
            [
              _vm._v("\n      " + _vm._s(_vm.textSnackbar) + "\n      "),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue", flat: "" },
                  on: {
                    click: function($event) {
                      _vm.snackbar = false
                    }
                  }
                },
                [_vm._v("Fechar")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            fab: "",
            large: "",
            fixed: "",
            bottom: "",
            right: "",
            href:
              "https://play.google.com/store/apps/details?id=com.trycode.piscinapp",
            target: "_blank"
          }
        },
        [
          _c(
            "div",
            { staticClass: "playstore-icon" },
            [_c("v-img", { attrs: { src: "/assets/img/playstore.svg" } })],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }