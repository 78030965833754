<template>
  <v-container>
    <FilterCard
      @clickOnReset="resetFilter"
      :searchButton="true"
      :resetButton="true"
      @clickOnSearch="submitFilter"
    >
      <v-flex px-2 xs12 sm12 md4 id="filterByNameEmailFranchisee">
        <v-text-field label="Pesquise pelo nome / e-mail" v-model="formFilter.string" />
      </v-flex>
      <v-flex px-2 xs12 sm12 md4 v-if="isAdmin || isCoordinador">
        <FranchiseeSelect v-model="formFilter.franchiseeId" />
      </v-flex>
      <v-flex px-2 xs12 sm12 md2 id="filterByType">
        <RolesSelect v-model="formFilter.role" :includeOptionAll="true" />
      </v-flex>
      <v-flex px-2 xs12 sm12 md2 id="filterByStatus">
        <StatusSelect v-model="formFilter.status" />
      </v-flex>
    </FilterCard>
    <CardList
      sortBy="undefined"
      v-if="this.listUsers.length"
      id="dataUsers"
      @click="(element) => openModal(element.id)"
      :contentList="formatedUsersList"
    />
    <LoadingCircle class="mt-3" v-if="isLoading" />
    <CardModal v-model="showModal" width="65%" :removeURLParams="true">
      <UserCreation :idUserEdit="idUserEdit" @showModal="showModal = $event" />
    </CardModal>
    <v-btn
      v-if="isAdmin || isCoordinador || isFranchisee || isAssociate || isLimited"
      fab
      bottom
      right
      color="#A6CE39"
      dark
      fixed
      @click="showModal = true"
      title="Novo"
      id="create"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import FilterCard from '@/components/filters/FilterCard.vue';
import CardList from '@/components/lists/CardList.vue';
import LoadingCircle from '@/components/animations/LoadingCircle.vue';
import CardModal from '@/components/modal/CardModal.vue';
import RolesSelect from '@/components/selects/RolesSelect.vue';
import StatusSelect from '@/components/selects/StatusSelect.vue';
import UserCreation from '@/components/user/UserCreation.vue';
import FranchiseeSelect from '@/components/selects/FranchiseeSelect.vue';

import { getAllUsers, rolesFilter } from '@/services/UserService';
import { isRole } from '@/services/UtilsService';

export default {
  data: () => ({
    isLoading: false,
    isAdmin: isRole('ADMIN'),
    isCoordinador: isRole('COORDINADOR'),
    isFranchisee: isRole('FRANCHISEE'),
    isAssociate: isRole('ASSOCIATE'),
    isLimited: isRole('LIMITED'),
    idUserEdit: '',
    showModal: false,
    listUsers: [],
    formFilter: {
      string: '',
      status: 'ACTIVE',
      role: 'ALL',
      franchiseeId: '',
    },
    graphqlVariables: {
      first: 25,
      after: null,
      where: {
        isDisabled: false,
      },
    },
  }),
  methods: {
    openModal(elementId = undefined) {
      this.idUserEdit = elementId;
      this.showModal = true;
    },
    async getUsersList() {
      this.isLoading = true;
      getAllUsers(this.$apollo, this.graphqlVariables)
        .then((resp) => {
          if (resp.users.length) {
            this.graphqlVariables.after = resp.users[resp.users.length - 1].id;
            this.listUsers = [...this.listUsers, ...resp.users];
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetFilter() {
      this.listUsers = [];
      this.formFilter = this.$options.data().formFilter;
      this.graphqlVariables = this.$options.data().graphqlVariables;
      this.getUsersList();
    },
    submitFilter() {
      this.listUsers = [];
      this.graphqlVariables = {
        ...this.graphqlVariables,
        after: undefined,
        first: undefined,
        where: {
          isDisabled: {
            ACTIVE: false,
            INACTIVE: true,
            ALL: undefined,
          }[this.formFilter.status],
          role: this.formFilter.role === 'ALL' ? undefined : this.formFilter.role,
          franchisee:
            this.formFilter.franchiseeId === '' ? undefined : { id: this.formFilter.franchiseeId },
          OR: this.formFilter.string
            ? [
                { email_contains: this.formFilter.string },
                { employee: { name_contains: this.formFilter.string } },
                { client: { name_contains: this.formFilter.string } },
              ]
            : undefined,
        },
      };
      this.getUsersList();
    },
  },
  computed: {
    formatedUsersList() {
      return {
        columns: [
          { text: 'Usuário', value: 'user', type: 'text' },
          { text: 'Tipo', value: 'type', type: 'text' },
          { text: 'Contato', value: 'contact', type: 'text' },
          { text: 'Franquia', value: 'franchisee', type: 'text' },
          { text: '', value: 'isDisabled', type: 'icon' },
        ],
        data: this.listUsers.map((user) => ({
          user: user.roles[0] === 'CLIENT' ? user.client.name : user.employee.name,
          type: rolesFilter(user.roles[0]),
          contact: user.telephones.length
            ? `(${user.telephones[0].ddd}) ${user.telephones[0].telephoneNumber}`
            : '',
          franchisee:
            user.franchisee.id !== 'cjqs1fn49c2js0762hu91g5ti'
              ? user.franchisee.employee.name
              : 'Piscina Fácil',
          isDisabled: user.isDisabled ? 'block' : 'done',
          id: user.id,
        })),
      };
    },
    telephones() {
      return (telephones) => {
        let formatedPhones = '';

        for (let i = 0; i < telephones.length; i += 1) {
          const telephone = telephones[i];
          if (formatedPhones === '' && telephones.length > 1) {
            formatedPhones += `(${telephone.ddd}) ${telephone.telephoneNumber} | `;
          } else {
            formatedPhones += `(${telephone.ddd}) ${telephone.telephoneNumber}`;
          }
        }

        if (formatedPhones === '') {
          formatedPhones = 'Sem telefones cadastrados';
        }

        return formatedPhones;
      };
    },
  },
  watch: {
    showModal(showModal) {
      if (showModal) return;
      if (!this.idUserEdit) {
        this.graphqlVariables = this.$options.data().graphqlVariables;
        this.listUsers = [];
        this.idUserEdit = null;
        this.getUsersList();
      } else {
        this.idUserEdit = null;
      }
    },
  },
  created() {
    this.getUsersList();
    window.onscroll = () => {
      if (this.isLoading || !this.graphqlVariables.first) return;
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        this.getUsersList();
      }
    };
  },
  mounted() {
    if (window.location.href.includes('?id=')) {
      const userId = this.$router.app.$route.fullPath.split('?id=')[1];
      this.openModal(userId);
    }
  },
  components: {
    UserCreation,
    FilterCard,
    CardModal,
    CardList,
    RolesSelect,
    StatusSelect,
    LoadingCircle,
    FranchiseeSelect,
  },
};
</script>
