<template>
  <v-container>
    <FilterCard @clickOnReset="resetFilter()" :searchButton="false" :resetButton="true">
      <v-flex xs12 sm12 md9>
        <v-text-field
          class="mx-2"
          label="Pesquise pelo nome do produto"
          v-model="searchName"
          id="searchName"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm12 md3>
        <StatusSelect class="mx-2" id="searchStatus" v-model="selectedStatus" />
      </v-flex>
    </FilterCard>
    <CardList
      :isLoading="isLoading"
      id="dataProducts"
      @click="(elementData) => openModal(elementData.id)"
      :contentList="formatedProductList"
    />
    <CardModal v-model="showModal" width="65%">
      <ProductCreation :idProductEdit="idProductEdit" v-model="showModal" />
    </CardModal>
    <v-btn
      v-if="isAdmin || isCoordinador"
      fab
      bottom
      right
      color="#A6CE39"
      dark
      fixed
      @click="openModal()"
      title="Novo"
      id="create"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import FilterCard from '@/components/filters/FilterCard.vue';

import ProductCreation from '@/components/products/ProductCreation.vue';
import CardList from '@/components/lists/CardList.vue';
import CardModal from '@/components/modal/CardModal.vue';
import StatusSelect from '@/components/selects/StatusSelect.vue';
import {
  filter,
  returnUnitsLongFilter,
  isStatusVisible,
  isRole,
} from '../../services/UtilsService';
import { getListProductDescriptions } from '../../services/ProductsService';

export default {
  data: () => ({
    isLoading: false,
    isAdmin: isRole('ADMIN'),
    isCoordinador: isRole('COORDINADOR'),
    dataProducts: '',
    searchName: '',
    selectedStatus: 'ACTIVE',
    listProducts: [],
    showModal: '',
    idProductEdit: '',
  }),
  components: {
    FilterCard,
    CardList,
    StatusSelect,
    CardModal,
    ProductCreation,
  },
  methods: {
    resetFilter() {
      this.searchName = '';
      this.selectedStatus = 'ACTIVE';
    },
    openModal(elementId = undefined) {
      this.idProductEdit = elementId;
      this.showModal = true;
    },
    async getProductList() {
      if (!this.listProducts.length) {
        this.isLoading = true;
      }
      getListProductDescriptions(this.$apollo)
        .then((products) => {
          this.listProducts = products;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    filteredProducts() {
      const filteredByName = filter(this.listProducts, 'name', this.searchName);
      return filteredByName.filter((element) =>
        isStatusVisible(this.selectedStatus, element.isDisabled),
      );
    },
    formatedProductList() {
      return {
        columns: [
          { text: 'Produto', value: 'name', type: 'text' },
          { text: 'Pureza Química (%)', value: 'chemicalPurity', type: 'text' },
          { text: 'Unidade de Medida', value: 'unit', type: 'text' },
          { text: '', value: 'isDisabled', type: 'icon' },
        ],
        data: this.filteredProducts.map((productDescriptions) => ({
          name: productDescriptions.name,
          chemicalPurity: productDescriptions.chemicalPurity,
          unit: returnUnitsLongFilter(productDescriptions.unit),
          isDisabled: productDescriptions.isDisabled ? 'block' : 'done',
          id: productDescriptions.id,
        })),
      };
    },
  },
  watch: {
    showModal(showModal) {
      if (!showModal) this.getProductList();
    },
  },
  created() {
    this.getProductList();
  },
};
</script>
