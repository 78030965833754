import Vue from 'vue';

export default new Vue({
  methods: {
    alertSnack(text, isError, timeout = null) {
      this.$emit('alertSnack', text, isError, timeout);
    },
    onAlertSnack(backText, backIsError, backTimeout) {
      this.$on('alertSnack', backText, backIsError, backTimeout);
    },
    snackBar(message) {
      this.$emit('snackBar', message);
    },
    onSnackBar(message) {
      this.$on('snackBar', message);
    },

    closeModal(close) {
      this.$emit('closeModal', close);
    },
    onCloseModal(callback) {
      this.$on('closeModal', callback);
    },

    openModal(id) {
      this.$emit('openModal', id);
    },
    onOpenModal(callback) {
      this.$on('openModal', callback);
    },

    reloadList(boolean) {
      this.$emit('reloadList', boolean);
    },
    onReloadList(callback) {
      this.$on('reloadList', callback);
    },

    selectedPlace(local) {
      this.$emit('selectedPlace', local);
    },
    onSelectedPlace(callback) {
      this.$on('selectedPlace', callback);
    },
    selectedPlaceTo(local) {
      this.$emit('selectedPlaceTo', local);
    },
    onSelectedPlaceTo(callback) {
      this.$on('selectedPlaceTo', callback);
    },
    selectedProduct(product) {
      this.$emit('productSelect', product);
    },
    onSelectedProduct(callback) {
      this.$on('productSelect', callback);
    },
    selectedFranchisee(franchisee) {
      this.$emit('selectedFranchisee', franchisee);
    },
    onSelectedFranchisee(callback) {
      this.$on('selectedFranchisee', callback);
    },

    singleUser(idUser) {
      this.$emit('singleUser', idUser);
    },
    onSingleUser(callback) {
      this.$on('singleUser', callback);
    },
    singlePlace(idPlace) {
      this.$emit('singlePlace', idPlace);
    },
    onSinglePlace(callback) {
      this.$on('singlePlace', callback);
    },
    singleProduct(idProduct) {
      this.$emit('singleProduct', idProduct);
    },
    onSingleProduct(callback) {
      this.$on('singleProduct', callback);
    },
    singleEquipment(equipmentId) {
      this.$emit('singleEquipment', equipmentId);
    },
    onSingleEquipment(callback) {
      this.$on('singleEquipment', callback);
    },
    singleTransference(transferenceId) {
      this.$emit('singleTransference', transferenceId);
    },
    onSingleTransference(callback) {
      this.$on('singleTransference', callback);
    },
    singleAnalyze(analyzeId) {
      this.$emit('singleAnalyze', analyzeId);
    },
    onSingleAnalyze(callback) {
      this.$on('singleAnalyze', callback);
    },
    singleNotification(notificationId) {
      this.$emit('singleNotification', notificationId);
    },
    onSingleNotification(callback) {
      this.$on('singleNotification', callback);
    },

    byPlaceForUser(msg) {
      this.$emit('byPlaceForUser', msg);
    },
    onByPlaceForUser(callback) {
      this.$on('byPlaceForUser', callback);
    },
  },
});
