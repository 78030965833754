var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "title" }, [
        _vm._v("Insira nova piscina ")
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          id: "poolName",
                          label: "Nome da piscina *",
                          rules: [_vm.validationRequired]
                        },
                        model: {
                          value: _vm.formPool.poolName,
                          callback: function($$v) {
                            _vm.$set(_vm.formPool, "poolName", $$v)
                          },
                          expression: "formPool.poolName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          id: "poolVolume",
                          label: "Volume (m³) *",
                          rules: [_vm.validationRequired],
                          type: "number"
                        },
                        model: {
                          value: _vm.formPool.volume,
                          callback: function($$v) {
                            _vm.$set(_vm.formPool, "volume", $$v)
                          },
                          expression: "formPool.volume"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.isAdmin || _vm.isCoordinador
                    ? _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("div", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formPool.hasTabletApplicationSupport,
                                expression:
                                  "formPool.hasTabletApplicationSupport"
                              }
                            ],
                            attrs: { id: "checkbox", type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.formPool.hasTabletApplicationSupport
                              )
                                ? _vm._i(
                                    _vm.formPool.hasTabletApplicationSupport,
                                    null
                                  ) > -1
                                : _vm.formPool.hasTabletApplicationSupport
                            },
                            on: {
                              change: function($event) {
                                var $$a =
                                    _vm.formPool.hasTabletApplicationSupport,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.formPool,
                                        "hasTabletApplicationSupport",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.formPool,
                                        "hasTabletApplicationSupport",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.formPool,
                                    "hasTabletApplicationSupport",
                                    $$c
                                  )
                                }
                              }
                            }
                          }),
                          _c("label", { attrs: { for: "checkbox" } }, [
                            _vm._v(" Suporta a aplicação de pastilhas")
                          ])
                        ]),
                        _c("div", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.formPool.isEnabledAutomaticSuggestion,
                                expression:
                                  "formPool.isEnabledAutomaticSuggestion"
                              }
                            ],
                            attrs: {
                              id: "automatic-suggestion-checkbox",
                              type: "checkbox"
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.formPool.isEnabledAutomaticSuggestion
                              )
                                ? _vm._i(
                                    _vm.formPool.isEnabledAutomaticSuggestion,
                                    null
                                  ) > -1
                                : _vm.formPool.isEnabledAutomaticSuggestion
                            },
                            on: {
                              change: function($event) {
                                var $$a =
                                    _vm.formPool.isEnabledAutomaticSuggestion,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.formPool,
                                        "isEnabledAutomaticSuggestion",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.formPool,
                                        "isEnabledAutomaticSuggestion",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.formPool,
                                    "isEnabledAutomaticSuggestion",
                                    $$c
                                  )
                                }
                              }
                            }
                          }),
                          _c(
                            "label",
                            { attrs: { for: "automatic-suggestion-checkbox" } },
                            [_vm._v(" Habilitar sugestão automática")]
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          !_vm.isClient
            ? _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("showNewPoolCard", false)
                        }
                      }
                    },
                    [_vm._v(" Fechar ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.isDisabledButtonSave(),
                        color: "primary",
                        id: "savePool"
                      },
                      on: {
                        click: function($event) {
                          return _vm.submitPool()
                        }
                      }
                    },
                    [_vm._v("Salvar")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }