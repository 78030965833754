<template>
  <v-container grid-list-xl>
    <v-expansion-panel expand v-model="panel">
      <v-expansion-panel-content>
        <template v-slot:header>
          <div>
            <v-layout class="justify-center align-center">
              <v-icon color="primary">account_circle</v-icon>
              <v-flex xs12>
                <h3>Clientes vinculados a este local</h3>
              </v-flex>
            </v-layout>
          </div>
        </template>
        <v-card>
          <v-card-text>
            <CardList
              v-if="!isLoading"
              sortBy="name"
              @click="
                (element) => {
                  panel = [0];
                  userShownId = element.id;
                }
              "
              :contentList="formatedUsers"
            />
            <LoadingCircle v-if="isLoading" />
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <UserCreation
      class="mt-5"
      v-if="userShownId"
      :idUserEdit="userShownId"
      @showModal="showModal = $event"
      :showToolbar="false"
      :showCloseButton="false"
    />
    <v-card-actions>
      <v-spacer />
      <v-btn flat color="primary" @click="closeModal()">Fechar</v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import { getUsersByLocalId } from '@/services/UserService';
import CardList from '@/components/lists/CardList.vue';
import LoadingCircle from '@/components/animations/LoadingCircle.vue';
import UserCreation from '@/components/user/UserCreation.vue';

export default {
  data: () => ({
    users: [],
    isLoading: false,
    userShownId: '',
    panel: [1],
  }),
  computed: {
    formatedUsers() {
      return {
        data: this.users.map((user) => ({
          name: user.client.name,
          email: user.email,
          role: this.getRoleName(user.roles[0]),
          telephones: user.telephones
            .map((telephone) => `${telephone.ddd} ${telephone.telephoneNumber}`)
            .join(', '),
          id: user.id,
        })),
        columns: [
          { type: 'text', text: 'Nome', value: 'name' },
          { type: 'text', text: 'Email', value: 'email' },
          { type: 'text', text: 'Telefones', value: 'telephones' },
        ],
      };
    },
  },
  methods: {
    getRoleName(role) {
      switch (role) {
        case 'EMPLOYEE':
          return 'Funcionário';
        case 'LIMITED':
          return 'Limitado';
        case 'FRANCHISEE':
          return 'Franqueado';
        case 'CLIENT':
          return 'Cliente';
        case 'COORDINADOR':
          return 'Coordenador';
        case 'ADMIN':
          return 'Administrador';
        default:
          return 'Desconhecido';
      }
    },
    getUsers() {
      this.isLoading = true;
      getUsersByLocalId(this.$apollo, { localId: this.idPlaceEdit })
        .then((resp) => {
          this.users = resp.users;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    closeModal() {
      this.userShownId = '';
      this.$emit('showModal', false);
    },
  },
  components: {
    CardList,
    LoadingCircle,
    UserCreation,
  },
  created() {
    this.getUsers();
  },
  props: ['idPlaceEdit'],
};
</script>
