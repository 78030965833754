<template>
  <div class="ballon-container" v-on:click="onMessageClick">
    <div v-bind:class="fromMe ? 'green-balloon' : 'white-balloon'">
      <div v-if="fromMe && time" class="blank-space"></div>
      <div v-if="!fromMe" class="triangle-topright"></div>
      <div v-bind:class="(fromMe ? 'from-me' : 'from-contact') + ' whatsapp-message'">
        <div class="whatsapp-message-text" v-if="!isFile">
          <div class="forward" v-if="forwardText">
            <img src="/assets/icons/forward.svg" />{{ forwardText }}
          </div>
          <div class="forward" v-if="id"><b>ID:</b> {{ id }}</div>
          <div :class="noWrap ? 'no-wrap' : 'pre-wrap'">{{ message }}</div>
        </div>
        <div v-if="isImage">
          <img :src="mediaUrl" class="attached-media" />
        </div>
        <div v-if="isVideo">
          <video controls class="attached-media">
            <source :src="mediaUrl" />
          </video>
        </div>
        <div v-if="isAudio">
          <audio controls class="attached-media">
            <source :src="mediaUrl" />
          </audio>
        </div>
        <div class="whatsapp-message-info" v-if="time">
          <div class="whatsapp-message-time">{{ time }}</div>
          <div class="whatsapp-message-status" v-if="fromMe">
            <img
              v-bind:class="
                status === statusDict.ACK_ERROR
                  ? 'red-svg'
                  : status === statusDict.ACK_PENDING || status === statusDict.ACK_SERVER
                  ? 'gray-svg'
                  : 'blue-svg'
              "
              src="/assets/icons/delivered.svg"
            />
          </div>
        </div>
      </div>
      <div v-if="fromMe" class="triangle-topleft"></div>
      <div v-if="!fromMe && time" class="blank-space"></div>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'Balloon',
  data() {
    return {
      statusDict: {
        ACK_ERROR: -1,
        ACK_PENDING: 0,
        ACK_SERVER: 1,
        ACK_DEVICE: 2,
        ACK_READ: 3,
        ACK_PLAYED: 4,
      },
    };
  },
  mounted() {},
  methods: {
    onMessageClick() {
      this.$emit('message-click');
    },
  },
  computed: {
    isFile() {
      return this.message.match(/^\[file\].*\[\/file\]$/) != null;
    },
    isImage() {
      return this.message.split('?')[0].match(/\.(jpeg|jpg|gif|png)$/) != null;
    },
    isVideo() {
      return this.message.split('?')[0].match(/\.(mp4|avi|mkv|webm)$/) != null;
    },
    isAudio() {
      return this.message.split('?')[0].match(/\.(mp3|wav|ogg|flac)$/) != null;
    },
    mediaUrl() {
      return this.message.replace(/^\[file\]/, '').replace(/\[\/file\]$/, '');
    },
  },
  props: {
    fromMe: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    status: {
      type: Number,
      default: 0,
    },
    noWrap: {
      type: Boolean,
      default: false,
    },
    forwardText: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.white-balloon {
  padding: 4px;
  display: flex;
  align-self: flex-start;
}

.green-balloon {
  padding: 4px;
  display: flex;
  align-self: flex-end;
}

.triangle-topleft {
  width: 0;
  height: 0;
  border-top: 10px solid #92cd1d;
  border-right: 10px solid transparent;
}

.triangle-topright {
  width: 0;
  height: 0;
  border-top: 10px solid #ffffff;
  border-left: 10px solid transparent;
}

.blank-space {
  padding-left: 10px;
}

.whatsapp-message {
  padding: 4px;
  box-shadow: 0 1px 0 0 rgb(0, 0, 0, 0.2);
  font-size: 10pt;
  color: #002941;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;
}

.whatsapp-message-text {
  flex: 1 1 auto;
  padding: 4px;
  max-width: 720px;
  overflow: auto;
}

.no-wrap {
  white-space: nowrap;
}

.pre-wrap {
  white-space: pre-wrap;
}

.whatsapp-message-info {
  font-size: 8pt;
  color: rgba(0, 41, 65, 0.45);
  padding: 0 0 0 8px;
  display: flex;
}

.whatsapp-message-time {
  padding-right: 4px;
}

.whatsapp-message-status {
  height: 20px;
  width: 20px;
  user-select: none;
  pointer-events: none;
}

.gray-svg {
  filter: invert(63%) sepia(1%) saturate(933%) hue-rotate(327deg) brightness(88%) contrast(89%);
}

.blue-svg {
  filter: invert(38%) sepia(99%) saturate(653%) hue-rotate(167deg) brightness(97%) contrast(86%);
}

.red-svg {
  filter: invert(18%) sepia(100%) saturate(5556%) hue-rotate(356deg) brightness(90%) contrast(117%);
}

.from-me {
  background-color: #92cd1d;
  border-radius: 5px 0 5px 5px;
  color: #000;
}

.from-contact {
  background-color: #ffffff;
  border-radius: 0 5px 5px 5px;
}
.ballon-container {
  position: relative;
}

.forward {
  color: #8696a0;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.forward img {
  height: 8px;
}

.attached-media {
  width: 100%;
  max-width: 720px;
  max-height: 400px;
}
</style>
