var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { color: "#0d1824", dark: "", flat: "" } },
        [
          _c("v-toolbar-title", [_vm._v("Cadastro de Produto")]),
          _c("div", { staticClass: "flex-grow-1" })
        ],
        1
      ),
      !_vm.isLoading
        ? _c(
            "v-container",
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _vm.idProductEdit
                            ? _c("v-switch", {
                                staticStyle: { float: "right" },
                                attrs: {
                                  label: _vm.newProductDescription.isEnable
                                    ? "Produto ativo"
                                    : "Produto inativo"
                                },
                                model: {
                                  value: _vm.newProductDescription.isEnable,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.newProductDescription,
                                      "isEnable",
                                      $$v
                                    )
                                  },
                                  expression: "newProductDescription.isEnable"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Nome *",
                              id: "productName",
                              rules: [_vm.validationRequired],
                              disabled: !_vm.isAdmin && !_vm.isCoordinador
                            },
                            model: {
                              value: _vm.newProductDescription.name,
                              callback: function($$v) {
                                _vm.$set(_vm.newProductDescription, "name", $$v)
                              },
                              expression: "newProductDescription.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Pureza química (%)",
                              id: "chemicalPurity",
                              type: "number",
                              disabled: !_vm.isAdmin && !_vm.isCoordinador
                            },
                            model: {
                              value: _vm.newProductDescription.chemicalPurity,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.newProductDescription,
                                  "chemicalPurity",
                                  $$v
                                )
                              },
                              expression: "newProductDescription.chemicalPurity"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              "menu-props": "auto",
                              items: _vm.units,
                              name: "unit",
                              label: "Unidade de medida *",
                              "item-text": "name",
                              "item-value": "value",
                              rules: [_vm.validationRequired],
                              disabled: !_vm.isAdmin && !_vm.isCoordinador
                            },
                            model: {
                              value: _vm.newProductDescription.unit,
                              callback: function($$v) {
                                _vm.$set(_vm.newProductDescription, "unit", $$v)
                              },
                              expression: "newProductDescription.unit"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              "menu-props": "auto",
                              items: _vm.types,
                              name: "type",
                              label: "Tipo",
                              "item-text": "name",
                              "item-value": "value",
                              rules: [],
                              disabled: !_vm.isAdmin && !_vm.isCoordinador
                            },
                            model: {
                              value: _vm.newProductDescription.type,
                              callback: function($$v) {
                                _vm.$set(_vm.newProductDescription, "type", $$v)
                              },
                              expression: "newProductDescription.type"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              "menu-props": "auto",
                              items: _vm.categories,
                              name: "category",
                              label: "Categoria",
                              "item-text": "name",
                              "item-value": "value",
                              rules: [],
                              disabled: !_vm.isAdmin && !_vm.isCoordinador
                            },
                            model: {
                              value: _vm.newProductDescription.category,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.newProductDescription,
                                  "category",
                                  $$v
                                )
                              },
                              expression: "newProductDescription.category"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              "menu-props": "auto",
                              items: _vm.utilities,
                              name: "utility",
                              label: "Utilidade",
                              "item-text": "name",
                              "item-value": "value",
                              rules: [],
                              disabled: !_vm.isAdmin && !_vm.isCoordinador
                            },
                            model: {
                              value: _vm.newProductDescription.utility,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.newProductDescription,
                                  "utility",
                                  $$v
                                )
                              },
                              expression: "newProductDescription.utility"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._l(_vm.newProductDescription.documents, function(
                        document,
                        index
                      ) {
                        return _c(
                          "div",
                          { key: index, staticClass: "uploaded-files" },
                          [
                            _c("v-icon", [_vm._v("insert_drive_file")]),
                            _c(
                              "div",
                              {
                                staticClass: "uploaded-file",
                                on: {
                                  click: function($event) {
                                    return _vm.downloadFile(document.url)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(document.name))]
                            ),
                            _c(
                              "v-icon",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "red"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.removeDocument(index)
                                  }
                                }
                              },
                              [_vm._v("\n            delete\n          ")]
                            )
                          ],
                          1
                        )
                      }),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c(
                          "div",
                          {
                            attrs: { id: "file-input-container" },
                            on: {
                              dragover: _vm.dragOver,
                              dragleave: _vm.dragLeave
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "file-input-label-wrap" },
                              [
                                !_vm.isUploadLoading
                                  ? _c(
                                      "span",
                                      { staticClass: "file-input-label" },
                                      [
                                        _vm._v(
                                          "\n                Adicionar documentos\n                "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "file-input-label-small"
                                          },
                                          [
                                            _c("br"),
                                            _vm._v(
                                              "\n                  Arraste e solte o arquivo aqui ou clique para selecionar\n                "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "primary"
                                      }
                                    })
                              ],
                              1
                            ),
                            _c("input", {
                              staticClass: "file-input",
                              attrs: {
                                type: "file",
                                id: "compressed-upload-input"
                              },
                              on: { change: _vm.onFileChange }
                            })
                          ]
                        )
                      ])
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", color: "primary", id: "closeModal" },
                      on: {
                        click: function($event) {
                          return _vm.closeModal()
                        }
                      }
                    },
                    [_vm._v("Fechar")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.isLoading,
                        id: "submitProduct",
                        disabled:
                          !_vm.newProductDescription.name ||
                          !_vm.newProductDescription.unit,
                        color: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.submit()
                        }
                      }
                    },
                    [_vm._v("\n        Salvar\n      ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "text-xs-center" },
            [
              _c("v-progress-circular", {
                staticClass: "loader ma-5",
                attrs: { indeterminate: "", color: "primary" }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }