import { mutation } from '@/services/GraphqlService';
import { downloadCSV, downloadAnalyzesPDF } from '@/services/DocumentsService';
import * as moment from 'moment';
import 'jspdf-autotable';
import reportClockIn from '@/graphql/report/reportClockIn.gql';
import reportDisplacement from '@/graphql/report/reportDisplacement.gql';
import reportVisit from '@/graphql/report/reportVisit.gql';
import reportTransference from '@/graphql/report/reportTransference.gql';
import reportAnalyzes from '@/graphql/report/reportAnalyzes.gql';
import reportProblem from '@/graphql/report/reportProblem.gql';
import reportStock from '@/graphql/report/reportStock.gql';
import reportDailyTreatment from '@/graphql/report/dailyWaterTreatment.gql';
import reportPoolConditions from '@/graphql/report/poolConditions.gql';
import { getUserClientById } from './UserService';

const symbol = {
  LITRO: 'L',
  METRO: 'm',
  KILO: 'kg',
  UNIT: 'un',
};

export const getStatusAnalyzesText = (status) => {
  if (!status) {
    return '';
  }
  return [
    { status: 'OPEN', text: 'Aberta' },
    { status: 'APPLIED', text: 'Aplicada' },
    { status: 'SUGGESTED', text: 'Sugerida' },
    { status: 'WITHOUT_SUGGESTED', text: 'Sem sugestão' },
  ].find((option) => option.status === status).text;
};

const getPhysicistChemicalParamText = (param) => {
  if (!param) {
    return '';
  }
  return [
    { param: 'INDISPONIVEL', text: 'indisponível' },
    { param: 'MUITO_LIMPO', text: 'Muito limpo' },
    { param: 'LIMPO', text: 'Limpo' },
    { param: 'SUJO', text: 'Sujo' },
    { param: 'MUITO_SUJO', text: 'Muito sujo' },
  ].find((option) => option.param === param).text;
};

const getWaterTypeText = (type) => {
  if (!type) {
    return '';
  }
  return [
    { type: 'INDISPONIVEL', text: 'indisponível' },
    { type: 'CRISTALINA_BRILHOSA', text: 'Cristalina brilhosa' },
    { type: 'TRANSPARENTE_OPACA', text: 'Transparente opaca' },
    { type: 'LEVEMENTE_TURVA', text: 'Levemente turva' },
    { type: 'TURVA', text: 'Turva' },
    { type: 'ESVERDEADA', text: 'Esverdeada' },
    { type: 'VERDE', text: 'Verde' },
    { type: 'OUTRA_COLORACAO', text: 'Outra coloração' },
  ].find((option) => option.type === type).text;
};
const getTypeTransferenceText = (type) => {
  if (!type) {
    return '';
  }
  return [
    { type: 'ENTRADA', text: 'Entrada' },
    { type: 'SAIDA', text: 'Saída' },
    { type: 'CONTAGEM_PARCIAL', text: 'Contagem parcial' },
    { type: 'CONTAGEM', text: 'Contagem total' },
    { type: 'APLICACAO', text: 'Aplicação' },
    { type: 'TRANSFERENCE', text: 'Transferência' },
  ].find((option) => option.type === type).text;
};

const getStockData = (result) => {
  const data = [];
  result.stockReport.report.forEach((line) => {
    data.push({
      Estoque: line.localName,
      Produto: line.productName,
      Ultima_Contagem: line.lastCountDate
        ? moment(line.lastCountDate).format('DD/MM/YYYY HH:mm')
        : 'Sem registro',
      Primeira_Aplicação: line.firstApplication
        ? moment(line.firstApplication).format('DD/MM/YYYY HH:mm')
        : 'Sem registro',
      Ultima_Aplicação: line.lastApplication
        ? moment(line.lastApplication).format('DD/MM/YYYY HH:mm')
        : 'Sem registro',
      Quantidade_De_Aplicações: line.applicationsAmount,
      Intervalo_Entre_Aplicações:
        line.applicationInterval && line.applicationInterval > 0 ? line.applicationInterval : '',
      Saldo: Number(line.amount).toFixed(3),
      Consumo: Number(line.periodConsumption).toFixed(3),
      Consumo_Por_Aplicação:
        !line.consumptionPerApplication || line.consumptionPerApplication === 'Infinity'
          ? ''
          : Number(line.consumptionPerApplication).toFixed(3),
      Consumo_Por_Dia: Number(line.consumptionPerDay).toFixed(3),
      Duração_Em_Dias:
        !line.duration || line.duration === 'Infinity' ? '' : Number(line.duration).toFixed(3),
    });
  });
  return data;
};

const getProblemData = (result) => {
  const data = [];
  result.problemReport.report.forEach((line) => {
    data.push({
      Colaborador: line.employeeName,
      Cidade: line.city,
      Estado: line.state,
      Unidade: line.localName,
      Equipamento: line.equipment,
      Problema: line.problem,
      Data_Hora: line.dateTime ? moment(line.dateTime).format('DD/MM/YYYY HH:mm') : 'Sem registro',
    });
  });
  return data;
};

const getClockInData = (result) => {
  const data = [];
  result.clockInReport.report.forEach((line) => {
    data.push({
      Colaborador: line.employeeName,
      Inicio_Celular: line.startInCell
        ? moment(line.startInCell).format('DD/MM/YYYY HH:mm')
        : 'Sem registro',
      Inicio_Colaborador: line.start
        ? moment(line.start).format('DD/MM/YYYY HH:mm')
        : 'Sem registro',
      Fim_Celular: line.endsInCell
        ? moment(line.endsInCell).format('DD/MM/YYYY HH:mm')
        : 'Sem registro',
      Fim_Colaborador: line.ends ? moment(line.ends).format('DD/MM/YYYY HH:mm') : 'Sem registro',
    });
  });
  return data;
};

const getDisplacementData = (result) => {
  const data = [];
  result.displacementReport.report.forEach((line) => {
    data.push({
      Colaborador: line.employeeName,
      Nome_Local: line.localName,
      Local_Origem: line.originLocalName,
      Inicio: line.start ? moment(line.start).format('DD/MM/YYYY HH:mm') : 'Sem registro',
      Fim: line.ends ? moment(line.start).format('DD/MM/YYYY HH:mm') : 'Sem registro',
      Distancia: line.distance,
    });
  });
  return data;
};

const getVisitData = (result) => {
  const data = [];
  result.visitReport.report.forEach((line) => {
    data.push({
      Colaborador: line.employeeName,
      Inicio_Celular: line.startInCell
        ? moment(line.startInCell).format('DD/MM/YYYY HH:mm')
        : 'Sem registro',
      Inicio_Colaborador: line.start
        ? moment(line.start).format('DD/MM/YYYY HH:mm')
        : 'Sem registro',
      Fim_Celular: line.endsInCell
        ? moment(line.endsInCell).format('DD/MM/YYYY HH:mm')
        : 'Sem registro',
      Fim_Colaborador: line.ends ? moment(line.ends).format('DD/MM/YYYY HH:mm') : 'Sem registro',
      Local: line.local,
    });
  });
  return data;
};

const getTransferenceData = (result) => {
  const data = [];
  const filteredResult = result.transferenceReport.report.filter(
    (line) => line.type !== 'TRANSFERENCIA_PARA_APLICACAO',
  );
  filteredResult.forEach((line) => {
    data.push({
      Data: line.dateTime ? moment(line.dateTime).format('DD/MM/YYYY HH:mm') : 'Sem registro',
      Colaborador: line.employeeName,
      Tipo_Movimentação: line.type ? getTypeTransferenceText(line.type) : '',
      Local: line.local,
      Produto: line.productName,
      Quantidade: line.amount,
    });
  });
  return data;
};

const getDailyTreatment = (result) => {
  const data = result.waterTreatmentReport;
  if (!data.report.length) { return []; }
  let csv = '';
  csv += `UNIDADE; ${data.info.local}\n`;
  csv += `PISCINA; ${data.info.pool}\n`;
  csv += `MÊS/ANO DE REFERÊNCIA; ${moment(data.report[0].dateTime).format('YYYY/MM')}\n`;
  csv += 'DATA;HORA;CLORO;PH;ALCALINIDADE;PRODUTOS APLICADOS 1;PRODUTOS APLICADOS 2;PRODUTOS APLICADOS 3;PRODUTOS APLICADOS 4;RESPONSÁVEL PELA INTERVENÇÃO\n';
  csv += data.report.reverse().reduce((currentCsv, lineData) => {
    let updatedCsv = `${currentCsv}${moment(lineData.dateTime).format('D')};${moment(lineData.dateTime).format('HH:mm')};`;
    updatedCsv += `${lineData.chlorine !== '99' ? lineData.chlorine : ''};${lineData.ph !== '99' ? lineData.ph : ''};${lineData.alkalinity !== '99' ? lineData.alkalinity : ''};`;
    updatedCsv += lineData.appliedProducts[0] ? `${lineData.appliedProducts[0].name} - ${lineData.appliedProducts[0].amount}${symbol[lineData.appliedProducts[0].unit]};` : ';';
    updatedCsv += lineData.appliedProducts[1] ? `${lineData.appliedProducts[1].name} - ${lineData.appliedProducts[1].amount}${symbol[lineData.appliedProducts[0].unit]};` : ';';
    updatedCsv += lineData.appliedProducts[2] ? `${lineData.appliedProducts[2].name} - ${lineData.appliedProducts[2].amount}${symbol[lineData.appliedProducts[0].unit]};` : ';';
    updatedCsv += lineData.appliedProducts[3] ? `${lineData.appliedProducts[3].name} - ${lineData.appliedProducts[3].amount}${symbol[lineData.appliedProducts[0].unit]};` : ';';
    updatedCsv += `${lineData.employee}\n`;
    return updatedCsv;
  }, '');
  return { csv };
};

const getXForEquals = (a, b) => a === b ? 'X' : '';

const getPoolConditions = (result) => {
  const data = result.poolCheckReport;
  if (!data.report.length) { return []; }
  let csv = '';
  csv += `UNIDADE:;${data.info.local}\n`;
  csv += `PISCINA:;${data.info.pool}\n`;
  csv += `MÊS/ANO DE REFERÊNCIA:;${moment(data.report[0].dateTime).format('YYYY/MM')}\n`;
  csv += 'DATA DA INSPEÇÃO;"1.A água da piscina se encontra límpida e cristalina, permitindo visualizar a parte mais profunda do tanque?";;';
  csv += '"2.Bordas, azulejos e rejuntes se encontram devidamente escovados e limpos, livre de algas e oleosidade?";;';
  csv += '"3.A piscina se encontra livre de detritos, sujeira ou resíduos decantados ou flutuantes?";;';
  csv += '4.Escadas de acesso à piscina se encontram devidamente limpas?;;';
  csv += '5.A Areá de entorno da piscina se encontra devidamente limpa?;;';
  csv += '6.A casa de máquinas e seus equipamentos se encontram devidamente limpos e em funcionamento?;;\n';
  csv += ';SIM;NÂO;SIM;NÂO;SIM;NÂO;SIM;NÂO;SIM;NÂO;SIM;NÂO\n';
  csv += data.report.reverse().reduce((currentCsv, lineData) => {
    let updatedCsv = `${currentCsv}${moment(lineData.dateTime).format('D')};`;
    updatedCsv += `${getXForEquals(lineData.isCleanWater, true)};${getXForEquals(lineData.isCleanWater, false)};`;
    updatedCsv += `${getXForEquals(lineData.isCleanEdge, true)};${getXForEquals(lineData.isCleanEdge, false)};`;
    updatedCsv += `${getXForEquals(lineData.isFreeOfDebris, true)};${getXForEquals(lineData.isFreeOfDebris, false)};`;
    updatedCsv += `${getXForEquals(lineData.isCleanStairs, true)};${getXForEquals(lineData.isCleanStairs, false)};`;
    updatedCsv += `${getXForEquals(lineData.isCleanSidewalk, true)};${getXForEquals(lineData.isCleanSidewalk, false)};`;
    updatedCsv += `${getXForEquals(lineData.isGoodConditionsMachineHouse, true)};${getXForEquals(lineData.isGoodConditionsMachineHouse, false)};`;
    updatedCsv += '\n';
    return updatedCsv;
  }, '');
  return { csv };
};

const groupLinesByLocal = (allLines) =>
  allLines.reduce((groups, line) => {
    let currentLineGroup = groups.find((group) => group[0].local === line.local);
    if (currentLineGroup) {
      currentLineGroup.push(line);
    } else {
      currentLineGroup = [line];
      groups.push(currentLineGroup);
    }
    return groups;
  }, []);

const getAnalyzePdfData = (result, clientData, isWithPhoto) => {
  if (!result.analyzeReport || result.analyzeReport.report.length === 0) {
    throw new Error('No data to report.');
  }

  const tableHead = [
    [
      'Data',
      'Piscina',
      'Volume',
      'Alcalinidade',
      'PH',
      'Cianúrico',
      'Dureza',
      'Temperatura',
      'Cloro total',
      'Cloro livre',
      'Água',
    ],
  ];

  let groupsByLocal = groupLinesByLocal(result.analyzeReport.report);

  groupsByLocal = groupsByLocal.map((group) => {
    const analyzes = group.map(analyze => {
      const lineData = [
        moment(analyze.data).format('DD/MM/YY'),
        analyze.pool,
        analyze.volum,
        analyze.alkalinity === '99' ? '' : analyze.alkalinity,
        analyze.ph === '99' ? '' : analyze.ph,
        analyze.cyanuric === '99' ? '' : analyze.cyanuric,
        analyze.hardness === '99' ? '' : analyze.hardness,
        analyze.temperature === '99' ? '' : analyze.temperature,
        analyze.totalChlorine === '99' ? '' : analyze.totalChlorine,
        analyze.freeChlorine === '99' ? '' : analyze.freeChlorine,
        analyze.water === 'INDISPONIVEL' ? '' : getWaterTypeText(analyze.water),
      ];
      if (isWithPhoto) { lineData.push(analyze.photo); }
      return lineData;
    });

    const docHeader = {
      employee: group[0].employee,
      local: group[0].local,
    };
    return { analyzes, docHeader };
  });

  const headerInfo = {
    publicPlace: clientData.localDefault.address.publicPlace,
    number: clientData.localDefault.address.number,
    neighborhood: clientData.localDefault.address.neighborhood,
    city: clientData.localDefault.address.city,
    state: clientData.localDefault.address.state,
    client: clientData.client.name,
  };
  return { tableHead, groupsByLocal, headerInfo };
};

const getAnalyzeData = (resultado) => {
  const data = [];
  resultado.analyzeReport.report.forEach((line) => {
    data.push({
      Data: line.data ? moment(line.data).format('DD/MM/YYYY HH:mm') : 'Sem registro',
      Colaborador: line.employee,
      Local: line.local,
      Piscina: line.pool,
      Status: line.status ? getStatusAnalyzesText(line.status) : '',
      Volume: line.volum,
      Alcalinidade: line.alkalinity === '99' ? 'indisponível' : line.alkalinity,
      PH: line.ph === '99' ? 'indisponível' : line.ph,
      Cianurico: line.cyanuric === '99' ? 'indisponível' : line.cyanuric,
      Dureza: line.hardness === '99' ? 'indisponível' : line.hardness,
      Temperatura: line.temperature === '99' ? 'indisponível' : line.temperature,
      Cloro_Total: line.totalChlorine === '99' ? 'indisponível' : line.totalChlorine,
      Cloro_Livre: line.freeChlorine === '99' ? 'indisponível' : line.freeChlorine,
      Agua: getWaterTypeText(line.water),
      Fundo: getPhysicistChemicalParamText(line.bottom),
      Superficie: getPhysicistChemicalParamText(line.surface),
      Borda: getPhysicistChemicalParamText(line.edge),
      Calçada: getPhysicistChemicalParamText(line.sidewalk),
    });
  });
  return data;
};

export const generateReport = (
  variables,
  type,
  isPDF,
  apollo,
) => {
  const operationList = [
    { type: 'CLOCK_IN', mutation: reportClockIn, getData: getClockInData },
    { type: 'DISPLACEMENT', mutation: reportDisplacement, getData: getDisplacementData },
    { type: 'VISITS', mutation: reportVisit, getData: getVisitData },
    { type: 'TRANSFERENCES', mutation: reportTransference, getData: getTransferenceData },
    {
      type: 'ANALYZE',
      mutation: reportAnalyzes,
      getData: getAnalyzeData,
      getDataToPdf: getAnalyzePdfData,
      itemsPerPage: 38,
    },
    {
      type: 'ANALYZE_WITH_PHOTO',
      mutation: reportAnalyzes,
      getDataToPdf: getAnalyzePdfData,
      itemsPerPage: 4,
    },
    { type: 'PROBLEMS', mutation: reportProblem, getData: getProblemData },
    { type: 'STOCK', mutation: reportStock, getData: getStockData },
    { type: 'DAILY_TRATAMENT', mutation: reportDailyTreatment, getData: getDailyTreatment },
    { type: 'POOL_CONDITIONS', mutation: reportPoolConditions, getData: getPoolConditions },
  ];

  const operation = operationList.find((operationOption) => operationOption.type === type);

  return mutation(apollo, operation.mutation, variables).then(async (resp) => {
    let result;
    if (isPDF) {
      const isWithPhoto = operation.type === 'ANALYZE_WITH_PHOTO';
      const clientData = await getUserClientById(apollo, { id: variables.client });
      result = operation.getDataToPdf(resp, clientData.user, isWithPhoto);
      return downloadAnalyzesPDF(
        `${operation.type + moment(new Date()).format('DD/MM/YYYY')}.pdf`, result, operation.itemsPerPage,
      );
    }
    result = operation.getData(resp);
    return downloadCSV(`${operation.type + moment(new Date()).format('DD/MM/YYYY')}.csv`, result);
  });
};
