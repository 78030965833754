var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      attrs: { width: _vm.windowWidth < 960 ? `${_vm.windowWidth}px` : "100%" }
    },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "#0d1824", dark: "", flat: "" } },
        [_c("v-toolbar-title", [_vm._v("Cadastro de Equipamento")])],
        1
      ),
      _c(
        "v-container",
        [
          _c(
            "v-flex",
            { attrs: { id: "selectType" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  autocomplete: "off",
                  "menu-props": "auto",
                  items: _vm.types,
                  "item-value": "value",
                  "item-text": "name",
                  label: "Selecione o tipo *"
                },
                model: {
                  value: _vm.selectedType,
                  callback: function($$v) {
                    _vm.selectedType = $$v
                  },
                  expression: "selectedType"
                }
              })
            ],
            1
          ),
          _vm.selectedType === "OUTRO"
            ? _c("v-text-field", {
                attrs: {
                  disabled: _vm.isLoading,
                  label: "Escreva o tipo *",
                  id: "textFieldType"
                },
                model: {
                  value: _vm.textFieldType,
                  callback: function($$v) {
                    _vm.textFieldType = $$v
                  },
                  expression: "textFieldType"
                }
              })
            : _vm._e(),
          _c("v-text-field", {
            attrs: {
              disabled: _vm.isLoading,
              label: "Marca",
              id: "brandField"
            },
            model: {
              value: _vm.equipmentDetails.brand,
              callback: function($$v) {
                _vm.$set(_vm.equipmentDetails, "brand", $$v)
              },
              expression: "equipmentDetails.brand"
            }
          }),
          _c("v-text-field", {
            attrs: {
              disabled: _vm.isLoading,
              label: "Modelo",
              id: "modelField"
            },
            model: {
              value: _vm.equipmentDetails.model,
              callback: function($$v) {
                _vm.$set(_vm.equipmentDetails, "model", $$v)
              },
              expression: "equipmentDetails.model"
            }
          }),
          _vm.selectedType !== "Filtro"
            ? _c("v-text-field", {
                attrs: {
                  disabled: _vm.isLoading,
                  label: "Pontência",
                  id: "powerField"
                },
                model: {
                  value: _vm.equipmentDetails.power,
                  callback: function($$v) {
                    _vm.$set(_vm.equipmentDetails, "power", $$v)
                  },
                  expression: "equipmentDetails.power"
                }
              })
            : _vm._e(),
          _c(
            "v-flex",
            { attrs: { id: "tensionSelect" } },
            [
              _vm.selectedType !== "Filtro"
                ? _c("v-autocomplete", {
                    attrs: {
                      autocomplete: "off",
                      "menu-props": "auto",
                      items: _vm.tensions,
                      "item-value": "value",
                      "item-text": "name",
                      label: "Tensão"
                    },
                    model: {
                      value: _vm.equipmentDetails.tension,
                      callback: function($$v) {
                        _vm.$set(_vm.equipmentDetails, "tension", $$v)
                      },
                      expression: "equipmentDetails.tension"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { id: "currentSelect" } },
            [
              _vm.selectedType !== "Filtro"
                ? _c("v-autocomplete", {
                    attrs: {
                      autocomplete: "off",
                      "menu-props": "auto",
                      items: _vm.currents,
                      "item-value": "value",
                      "item-text": "name",
                      label: "Corrente"
                    },
                    model: {
                      value: _vm.equipmentDetails.current,
                      callback: function($$v) {
                        _vm.$set(_vm.equipmentDetails, "current", $$v)
                      },
                      expression: "equipmentDetails.current"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.selectedType !== "Bomba"
            ? _c("v-text-field", {
                attrs: {
                  disabled: _vm.isLoading,
                  label: "Diâmetro (cm)",
                  id: "diameterField",
                  type: "number"
                },
                model: {
                  value: _vm.equipmentDetails.diameter,
                  callback: function($$v) {
                    _vm.$set(_vm.equipmentDetails, "diameter", $$v)
                  },
                  expression: "equipmentDetails.diameter"
                }
              })
            : _vm._e(),
          _vm.selectedType !== "Bomba"
            ? _c("v-text-field", {
                attrs: {
                  disabled: _vm.isLoading,
                  label: "Área (m²)",
                  id: "areaField",
                  type: "number"
                },
                model: {
                  value: _vm.equipmentDetails.area,
                  callback: function($$v) {
                    _vm.$set(_vm.equipmentDetails, "area", $$v)
                  },
                  expression: "equipmentDetails.area"
                }
              })
            : _vm._e(),
          _vm.selectedType !== "Bomba"
            ? _c("v-text-field", {
                attrs: {
                  disabled: _vm.isLoading,
                  label: "Carga (kg)",
                  id: "loadField",
                  type: "number"
                },
                model: {
                  value: _vm.equipmentDetails.load,
                  callback: function($$v) {
                    _vm.$set(_vm.equipmentDetails, "load", $$v)
                  },
                  expression: "equipmentDetails.load"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _vm.equipmentId && !this.isLoading && !_vm.isFranchisee()
            ? _c(
                "v-btn",
                {
                  attrs: { flat: "", color: "primary", id: "disabledButton" },
                  on: {
                    click: function($event) {
                      return _vm.updateEquipmentStatus(!_vm.isDisabled)
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.isDisabled
                        ? "Ativar Equipamento"
                        : "Desativar Equipamento"
                    )
                  )
                ]
              )
            : _vm._e(),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                color: "primary",
                disabled: _vm.isLoading,
                id: "closeModal"
              },
              on: {
                click: function($event) {
                  return _vm.closeModal()
                },
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.closeModal()
                }
              }
            },
            [_vm._v("Fechar")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                id: "submitEquipment",
                disabled:
                  _vm.isLoading || (!_vm.selectedType && !_vm.textFieldType),
                loading: _vm.isLoading,
                color: "primary"
              },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("Salvar")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }