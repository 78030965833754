<template>
  <v-card
    v-if="dataAnalyze"
    @keyup.esc="closeModal"
    @keyup.left="navigateBetweenAnalyzes('previous')"
    @keyup.right="navigateBetweenAnalyzes('next')"
    tabindex="0"
  >
    <v-toolbar color="#0d1824" dark flat>
      <v-spacer />
      <v-btn flat outline icon v-if="currentIndex > 0" @click="navigateBetweenAnalyzes('first')">
        <v-icon>keyboard_double_arrow_left</v-icon>
      </v-btn>
      <v-btn flat outline icon v-if="currentIndex > 0" @click="navigateBetweenAnalyzes('previous')">
        <v-icon title="Anterior">keyboard_arrow_left</v-icon>
      </v-btn>
      <h1 title="Piscina">
        <span> {{ currentAnalyzeLabel }}</span> -
        <span class="header-link" @click="handlePoolClick()">{{ dataAnalyze.pool.name }}</span>
        - {{ dataAnalyze.createDay | dateTime }}
      </h1>
      <v-btn
        flat
        outline
        icon
        v-if="currentIndex != lastIndexAnalyze"
        @click="navigateBetweenAnalyzes('next')"
      >
        <v-icon title="Próxima">keyboard_arrow_right</v-icon>
      </v-btn>
      <v-btn
        flat
        outline
        icon
        v-if="currentIndex != lastIndexAnalyze"
        @click="navigateBetweenAnalyzes('last')"
      >
        <v-icon>keyboard_double_arrow_right</v-icon>
      </v-btn>
      <v-spacer />
    </v-toolbar>

    <v-container>
      <v-layout wrap>
        <v-flex><PhotosBoard class="ma-1" :analyze="dataAnalyze"/></v-flex>
        <div class="ma-1 info-container" v-if="dataAnalyze.employee || dataAnalyze.pool.local">
          <v-card class="pa-2" v-if="dataAnalyze.employee">
            <div v-if="isAdmin || isCoordinador">
              <h3>Colaborador: {{ dataAnalyze.employee.name }}</h3>
              <v-flex
                v-show="!isClient"
                v-for="telephone in dataAnalyze.employee.user.telephones"
                :key="telephone.id"
              >
                Contato: ({{ telephone.ddd }}) {{ telephone.telephoneNumber }}
              </v-flex>
            </div>
          </v-card>
          <v-card v-if="dataAnalyze.pool.local" class="pa-2 mt-2">
            <h3 class="header-link" @click="handleLocalClick()">
              {{ dataAnalyze.pool.local.localName }}
            </h3>
            <b>Piscinas neste local:</b>
            <v-flex
              v-for="pool in dataAnalyze.pool.local.pools"
              :key="pool.id"
              @click="goToAnalyze(pool.analyze[0].id)"
              :class="{ 'pool-link': pool.analyze[0] && pool.id !== dataAnalyze.pool.id }"
            >
              {{ pool.name }}
            </v-flex>
          </v-card>
        </div>
      </v-layout>

      <ParametersBoard class="mx-1 mt-1" :analyze="this.dataAnalyze" />

      <v-layout wrap>
        <v-flex v-if="!isClient" xs12 md6>
          <StockBoard
            class="mx-1 mt-2"
            :localName="
              this.dataAnalyze.pool.local ? this.dataAnalyze.pool.local.localName : undefined
            "
            :products="availableStockProducts || allProducts"
          />
        </v-flex>
        <v-flex xs12 md6>
          <ProductsBoard
            :poolVolume="dataAnalyze.pool.volume"
            v-if="iaSuggestion.length && (isCoordinador || isAdmin)"
            class="mx-1 mt-2"
            title="Sugestão automática"
            :products="iaSuggestion"
          />
          <SuggestionBoard
            :poolVolume="dataAnalyze.pool.volume"
            v-if="
              !this.dataAnalyze.suggestion &&
                (isCoordinador || isAdmin) &&
                (!this.dataAnalyze.standbyApplication ||
                  !this.dataAnalyze.standbyApplication.length)
            "
            class="mx-1 mt-2"
            @suggestedProducts="
              (event) => {
                suggestedProducts = event;
              }
            "
            v-model="suggestedProducts"
            :iaSuggestion="this.iaSuggestion"
            :availableProducts="availableStockProductIds"
          />
          <ProductsBoard
            :poolVolume="dataAnalyze.pool.volume"
            v-if="suggestedProducts.length && !isClient"
            class="mx-1 mt-2"
            :hasRemoveBtn="!this.dataAnalyze.suggestion"
            title="Produtos sugeridos"
            :products="suggestedProducts"
          />

          <ProductsBoard
            :poolVolume="dataAnalyze.pool.volume"
            v-if="aplicationProducts.length"
            class="mx-1 mt-2"
            title="Produtos aplicados"
            :products="aplicationProducts"
          />
        </v-flex>
      </v-layout>
    </v-container>

    <v-card-actions>
      <div class="button-wrap">
        <v-btn
          v-if="
            (isCoordinador || isAdmin) && (this.dataAnalyze.deviceId || this.dataAnalyze.employee)
          "
          outline
          color="primary"
          @click="openChat"
          ><v-icon left>support</v-icon>Suporte</v-btn
        >
        <v-btn
          outline
          color="primary"
          @click="navigateBetweenAnalyzes('previous')"
          :disabled="currentIndex === 0"
        >
          <v-icon left>chevron_left</v-icon>
          Anterior
        </v-btn>
        <v-btn
          outline
          color="primary"
          @click="navigateBetweenAnalyzes('next')"
          :disabled="currentIndex === lastIndexAnalyze"
        >
          Próxima
          <v-icon right>chevron_right</v-icon>
        </v-btn>
        <v-btn outline color="primary" @click="closeModal()">
          <v-icon left>close</v-icon>
          Fechar modal
        </v-btn>
        <v-btn
          :loading="isLoading"
          color="red"
          v-if="!this.dataAnalyze.suggestion && (isCoordinador || isAdmin)"
          @click="submitSuggestionIA()"
          outline
        >
          <v-icon left>check</v-icon>
          Fechar análise
        </v-btn>
        <v-btn
          :loading="isLoading"
          color="green"
          outline
          v-if="!this.dataAnalyze.suggestion && (isCoordinador || isAdmin)"
          @click="submitSuggestion()"
          id="submitSuggestion"
        >
          <v-icon left>send</v-icon>
          Enviar sugestão
        </v-btn>
      </div>
    </v-card-actions>
    <CardModal v-model="showNewPoolCard" width="30%">
      <NewPoolCard
        @showNewPoolCard="onShowNewPoolCard"
        :id="dataAnalyze.pool.id"
        :volume="dataAnalyze.pool.volume"
        :name="dataAnalyze.pool.name"
        :hasTabletSupport="dataAnalyze.pool.hasTabletApplicationSupport"
        :hasAutomaticSuggestion="dataAnalyze.pool.isEnabledAutomaticSuggestion"
      />
    </CardModal>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate';
import ParametersBoard from '@/components/analyzes/ParametersBoard.vue';
import PhotosBoard from '@/components/analyzes/PhotosBoard.vue';
import StockBoard from '@/components/analyzes/StockBoard.vue';
import SuggestionBoard from '@/components/analyzes/SuggestionBoard.vue';
import ProductsBoard from '@/components/analyzes/ProductsBoard.vue';
import { getAnalyze, getAnalyzePool } from '@/services/AnalyzeService';
import { createSuggestion } from '@/services/SuggestionService';
import { isRole } from '@/services/UtilsService';
import NewPoolCard from '@/components/places/NewPoolCard.vue';
import CardModal from '@/components/modal/CardModal.vue';

import eventBus from '@/eventBus';
import * as moment from 'moment';

export default {
  mixins: [validationMixin],
  validations: {},

  data: () => ({
    showNewPoolCard: false,
    isCoordinador: isRole('COORDINADOR'),
    isAdmin: isRole('ADMIN'),
    isClient: isRole('CLIENT'),
    isLoading: false,
    isShowAllProductsSelected: false,
    iaSuggestion: '',
    availableProducts: '',
    currentIndex: '',
    dataAnalyze: '',
    analyzeProduct: '',
    isDisabled: false,
    isRequired: true,
    suggestedProducts: [],
    aplicationProducts: [],
    lastIndexAnalyze: '',
    idsAnalyzes: [],
    analyzeIdData: '',
  }),
  components: {
    ProductsBoard,
    SuggestionBoard,
    ParametersBoard,
    PhotosBoard,
    StockBoard,
    NewPoolCard,
    CardModal,
  },
  computed: {
    currentAnalyzeLabel() {
      return `${this.currentIndex + 1}/${this.dataAnalyze.pool.analyze.length}`;
    },
    allProducts() {
      const localPool = this.dataAnalyze.pool.local;
      if (!localPool) return [];

      const stockDefault = localPool.stock;
      if (this.isClient || !localPool.localServingStock) return stockDefault;

      return stockDefault.reduce((mainStock, stockDefaultProduct) => {
        const mainStockProduct = mainStock.find(
          (product) => product.description.id === stockDefaultProduct.description.id,
        );
        if (mainStockProduct) {
          mainStockProduct.amount += stockDefaultProduct.amount;
        } else {
          mainStock.push(stockDefaultProduct);
        }
        return mainStock;
      }, localPool.localServingStock.stock);
    },
    availableStockProducts() {
      if (this.dataAnalyze.availableProducts && this.dataAnalyze.availableProducts.length) {
        return this.dataAnalyze.availableProducts.map((description) => ({ description }));
      }
      return (
        this.dataAnalyze.employee &&
        this.dataAnalyze.employee.availableProducts &&
        this.dataAnalyze.employee.availableProducts.length &&
        this.dataAnalyze.employee.availableProducts.map((description) => ({ description }))
      );
    },
    availableStockProductIds() {
      return (
        this.availableStockProducts &&
        this.availableStockProducts.map((product) => product.description.id)
      );
    },
  },
  methods: {
    openChat() {
      const originId = this.dataAnalyze.employee?.user?.id || this.dataAnalyze.deviceId;
      window.open(`${window.location.origin}/dashboard/chat?originId=${originId}`, '_blank');
    },
    onShowNewPoolCard(value) {
      this.showNewPoolCard = value;
      if (!value) {
        this.selectSingleAnalyze();
      }
    },
    handlePoolClick() {
      this.showNewPoolCard = true;
    },
    handleLocalClick() {
      const page = `/dashboard/locais?id=${this.dataAnalyze.pool.local.id}`;
      window.location.href = page;
    },
    findCurrentAnalyzeIndex() {
      this.currentIndex = this.dataAnalyze.pool.analyze.findIndex(
        (analyze) => analyze.id === this.analyzeIdData,
      );
    },
    closeModal() {
      this.$emit('showModal', false);
    },
    resetSingleAnalyze() {
      this.iaSuggestion = '';
      this.dataAnalyze = '';
      this.aplicationProducts = [];
      this.suggestedProducts = [];
    },
    async selectSingleAnalyze() {
      this.resetSingleAnalyze();
      const analyzeRespPromise = getAnalyze(this.$apollo, { id: this.analyzeIdData });
      const poolRespPromise = getAnalyzePool(this.$apollo, { id: this.analyzeIdData });
      const [{ analyze }, { pool }] = await Promise.all([analyzeRespPromise, poolRespPromise]);
      analyze.pool = pool;
      this.dataAnalyze = analyze;
      this.lastIndexAnalyze = analyze.pool.analyze.length - 1;
      this.aplicationProducts =
        analyze.suggestion?.aplication?.products || analyze.standbyApplication || [];
      this.suggestedProducts = analyze.suggestion?.suggestedProducts || [];
      this.iaSuggestion = analyze.iaSuggestion?.suggestedProducts || [];

        this.findCurrentAnalyzeIndex();
    },
    submitSuggestion() {
      this.isLoading = true;
      const products = this.suggestedProducts.map((element) => ({
        amount: element.amount,
        description: { connect: { id: element.description.id } },
      }));

      const variables = {
        data: {
          isCreatedByHuman: true,
          isCreatedByIA: false,
          analyze: { connect: { id: this.analyzeIdData } },
          suggestedProducts: { create: products },
        },
      };
      createSuggestion(this.$apollo, variables).then(() => this.finish());
    },
    submitSuggestionIA() {
      this.isLoading = true;
      const products = this.iaSuggestion.map((element) => ({
        amount: element.amount,
        description: { connect: { id: element.description.id } },
      }));

      const variables = {
        data: {
          isCreatedByHuman: false,
          isCreatedByIA: true,
          analyze: { connect: { id: this.analyzeIdData } },
          suggestedProducts: { create: products },
        },
      };
      createSuggestion(this.$apollo, variables).then(() => this.finish());
    },
    finish() {
      this.$emit('reloadList');
      this.closeModal();
      this.isLoading = false;
    },
    navigateBetweenAnalyzes(action) {
      const actionMap = {
        first: 0,
        previous: this.currentIndex - 1,
        next: this.currentIndex + 1,
        last: this.lastIndexAnalyze,
      };
      this.analyzeIdData = this.dataAnalyze.pool.analyze[actionMap[action]].id;
      this.addIdToURL();
      this.selectSingleAnalyze();
    },
    goToAnalyze(id) {
      if (this.analyzeIdData !== id) {
        this.analyzeIdData = id;
        this.addIdToURL();
        this.selectSingleAnalyze();
      }
    },
    addIdToURL() {
      window.history.replaceState(null, null, `?id=${this.analyzeIdData}`);
    },
  },
  filters: {
    dateTime(day) {
      return moment(day).format('DD/MM/YY HH:mm');
    },
  },
  watch: {
    analyzeId() {
      this.analyzeIdData = this.analyzeId;
      this.selectSingleAnalyze();
      this.addIdToURL();
    },
  },
  created() {
    this.analyzeIdData = this.analyzeId;
    eventBus.onSelectedProduct((product) => {
      this.analyzeProduct = product;
    });
  },
  props: ['analyzeId'],
};
</script>
<style>
.button-wrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0;
}

.button-wrap > .v-btn {
  min-width: 0;
  margin: 0;
}

.button-wrap > .v-btn + .v-btn {
  margin: 0;
}
#analyzeImage {
  width: 100%;
  height: 100%;
}
#analyzeImage:hover {
  opacity: 0.7;
  transition: 0.5s ease;
}
.v-dialog {
  width: fit-content;
}
.table {
  width: 100%;
}
.table th {
  text-align: center;
  background-color: rgb(196, 198, 221);
}
.table td {
  text-align: center;
}
.separator {
  margin: 0 30px 0 30px;
}
.pool-link {
  cursor: pointer;
  text-decoration: underline;
  color: #1e88ca;
}
.info-container {
  max-width: 260px;
}
.header-link {
  cursor: pointer;
}
@media screen and (max-width: 1366px) {
  #analyzeImage {
    width: 110%;
    height: 95%;
  }
  .analyzeCardTitle {
    font-size: 80%;
  }
  .analyzePoolName {
    font-size: 60%;
  }
  .analyzeCardSubTitle {
    font-size: 80%;
  }
  .stock {
    font-size: 80%;
  }
  .appliedProducts {
    font-size: 80%;
  }
}
@media screen and (max-width: 960px) {
  #analyzeImage {
    width: 100%;
    height: 100%;
  }
  .analyzeCardTitle {
    font-size: 70%;
  }
  .analyzeCardSubTitle {
    font-size: 70%;
  }
  .suggestionCard {
    padding-top: 9%;
  }
  .suggestionQuantityProducts {
    margin-right: 4%;
  }
  .button-wrap > .v-btn {
    flex: auto;
  }
}
</style>
