var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-snackbar",
        {
          attrs: {
            id: "snackbar",
            right: true,
            timeout: _vm.value.timeout || 2000,
            color: _vm.value.isError ? "red" : "grey darken-4"
          },
          model: {
            value: _vm.showSnack,
            callback: function($$v) {
              _vm.showSnack = $$v
            },
            expression: "showSnack"
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.value.text) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }