var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        "margin-bottom": "8px",
        "box-shadow": "0px 2px 5px 2px rgba(0,0,0,0.40)"
      },
      attrs: { id: "clockCheck" }
    },
    _vm._l(_vm.contentLines, function(line, index) {
      return _c(
        "v-list-tile",
        {
          key: index,
          class:
            line.type === "Ponto"
              ? line.duration.alert == true
                ? "tileHeaderAlert"
                : "tileHeader"
              : "tileContent",
          attrs: { avatar: "" },
          on: {
            click: function($event) {
              return _vm.openPhoto(line)
            }
          }
        },
        [
          _c(
            "v-flex",
            { attrs: { xs6: "", lg4: "" } },
            [
              _c(
                "v-list-tile-content",
                [
                  _c("v-list-tile-title", {
                    domProps: { textContent: _vm._s(line.employeeName) }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "hideMobile", attrs: { xs4: "", lg4: "" } },
            [
              _c(
                "v-list-tile-content",
                [
                  _c("v-list-tile-title", {
                    domProps: { textContent: _vm._s(line.local) }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs3: "", lg1: "" } },
            [
              _c(
                "v-list-tile-content",
                {
                  staticClass: "alignment",
                  attrs: {
                    title: _vm.formatDateTimeAndObeservationText(
                      line.startInCell,
                      line.observationStart
                    )
                  }
                },
                [
                  _vm._v("\n        " + _vm._s(line.start.time) + "\n        "),
                  _c("br"),
                  _vm._v(
                    "\n        " +
                      _vm._s(!index ? line.start.date : "") +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs3: "", lg1: "" } },
            [
              _c(
                "v-list-tile-content",
                {
                  staticClass: "alignment",
                  attrs: {
                    title: _vm.formatDateTimeAndObeservationText(
                      line.endsInCell,
                      line.observationEnds
                    )
                  }
                },
                [
                  _vm._v("\n        " + _vm._s(line.ends.time) + "\n        "),
                  _c("br"),
                  _vm._v(
                    "\n        " +
                      _vm._s(!index ? line.ends.date : "") +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "hideMobile", attrs: { xs3: "", lg1: "" } },
            [
              _c(
                "v-list-tile-content",
                [
                  _c("v-list-tile-title", {
                    staticClass: "alignment",
                    domProps: { textContent: _vm._s(line.delay) }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "hideMobile", attrs: { xs2: "", lg2: "" } },
            [
              _c(
                "v-list-tile-content",
                [
                  _c("v-list-tile-title", {
                    staticClass: "alignment",
                    domProps: { textContent: _vm._s(line.duration.string) }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }