var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    attrs: {
      autocomplete: "off",
      "menu-props": "auto",
      disabled: _vm.disabled,
      items: _vm.avalibleProduts,
      value: _vm.value,
      label: "Selecione o produto",
      "item-text": "name",
      "item-value": "value"
    },
    on: {
      change: id => {
        _vm.$emit("input", id)
        _vm.$emit(
          "selected",
          _vm.productDescriptionList.find(
            description => description.value === id
          )
        )
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }