var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      attrs: { width: _vm.windowWidth < 960 ? `${_vm.windowWidth}px` : "100%" }
    },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "#0d1824", dark: "", flat: "" } },
        [_c("v-toolbar-title", [_vm._v("Notificação")])],
        1
      ),
      !_vm.isLoading
        ? _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("strong", [_vm._v("Tipo:")]),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("notificationType")(_vm.dataNotification.type)
                        ) +
                        "\n      "
                    )
                  ]),
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("strong", [_vm._v("Status:")]),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("notificationStatus")(
                            _vm.dataNotification.status
                          )
                        ) +
                        "\n      "
                    )
                  ]),
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("strong", [_vm._v("Data:")]),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("formatedDay")(_vm.dataNotification.day)
                        ) +
                        "\n      "
                    )
                  ]),
                  _c("v-flex", { staticClass: "my-2", attrs: { xs12: "" } }, [
                    _c("strong", [_vm._v("Título:")]),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.dataNotification.title) +
                        "\n      "
                    )
                  ])
                ],
                1
              ),
              _c("v-layout", { staticClass: "mb-2", attrs: { row: "" } }, [
                _c("strong", [_vm._v("Mensagem:")]),
                !_vm.messageData
                  ? _c("div", [_vm._v(_vm._s(_vm.dataNotification.message))])
                  : _vm._e(),
                _vm.messageData && !_vm.messageData.local
                  ? _c("div", [_vm._v(_vm._s(_vm.messageData.message))])
                  : _vm._e()
              ]),
              _vm.dataNotification.type === "GEOLOCALIZACAO"
                ? _c(
                    "v-layout",
                    [
                      _c("googleMaps", {
                        staticClass: "map",
                        attrs: {
                          center: _vm.markersCenter(),
                          defaultMarkers: _vm.markers(),
                          localMarkers: _vm.markerPlace,
                          xs4: ""
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-layout", [
                _vm.messageData && _vm.messageData.differences
                  ? _c(
                      "div",
                      [
                        _c("p", [_vm._v(_vm._s(_vm.messageData.header))]),
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-tile",
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs4: "" } },
                                  [
                                    _c("v-list-tile-content", [
                                      _c("b", [_vm._v("Produto")])
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs4: "" } },
                                  [
                                    _c("v-list-tile-content", [
                                      _c("b", [_vm._v("Unidade")])
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs4: "" } },
                                  [
                                    _c("v-list-tile-content", [
                                      _c("b", [_vm._v("Contagem")])
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs4: "" } },
                                  [
                                    _c("v-list-tile-content", [
                                      _c("b", [_vm._v("Esperado")])
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs4: "" } },
                                  [
                                    _c("v-list-tile-content", [
                                      _c("b", [_vm._v("Diferença")])
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs4: "" } },
                                  [
                                    _c("v-list-tile-content", [
                                      _c("b", [_vm._v("%")])
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._l(_vm.messageData.differences, function(
                              difference,
                              index
                            ) {
                              return _c(
                                "v-list-tile",
                                { key: index },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs2: "" } },
                                    [
                                      _c("v-list-tile-content", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(difference.name) +
                                            "\n              "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs2: "" } },
                                    [
                                      _c("v-list-tile-content", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("units")(difference.unit)
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs2: "" } },
                                    [
                                      _c("v-list-tile-content", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              difference.tranferenceAmount.toFixed(
                                                2
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs2: "" } },
                                    [
                                      _c("v-list-tile-content", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              difference.localAmount.toFixed(2)
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs2: "" } },
                                    [
                                      _c("v-list-tile-content", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              difference.difference.toFixed(2)
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs2: "" } },
                                    [
                                      _c("v-list-tile-content", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              difference.differencePercentage
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.messageData && _vm.messageData.negativeProducts
                  ? _c(
                      "div",
                      [
                        _c("p", [_vm._v(_vm._s(_vm.messageData.header))]),
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-tile",
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs4: "" } },
                                  [
                                    _c("v-list-tile-content", [
                                      _c("b", [_vm._v("Produto")])
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs4: "" } },
                                  [
                                    _c("v-list-tile-content", [
                                      _c("b", [_vm._v("Unidade")])
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs4: "" } },
                                  [
                                    _c("v-list-tile-content", [
                                      _c("b", [_vm._v("Quantidade anterior")])
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs4: "" } },
                                  [
                                    _c("v-list-tile-content", [
                                      _c("b", [_vm._v("Após contagem")])
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._l(_vm.messageData.negativeProducts, function(
                              negativeProduct,
                              index
                            ) {
                              return _c(
                                "v-list-tile",
                                { key: index },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs4: "" } },
                                    [
                                      _c("v-list-tile-content", [
                                        _vm._v(_vm._s(negativeProduct.name))
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs4: "" } },
                                    [
                                      _c("v-list-tile-content", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("units")(
                                              negativeProduct.unit
                                            )
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs4: "" } },
                                    [
                                      _c("v-list-tile-content", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              negativeProduct.amountBeforeTranference.toFixed(
                                                2
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs4: "" } },
                                    [
                                      _c("v-list-tile-content", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              negativeProduct.amountAfterTranference.toFixed(
                                                2
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { xs12: "" }
                    },
                    [
                      _c("strong", [_vm._v("Observação:")]),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.dataNotification.observations
                              ? _vm.dataNotification.observations[0]
                              : ""
                          ) +
                          "\n      "
                      )
                    ]
                  ),
                  _vm.dataNotification.status === "ABERTA"
                    ? _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.observations,
                            expression: "observations"
                          }
                        ],
                        staticStyle: {
                          width: "700px",
                          height: "50px",
                          "margin-top": "20px"
                        },
                        attrs: {
                          xs12: "",
                          id: "observation",
                          placeholder: "Escreva sua observação aqui.",
                          type: "text"
                        },
                        domProps: { value: _vm.observations },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.observations = $event.target.value
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md4: "", lg3: "" } },
                        [
                          _vm.dataNotification.status === "ABERTA"
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mx-0 mb-0 mt-2",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    color: "primary",
                                    title:
                                      "Salva a observação e muda seu status para fechada",
                                    id: "submitClose"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.submit(true)
                                    }
                                  }
                                },
                                [_vm._v("Fechar notificação")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            md4: "",
                            lg3: "",
                            "offset-lg6": "",
                            "offset-md4": "",
                            "justify-end": ""
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-0 mb-0 mt-2",
                              staticStyle: { width: "50%" },
                              attrs: {
                                flat: "",
                                color: "primary",
                                id: "closeModal"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.closeModal()
                                }
                              }
                            },
                            [_vm._v("Fechar")]
                          ),
                          _vm.dataNotification.status === "ABERTA"
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mx-0 mb-0 mt-2",
                                  staticStyle: { width: "50%" },
                                  attrs: {
                                    flat: "",
                                    color: "primary",
                                    title: "Salva a observação",
                                    id: "submit"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.submit(false)
                                    }
                                  }
                                },
                                [_vm._v("Salvar")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "text-xs-center" },
            [
              _c("v-progress-circular", {
                staticClass: "loader ma-5",
                attrs: { indeterminate: "", color: "primary" }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }