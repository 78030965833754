<template>
  <LoadingCircle class="ma-5" v-if="isLoading" />
  <v-card v-else class="mt-4" justify-center>
    <v-data-table
      :pagination.sync="pagination"
      hide-actions
      :headers="contentList.columns"
      :items="contentList.data"
      class="elevation-1"
      no-data-text="Não há dados a serem exibidos"
    >
      <template v-if="isTemplateVisible" v-slot:items="props">
        <td
          :style="`cursor: pointer;`"
          :class="itemClasses[props.item.id]"
          @click="
            () => {
              if (hasClickedPainting) {
                paintItem(props.item);
              }
              $emit('click', props.item);
            }
          "
          v-for="column in contentList.columns"
          :key="column.value"
        >
          <p v-if="column.type === 'text'">
            {{ props.item[column.value] }}
          </p>
          <v-icon v-if="column.type === 'icon'">
            {{ props.item[column.value] }}
          </v-icon>
          <div v-if="column.type === 'photo'">
            <v-img
              aspect-ratio="2"
              v-if="props.item[column.value]"
              :src="props.item[column.value]"
              width="50"
              height="50"
              style="margin-top: 5px;"
            />
            <i v-else class="material-icons" style="margin-left: 12px;">visibility_off</i>
          </div>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { isScreenSize } from '@/services/UtilsService';
import LoadingCircle from '@/components/animations/LoadingCircle.vue';

export default {
  data: () => ({
    isTemplateVisible: true,
    itemClasses: {},
    pagination: { rowsPerPage: -1 },
    localStorage,
    isScreenSize,
  }),
  methods: {
    paintItem(item) {
      this.itemClasses[item.id] = 'clicked';
      this.$forceUpdate();
    },
    shortenIfSmallScreen(value) {
      if (!value) return '';
      const difer = value.length > 8 ? value.length - 8 : 0;
      return this.isScreenSize(localStorage, ['xs', 'sm']) && !Number(value) && difer
        ? `${value.slice(0, difer * -1)}...`
        : value;
    },
  },
  components: {
    LoadingCircle,
  },
  created() {
    if (this.sortBy) {
      this.pagination = { descending: this.isDescending, sortBy: this.sortBy, rowsPerPage: -1 };
    }
  },
  props: ['contentList', 'isLoading', 'isDescending', 'sortBy', 'hasClickedPainting'],
};
</script>
<style>
.toolbarFilters {
  padding-top: 10px;
}
.v-toolbar__content {
  padding-left: 16px;
}
.headerList {
  font-size: large;
  text-align: center;
}
.clicked {
  background: #d5e5f6;
}
</style>
