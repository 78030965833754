var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "grid-list-xl": "", id: "containerStockTab" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", md7: "" } }, [
            _c("h2", [_vm._v("Piscinas deste local")])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", md5: "" } },
            [
              !_vm.isClient
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ma-0",
                      staticStyle: { width: "100%" },
                      attrs: { large: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.showNewPoolCard = !_vm.showNewPoolCard
                        }
                      }
                    },
                    [_vm._v("Cadastrar piscina")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("DataTable", {
                attrs: { tableContent: _vm.tablePool },
                on: {
                  edit: index => _vm.editPool(index),
                  urlForQrCode: url => _vm.setUrlForQrCode(url)
                }
              })
            ],
            1
          )
        ],
        1
      ),
      !_vm.isClient
        ? _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { flat: "", color: "primary", id: "closePool" },
                  on: {
                    click: function($event) {
                      return _vm.closeModal()
                    }
                  }
                },
                [_vm._v("Fechar")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "CardModal",
        {
          attrs: { width: "30%" },
          model: {
            value: _vm.showNewPoolCard,
            callback: function($$v) {
              _vm.showNewPoolCard = $$v
            },
            expression: "showNewPoolCard"
          }
        },
        [
          _c("NewPoolCard", {
            attrs: {
              contentList: _vm.tablePool.contentList,
              idPlaceEdit: _vm.idPlaceEdit,
              id: _vm.poolToEdit.id,
              volume: _vm.poolToEdit.volume,
              name: _vm.poolToEdit.name,
              hasTabletSupport: _vm.poolToEdit.hasTabletSupport,
              hasAutomaticSuggestion: _vm.poolToEdit.hasAutomaticSuggestion
            },
            on: {
              showNewPoolCard: function($event) {
                _vm.showNewPoolCard = $event
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          model: {
            value: _vm.showQrCode,
            callback: function($$v) {
              _vm.showQrCode = $$v
            },
            expression: "showQrCode"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-container",
                [
                  _c("qrcode", {
                    attrs: {
                      cls: "qrcode",
                      value: _vm.urlForQrCode,
                      size: _vm.qrCodeSize
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }