<template>
  <v-card>
    <v-layout class="grey lighten-3" justify-center>
      <v-card-title>
        <h3>{{ title }}</h3>
      </v-card-title>
    </v-layout>
    <v-card-text>
      <table class="table">
        <thead>
          <tr>
            <th>Produto</th>
            <th>Pureza</th>
            <th>Quantidade</th>
          </tr>
        </thead>
        <tbody>
          <tr width="100%" v-for="(product, index) in products" :key="product.id">
            <td style="text-align: left">{{ product.description.name }}</td>
            <td>{{ product.description.chemicalPurity }}%</td>
            <td>
              {{ product.amount }}
              {{ product.description.unit | units }}
              &nbsp;&nbsp;{{
                {
                  amount:
                    product.description.name === 'Pastilha tricloro'
                      ? product.amount * 0.2
                      : product.amount,
                  volume: poolVolume,
                  purity: product.description.chemicalPurity,
                } | ppm
              }}
            </td>
            <td v-if="hasRemoveBtn" width="36px">
              <v-btn icon style="margin: 0px; float: right" flat @click="removeProduct(index)">
                <v-icon>{{ 'close' }}</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({}),
  computed: {},
  methods: {
    removeProduct(index) {
      this.products.splice(index, 1);
    },
  },
  filters: {
    units(unit) {
      return (
        {
          LITRO: 'L',
          METRO: 'm',
          KILO: 'kg',
          UNIT: 'unid',
        }[unit] || 'Indisponível'
      );
    },
    ppm(data) {
      const purity = data.purity ? data.purity : 100;
      return data.volume
        ? `(${((data.amount * 1000 * (purity / 100)) / data.volume).toFixed(1)} ppm)`
        : '';
    },
  },
  components: {},
  props: ['products', 'title', 'hasRemoveBtn', 'poolVolume'],
};
</script>

<style scoped>
</style>
