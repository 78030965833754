import { query, mutation } from '@/services/GraphqlService';

import createUserMutation from '@/graphql/user/createUserMutation.gql';
import updateUserMutation from '@/graphql/user/updateUserMutation.gql';
import getUsersFranchiseeQuery from '@/graphql/user/getUsersFranchiseeQuery.gql';
import getUsersAllClientNameQuery from '@/graphql/user/getUsersAllClientNameQuery.gql';
import getNotClientUsersQuery from '@/graphql/user/getNotClientUsersQuery.gql';
import getUserClientByIdQuery from '@/graphql/user/getUserClientByIdQuery.gql';
import getUserByIdQuery from '@/graphql/user/getUserByIdQuery.gql';
import getAllUsersQuery from '@/graphql/user/getAllUsersQuery.gql';
import requestPasswordResetMutation from '@/graphql/login/requestPasswordReset.gql';
import getUsersByLocalIdQuery from '@/graphql/user/getUsersByLocalIdQuery.gql';

export const createUser = (apollo, variables) => mutation(apollo, createUserMutation, variables);
export const updateUser = (apollo, variables) => {
  delete variables.password;
  return mutation(apollo, updateUserMutation, variables);
};
export const getUsersFranchisee = (apollo) => query(apollo, getUsersFranchiseeQuery);
export const getUsersAllClientName = (apollo) => query(apollo, getUsersAllClientNameQuery);
export const getNotClientNames = (apollo) => query(apollo, getNotClientUsersQuery);
export const getUserClientById = (apollo, variables) =>
  query(apollo, getUserClientByIdQuery, variables);
export const getUserById = (apollo, variables) => query(apollo, getUserByIdQuery, variables);
export const getAllUsers = (apollo, variables) => query(apollo, getAllUsersQuery, variables);
export const resetPassword = (apollo, email) =>
  mutation(apollo, requestPasswordResetMutation, { email });
export const getUsersByLocalId = (apollo, variables) =>
  query(apollo, getUsersByLocalIdQuery, variables);

export const rolesFilter = (role) => {
  const data = {
    ADMIN: 'Administrador',
    COORDINADOR: 'Coordenador',
    FRANCHISEE: 'Franqueado',
    EMPLOYEE: 'Colaborador',
    CLIENT: 'Cliente',
    ASSOCIATE: 'Associado',
    LIMITED: 'Limitado',
  };
  return data[role];
};
