<template>
  <div>
    <v-tabs id="tabs">
      <v-tab
        v-for="(button, index) in buttons"
        :key="index"
        @click="$emit('selected', button.value)"
        id="localTab"
      >
        {{ button.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items>
      <v-tab-item>
        <v-card flat color="basil">
          <v-card-text>
            <slot></slot>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
export default {
  props: ['buttons'],
};
</script>
