<template>
  <v-card :width="windowWidth < 960 ? `${windowWidth}px` : '100%'">
    <v-toolbar color="#0d1824" dark flat>
      <v-toolbar-title>Movimentação</v-toolbar-title>
    </v-toolbar>
    <v-container v-if="transference">
      <v-layout wrap>
        <v-flex class="mr-2" xs6 md2>
          <h3>Data</h3>
          <p>{{ transference.day | dateTime }}</p>
        </v-flex>
        <v-flex class="mr-2" xs6 md2>
          <h3>Tipo</h3>
          <p>{{ transference.type | transferenceType }}</p>
        </v-flex>
        <v-flex class="mr-2" xs6 md2>
          <h3>Colaborador</h3>
          <p>{{ transference.employee.name }}</p>
        </v-flex>
        <v-flex class="mr-2" xs6 md2>
          <h3>Local</h3>
          <p>{{ transference.from.localName }}</p>
        </v-flex>
        <v-flex class="mr-2" v-if="transference.to" xs6 md2>
          <h3>Destino</h3>
          <p>{{ transference.to.localName }}</p>
        </v-flex>
      </v-layout>
      <h2 class="mb-2">Produtos</h2>
      <v-layout wrap>
        <v-flex class="mr-2" v-for="(product, index) in transference.products" :key="index" xs6 md2>
          <h3>{{ product.description.name }}</h3>
          <p>{{ product.amount }}{{ product.description.unit | unitOfMeasurement }}</p>
        </v-flex>
      </v-layout>
      <v-carousel
        v-if="transference.urlPhotos.length"
        :cycle="false"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item
          v-for="(urlPhoto, index) in transference.urlPhotos"
          :key="index"
          :src="urlPhoto"
        ></v-carousel-item>
      </v-carousel>
    </v-container>
    <div v-else class="text-xs-center">
      <v-progress-circular indeterminate color="primary" class="loader ma-5"></v-progress-circular>
    </div>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" flat @click="closeModal()">Fechar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getTransference } from '@/services/TransferenceService';
import * as moment from 'moment';
import { returnUnitsShortFilter, typesFilter } from '@/services/UtilsService';

export default {
  data: () => ({
    windowWidth: window.innerWidth,
    isLoading: false,
    transference: '',
  }),
  computed: {},
  methods: {
    closeModal() {
      this.$emit('showModal', false);
    },
    loadTransference() {
      this.isLoading = true;
      getTransference(this.$apollo, this.transferenceId)
        .then((resp) => {
          this.transference = resp.transference;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    transferenceId(id) {
      this.transference = this.$options.data().transference;
      if (id) {
        this.loadTransference();
      }
    },
  },
  filters: {
    unitOfMeasurement(value) {
      return returnUnitsShortFilter(value);
    },
    transferenceType(value) {
      return typesFilter(value);
    },
    dateTime(IsoString) {
      return moment(IsoString).format('DD/MM/YYYY HH:mm');
    },
  },
  components: {},
  props: ['transferenceId'],
};
</script>

<style scoped>
</style>
