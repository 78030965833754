<template>
  <v-autocomplete
    autocomplete="off"
    menu-props="auto"
    :disabled="selectList.length === 0"
    :items="selectList"
    @change="$emit('input', $event)"
    :value="value"
    label="Selecione o cliente."
    item-text="name"
    item-value="value"
  />
</template>
<script>
import { getUsersAllClientName } from '@/services/UserService';

export default {
  data: () => ({
    usersList: [],
  }),
  computed: {
    selectList() {
      const formatedUserList = this.usersList.map((user) => ({
        name: user.client.name,
        value: user.id,
      }));
      return formatedUserList;
    },
  },
  methods: {
    async geUsers() {
      const resp = await getUsersAllClientName(this.$apollo);
      this.usersList = resp.users;
    },
  },
  created() {
    this.geUsers();
  },
  props: ['value'],
};
</script>
