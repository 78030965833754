import moment from 'moment';
import eventBus from '@/eventBus';

export const normalizeString = (string) =>
  String(string)
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

export const filter = (array, where, searchTerm) =>
  array.filter((element) => normalizeString(element[where]).includes(normalizeString(searchTerm)));

export const isRole = (roleString) => localStorage.userRole === roleString;

export const formatDate = (date) => (date ? moment(date).format('DD/MM/YYYY') : '- -');

export const alertSnack = (text, isError = false) => eventBus.alertSnack(text, isError);

export const validationRequired = (value) =>
  Boolean(value) || 'O preenchimento deste campo é obrigatório.';

export const validationPassword = (value) => {
  if (!value) return validationRequired(value);
  const lowerCaseLetters = /[a-z]/g;
  const upperCaseLetters = /[A-Z]/g;
  const numbers = /[0-9]/g;
  if (!value.match(lowerCaseLetters)
    || !value.match(upperCaseLetters)
    || !value.match(numbers)
    || value.length < 8
  ) {
    return 'Sua senha deve ter pelo menos 1 letra minúscula, 1 letra maiúscula, 1 número e no minimo 8 caracteres.';
  }
  return true;
};

export const validationEmail = (value) => {
  if (!value) return validationRequired(value);
  const sapareteAtSign = value.split('@');
  const isValid =
    sapareteAtSign.length === 2
      ? Boolean(
        sapareteAtSign[0] &&
        sapareteAtSign[1].indexOf('.') >= 1 &&
        sapareteAtSign[1].length > sapareteAtSign[1].indexOf('.') + 1,
      )
      : false;
  return isValid || 'Insira um email válido.';
};

export const validationNumber = (value) => Number(value) || 'Utilize somente números.';

export const validationLatAndLng = (value) => value !== '0' || 'Insira um valor válido.';

export const validationPhone = (value) => value.length >= 9 || 'Digite o DDD + número.';

export const validationNotRepeat = (value, array) =>
  Boolean(!array.find((element) => element.name === value)) ||
  'Não é possível utilizar nome repetido no cadastro.';

export const returnUnitsShortFilter = (unit) => {
  const data = {
    LITRO: 'L',
    METRO: 'm',
    KILO: 'kg',
    UNIT: 'un',
  };
  return !unit || data[unit];
};

export const returnUnitsLongFilter = (unit) => {
  const data = {
    LITRO: 'Litro',
    KILO: 'Quilograma',
    METRO: 'Metro',
    UNIT: 'Unidade',
  };
  return unit ? data[unit] : 'Indisponível';
};

export const typesFilter = (type) => {
  const data = {
    ENTRADA: 'Entrada',
    SAIDA: 'Saída',
    CONTAGEM: 'Contagem total',
    CONTAGEM_PARCIAL: 'Contagem parcial',
    TRANSFERENCIA: 'Transferência',
    APLICACAO: 'Aplicação',
  };
  return type ? data[type] : 'Indisponível';
};

export const isScreenSize = (localStorage, inputSizes) => {
  const sizes = !Array.isArray(inputSizes) ? [inputSizes] : inputSizes;
  return Boolean(sizes.find((size) => size === localStorage.screenSize));
};

export const rewriteUrl = (router, route, page) => {
  router.replace(route.params.wantedRoute || { name: page });
};

export const isStatusVisible = (selectedStatus, isElementDisabled) => (
  selectedStatus === 'ALL' ||
  (selectedStatus === 'ACTIVE' && !isElementDisabled) ||
  (selectedStatus === 'INACTIVE' && isElementDisabled)
);

export function isHaveThisString(searchString, biggerString) {
  return normalizeString(biggerString).indexOf(normalizeString(searchString)) >= 0;
}

export const orderByAscList = (users) => users.sort((a, b) => {
  const nameA = a.roles[0] === 'CLIENT' ? a.client.name : a.employee.name;
  const nameB = b.roles[0] === 'CLIENT' ? b.client.name : b.employee.name;
  if (nameA > nameB) {
    return 1;
  }
  if (nameA < nameB) {
    return -1;
  }
  return 0;
});
