<template>
  <v-card>
    <v-toolbar v-if="isToolbarVisible" color="#0d1824" dark flat>
      <v-toolbar-title>Cadastro de Usuário</v-toolbar-title>
    </v-toolbar>
    <v-layout mx-2 v-if="!isDataLoading">
      <v-flex xs12>
        <v-switch
          style="float: right"
          v-model="formData.isEnabled"
          :label="formData.isEnabled ? 'Usuário ativo' : 'Usuário inativo'"
        />
        <v-btn
          v-if="idUserEdit"
          class="ma-3"
          style="float: right"
          :loading="isLoadingResetPassword"
          color="secundary"
          @click="resetPassword()"
          >Recuperar senha</v-btn
        >
      </v-flex>
    </v-layout>
    <v-container grid-list-xl>
      <v-form ref="form" v-if="!isDataLoading">
        <v-layout>
          <v-icon>person</v-icon>
          <h3 class="ml-2">Dados Pessoais</h3>
        </v-layout>
        <v-layout wrap>
          <v-flex md6 sm12>
            <v-text-field
              id="nameUser"
              placeholder="Nome"
              autocomplete="off"
              v-model="formData.firstName"
              :rules="[validationRequired]"
            />
          </v-flex>
          <v-flex md6 sm12>
            <v-text-field
              id="lastName"
              placeholder="Sobrenome"
              v-model="formData.lastName"
              :rules="[validationRequired]"
            />
          </v-flex>
          <v-flex md6 sm12>
            <v-text-field
              id="emailUser"
              prepend-icon="mail_outline"
              placeholder="E-mail"
              type="email"
              v-model="formData.email"
              :rules="[validationEmail]"
            />
          </v-flex>
          <v-flex v-if="!idUserEdit || isAdmin" md6 sm12>
            <v-text-field
              autocomplete="new-password"
              :append-icon="showPassword ? 'visibility_off' : 'visibility'"
              @click:append="showPassword = !showPassword"
              prepend-icon="lock_outline"
              id="passwordField"
              placeholder="Senha"
              v-model="formData.password"
              :type="showPassword ? 'password' : 'text'"
              :rules="[(value) => (() => validationPassword(value))]"
            />
          </v-flex>
          <v-flex v-if="!isLimited" md6 sm12>
            <RolesSelect
              v-model="formData.selectedRole"
              :rules="[validationRequired]"
              @input="
                (value) => {
                  if (value === 'FRANCHISEE' || currentUser.selectedRole === 'FRANCHISEE') {
                    formData.placesRemoved = formData.places.map((place) => ({ id: place.id }));
                    formData.places = [];
                  }
                }
              "
            />
          </v-flex>
          <v-flex md6 sm12>
            <v-text-field
              id="cpfCnpj"
              placeholder="CPF/CNPJ"
              minlength="11"
              maxlength="14"
              type="text"
              mask="###.###.###-##"
              v-model="formData.cpfCnpj"
            />
          </v-flex>
        </v-layout>
        <v-layout align-center>
          <v-icon>location_on</v-icon>
          <h3 class="ml-2">Locais</h3>
        </v-layout>
        <v-layout wrap>
          <v-flex v-if="formData.selectedRole !== 'FRANCHISEE' && !isLimited" md6 sm12>
            <FranchiseeSelect
              :disabled="!isAdmin && !isCoordinador"
              @input="formData.places = []"
              v-model="formData.selectedFranchiseeId"
            />
          </v-flex>
          <v-flex v-if="formData.selectedRole !== 'FRANCHISEE'" md6 sm12>
            <PlaceSelect
              :franchisee="formData.selectedFranchiseeId"
              v-model="formData.selectedPlace"
              id="selectPlaces"
            />
          </v-flex>
          <v-flex v-if="formData.selectedRole !== 'FRANCHISEE'" md6 sm12>
            <v-btn
              style="width: 100%; margin: 0px"
              :disabled="!formData.selectedPlace"
              @click="addLocal()"
              color="secundary"
            >
              <v-icon>add</v-icon> Adicionar Local
            </v-btn>
          </v-flex>
          <v-flex md6 sm12>
            <v-btn
              style="width: 100%; margin: 0px"
              @click="showLocalCreation = true"
              color="secundary"
            >
              <v-icon>add</v-icon> Novo Local
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout
          align-center
          wrap
          v-for="(place, index) in formData.places"
          :key="'place' + index"
        >
          <v-flex md6 sm10>
            <h3 class="d-inline">{{ place.localName }}</h3>
          </v-flex>
          <v-flex md6 sm2>
            <v-btn icon @click="removeLocal(index)">
              <v-icon>close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <br />
        <v-layout align-center>
          <v-icon>phone</v-icon>
          <v-flex xs12>
            <h3 class="d-inline ml-2">Contatos</h3>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex md6 sm12>
            <v-text-field
              id="contactName"
              label="Nome do contato"
              type="text"
              v-model="formData.newPhone.contactName"
            />
          </v-flex>
          <v-flex md1 sm2>
            <v-text-field
              id="dddPhone"
              label="DDD"
              minlength="2"
              maxlength="2"
              type="text"
              mask="(##)"
              v-model="formData.newPhone.ddd"
            />
          </v-flex>
          <v-flex md5 sm10>
            <v-text-field
              id="numberPhone"
              label="Número"
              type="text"
              mask="#####-####"
              v-model="formData.newPhone.telephoneNumber"
            />
          </v-flex>
          <v-flex md12 sm12>
            <v-btn
              :disabled="
                !formData.newPhone.contactName ||
                  !formData.newPhone.ddd ||
                  formData.newPhone.telephoneNumber.length < 9
              "
              id="addPhone"
              @click="addPhone()"
              color="secundary"
              ><v-icon>add</v-icon> Adiconar Número
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout
          align-center
          wrap
          v-for="(phone, index) in formData.telephones"
          :key="'phone' + index"
        >
          <v-flex md5 sm11>
            <h3 class="d-inline">{{ phone.contactName }}</h3></v-flex
          >
          <v-flex md3 sm9>
            <h3 class="d-inline">({{ phone.ddd }}) {{ phone.telephoneNumber }}</h3>
          </v-flex>
          <v-flex md2 sm2>
            <v-btn icon @click="removePhone(index)">
              <v-icon>close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-flex v-if="isAdmin || isCoordinador" ma-2 xs12>
          <v-layout>
            <v-switch
              v-model="formData.hasInstantMessageEnabled"
              label="Receber mensagens por Whatsapp"
          /></v-layout>
        </v-flex>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" flat @click="closeModal()" v-if="isCloseButtonVisible"
            >Fechar</v-btn
          >
          <v-btn
            v-if="(isCoordinador || isAdmin) && idUserEdit"
            outline
            color="primary"
            @click="openChat"
            ><v-icon left>support</v-icon>Suporte</v-btn
          >
          <v-btn
            id="saveUser"
            :loading="isSubmitLoading"
            color="primary"
            @click="submit()"
            :disabled="!isEnabledSubmit"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-form>
      <LoadingCircle v-if="isDataLoading" class="mt-1" />
    </v-container>
    <CardModal v-model="showLocalCreation" width="45%">
      <v-card>
        <v-toolbar color="#0d1824" dark flat>
          <v-toolbar-title>Cadastro de Local</v-toolbar-title>
          <div class="flex-grow-1"></div>
        </v-toolbar>
        <PlaceTab
          @showModal="showLocalCreation = $event"
          @variablesLocalCreation="variablesLocalCreation = $event"
          :inUserCreation="true"
        />
      </v-card>
    </CardModal>
  </v-card>
</template>

<script>
import FranchiseeSelect from '@/components/selects/FranchiseeSelect.vue';
import PlaceSelect from '@/components/selects/PlaceSelect.vue';
import RolesSelect from '@/components/selects/RolesSelect.vue';
import CardModal from '@/components/modal/CardModal.vue';
import PlaceTab from '@/components/places/tabs/PlaceTab.vue';

import { createUser, updateUser, getUserById, resetPassword } from '@/services/UserService';
import {
  validationRequired,
  validationPassword,
  validationEmail,
  alertSnack,
  isRole,
} from '@/services/UtilsService';
import LoadingCircle from '@/components/animations/LoadingCircle.vue';

export default {
  data: () => ({
    isToolbarVisible: true,
    isCloseButtonVisible: true,
    isLoadingResetPassword: false,
    isSubmitLoading: false,
    isDataLoading: false,
    variablesLocalCreation: '',
    validationRequired,
    validationPassword,
    validationEmail,
    isAdmin: isRole('ADMIN'),
    isCoordinador: isRole('COORDINADOR'),
    isLimited: isRole('LIMITED'),
    showPassword: true,
    showLocalCreation: '',
    formData: {
      localDefault: null,
      newPlaces: [],
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      selectedRole: isRole('LIMITED') ? 'CLIENT' : '',
      cpfCnpj: '',
      hasInstantMessageEnabled: true,
      selectedFranchiseeId: localStorage.franchiseeId,
      selectedPlace: '',
      places: [],
      placesRemoved: [],
      newPhone: { contactName: '', ddd: '', telephoneNumber: '' },
      telephones: [],
      telephonesRemoved: [],
      isEnabled: true,
    },
    currentUser: {
      clientId: '',
      employeeId: '',
      selectedRole: '',
    },
  }),
  computed: {
    isEnabledSubmit() {
      return Boolean(
        this.formData.firstName &&
          this.formData.lastName &&
          validationEmail(this.formData.email) === true &&
          (this.idUserEdit || validationPassword(this.formData.password) === true) &&
          this.formData.selectedRole &&
          this.formData.places.length,
      );
    },
  },
  methods: {
    resetPassword() {
      this.isLoadingResetPassword = true;
      resetPassword(this.$apollo, this.formData.email).then(() => {
        alertSnack(
          `Foi enviado um e-mail para ${this.formData.email} com instruções para redefinição de senha.`,
        );
        this.isLoadingResetPassword = false;
      });
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.formData = this.$options.data().formData;
      this.currentUser = {};
    },
    closeModal() {
      this.$emit('showModal', false);
      this.resetForm();
    },
    submit() {
      this.isSubmitLoading = true;
      const variables = this.normalizeData();
      const functionQuery = variables.idUser ? updateUser : createUser;
      functionQuery(this.$apollo, variables)
        .then(() => {
          alertSnack('saveDefault');
          if (this.showCloseButton !== false) {
            this.closeModal();
          }
        })
        .catch(() => {
          alertSnack('errorDefault');
        })
        .finally(() => {
          this.isSubmitLoading = false;
        });
    },
    normalizeData() {
      return {
        ...(this.idUserEdit ? { idUser: this.idUserEdit } : null),
        data: {
          email: this.formData.email,
          ...(this.formData.password ? { password: this.formData.password } : null),
          cpfCnpj: this.formData.cpfCnpj,
          roles: { set: this.formData.selectedRole },
          places: this.normalizePlaces(),
          ...(this.formData.localDefault ? { localDefault: this.formData.localDefault } : {}),
          hasInstantMessageEnabled: this.formData.hasInstantMessageEnabled,
          telephones: this.normalizePhone(),
          ...(this.formData.selectedRole !== 'CLIENT'
            ? { employee: this.normalizePersonName() }
            : null),
          ...(this.formData.selectedRole === 'CLIENT'
            ? { client: this.normalizePersonName() }
            : null),
          ...(this.formData.selectedRole === 'FRANCHISEE'
            ? { franchisee: { connect: { email: this.formData.email } } }
            : { franchisee: { connect: { id: this.formData.selectedFranchiseeId } } }),
          isDisabled: !this.formData.isEnabled,
        },
      };
    },
    normalizePlaces() {
      if (!this.formData.places[0].id) {
        this.formData.newPlaces[0].userLocalDefault = { connect: { email: this.formData.email } };
      } else {
        this.formData.localDefault = { connect: { id: this.formData.places[0].id } };
      }
      this.formData.newPlaces.forEach((place, index) => {
        this.formData.newPlaces[index].franchisee =
          this.formData.selectedRole === 'FRANCHISEE'
            ? { connect: { email: this.formData.email } }
            : { connect: { id: this.formData.selectedFranchiseeId } };
      });
      const create = this.formData.newPlaces;
      const connect = this.formData.places
        .filter((place) => place.id)
        .map((local) => ({ id: local.id }));
      const disconnect = this.formData.placesRemoved;

      return {
        ...(create.length ? { create } : null),
        ...(connect.length ? { connect } : null),
        ...(disconnect.length ? { disconnect } : null),
      };
    },
    normalizePhone() {
      const create = this.formData.telephones.filter((phone) => !phone.id);
      const connect = this.formData.telephones
        .filter((phone) => phone.id)
        .map((phone) => ({ id: phone.id }));
      const disconnect = this.formData.telephonesRemoved;
      return {
        ...(create.length ? { create } : null),
        ...(connect.length ? { connect } : null),
        ...(disconnect.length ? { disconnect } : null),
      };
    },
    normalizePersonName() {
      const name = { name: `${this.formData.firstName.trim()} ${this.formData.lastName.trim()}` };
      return (this.formData.selectedRole === 'CLIENT' && this.currentUser.clientId) ||
        (this.formData.selectedRole !== 'CLIENT' && this.currentUser.employeeId)
        ? { update: name }
        : { create: name };
    },
    removePhone(index) {
      const idPhoneRemoved = this.formData.telephones[index].id;
      if (idPhoneRemoved) this.formData.telephonesRemoved.push({ id: idPhoneRemoved });
      return this.formData.telephones.splice(index, 1);
    },
    removeLocal(index) {
      const idPlaceRemoved = this.formData.places[index].id;
      if (idPlaceRemoved) this.formData.placesRemoved.push({ id: idPlaceRemoved });
      return this.formData.places.splice(index, 1);
    },
    addPhone() {
      if (this.formData.newPhone) {
        this.formData.telephones.push(this.formData.newPhone);
        this.formData.newPhone = this.$options.data().formData.newPhone;
      }
    },
    addLocal() {
      const isAlreadyInTheList = this.formData.places.find(
        (place) => place.id === this.formData.selectedPlace.id,
      );
      if (isAlreadyInTheList) alertSnack('Este local já foi selecionado anteriormente.', true);
      else this.formData.places.push(this.formData.selectedPlace);
      this.formData.selectedPlace = this.$options.data().formData.selectedRole;
    },
    async loadUser() {
      if (!this.idUserEdit) return;
      this.isDataLoading = true;
      const resp = (await getUserById(this.$apollo, { id: this.idUserEdit })).user;
      this.isDataLoading = false;

      const allNames = (resp.roles[0] === 'CLIENT' ? resp.client.name : resp.employee.name)
        .trim()
        .split(' ');
      const firstName = allNames[0] || '';
      allNames.shift();
      const lastName = allNames.length > 0 ? allNames.join(' ') : '';

      const originalFormData = this.$options.data().formData;
      this.currentUser = {
        clientId: resp.client ? resp.client.id : null,
        employeeId: resp.employee ? resp.employee.id : null,
        selectedRole: resp.roles[0],
      };

      this.formData = {
        newPlaces: [],
        firstName,
        lastName,
        email: resp.email,
        password: originalFormData.password,
        cpfCnpj: resp.cpfCnpj,
        selectedRole: resp.roles[0],
        hasInstantMessageEnabled: resp.hasInstantMessageEnabled,
        selectedFranchiseeId: resp.franchisee.id,
        selectedPlace: originalFormData.selectedPlace,
        places: resp.places,
        placesRemoved: originalFormData.placesRemoved,
        newPhone: originalFormData.newPhone,
        telephones: resp.telephones,
        telephonesRemoved: originalFormData.telephonesRemoved,
        isEnabled: !resp.isDisabled,
      };
    },
    openChat() {
      window.open(`${window.location.origin}/dashboard/chat?originId=${this.idUserEdit}`, '_blank');
    },
    addIdToURL() {
      if (this.idUserEdit) {
        window.history.replaceState(null, null, `?id=${this.idUserEdit}`);
      }
    },
  },
  created() {
    if (this.showToolbar === false) {
      this.isToolbarVisible = false;
    }
    if (this.showCloseButton === false) {
      this.isCloseButtonVisible = false;
    }
    if (this.idUserEdit) {
      this.loadUser();
    }
  },
  watch: {
    idUserEdit(value) {
      if (!value) {
        this.resetForm();
      } else {
        this.loadUser();
        this.addIdToURL();
      }
    },
    variablesLocalCreation(value) {
      this.formData.newPlaces.push(value);
      this.formData.places.push({ localName: value.localName });
    },
  },
  components: { FranchiseeSelect, PlaceSelect, RolesSelect, CardModal, PlaceTab, LoadingCircle },
  props: ['idUserEdit', 'showToolbar', 'showCloseButton'],
};
</script>
