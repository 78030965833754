var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "containerPlace" } },
    [
      _c(
        "FilterCard",
        {
          attrs: { resetButton: true, searchButton: true },
          on: {
            clickOnReset: function($event) {
              return _vm.resetFilter()
            },
            clickOnSearch: function($event) {
              return _vm.submitFilter()
            }
          }
        },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm12: "", md6: "" } },
            [
              _c("v-text-field", {
                staticClass: "mx-2",
                attrs: {
                  id: "formFilter.searchString",
                  label: "Pesquise pelo nome / estado"
                },
                model: {
                  value: _vm.formFilter.searchString,
                  callback: function($$v) {
                    _vm.$set(_vm.formFilter, "searchString", $$v)
                  },
                  expression: "formFilter.searchString"
                }
              })
            ],
            1
          ),
          _vm.isAdmin || _vm.isCoordinador
            ? _c(
                "v-flex",
                { attrs: { "px-2": "", xs12: "", sm12: "", md4: "" } },
                [
                  _c("FranchiseeSelect", {
                    model: {
                      value: _vm.formFilter.franchiseeId,
                      callback: function($$v) {
                        _vm.$set(_vm.formFilter, "franchiseeId", $$v)
                      },
                      expression: "formFilter.franchiseeId"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            {
              attrs: { id: "searchSelectStatus", xs12: "", sm12: "", md2: "" }
            },
            [
              _c("StatusSelect", {
                staticClass: "mx-2",
                model: {
                  value: _vm.formFilter.status,
                  callback: function($$v) {
                    _vm.$set(_vm.formFilter, "status", $$v)
                  },
                  expression: "formFilter.status"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.listPlaces.length
        ? _c("CardList", {
            attrs: {
              sortBy: "undefined",
              id: "dataPlaces",
              contentList: _vm.formatedPlacesList
            },
            on: { click: element => _vm.openModal(element.id) }
          })
        : _vm._e(),
      _vm.isLoading ? _c("LoadingCircle", { staticClass: "mt-3" }) : _vm._e(),
      _c(
        "CardModal",
        {
          attrs: { removeURLParams: true, width: "75%" },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("LocalCreation", {
            attrs: { idPlaceEdit: _vm.idPlaceEdit },
            on: {
              showModal: function($event) {
                _vm.showModal = $event
              }
            }
          })
        ],
        1
      ),
      !_vm.isClient && !_vm.isLimited
        ? _c(
            "v-btn",
            {
              attrs: {
                fab: "",
                bottom: "",
                right: "",
                color: "#A6CE39",
                dark: "",
                fixed: "",
                title: "Novo",
                id: "create"
              },
              on: {
                click: function($event) {
                  return _vm.openModal()
                }
              }
            },
            [_c("v-icon", [_vm._v("add")])],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }