<template>
  <v-app id="inspire">
    <v-navigation-drawer
      :clipped="$vuetify.breakpoint.lgAndUp"
      v-model="drawer"
      fixed
      app
      style="background-color: #484857"
      class="pt-5"
    >
      <v-list style="background-color: #484857" dense>
        <div style="margin-top:20px"></div>
        <template>
          <v-list-tile
            v-for="item in userMenuItens"
            :key="item.text"
            @click="redirect(item.name)"
            style="height:40px; margin-top: 15px"
            :class="styleForMenu(item.name)"
          >
            <v-list-tile-action>
              <v-icon size="26px" color="#A6CE39">
                {{ item.icon }}
              </v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title class="titleMenu" :id="item.text">
                {{ item.text }}
              </v-list-tile-title>
            </v-list-tile-content>
            <div class="alert-count-circle-wrapper" v-if="item.name === 'chat'">
              <div
                class="alert-count-circle"
                v-if="unreadChatsCount && (isAdmin() || isCoordinador())"
              >
                {{ unreadChatsCount }}
              </div>
            </div>
          </v-list-tile>
        </template>
      </v-list>
    </v-navigation-drawer>
    <div class="app-toolbar">
      <v-icon class="mx-2" color="#ffffff" @click.stop="drawer = !drawer">menu</v-icon>
      <a href="/">
        <img src="/assets/img/logo-piscinapp-35px.png" class="logo" title="PiscinApp" />
      </a>
      <v-spacer />
      <span id="userEmail" v-if="screenSize != 'xs' && screenSize != 'sm'">{{ userEmail }}</span>
      <v-btn icon @click="logout">
        <v-icon title="Sair" color="#ffffff" id="buttonClose">logout</v-icon>
      </v-btn>
    </div>
    <v-content class="px-1 pt-5">
      <component v-if="pageComponent" :is="pageComponent" />
    </v-content>
    <snackAlert v-model="snackBar"></snackAlert>
  </v-app>
</template>

<script>
import UsersList from '@/components/user/UsersList.vue';
import PlacesList from '@/components/places/PlacesList.vue';
// import MachineLearning from '@/components/machine-learning/MachineLearning.vue';
import Configs from '@/components/configs/Configs.vue';
// import Whatsapp from '@/components/whatsapp/Whatsapp.vue';
import SupportChat from '@/components/chat/SupportChat.vue';
import ProductList from '@/components/products/ProductList.vue';
import TransferencesList from '@/components/transferences/TransferencesList.vue';
import AnalyzeList from '@/components/analyzes/AnalyzeList.vue';
import NotificationsList from '@/components/notifications/NotificationsList.vue';
import EquipmentsList from '@/components/equipments/EquipmentsList.vue';
import Reports from '@/components/reports/Reports.vue';
import MonitoringDashboard from '@/components/monitoring/MonitoringDashboard.vue';
import { getSupportChatsCount } from '@/services/ChatService';

import snackAlert from '@/components/snackAlert/snackAlert.vue';

import countOpenAnalyzeAndNotification from '@/graphql/menu/countOpenAnalyzeAndNotification.gql';

import { onLogout } from '@/vue-apollo';
import eventBus from '@/eventBus';

export default {
  // eslint-disable-next-line max-lines-per-function
  data: () => ({
    screenSize: '',
    unreadChatsCount: undefined,
    idLastNotification: '',
    userEmail: localStorage.userEmail,
    pageComponent: '',
    drawer: null,
    snackBar: '',
    timeout: 6000,
    menuClient: 'locais, analises, monitoramento, relatorios',
    items: [
      {
        name: 'usuarios',
        icon: 'account_circle',
        text: 'Usuários',
        pageComponent: 'UsersList',
        hideFromRoles: ['CLIENT', 'LIMITED'],
      },
      {
        name: 'locais',
        icon: 'location_on',
        text: 'Locais',
        pageComponent: 'PlacesList',
        hideFromRoles: ['LIMITED'],
      },
      {
        name: 'produtos',
        icon: 'layers',
        text: 'Produtos',
        pageComponent: 'ProductList',
        hideFromRoles: ['FRANCHISEE', 'CLIENT', 'LIMITED'],
      },
      {
        name: 'equipamentos',
        icon: 'build',
        text: 'Equipamentos',
        pageComponent: 'EquipmentsList',
        hideFromRoles: ['FRANCHISEE', 'CLIENT', 'LIMITED'],
      },
      {
        name: 'movimentacoes',
        icon: 'swap_horiz',
        text: 'Movimentações',
        pageComponent: 'TransferencesList',
        hideFromRoles: ['CLIENT', 'LIMITED'],
      },
      { name: 'analises', icon: 'assessment', text: 'Análises', pageComponent: 'AnalyzeList' },
      {
        name: 'relatorios',
        icon: 'description',
        text: 'Relatórios',
        pageComponent: 'Reports',
        hideFromRoles: ['LIMITED'],
      },
      {
        name: 'notificacoes',
        icon: 'notifications',
        text: 'Notificações',
        pageComponent: 'NotificationsList',
        hideFromRoles: ['CLIENT', 'LIMITED'],
      },
      {
        name: 'monitoramento',
        icon: 'monitor',
        text: 'Monitoramento',
        pageComponent: 'MonitoringDashboard',
        hideFromRoles: ['LIMITED'],
      },
      /*       {
        name: 'machineLearning',
        icon: 'settings',
        text: 'Machine Learning',
        pageComponent: 'MachineLearning',
        hideFromRoles: ['CLIENT', 'LIMITED', 'FRANCHISEE'],
      }, */
      {
        name: 'configuracoes',
        icon: 'settings',
        text: 'Configurações',
        pageComponent: 'Configs',
        hideFromRoles: ['CLIENT', 'LIMITED', 'FRANCHISEE'],
      },
      /*       {
        name: 'whatsapp',
        icon: 'message',
        text: 'Whatsapp',
        pageComponent: 'Whatsapp',
        hideFromRoles: ['CLIENT', 'LIMITED', 'FRANCHISEE'],
      }, */
      {
        name: 'chat',
        icon: 'chat',
        text: 'Chat',
        pageComponent: 'SupportChat',
        hideFromRoles: ['CLIENT', 'LIMITED', 'FRANCHISEE'],
      },
    ],
  }),
  components: {
    UsersList,
    PlacesList,
    ProductList,
    EquipmentsList,
    TransferencesList,
    AnalyzeList,
    NotificationsList,
    Reports,
    MonitoringDashboard,
    snackAlert,
    // MachineLearning,
    // Whatsapp,
    Configs,
    SupportChat,
  },
  methods: {
    queryUnreadChatsCount() {
      getSupportChatsCount(this.$root.$apolloProvider.defaultClient).then((resp) => {
        this.unreadChatsCount = resp.count;
      });
    },
    styleForMenu(itemName) {
      if (itemName === localStorage.currentPage) {
        return 'selectedMenu';
      }
      return '';
    },
    isClient() {
      return localStorage.userRole === 'CLIENT';
    },
    isAdmin() {
      return localStorage.userRole === 'ADMIN';
    },
    isCoordinador() {
      return localStorage.userRole === 'COORDINADOR';
    },
    isLimited() {
      return localStorage.userRole === 'LIMITED';
    },
    redirect(nameRouter) {
      window.history.pushState({}, nameRouter, window.location.href);
      this.$router.replace(this.$route.params.wantedRoute || { name: nameRouter });
      this.change(nameRouter);
    },
    change(itemName) {
      if (
        ['userRole', 'userEmail', 'apollo-token', 'userId'].some(
          (storageKey) => !localStorage[storageKey] || localStorage[storageKey] === 'null',
        )
      ) {
        this.logout();
      } else {
        localStorage.currentPage = itemName || 'analises';
        const selectedMenuItem = this.items.find((element) => itemName === element.name);
        this.pageComponent = selectedMenuItem.pageComponent;
      }
    },
    logout() {
      onLogout(this.$root.$apolloProvider.defaultClient);
      localStorage.clear();
      this.$router.replace({ name: 'login' });
    },
    monitorUnreadChats() {
      if (this.isAdmin() || this.isCoordinador()) {
        this.queryUnreadChatsCount();
        setInterval(() => {
          this.queryUnreadChatsCount();
        }, 5000);
      }
    },
    countOpenAnalyzeAndNotification() {
      this.$root.$apolloProvider.defaultClient
        .query({
          fetchPolicy: 'no-cache',
          query: countOpenAnalyzeAndNotification,
          variables: { franchiseeId: localStorage.franchiseeId },
        })
        .then((resp) => {
          this.countAnalyze = resp.data.analyzes.length <= 99 ? resp.data.analyzes.length : '9+';
          this.countNotification =
            resp.data.notifications.length <= 99 ? resp.data.notifications.length : '9+';
        });
    },
    hasPageAccess(pageName) {
      const page = this.items.find((item) => item.name === pageName);
      return (
        page &&
        (!page.hideFromRoles || !page.hideFromRoles.some((role) => role === localStorage.userRole))
      );
    },
    /*     updateListNotification() {
      setInterval(() => {
        getLastlastNotification(this.$apollo).then((resp) => {
          if (resp.notifications.length && this.idLastNotification !== resp.notifications[0].id) {
            this.idLastNotification = resp.notifications[0].id;
          } else if (!this.idLastNotification) {
            this.idLastNotification = resp.notifications[0].id;
          }
        });
      }, 30000);
    }, */
  },
  computed: {
    userMenuItens() {
      return this.items.filter(
        (item) =>
          !item.hideFromRoles || !item.hideFromRoles.some((role) => role === localStorage.userRole),
      );
    },
  },
  props: {
    source: String,
  },
  created() {
    window.onpopstate = (event) => {
      if (event && event.state) {
        window.location.reload();
      }
    };
    this.screenSize = ['xs', 'sm', 'md', 'lg', 'xl'].find((size) => this.$vuetify.breakpoint[size]);
    localStorage.screenSize = this.screenSize;

    const urlPart = this.$router.app.$route.path.split('/');
    localStorage.currentPage = urlPart[2];

    if (urlPart[1] === 'dashboard' && this.hasPageAccess(urlPart[2])) {
      localStorage.currentPage = urlPart[2];
      this.change(localStorage.currentPage);
    } else {
      this.redirect('analises');
    }

    // this.countOpenAnalyzeAndNotification();

    eventBus.onAlertSnack((text, isError, timeout) => {
      this.snackBar = { text, isError, timeout: timeout || 2000 };
    });
    this.monitorUnreadChats();
  },
};
</script>

<style>
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-outer-spin-button,
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.menu-items {
  background-color: #fff;
}
.alert-count-circle {
  background-color: #f55;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  margin-right: -20px;
  text-align: center;
  z-index: 99;
  font-size: 14px;
  position: relative;
  top: -10px;
  left: -26px;
  color: antiquewhite;
}
.logo {
  margin: 5px 0px 0px 0px;
}
.selectedMenu {
  background: #0d1824;
  color: #fff;
}
.titleMenu {
  font-size: 15px;
  color: antiquewhite;
  margin-top: 10px;
  height: 55px;
}
.text-center {
  text-align: center;
}
@media screen and (max-width: 960px) {
  #inspire #userEmail {
    display: none;
  }
}
@media screen and (min-width: 960px) {
  ::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  ::-webkit-scrollbar-thumb {
    background: #48485785;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #484857;
  }
}

.app-toolbar {
  background-color: #0d1824;
  color: #fff;
  height: 50px;
  padding: 0px 10px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 0 0 rgb(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 7;
  user-select: none;
}

#userEmail {
  user-select: text;
}

.alert-count-circle-wrapper {
  padding-top: 20px;
}
</style>
