<template>
  <v-autocomplete
    autocomplete="off"
    menu-props="auto"
    :disabled="selectList.length === 0"
    :items="selectList"
    @change="$emit('input', $event)"
    :value="value"
    :rules="rules"
    :label="`${label || 'Selecione um local'}${rules ? ' *' : ''}`"
    item-text="optionText"
    item-value="value"
  />
</template>
<script>
import { getPlacesToSelectByFranchisee, getPlacesToSelectByUser } from '@/services/PlacesService';

export default {
  data: () => ({
    placesList: [],
  }),
  computed: {
    selectList() {
      return this.placesList.map((local) => ({
        optionText: local.localName,
        value: { id: local.id, localName: local.localName },
      }));
    },
  },
  methods: {
    async getFranchisePlaces(id) {
      const variables = id
        ? {
            where: {
              isDisabled: false,
              franchisee: { id },
            },
          }
        : {
            where: {
              isDisabled: false,
            },
          };
      const resp = await getPlacesToSelectByFranchisee(this.$apollo, variables);
      this.placesList = resp.locals;
    },
    async getClientPlaces(id) {
      const variables = {};
      variables.userId = id;
      const resp = await getPlacesToSelectByUser(this.$apollo, variables);
      this.placesList = resp.locals;
    },
  },
  watch: {
    removePlaceId() {
      this.placesList = this.placesList.filter((local) => local.id !== this.removePlaceId);
    },
    franchisee: async function getFranchisePlaces(id) {
      await this.getFranchisePlaces(id);
    },
    client: async function getClientPlaces(id) {
      await this.getFranchisePlaces(id);
    },
  },
  created() {
    if (this.client) {
      this.getClientPlaces(this.client);
    } else {
      this.getFranchisePlaces(this.franchisee);
    }
  },
  props: ['franchisee', 'client', 'value', 'rules', 'label', 'removePlaceId'],
};
</script>
