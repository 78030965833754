var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      attrs: { width: _vm.windowWidth < 960 ? `${_vm.windowWidth}px` : "100%" }
    },
    [
      _vm.isLoading
        ? _c(
            "v-card-text",
            { staticClass: "text-xs-center" },
            [
              _c("v-progress-circular", {
                staticClass: "loader",
                attrs: { indeterminate: "", color: "primary" }
              })
            ],
            1
          )
        : _vm.selectedEquipment
        ? _c(
            "div",
            [
              _c("v-card-title", [
                _c("h1", [
                  _vm._v(
                    "\n        " +
                      _vm._s(`${_vm.selectedEquipment.details.type || ""}
        ${_vm.selectedEquipment.details.brand || ""}
        ${_vm.selectedEquipment.details.model || ""}`) +
                      "\n        " +
                      _vm._s(
                        _vm.selectedEquipment.codeIdentifier
                          ? ` - ID nº: ${_vm.selectedEquipment.codeIdentifier}`
                          : ""
                      ) +
                      "\n      "
                  )
                ])
              ]),
              _c(
                "v-card-text",
                { staticClass: "mx-0 px-0" },
                [
                  _c(
                    "VTabs",
                    {
                      attrs: { buttons: _vm.tabButtons },
                      on: {
                        selected: function($event) {
                          _vm.selectedTab = $event
                        }
                      }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("p", { staticClass: "headline" }, [
                              _vm._v(_vm._s(_vm.getTitle))
                            ])
                          ]),
                          _vm.selectedTab === "registers"
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("DataTable", {
                                    attrs: { tableContent: _vm.tableEquipments }
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: { float: "right" },
                                      attrs: { flat: "", color: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.closeCardModal()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Fechar\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm.selectedTab === "details"
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("DataIterator", {
                                    attrs: { details: _vm.detailsContent }
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: { float: "right" },
                                      attrs: { flat: "", color: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.closeCardModal()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Fechar\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm.selectedTab === "createRegister"
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", id: "statusList" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          autocomplete: "off",
                                          "menu-props": "auto",
                                          items: _vm.statusList,
                                          "item-value": "value",
                                          "item-text": "name",
                                          label: "Status do equipamento *"
                                        },
                                        model: {
                                          value:
                                            _vm.newEquipmentRegister.status,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newEquipmentRegister,
                                              "status",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newEquipmentRegister.status"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Descrição",
                                          id: "registerDescription"
                                        },
                                        model: {
                                          value:
                                            _vm.newEquipmentRegister
                                              .description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newEquipmentRegister,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newEquipmentRegister.description"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("datePicker", {
                                        attrs: {
                                          label: "Última manutenção",
                                          id: "registerLastMaintenance"
                                        },
                                        model: {
                                          value:
                                            _vm.newEquipmentRegister
                                              .lastMaintenance,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newEquipmentRegister,
                                              "lastMaintenance",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newEquipmentRegister.lastMaintenance"
                                        }
                                      }),
                                      _c("datePicker", {
                                        attrs: {
                                          label: "Próxima manutenção",
                                          id: "registerNextMaintenance"
                                        },
                                        model: {
                                          value:
                                            _vm.newEquipmentRegister
                                              .nextMaintenance,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newEquipmentRegister,
                                              "nextMaintenance",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newEquipmentRegister.nextMaintenance"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          rules:
                                            _vm.newEquipmentRegister.status ===
                                            "DISABLED"
                                              ? [_vm.validationRequired]
                                              : [],
                                          label:
                                            _vm.newEquipmentRegister.status ===
                                            "DISABLED"
                                              ? "Escreva uma observação *"
                                              : "Escreva uma observação",
                                          error:
                                            _vm.newEquipmentRegister.status ===
                                              "DISABLED" &&
                                            !_vm.newEquipmentRegister
                                              .observation,
                                          id: "registerObservation"
                                        },
                                        model: {
                                          value:
                                            _vm.newEquipmentRegister
                                              .observation,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newEquipmentRegister,
                                              "observation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newEquipmentRegister.observation"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticStyle: { float: "right" },
                                          attrs: {
                                            color: "primary",
                                            disabled:
                                              _vm.isDisabledCreateRegister,
                                            id: "saveRegister"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.saveEquipmentRegister()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                Adicionar\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticStyle: { float: "right" },
                                          attrs: { flat: "", color: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.closeCardModal()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                Fechar\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm.selectedTab === "createTransference"
                            ? _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    id: "transferencePlaceSelect"
                                  }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("placeSelect", {
                                        attrs: {
                                          rules: [_vm.validationRequired]
                                        },
                                        model: {
                                          value: _vm.idPlaceEditToTransference,
                                          callback: function($$v) {
                                            _vm.idPlaceEditToTransference = $$v
                                          },
                                          expression:
                                            "idPlaceEditToTransference"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticStyle: { float: "right" },
                                          attrs: {
                                            color: "primary",
                                            disabled: !_vm.idPlaceEditToTransference,
                                            id: "saveTransference"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.saveTransference()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                Transferir\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticStyle: { float: "right" },
                                          attrs: { flat: "", color: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.closeCardModal()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                Fechar\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }