var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-tabs",
        { attrs: { id: "tabs" } },
        _vm._l(_vm.buttons, function(button, index) {
          return _c(
            "v-tab",
            {
              key: index,
              attrs: { id: "localTab" },
              on: {
                click: function($event) {
                  return _vm.$emit("selected", button.value)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(button.name) + "\n    ")]
          )
        }),
        1
      ),
      _c(
        "v-tabs-items",
        [
          _c(
            "v-tab-item",
            [
              _c(
                "v-card",
                { attrs: { flat: "", color: "basil" } },
                [_c("v-card-text", [_vm._t("default")], 2)],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }