import { query, mutation } from '@/services/GraphqlService';

import createOfferMutation from '@/graphql/configs/createOffer.gql';
import getOffersQuery from '@/graphql/configs/getOffers.gql';
import deleteOfferMutation from '@/graphql/configs/deleteOffer.gql';

export const createOffer = (apollo, variables) => mutation(apollo, createOfferMutation, variables);

export const getOffers = (apollo) => query(apollo, getOffersQuery);

export const deleteOffer = (apollo, variables) => mutation(apollo, deleteOfferMutation, variables);
