<template>
  <v-autocomplete
    autocomplete="off"
    menu-props="auto"
    :disabled="selectList.length === 0"
    :items="selectList"
    @change="$emit('input', $event)"
    :value="value"
    label="Selecione um equipamento"
    item-text="name"
    item-value="value"
  />
</template>
<script>
import { getAllEquipmentDetailsToSelect } from '@/services/EquipmentService';

export default {
  data: () => ({
    equipmentList: [],
  }),
  computed: {
    selectList() {
      return this.equipmentList
        .filter((equipment) => !equipment.isDisabled)
        .map((equipment) => ({
          name: `${equipment.type ? equipment.type : ''} ${
            equipment.brand ? equipment.brand : ''
          } ${equipment.model ? equipment.model : ''}`,
          value: equipment.id,
        }));
    },
  },
  methods: {
    async getEquipments() {
      this.equipmentList = await getAllEquipmentDetailsToSelect(this.$apollo);
    },
  },
  created() {
    this.getEquipments();
  },
  props: ['value'],
};
</script>
