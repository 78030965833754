var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "support-chat" },
      [
        _c(
          "v-toolbar",
          {
            staticClass: "toolbar",
            attrs: { color: "#0d1824", dark: "", flat: "" }
          },
          [
            _c("v-toolbar-title", { staticClass: "toolbar-title" }, [
              _vm._v("Suporte")
            ]),
            _c("v-spacer"),
            _vm.originId
              ? _c("ShowAnalyzesButton", { attrs: { originId: _vm.originId } })
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "chat-container" },
          [
            _c("ChatSideBar", { on: { "chat-click": _vm.onChatClick } }),
            _c("div", { staticClass: "chat-content" }, [
              _c(
                "div",
                { ref: "chatContainer", staticClass: "messages-container" },
                _vm._l(_vm.messages, function(message) {
                  return _c(
                    "div",
                    {
                      key: message.id || message.sentIn,
                      staticClass: "message"
                    },
                    [
                      _c("Balloon", {
                        class: !message.isFromUser ? "align-right" : "",
                        attrs: {
                          fromMe: !message.isFromUser,
                          message: message.body,
                          time: _vm._f("formatDate")(message.sentIn),
                          status: message.isRead ? 3 : 1
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              ),
              _vm.originId
                ? _c(
                    "div",
                    { staticClass: "message-input" },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.message,
                            expression: "message"
                          }
                        ],
                        attrs: {
                          placeholder: "Digite sua mensagem...",
                          rows: "4"
                        },
                        domProps: { value: _vm.message },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.message = $event.target.value
                          }
                        }
                      }),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "#0d1824", dark: "" },
                          on: { click: _vm.sendMessage }
                        },
                        [_vm._v("\n            Enviar\n          ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }