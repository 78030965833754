<template>
  <v-card>
    <v-card-title class="title">Insira nova piscina </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-layout wrap>
          <v-flex xs12>
            <v-text-field
              id="poolName"
              label="Nome da piscina *"
              v-model="formPool.poolName"
              :rules="[validationRequired]"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              id="poolVolume"
              label="Volume (m³) *"
              v-model="formPool.volume"
              :rules="[validationRequired]"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-flex v-if="isAdmin || isCoordinador" xs12>
            <div>
              <input id="checkbox" type="checkbox" v-model="formPool.hasTabletApplicationSupport" />
              <label for="checkbox"> Suporta a aplicação de pastilhas</label>
            </div>
            <div>
              <input
                id="automatic-suggestion-checkbox"
                type="checkbox"
                v-model="formPool.isEnabledAutomaticSuggestion"
              />
              <label for="automatic-suggestion-checkbox"> Habilitar sugestão automática</label>
            </div>
          </v-flex>
        </v-layout>
      </v-form>
      <v-card-actions v-if="!isClient">
        <v-spacer />
        <v-btn flat color="primary" @click="$emit('showNewPoolCard', false)"> Fechar </v-btn>
        <v-btn
          :disabled="isDisabledButtonSave()"
          color="primary"
          @click="submitPool()"
          id="savePool"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { createPool, updatePool } from '@/services/PlacesService';
import {
  isRole,
  validationRequired,
  validationNotRepeat,
  alertSnack,
} from '@/services/UtilsService';

export default {
  data: () => ({
    validationRequired,
    validationNotRepeat,
    isClient: isRole('CLIENT'),
    isCoordinador: isRole('COORDINADOR'),
    isAdmin: isRole('ADMIN'),
    isLoading: false,
    formPool: {
      isEnabledAutomaticSuggestion: true,
      hasTabletApplicationSupport: true,
      timeToAutomaticSuggestion: 2,
      poolName: null,
      volume: null,
      analyze: null,
    },
  }),
  methods: {
    isDisabledButtonSave() {
      return Boolean(this.isLoading || !this.formPool.poolName || !this.formPool.volume);
    },
    submitPool() {
      if (!this.id) {
        this.createPool();
      } else {
        this.updatePool();
      }
    },
    updatePool() {
      this.isLoading = true;
      const variables = {
        name: this.formPool.poolName,
        volume: this.formPool.volume,
        hasTabletApplicationSupport: this.formPool.hasTabletApplicationSupport,
        isEnabledAutomaticSuggestion: this.formPool.isEnabledAutomaticSuggestion,
        id: this.id,
      };
      updatePool(this.$apollo, variables)
        .then(() => this.finish())
        .catch(() => alertSnack('errorDefault'))
        .finally(() => {
          this.isLoading = false;
        });
    },
    createPool() {
      this.isLoading = true;
      const variables = {
        data: {
          name: this.formPool.poolName,
          volume: this.formPool.volume,
          hasTabletApplicationSupport: this.formPool.hasTabletApplicationSupport,
          isEnabledAutomaticSuggestion: this.formPool.isEnabledAutomaticSuggestion,
          timeToAutomaticSuggestion: this.formPool.timeToAutomaticSuggestion * 60,
          local: {
            connect: {
              id: this.idPlaceEdit,
            },
          },
        },
      };
      createPool(this.$apollo, variables)
        .then(() => this.finish())
        .catch(() => alertSnack('errorDefault'))
        .finally(() => {
          this.isLoading = false;
        });
    },
    finish() {
      this.$emit('showNewPoolCard', false);
      alertSnack('saveDefault');
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    setForm() {
      this.formPool.poolName = this.name;
      this.formPool.volume = this.volume;
      this.formPool.hasTabletApplicationSupport = this.hasTabletSupport;
      this.formPool.isEnabledAutomaticSuggestion = this.hasAutomaticSuggestion;
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.setForm();
      } else {
        this.formPool = this.$options.data().formPool;
        this.$refs.form.resetValidation();
      }
    },
  },
  created() {
    if (this.id) {
      this.setForm();
    }
  },
  props: [
    'contentList',
    'idPlaceEdit',
    'id',
    'name',
    'volume',
    'hasTabletSupport',
    'hasAutomaticSuggestion',
  ],
};
</script>
