import { query, mutation } from '@/services/GraphqlService';

import saveCheckListMutation from '@/graphql/checklist/saveCheckListMutation.gql';
import updateStatusCheckListByPlaceMutation from '@/graphql/checklist/updateStatusCheckListByPlaceMutation.gql';
import updateCheckListOptionMutation from '@/graphql/checklist/updateCheckListOptionMutation.gql';
import updateAllCheckListByPlaceMutation from '@/graphql/checklist/updateAllCheckListByPlaceMutation.gql';
import getStatusCheckListByPlaceQuery from '@/graphql/checklist/getStatusCheckListByPlaceQuery.gql';
import getCheckListOptionsQuery from '@/graphql/checklist/getCheckListOptionsQuery.gql';

export const saveCheckList = (apollo, variables) =>
  mutation(apollo, saveCheckListMutation, variables);

export const updateStatusCheckListByPlace = (apollo, variables) =>
  mutation(apollo, updateStatusCheckListByPlaceMutation, variables);

export const updateCheckListOption = (apollo, variables) =>
  mutation(apollo, updateCheckListOptionMutation, variables);

export const updateAllCheckListByPlace = (apollo, variables) =>
  mutation(apollo, updateAllCheckListByPlaceMutation, variables);

export const getStatusCheckListByPlace = (apollo, variables) =>
  query(apollo, getStatusCheckListByPlaceQuery, variables);

export const getCheckListOptions = (apollo, variables) =>
  query(apollo, getCheckListOptionsQuery, variables);
