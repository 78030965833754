var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-layout",
        { staticClass: "grey lighten-3", attrs: { "justify-center": "" } },
        [_c("v-card-title", [_c("h3", [_vm._v("Fazer sugestão")])])],
        1
      ),
      _vm.iaSuggestion.length > 0 && (_vm.isAdmin || _vm.isCoordinador)
        ? _c("div", [
            _c(
              "a",
              {
                staticClass: "ml-2",
                on: {
                  click: function($event) {
                    return _vm.addIaSuggestedProducts()
                  }
                }
              },
              [_vm._v("aceitar sugestão automática")]
            )
          ])
        : _vm._e(),
      _c(
        "v-layout",
        { attrs: { "pa-0": "", row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { md12: "", lg6: "" } },
            [
              _c("ProductDescriptionSelect", {
                staticClass: "suggestionProducts",
                attrs: {
                  availableProductsIdList:
                    !_vm.isShowAllProductsSelected && _vm.availableProducts,
                  id: "selectProduct"
                },
                on: {
                  selected: description => {
                    _vm.selectedProduct = description
                  }
                },
                model: {
                  value: _vm.selectedProductId,
                  callback: function($$v) {
                    _vm.selectedProductId = $$v
                  },
                  expression: "selectedProductId"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs7: "", lg3: "" } },
            [
              _c("v-text-field", {
                staticClass: "amount",
                attrs: {
                  label: "Quantidade",
                  title: "Informe a quantidade a ser aplicada.",
                  type: "number",
                  min: "0",
                  id: "amount"
                },
                model: {
                  value: _vm.analyzeProductAmount,
                  callback: function($$v) {
                    _vm.analyzeProductAmount = $$v
                  },
                  expression: "analyzeProductAmount"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "suggestion-box-ppm", attrs: { xs5: "", lg3: "" } },
            [
              _vm._v(
                "  " +
                  _vm._s(
                    _vm._f("ppm")(
                      _vm.selectedProduct && {
                        amount:
                          _vm.selectedProduct.name === "Pastilha tricloro"
                            ? _vm.analyzeProductAmount * 0.2
                            : _vm.analyzeProductAmount,
                        volume: _vm.poolVolume,
                        purity: _vm.selectedProduct.chemicalPurity
                      }
                    )
                  )
              )
            ]
          ),
          _c(
            "v-flex",
            { staticClass: "suggestionButton", attrs: { xs12: "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: !_vm.isEnabledButtonSuggestion(),
                    id: "addSuggestedProduct"
                  },
                  on: {
                    click: function($event) {
                      return _vm.addSuggestedProduct()
                    }
                  }
                },
                [_vm._v("Sugerir")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "ml-2" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.isShowAllProductsSelected,
              expression: "isShowAllProductsSelected"
            }
          ],
          attrs: { id: "checkbox", type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.isShowAllProductsSelected)
              ? _vm._i(_vm.isShowAllProductsSelected, null) > -1
              : _vm.isShowAllProductsSelected
          },
          on: {
            change: function($event) {
              var $$a = _vm.isShowAllProductsSelected,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.isShowAllProductsSelected = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.isShowAllProductsSelected = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.isShowAllProductsSelected = $$c
              }
            }
          }
        }),
        _c("label", { attrs: { for: "checkbox" } }, [
          _vm._v(" Mostrar todos os produtos")
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }