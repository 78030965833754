var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dataAnalyze
    ? _c(
        "v-card",
        {
          attrs: { tabindex: "0" },
          on: {
            keyup: [
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape"
                  ])
                )
                  return null
                return _vm.closeModal.apply(null, arguments)
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "left", 37, $event.key, [
                    "Left",
                    "ArrowLeft"
                  ])
                )
                  return null
                if ("button" in $event && $event.button !== 0) return null
                return _vm.navigateBetweenAnalyzes("previous")
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "right", 39, $event.key, [
                    "Right",
                    "ArrowRight"
                  ])
                )
                  return null
                if ("button" in $event && $event.button !== 2) return null
                return _vm.navigateBetweenAnalyzes("next")
              }
            ]
          }
        },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "#0d1824", dark: "", flat: "" } },
            [
              _c("v-spacer"),
              _vm.currentIndex > 0
                ? _c(
                    "v-btn",
                    {
                      attrs: { flat: "", outline: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.navigateBetweenAnalyzes("first")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("keyboard_double_arrow_left")])],
                    1
                  )
                : _vm._e(),
              _vm.currentIndex > 0
                ? _c(
                    "v-btn",
                    {
                      attrs: { flat: "", outline: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.navigateBetweenAnalyzes("previous")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { title: "Anterior" } }, [
                        _vm._v("keyboard_arrow_left")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c("h1", { attrs: { title: "Piscina" } }, [
                _c("span", [_vm._v(" " + _vm._s(_vm.currentAnalyzeLabel))]),
                _vm._v(" -\n      "),
                _c(
                  "span",
                  {
                    staticClass: "header-link",
                    on: {
                      click: function($event) {
                        return _vm.handlePoolClick()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.dataAnalyze.pool.name))]
                ),
                _vm._v(
                  "\n      - " +
                    _vm._s(_vm._f("dateTime")(_vm.dataAnalyze.createDay)) +
                    "\n    "
                )
              ]),
              _vm.currentIndex != _vm.lastIndexAnalyze
                ? _c(
                    "v-btn",
                    {
                      attrs: { flat: "", outline: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.navigateBetweenAnalyzes("next")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { title: "Próxima" } }, [
                        _vm._v("keyboard_arrow_right")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentIndex != _vm.lastIndexAnalyze
                ? _c(
                    "v-btn",
                    {
                      attrs: { flat: "", outline: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.navigateBetweenAnalyzes("last")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("keyboard_double_arrow_right")])],
                    1
                  )
                : _vm._e(),
              _c("v-spacer")
            ],
            1
          ),
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    [
                      _c("PhotosBoard", {
                        staticClass: "ma-1",
                        attrs: { analyze: _vm.dataAnalyze }
                      })
                    ],
                    1
                  ),
                  _vm.dataAnalyze.employee || _vm.dataAnalyze.pool.local
                    ? _c(
                        "div",
                        { staticClass: "ma-1 info-container" },
                        [
                          _vm.dataAnalyze.employee
                            ? _c("v-card", { staticClass: "pa-2" }, [
                                _vm.isAdmin || _vm.isCoordinador
                                  ? _c(
                                      "div",
                                      [
                                        _c("h3", [
                                          _vm._v(
                                            "Colaborador: " +
                                              _vm._s(
                                                _vm.dataAnalyze.employee.name
                                              )
                                          )
                                        ]),
                                        _vm._l(
                                          _vm.dataAnalyze.employee.user
                                            .telephones,
                                          function(telephone) {
                                            return _c(
                                              "v-flex",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.isClient,
                                                    expression: "!isClient"
                                                  }
                                                ],
                                                key: telephone.id
                                              },
                                              [
                                                _vm._v(
                                                  "\n              Contato: (" +
                                                    _vm._s(telephone.ddd) +
                                                    ") " +
                                                    _vm._s(
                                                      telephone.telephoneNumber
                                                    ) +
                                                    "\n            "
                                                )
                                              ]
                                            )
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e(),
                          _vm.dataAnalyze.pool.local
                            ? _c(
                                "v-card",
                                { staticClass: "pa-2 mt-2" },
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "header-link",
                                      on: {
                                        click: function($event) {
                                          return _vm.handleLocalClick()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.dataAnalyze.pool.local.localName
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  ),
                                  _c("b", [_vm._v("Piscinas neste local:")]),
                                  _vm._l(
                                    _vm.dataAnalyze.pool.local.pools,
                                    function(pool) {
                                      return _c(
                                        "v-flex",
                                        {
                                          key: pool.id,
                                          class: {
                                            "pool-link":
                                              pool.analyze[0] &&
                                              pool.id !==
                                                _vm.dataAnalyze.pool.id
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.goToAnalyze(
                                                pool.analyze[0].id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(pool.name) +
                                              "\n          "
                                          )
                                        ]
                                      )
                                    }
                                  )
                                ],
                                2
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("ParametersBoard", {
                staticClass: "mx-1 mt-1",
                attrs: { analyze: this.dataAnalyze }
              }),
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  !_vm.isClient
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "", md6: "" } },
                        [
                          _c("StockBoard", {
                            staticClass: "mx-1 mt-2",
                            attrs: {
                              localName: this.dataAnalyze.pool.local
                                ? this.dataAnalyze.pool.local.localName
                                : undefined,
                              products:
                                _vm.availableStockProducts || _vm.allProducts
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", md6: "" } },
                    [
                      _vm.iaSuggestion.length &&
                      (_vm.isCoordinador || _vm.isAdmin)
                        ? _c("ProductsBoard", {
                            staticClass: "mx-1 mt-2",
                            attrs: {
                              poolVolume: _vm.dataAnalyze.pool.volume,
                              title: "Sugestão automática",
                              products: _vm.iaSuggestion
                            }
                          })
                        : _vm._e(),
                      !this.dataAnalyze.suggestion &&
                      (_vm.isCoordinador || _vm.isAdmin) &&
                      (!this.dataAnalyze.standbyApplication ||
                        !this.dataAnalyze.standbyApplication.length)
                        ? _c("SuggestionBoard", {
                            staticClass: "mx-1 mt-2",
                            attrs: {
                              poolVolume: _vm.dataAnalyze.pool.volume,
                              iaSuggestion: this.iaSuggestion,
                              availableProducts: _vm.availableStockProductIds
                            },
                            on: {
                              suggestedProducts: event => {
                                _vm.suggestedProducts = event
                              }
                            },
                            model: {
                              value: _vm.suggestedProducts,
                              callback: function($$v) {
                                _vm.suggestedProducts = $$v
                              },
                              expression: "suggestedProducts"
                            }
                          })
                        : _vm._e(),
                      _vm.suggestedProducts.length && !_vm.isClient
                        ? _c("ProductsBoard", {
                            staticClass: "mx-1 mt-2",
                            attrs: {
                              poolVolume: _vm.dataAnalyze.pool.volume,
                              hasRemoveBtn: !this.dataAnalyze.suggestion,
                              title: "Produtos sugeridos",
                              products: _vm.suggestedProducts
                            }
                          })
                        : _vm._e(),
                      _vm.aplicationProducts.length
                        ? _c("ProductsBoard", {
                            staticClass: "mx-1 mt-2",
                            attrs: {
                              poolVolume: _vm.dataAnalyze.pool.volume,
                              title: "Produtos aplicados",
                              products: _vm.aplicationProducts
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-card-actions", [
            _c(
              "div",
              { staticClass: "button-wrap" },
              [
                (_vm.isCoordinador || _vm.isAdmin) &&
                (this.dataAnalyze.deviceId || this.dataAnalyze.employee)
                  ? _c(
                      "v-btn",
                      {
                        attrs: { outline: "", color: "primary" },
                        on: { click: _vm.openChat }
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("support")
                        ]),
                        _vm._v("Suporte")
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      outline: "",
                      color: "primary",
                      disabled: _vm.currentIndex === 0
                    },
                    on: {
                      click: function($event) {
                        return _vm.navigateBetweenAnalyzes("previous")
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v("chevron_left")
                    ]),
                    _vm._v("\n        Anterior\n      ")
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      outline: "",
                      color: "primary",
                      disabled: _vm.currentIndex === _vm.lastIndexAnalyze
                    },
                    on: {
                      click: function($event) {
                        return _vm.navigateBetweenAnalyzes("next")
                      }
                    }
                  },
                  [
                    _vm._v("\n        Próxima\n        "),
                    _c("v-icon", { attrs: { right: "" } }, [
                      _vm._v("chevron_right")
                    ])
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { outline: "", color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.closeModal()
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [_vm._v("close")]),
                    _vm._v("\n        Fechar modal\n      ")
                  ],
                  1
                ),
                !this.dataAnalyze.suggestion &&
                (_vm.isCoordinador || _vm.isAdmin)
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          loading: _vm.isLoading,
                          color: "red",
                          outline: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.submitSuggestionIA()
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("check")
                        ]),
                        _vm._v("\n        Fechar análise\n      ")
                      ],
                      1
                    )
                  : _vm._e(),
                !this.dataAnalyze.suggestion &&
                (_vm.isCoordinador || _vm.isAdmin)
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          loading: _vm.isLoading,
                          color: "green",
                          outline: "",
                          id: "submitSuggestion"
                        },
                        on: {
                          click: function($event) {
                            return _vm.submitSuggestion()
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [_vm._v("send")]),
                        _vm._v("\n        Enviar sugestão\n      ")
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _c(
            "CardModal",
            {
              attrs: { width: "30%" },
              model: {
                value: _vm.showNewPoolCard,
                callback: function($$v) {
                  _vm.showNewPoolCard = $$v
                },
                expression: "showNewPoolCard"
              }
            },
            [
              _c("NewPoolCard", {
                attrs: {
                  id: _vm.dataAnalyze.pool.id,
                  volume: _vm.dataAnalyze.pool.volume,
                  name: _vm.dataAnalyze.pool.name,
                  hasTabletSupport:
                    _vm.dataAnalyze.pool.hasTabletApplicationSupport,
                  hasAutomaticSuggestion:
                    _vm.dataAnalyze.pool.isEnabledAutomaticSuggestion
                },
                on: { showNewPoolCard: _vm.onShowNewPoolCard }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }