var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c(
        "v-card",
        { staticClass: "elevation-12" },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "secondary" } },
            [_c("v-toolbar-title", [_vm._v("Cadastro de usuário")])],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                [
                  _c("v-text-field", {
                    attrs: {
                      "prepend-icon": "person",
                      label: "Nome",
                      type: "text"
                    },
                    model: {
                      value: _vm.userName,
                      callback: function($$v) {
                        _vm.userName = $$v
                      },
                      expression: "userName"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      "prepend-icon": "mail_outline",
                      label: "Email",
                      type: "text"
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      "prepend-icon": "lock",
                      label: "Senha",
                      type: "password",
                      rules: [_vm.validationPassword],
                      "validate-on-blur": ""
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2 buttonFull",
                  attrs: {
                    loading: _vm.isSavingUser,
                    color: "primary",
                    block: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.saveUser()
                    }
                  }
                },
                [_vm._v("\n      Salvar\n    ")]
              )
            ],
            1
          ),
          _c("v-card-actions", [
            _c(
              "a",
              {
                staticClass: "ml-2",
                attrs: { id: "forgotPassword" },
                on: {
                  click: function($event) {
                    return _vm.$emit("component-change", "Login")
                  }
                }
              },
              [_vm._v("Voltar")]
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }