<template>
  <v-card class="elevation-12">
    <v-toolbar dark color="secondary">
      <v-toolbar-title>Cadastro de usuário</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form>
        <v-text-field
          prepend-icon="person"
          label="Nome"
          type="text"
          v-model="userName"
        ></v-text-field>
        <v-text-field
          prepend-icon="mail_outline"
          label="Email"
          type="text"
          v-model="email"
        ></v-text-field>
        <v-text-field
          prepend-icon="lock"
          label="Senha"
          type="password"
          v-model="password"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="saveUser()" block class="mx-2 buttonFull">
        Salvar
      </v-btn>
    </v-card-actions>
    <v-card-actions>
      <a @click="$emit('component-change', 'Login')" id="forgotPassword" class="ml-2">Voltar</a>
    </v-card-actions>
  </v-card>
</template>
<script>
import { createUser } from '@/services/UserService';

export default {
  data: () => ({
    userName: '',
    email: '',
    password: '',
  }),
  methods: {
    saveUser() {
      const variables = {
        data: {
          employee: { create: { name: this.userName } },
          email: this.email,
          password: this.password,
          franchisee: {
            connect: { email: this.email },
          },
          roles: { set: 'LIMITED' },
        },
      };

      createUser(this.$apollo, variables)
        .then(() => {
          this.$emit('textSnackbar', 'Usuário criado com sucesso.');
          this.$emit('component-change', 'Login');
        })
        .catch(() => {
          this.$emit(
            'textSnackbar',
            'Sua senha deve ter pelo menos 1 letra minúscula, 1 letra maiúscula, 1 número e no minimo 8 caracteres.',
          );
        });
    },
  },
};
</script>
