<template>
  <v-container>
    <FilterCard
      @clickOnReset="resetFilter()"
      @clickOnSearch="loadTransferences()"
      :resetButton="true"
      :searchButton="true"
    >
      <v-flex xs12 xl3 md6 class="spaceBetweenFields">
        <v-text-field
          class="mx-2"
          label="Pesquise pelo colaborador"
          v-model="filter.employeeName"
          id="searchEmployeeName"
        />
      </v-flex>
      <v-flex xs12 xl3 md6 class="spaceBetweenFields">
        <v-text-field
          class="mx-2"
          label="Pesquise pelo local"
          v-model="filter.localName"
          id="searchLocalName"
        />
      </v-flex>
      <v-flex xs12 xl2 md4 class="spaceBetweenFields" id="searchByType">
        <v-autocomplete
          class="mx-2"
          autocomplete="off"
          menu-props="auto"
          :items="types"
          v-model="filter.type"
          label="Pesquise pelo tipo"
          item-text="name"
          item-value="value"
        />
      </v-flex>
      <v-flex xs12 xl2 md4>
        <datePicker class="mx-2" label="Data inicial" v-model="filter.startDate"></datePicker>
      </v-flex>
      <v-flex xs12 xl2 md4>
        <datePicker class="mx-2" label="Data final" v-model="filter.endDate"></datePicker>
      </v-flex>
    </FilterCard>
    <CardList
      :isDescending="true"
      :sortBy="'date'"
      :isLoading="isLoading"
      @click="(transference) => openDetailsModal(transference.id)"
      :contentList="filteredTrasferenceTable"
    />
    <CardModal v-model="showDetailsModal" width="65%">
      <TransferenceDetails
        :transferenceId="selectedTransferenceId"
        @showModal="showDetailsModal = $event"
      />
    </CardModal>
    <CardModal v-model="showCreateModal" width="65%">
      <TransferenceCreation @showModal="showCreateModal = $event" />
    </CardModal>
    <v-btn
      v-if="!isClient"
      fab
      bottom
      right
      color="#A6CE39"
      dark
      fixed
      @click="openCreateModal()"
      title="Novo"
      id="create"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import * as moment from 'moment';
import FilterCard from '@/components/filters/FilterCard.vue';
import datePicker from '@/components/date-time/DatePicker.vue';
import { getTransferences } from '@/services/TransferenceService';
import CardList from '@/components/lists/CardList.vue';
import CardModal from '@/components/modal/CardModal.vue';
import TransferenceDetails from '@/components/transferences/TrasferenceDetails.vue';
import TransferenceCreation from '@/components/transferences/TransferenceCreation.vue';
import { typesFilter, normalizeString, isRole } from '@/services/UtilsService';

export default {
  data: () => ({
    isClient: isRole('Client'),
    selectedTransferenceId: '',
    showDetailsModal: false,
    showCreateModal: false,
    isLoading: false,
    trasferenceTableData: [],
    transferenceTableColumns: [
      { text: 'Data', value: 'date', type: 'text' },
      { text: 'Colaborador', value: 'employeeName', type: 'text' },
      { text: 'Tipo de movimentação', value: 'type', type: 'text' },
      { text: 'Local', value: 'local', type: 'text' },
    ],
    types: [
      { name: 'Todas', value: null },
      { name: 'Aplicação', value: 'APLICACAO' },
      { name: 'Contagem total', value: 'CONTAGEM' },
      { name: 'Contagem parcial', value: 'CONTAGEM_PARCIAL' },
      { name: 'Entrada', value: 'ENTRADA' },
      { name: 'Saída', value: 'SAIDA' },
      { name: 'Transferência', value: 'TRANSFERENCIA' },
      { name: 'Transferência para aplicação', value: 'TRANSFERENCIA_PARA_APLICACAO' },
    ],
    filter: {
      employeeName: '',
      localName: '',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      type: null,
    },
  }),
  components: {
    TransferenceDetails,
    TransferenceCreation,
    CardModal,
    datePicker,
    CardList,
    FilterCard,
  },
  methods: {
    getTransferenceType(value) {
      return typesFilter(value);
    },
    openDetailsModal(id) {
      this.selectedTransferenceId = id;
      this.showDetailsModal = true;
    },
    openCreateModal() {
      this.showCreateModal = true;
    },
    setTransferenceTableData(transferences) {
      this.trasferenceTableData = transferences.map((transference) => ({
        date: moment(transference.day).format('DD/MM/YY HH:mm'),
        employeeName: transference.employee ? transference.employee.name : '',
        type: this.getTransferenceType(transference.type),
        local: `${transference.from.localName}${transference.to ? ' ⇨ ' : ''}${
          transference.to ? transference.to.localName : ''
        }`,
        id: transference.id,
      }));
    },
    resetFilter() {
      this.filter = this.$options.data().filter;
    },
    async loadTransferences() {
      this.isLoading = true;
      const variables = {
        data: {
          products_some: { amount_gt: 0 },
          ...(this.filter.type ? { type: this.filter.type } : {}),
          day_gte: moment(this.filter.startDate)
            .startOf('day')
            .toISOString(),
          day_lte: moment(this.filter.endDate)
            .endOf('day')
            .toISOString(),
        },
      };
      getTransferences(this.$apollo, variables)
        .then((resp) =>
          this.setTransferenceTableData(
            resp.transferences
              .filter((transference) => transference.from)
              .filter((transference) => transference.type !== 'TRANSFERENCIA_PARA_APLICACAO'),
          ),
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    filteredTrasferenceTable() {
      return {
        columns: this.transferenceTableColumns,
        data: this.trasferenceTableData
          .filter((data) =>
            normalizeString(data.employeeName).includes(normalizeString(this.filter.employeeName)),
          )
          .filter((data) =>
            normalizeString(data.local).includes(normalizeString(this.filter.localName)),
          ),
      };
    },
  },
  created() {
    this.loadTransferences();
  },
};
</script>

<style></style>
