var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "grid-list-xl": "", id: "containerStockTab" } },
    [
      _c(
        "CardModal",
        {
          attrs: { width: "30%" },
          model: {
            value: _vm.showModalSecondStock,
            callback: function($$v) {
              _vm.showModalSecondStock = $$v
            },
            expression: "showModalSecondStock"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Estoque externo:")
              ]),
              _c(
                "v-card-text",
                [
                  !_vm.isClient
                    ? _c(
                        "v-form",
                        { ref: "form" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("SelectPlaces", {
                                    attrs: {
                                      id: "selectPlaces",
                                      franchiseeId: _vm.formStock.franchiseeId,
                                      removePlaceId: _vm.formStock.id,
                                      rules: [_vm.validationRequired]
                                    },
                                    model: {
                                      value: _vm.localSelectedStock,
                                      callback: function($$v) {
                                        _vm.localSelectedStock = $$v
                                      },
                                      expression: "localSelectedStock"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("p", { staticClass: "subtitle-1" }, [
                                    _vm._v(
                                      "\n                As próximas aplicações neste local serão feitas por padrão no estoque selecionado.\n              "
                                    )
                                  ]),
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: { mandatory: true },
                                      model: {
                                        value: _vm.isUsingAnotherStock,
                                        callback: function($$v) {
                                          _vm.isUsingAnotherStock = $$v
                                        },
                                        expression: "isUsingAnotherStock"
                                      }
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label: `Estoque principal: ${_vm.formStock.localName}`,
                                          value: false
                                        }
                                      }),
                                      _c("v-radio", {
                                        attrs: {
                                          id: "alternativeStock",
                                          label: `Estoque secundário: ${_vm.localSelectedStock.localName}`,
                                          value: true,
                                          disabled: !_vm.localSelectedStock.id
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "", color: "primary" },
                          on: {
                            click: function($event) {
                              _vm.showModalSecondStock = !_vm.showModalSecondStock
                            }
                          }
                        },
                        [_vm._v("Fechar")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", md7: "" } }, [
            _c("h2", [_vm._v("Estoque deste local")])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", md5: "" } },
            [
              !_vm.isClient
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ma-0",
                      staticStyle: { width: "100%" },
                      attrs: { large: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.showModalSecondStock = !_vm.showModalSecondStock
                        }
                      }
                    },
                    [_vm._v("Estoque externo")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-content",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-layout",
                                    [
                                      _c("v-flex", [
                                        _c(
                                          "h1",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: !_vm.isUsingAnotherStock
                                                    ? "primary"
                                                    : ""
                                                }
                                              },
                                              [_vm._v("layers")]
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c("h3", [_vm._v("Estoque Principal")]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.formStock.localName)
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("DataTable", {
                                attrs: {
                                  tableContent: _vm.tableStockDefault,
                                  hideColumnValue: _vm.isClient
                                    ? "minimumQuantity"
                                    : null
                                },
                                on: {
                                  inputedMinimumQuantity: function($event) {
                                    return _vm.formatMinimumQuantity($event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isUsingAnotherStock && _vm.localSelectedStock.id
                    ? _c(
                        "v-expansion-panel-content",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-layout",
                                          [
                                            _c("v-flex", [
                                              _c(
                                                "h1",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "primary"
                                                      }
                                                    },
                                                    [_vm._v("layers")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "" } },
                                              [
                                                _c("h3", [
                                                  _vm._v("Estoque Alternativo")
                                                ]),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.localSelectedStock
                                                        .localName
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            303111894
                          )
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("DataTable", {
                                    attrs: {
                                      tableContent: _vm.tableSecondStock,
                                      hideColumnValue: "minimumQuantity"
                                    },
                                    on: {
                                      inputedMinimumQuantity: function($event) {
                                        return _vm.formatMinimumQuantity($event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-content",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-layout",
                                    [
                                      _c("v-flex", [
                                        _c(
                                          "h1",
                                          [
                                            _c("v-icon", [_vm._v("swap_horiz")])
                                          ],
                                          1
                                        )
                                      ]),
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c("h3", [
                                          _vm._v("Últimas movimentações")
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            "Estoque principal: " +
                                              _vm._s(_vm.formStock.localName)
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("DataTable", {
                                attrs: { tableContent: _vm.tableLastMove }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.showModal = false
                }
              }
            },
            [_vm._v("Fechar")]
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", id: "saveStock" },
              on: {
                click: function($event) {
                  return _vm.submitStock()
                }
              }
            },
            [_vm._v("Salvar")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }