<template>
  <GmapMap
    ref="mapRef"
    gestureHandling="greedy"
    :center="currentMapCenter"
    :zoom="currentZoom"
    map-type-id="satellite"
    @click="
      (event) => {
        infoWindowIsOpened = false;
        $emit('click', { lat: event.latLng.lat(), lng: event.latLng.lng() });
      }
    "
    @zoom_changed="
      $refs.mapRef.$mapPromise.then((map) => {
        currentZoom = map.getZoom();
      })
    "
  >
    <GmapCluster
      @click="
        (cluster) => {
          lastMapCenter = cluster.markers_[0].position;
          infoWindowIsOpened = currentZoom > 17;
          infoWindowPosition = cluster.markers_[0].position;
          infoWindowMessages = cluster.markers_.map((marker) => marker.title);
          currentZoom = zoomIn(currentZoom);
        }
      "
    >
      <GmapInfoWindow
        :position="infoWindowPosition"
        :opened="infoWindowIsOpened"
        @closeclick="() => (infoWindowIsOpened = false)"
      >
        <h1>Registros</h1>
        <br />
        <p v-for="(message, index) in infoWindowMessages" :key="index">{{ message }}</p>
      </GmapInfoWindow>
      <GmapMarker
        :key="index + 'notification'"
        v-for="(marker, index) in notificationMarkers"
        :position="marker.position"
        :title="marker.title"
        :clickable="true"
        :draggable="false"
        :icon="'/assets/icons/notification.svg'"
        @click="
          lastMapCenter = marker.position;
          infoWindowMessages = [marker.title];
          currentZoom = zoomIn(currentZoom);
          infoWindowIsOpened = currentZoom > 17;
          infoWindowPosition = marker.position;
        "
      />
      <GmapMarker
        :key="index + 'analyzes'"
        v-for="(marker, index) in analyzesMarkers"
        :position="marker.position"
        :title="marker.title"
        :clickable="true"
        :draggable="false"
        :icon="'/assets/icons/test-tube.svg'"
        @click="
          lastMapCenter = marker.position;
          infoWindowMessages = [marker.title];
          currentZoom = zoomIn(currentZoom);
          infoWindowIsOpened = currentZoom > 17;
          infoWindowPosition = marker.position;
        "
      />
      <GmapMarker
        :key="index + 'default'"
        v-for="(marker, index) in defaultMarkers"
        :position="marker.position"
        :clickable="true"
        :title="marker.title"
        :draggable="false"
        @click="
          lastMapCenter = marker.position;
          currentZoom = zoomIn(currentZoom);
          infoWindowMessages = [marker.title];
          infoWindowIsOpened = currentZoom > 17;
          infoWindowPosition = marker.position;
        "
      ></GmapMarker>
    </GmapCluster>
    <GmapMarker
      :key="index + 'local'"
      v-for="(marker, index) in localMarkers"
      :position="marker.position"
      :title="marker.title"
      :clickable="true"
      :draggable="false"
      :icon="'/assets/icons/localIcon.svg'"
      @click="
        lastMapCenter = marker.position;
        infoWindowMessages = [marker.title];
        infoWindowIsOpened = currentZoom > 17;
        infoWindowPosition = marker.position;
        currentZoom = zoomIn(currentZoom);
      "
    />
  </GmapMap>
</template>

<script>
export default {
  computed: {
    currentMapCenter() {
      return this.lastMapCenter || this.center || { lat: -15.0, lng: -47.0 };
    },
  },
  watch: {
    zoom(zoom) {
      this.currentZoom = zoom;
    },
  },
  methods: {
    zoomIn(currentZoom) {
      return currentZoom < 8 ? currentZoom + 4 : currentZoom + 2;
    },
  },
  data: () => ({
    lastMapCenter: '',
    currentZoom: 10,
    infoWindowMessages: [],
    infoWindowPosition: { lat: 0.0, lng: 0.0 },
    infoWindowIsOpened: false,
  }),
  props: [
    'defaultMarkers',
    'localMarkers',
    'notificationMarkers',
    'center',
    'zoom',
    'analyzesMarkers',
  ],
  created() {
    this.currentZoom = this.zoom || 5;
    if (this.defaultMarkers[0] && !this.center) {
      this.lastMapCenter = this.defaultMarkers[0].position;
      this.currentZoom = 15;
    }
  },
};
</script>
