import notificationList from '@/graphql/notification/listNotifications.gql';
import { query, mutation } from '@/services/GraphqlService';
import updateNotificationMutation from '@/graphql/notification/updateNotificationMutation.gql';
import getNotificationQuery from '@/graphql/notification/singleNotification.gql';
import getLastlastNotificationQuery from '@/graphql/notification/lastNotification.gql';

export const getLastlastNotification = (apollo) =>
  query(apollo, getLastlastNotificationQuery, {});

export const getNotification = (apollo, variables) =>
  query(apollo, getNotificationQuery, variables);

export const getAllNotifications = (apollo, variables) =>
  query(apollo, notificationList, variables).then((resp) => resp.notifications);

export const updateNotification = (apollo, variables) =>
  mutation(apollo, updateNotificationMutation, variables);
