<template>
  <v-container class="pt-0">
    <div class="text-xs-center" v-if="isLoading">
      <v-progress-circular size="35" indeterminate color="primary"></v-progress-circular>
      <br />
    </div>
    <div v-else>
      <v-layout>
        <v-flex xs12>
          <v-switch
            style="float: right"
            v-if="isAdmin || isCoordinador || isFranchisee"
            v-model="isCheckListEnabled"
            :label="isCheckListEnabled ? 'CheckList ativo' : 'CheckList inativo'"
          />
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12>
          <h2>Checklist de tarefas deste local</h2>
        </v-flex>
        <v-flex xs12>
          <v-card>
            <v-toolbar flat>
              <v-layout wrap>
                <v-flex xs12 md6>
                  <v-btn
                    class="mx-0 mb-2"
                    v-if="isAdmin || isCoordinador"
                    color="primary"
                    @click="showModalNewTask = true"
                    style="float: right; width:100%;"
                    :disabled="!isCheckListEnabled"
                  >
                    <v-icon>add</v-icon>Adicionar tarefa
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-toolbar>
            <v-toolbar flat>
              <v-layout wrap>
                <v-flex xs12>
                  <p class="mt-2">
                    Selecione tarefas que devem ser cumpridas pelo piscineiro ao finalizar uma
                    visita.
                  </p>
                </v-flex>
              </v-layout>
            </v-toolbar>
            <v-list subheader two-line style="overflow: auto; height: 300px;">
              <v-divider></v-divider>
              <v-list-tile
                @click="isCheckListEnabled ? (content.check = !content.check) : ''"
                v-for="(content, index) in optionList"
                :key="index"
              >
                <v-list-tile-action>
                  <v-checkbox
                    :disabled="!isCheckListEnabled"
                    :input-value="content.check"
                  ></v-checkbox>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>{{ content.name }}</v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-layout>
                    <v-flex mx-3>
                      <v-btn
                        :disabled="!isCheckListEnabled"
                        v-if="isAdmin || isCoordinador"
                        icon
                        @click="editOption(index)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex mx-3>
                      <v-btn
                        v-if="isAdmin || isCoordinador"
                        icon
                        @click="confirmRemoveOption(index)"
                        :disabled="!isCheckListEnabled"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
            <v-divider></v-divider>
            <v-list>
              <v-list-tile>
                <v-spacer />
                <v-btn
                  :loading="isSaveLoading"
                  color="primary"
                  :disabled="!isCheckListEnabled"
                  @click="submitAllCheck()"
                >
                  Salvar
                </v-btn>
              </v-list-tile>
            </v-list>
          </v-card>
        </v-flex>
        <CardModal v-model="showModalNewTask">
          <v-card>
            <v-card-title class="headline">
              {{ this.formOption.id ? 'Editar tarefa' : 'Nova tarefa' }}
            </v-card-title>
            <v-card-text>
              <v-form ref="form">
                <v-flex xs12>
                  <v-text-field label="Tarefa" v-model="formOption.name"></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-checkbox
                    v-model="formOption.check"
                    label="Adicionar à checklist do piscineiro"
                  ></v-checkbox>
                </v-flex>
              </v-form>
              <v-flex xs12> </v-flex>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="primary darken-1" flat="flat" @click="showModalNewTask = false">
                Fechar
              </v-btn>
              <v-btn
                color="primary"
                @click="
                  formOption.id ? submitEditOption(formOption) : submitCreateOption(formOption)
                "
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </CardModal>
        <CardModal v-model="showModalConfirmRemove">
          <v-card>
            <v-card-title class="title">Confirmar exclusão</v-card-title>
            <v-card-text>
              Você tem certeza que deseja excluir permanentemente esta opção?<br />
              <b v-if="this.indexOptionRemove !== ''">
                {{ this.optionList[this.indexOptionRemove].name }}
              </b>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" flat @click="showModalConfirmRemove = false">
                Cancelar
              </v-btn>
              <v-btn color="primary" @click="removeOption(indexOptionRemove)">
                Excluir
              </v-btn>
            </v-card-actions>
          </v-card>
        </CardModal>
      </v-layout>
      <v-layout>
        <v-spacer />
        <v-btn color="primary" flat @click="$emit('showModal', false)">
          Fechar
        </v-btn>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import CardModal from '@/components/modal/CardModal.vue';
import { isRole, alertSnack } from '@/services/UtilsService';
import {
  saveCheckList,
  updateStatusCheckListByPlace,
  updateCheckListOption,
  updateAllCheckListByPlace,
  getStatusCheckListByPlace,
  getCheckListOptions,
} from '@/services/ChecklistService';

export default {
  data: () => ({
    isAdmin: isRole('ADMIN'),
    isCoordinador: isRole('COORDINADOR'),
    isFranchisee: isRole('FRANCHISEE'),
    localCheckList: [],
    checkListOptions: [],
    indexOptionRemove: '',
    showModalConfirmRemove: false,
    showModalNewTask: false,
    formOption: {
      id: '',
      name: '',
      check: false,
    },
    optionList: [],
    isLoading: false,
    isSaveLoading: false,
    isCheckListEnabled: '',
  }),
  methods: {
    async getStatusLocalCheckList() {
      return getStatusCheckListByPlace(this.$apollo, { placeId: this.idPlaceEdit }).then(
        (result) => !result.local.isCheckListDisabled,
      );
    },
    async getCheckListOptions() {
      const checkListOptions = await getCheckListOptions(this.$apollo, {
        idPlace: this.idPlaceEdit,
      }).then((result) => result.checkListOptions);

      checkListOptions.reverse();
      return checkListOptions.map((option) => ({
        id: option.id,
        name: option.text,
        check: Boolean(option.locals.find((local) => local.id === this.idPlaceEdit)),
      }));
    },
    neutralizeClickCheck(index) {
      this.optionList[index].check = !this.optionList[index].check;
    },
    editOption(index) {
      this.neutralizeClickCheck(index);
      this.showModalNewTask = true;
      this.formOption = this.optionList[index];
    },
    async submitEditOption(option) {
      const variables = {
        id: option.id,
        data: {
          text: option.name,
        },
      };

      const oldOptionList = await this.getCheckListOptions();
      const isChange =
        oldOptionList.find((oldOption) => oldOption.id === option.id).check !== option.check;

      if (isChange) {
        variables.data.locals = option.check
          ? { connect: { id: this.idPlaceEdit } }
          : { disconnect: { id: this.idPlaceEdit } };
      }

      await updateCheckListOption(this.$apollo, variables)
        .then(() => {
          alertSnack('saveDefault');
          this.showModalNewTask = false;
        })
        .catch(() => {
          alertSnack('errorDefault');
        });

      this.optionList = await this.getCheckListOptions();
    },
    async submitCreateOption(option) {
      const variables = {
        data: { text: option.name },
      };

      if (option.check) variables.data.locals = { connect: { id: this.idPlaceEdit } };

      await saveCheckList(this.$apollo, variables)
        .then(() => {
          alertSnack('saveDefault');
          this.showModalNewTask = false;
        })
        .catch(() => {
          alertSnack('errorDefault');
        });

      this.optionList = await this.getCheckListOptions();
    },
    confirmRemoveOption(index) {
      this.neutralizeClickCheck(index);
      this.showModalConfirmRemove = true;
      this.indexOptionRemove = index;
    },
    async removeOption(index) {
      const variables = { id: this.optionList[index].id, data: { isDeleted: true } };
      updateCheckListOption(this.$apollo, variables).then(() => {
        alertSnack('Opção removida com sucesso.');
      });
      this.optionList = await this.getCheckListOptions();
      this.indexOptionRemove = '';
      this.showModalConfirmRemove = false;
    },
    async submitAllCheck() {
      this.isSaveLoading = true;
      const oldOptionList = await this.getCheckListOptions();

      const variables = this.optionList.reduce(
        (accumulator, option) => {
          const isChange = oldOptionList.some(
            (oldOption) => oldOption.id === option.id && oldOption.check !== option.check,
          );

          if (isChange && option.check) {
            accumulator.connect.push({ id: option.id });
          } else if (isChange) {
            accumulator.disconnect.push({ id: option.id });
          }
          return accumulator;
        },
        { connect: [], disconnect: [], placeId: this.idPlaceEdit },
      );

      updateAllCheckListByPlace(this.$apollo, variables)
        .then(() => {
          alertSnack('saveDefault');
        })
        .catch(() => {
          alertSnack('errorDefault');
        })
        .finally(() => {
          this.isSaveLoading = false;
        });
    },
  },
  components: {
    CardModal,
  },
  watch: {
    isCheckListEnabled(value, oldValue) {
      if (oldValue === '') return;

      const variables = {
        placeId: this.idPlaceEdit,
        isDisableCheckList: !value,
      };

      updateStatusCheckListByPlace(this.$apollo, variables)
        .then(() => {
          alertSnack('Status atualizado com sucesso.');
        })
        .catch(() => {
          alertSnack('errorDefault');
        });
    },
    async showModalNewTask(value) {
      if (value) return;
      this.formOption = this.$options.data().formOption;
    },
    async showModalConfirmRemove() {
      this.optionList = await this.getCheckListOptions();
    },
  },
  async created() {
    this.isLoading = true;
    this.isCheckListEnabled = await this.getStatusLocalCheckList();
    this.optionList = await this.getCheckListOptions();
    this.isLoading = false;
  },
  props: ['idPlaceEdit'],
};
</script>
