import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBsu7zLSMrEB7tc377iC4D60Okod1yF1U0',
  authDomain: 'piscinapp-649a8.firebaseapp.com',
  databaseURL: 'https://piscinapp-649a8.firebaseio.com',
  projectId: 'piscinapp-649a8',
  storageBucket: 'piscinapp-649a8.appspot.com',
  messagingSenderId: '431847117397',
  appId: '1:431847117397:web:882c69a599ab0098aebadc',
};
initializeApp(firebaseConfig);

export const uploadFile = (file) => {
  const storage = getStorage();
  const storageRef = ref(storage, `documents/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);
  return new Promise((resolve, reject) => {
    uploadTask.then((snapshot) => {
      const fileName = snapshot.metadata.name;
      const storageBucket = firebaseConfig.storageBucket;
      const downloadLink = `https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/documents%2F${fileName}?alt=media`;
      resolve(downloadLink);
    }).catch(error => reject(error));
  });
};
