<template>
  <div class="container" ref="chatsContainer">
    <div
      class="chat-info-wrapper"
      :class="{
        'current-chat': chat.isShown,
      }"
      v-for="chat in chats"
      :key="chat.id"
      @click="onChatClick(chat.originId)"
    >
      <div class="chat-info">
        <div class="sent-at">{{ chat.lastMessage.sentIn | formatDate }}</div>
        <div class="chat-last-message">
          <div class="last-message-text">{{ chat.lastMessage.body | chatMessagePreview }}</div>
          <div
            class="unread-message-dot"
            v-if="!chat.lastMessage.isRead && chat.lastMessage.isFromUser"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSupportChats } from '../../services/ChatService';

export default {
  name: 'ChatSideBar',
  data() {
    return {
      chats: [],
      updateChatsInterval: undefined,
    };
  },
  methods: {
    async queryChats() {
      const resp = await getSupportChats(this.$apollo);
      this.chats = resp.supportChats
        .filter((chat) => chat.messages.length)
        .map((chat) => ({
          ...chat,
          lastMessage: chat.messages[0],
        }))
        .sort((chatA, chatB) => {
          const hasChatAUnreadUserMessage =
            !chatA.lastMessage.isRead && chatA.lastMessage.isFromUser;
          const hasChatBUnreadUserMessage =
            !chatB.lastMessage.isRead && chatB.lastMessage.isFromUser;
          if (hasChatAUnreadUserMessage && !hasChatBUnreadUserMessage) return -1;
          if (!hasChatAUnreadUserMessage && hasChatBUnreadUserMessage) return 1;
          return new Date(chatA.lastMessage.sentIn) - new Date(chatB.lastMessage.sentIn);
        });
    },
    onChatClick(originId) {
      this.updateChat(originId);
      this.$emit('chat-click', originId);
    },
    updateChat(originId) {
      this.chats = this.chats.map((chat) => ({
        ...chat,
        isShown: chat.originId === originId,
        ...(chat.originId === originId && {
          lastMessage: {
            ...chat.lastMessage,
            isRead: true,
          },
        }),
      }));
    },
    updateChats() {
      this.queryChats().then(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const originId = urlParams.get('originId');
        if (originId) {
          this.updateChat(originId);
        }
        this.chats.sort((chatA, chatB) => {
          const hasChatAUnreadUserMessage =
            !chatA.lastMessage.isRead && chatA.lastMessage.isFromUser;
          const hasChatBUnreadUserMessage =
            !chatB.lastMessage.isRead && chatB.lastMessage.isFromUser;
          if (hasChatAUnreadUserMessage && !hasChatBUnreadUserMessage) return -1;
          if (!hasChatAUnreadUserMessage && hasChatBUnreadUserMessage) return 1;

          return new Date(chatB.lastMessage.sentIn) - new Date(chatA.lastMessage.sentIn);
        });
      });
    },
    setUpdateChatsInterval() {
      this.updateChatsInterval = setInterval(() => {
        this.updateChats();
      }, 5000);
    },
  },
  mounted() {
    this.updateChats();
    this.setUpdateChatsInterval();
  },
  beforeDestroy() {
    clearInterval(this.updateChatsInterval);
  },
  filters: {
    formatDate(value) {
      return new Date(value).toLocaleString('pt-BR', {
        hour: 'numeric',
        minute: 'numeric',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      });
    },
    chatMessagePreview(value) {
      return value.match(/^\[file\].*\[\/file\]$/) != null ? 'Arquivo' : value;
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 300px;
  min-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #484857;
  box-shadow: 0 0 10px 0 rgb(0, 0, 0, 0.2);
  padding: 0;
  overflow-y: scroll;
  scrollbar-width: none;
}
.container::-webkit-scrollbar {
  display: none;
}

.chat-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px 0 8px;
  border-bottom: 1px solid #2c2c37;
}

.chat-last-message {
  font-size: 14px;
  color: #fff;
  width: 100%;
  margin-bottom: 8px;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.last-message-text {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sent-at {
  font-size: 12px;
  color: #b3b3b3;
  width: 100%;
  text-align: right;
}

.chat-info-wrapper {
  padding: 0px 16px;
  cursor: pointer;
  user-select: none;
}

.chat-info-wrapper:hover {
  background-color: #3a3a47;
}

.chat-info-wrapper:active {
  background-color: #2c2c37;
}

.current-chat {
  background-color: #3a3a47;
}
.unread-message-dot {
  max-width: 8px;
  max-height: 8px;
  min-width: 8px;
  min-height: 8px;
  border-radius: 50%;
  background-color: #fff;
  margin-left: 8px;
}
</style>
