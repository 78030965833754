var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "toolbar",
              attrs: { color: "#0d1824", dark: "", flat: "" }
            },
            [
              _c("v-toolbar-title", { staticClass: "toolbar-title" }, [
                _vm._v("Ofertas")
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", outline: "" },
                  on: {
                    click: function($event) {
                      _vm.isModalShown = true
                    }
                  }
                },
                [_c("v-icon", [_vm._v("add")])],
                1
              )
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                staticClass: "elevation-0",
                attrs: {
                  headers: [
                    { text: "Produto", value: "classification" },
                    { text: "Link", value: "link" },
                    { text: "Criação", value: "createdAt" },
                    {
                      text: "",
                      value: "actions",
                      sortable: false,
                      align: "right",
                      width: "20px"
                    }
                  ],
                  items: _vm.offers,
                  "items-per-page": 5,
                  "no-data-text": _vm.isLoading
                    ? "Carregando..."
                    : "Não há ofertas a serem exibidas",
                  "rows-per-page-text": "Itens por página",
                  "rows-per-page-items": [10, 20, 50],
                  loading: _vm.isLoading
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function(props) {
                      return [
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("classificationText")(
                                props.item.classification
                              )
                            )
                          )
                        ]),
                        _c(
                          "td",
                          {
                            staticClass: "product-link",
                            on: {
                              click: function($event) {
                                return _vm.openOfferPage(props.item)
                              }
                            }
                          },
                          [_vm._v(_vm._s(props.item.link))]
                        ),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm._f("formatDate")(props.item.createdAt))
                          )
                        ]),
                        _c(
                          "td",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "", small: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteOffer(props.item.id)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { color: "red", size: "20px" } },
                                  [_vm._v("delete")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CardModal",
        {
          staticStyle: { position: "fixed" },
          attrs: { width: "480" },
          model: {
            value: _vm.isModalShown,
            callback: function($$v) {
              _vm.isModalShown = $$v
            },
            expression: "isModalShown"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "#0d1824", dark: "", flat: "" } },
                [_c("v-toolbar-title", [_vm._v("Adicionar oferta")])],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "padding-modal" },
                [
                  _c(
                    "v-flex",
                    [
                      _c("v-select", {
                        attrs: {
                          "full-width": "",
                          items: _vm.productClassifications,
                          label: "Produto"
                        },
                        model: {
                          value: _vm.form.classification,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "classification", $$v)
                          },
                          expression: "form.classification"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: { label: "Link", type: "text" },
                        model: {
                          value: _vm.form.link,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "link", $$v)
                          },
                          expression: "form.link"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary darken-1", flat: "flat" },
                      on: {
                        click: function($event) {
                          _vm.isModalShown = false
                        }
                      }
                    },
                    [_vm._v("\n          Fechar\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.isLoading,
                        color: "primary",
                        disabled: !_vm.form.classification || !_vm.isLinkValid
                      },
                      on: {
                        click: function($event) {
                          return _vm.createOffer()
                        }
                      }
                    },
                    [_vm._v("\n          Salvar\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }