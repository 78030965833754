var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "FilterCard",
        {
          attrs: { searchButton: false, resetButton: true },
          on: {
            clickOnReset: function($event) {
              return _vm.resetFilter()
            }
          }
        },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm12: "", md6: "", xl6: "" } },
            [
              _c("v-text-field", {
                staticClass: "mx-2",
                attrs: {
                  padding: "",
                  label: "Pesquise pelo tipo",
                  id: "searchType"
                },
                model: {
                  value: _vm.filterForm.type,
                  callback: function($$v) {
                    _vm.$set(_vm.filterForm, "type", $$v)
                  },
                  expression: "filterForm.type"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm12: "", md3: "", xl4: "" } },
            [
              _c("v-text-field", {
                staticClass: "mx-2",
                attrs: { id: "brandSearchTerm", label: "Pesquise pela marca" },
                model: {
                  value: _vm.filterForm.brand,
                  callback: function($$v) {
                    _vm.$set(_vm.filterForm, "brand", $$v)
                  },
                  expression: "filterForm.brand"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm12: "", md3: "", xl2: "" } },
            [
              _c("StatusSelect", {
                staticClass: "mx-2",
                attrs: { id: "searchStatus" },
                model: {
                  value: _vm.filterForm.status,
                  callback: function($$v) {
                    _vm.$set(_vm.filterForm, "status", $$v)
                  },
                  expression: "filterForm.status"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("CardList", {
        attrs: {
          id: "dataEquipments",
          contentList: _vm.formatedListEquipments,
          isLoading: _vm.isLoading
        },
        on: { click: element => _vm.openModal(element) }
      }),
      _c(
        "CardModal",
        {
          attrs: { width: "65%" },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("CreateEquipament", {
            attrs: { equipmentData: _vm.equipmentData },
            on: {
              showModal: function($event) {
                _vm.showModal = $event
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            fab: "",
            bottom: "",
            right: "",
            color: "#A6CE39",
            dark: "",
            fixed: "",
            title: "Novo",
            id: "create"
          },
          on: {
            click: function($event) {
              return _vm.openModal()
            }
          }
        },
        [_c("v-icon", [_vm._v("add")])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }