var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "elevation-12" },
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", color: "secondary" } },
        [_c("v-toolbar-title", [_vm._v("Nova senha")])],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            [
              _c("v-text-field", {
                attrs: {
                  id: "password",
                  "prepend-icon": "lock",
                  name: "password",
                  label: "Insira nova senha",
                  type: "password"
                },
                model: {
                  value: _vm.newPassword,
                  callback: function($$v) {
                    _vm.newPassword = $$v
                  },
                  expression: "newPassword"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              staticClass: "mx-2 buttonFull",
              attrs: {
                loading: _vm.isLoading,
                color: "primary",
                id: "buttonLogin",
                block: ""
              },
              on: {
                click: function($event) {
                  return _vm.changePassword()
                }
              }
            },
            [_vm._v("\n      Alterar senha\n    ")]
          )
        ],
        1
      ),
      _c("v-card-actions", [
        _c(
          "a",
          {
            staticClass: "ml-2",
            attrs: { id: "forgotPassword" },
            on: {
              click: function($event) {
                return _vm.$emit("component-change", "Login")
              }
            }
          },
          [_vm._v("Login")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }