<template>
  <v-card :width="windowWidth < 960 ? `${windowWidth}px` : '100%'">
    <v-card-text v-if="isLoading" class="text-xs-center">
      <v-progress-circular indeterminate color="primary" class="loader"></v-progress-circular>
    </v-card-text>
    <div v-else-if="selectedEquipment">
      <v-card-title>
        <h1>
          {{
            `${selectedEquipment.details.type || ''}
          ${selectedEquipment.details.brand || ''}
          ${selectedEquipment.details.model || ''}`
          }}
          {{
            selectedEquipment.codeIdentifier ? ` - ID nº: ${selectedEquipment.codeIdentifier}` : ''
          }}
        </h1>
      </v-card-title>
      <v-card-text class="mx-0 px-0">
        <VTabs :buttons="tabButtons" @selected="selectedTab = $event">
          <v-layout wrap>
            <v-flex xs12>
              <p class="headline">{{ getTitle }}</p>
            </v-flex>
            <v-flex xs12 v-if="selectedTab === 'registers'">
              <DataTable :tableContent="tableEquipments" />
              <v-btn style="float: right" flat color="primary" @click="closeCardModal()">
                Fechar
              </v-btn>
            </v-flex>
            <v-flex xs12 v-else-if="selectedTab === 'details'">
              <DataIterator :details="detailsContent" />
              <v-btn style="float: right" flat color="primary" @click="closeCardModal()">
                Fechar
              </v-btn>
            </v-flex>
            <v-flex xs12 v-else-if="selectedTab === 'createRegister'">
              <v-flex xs12 id="statusList">
                <v-autocomplete
                  autocomplete="off"
                  menu-props="auto"
                  :items="statusList"
                  v-model="newEquipmentRegister.status"
                  item-value="value"
                  item-text="name"
                  label="Status do equipamento *"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  label="Descrição"
                  v-model="newEquipmentRegister.description"
                  id="registerDescription"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <datePicker
                  label="Última manutenção"
                  v-model="newEquipmentRegister.lastMaintenance"
                  id="registerLastMaintenance"
                ></datePicker>
                <datePicker
                  label="Próxima manutenção"
                  v-model="newEquipmentRegister.nextMaintenance"
                  id="registerNextMaintenance"
                ></datePicker>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  :rules="newEquipmentRegister.status === 'DISABLED' ? [validationRequired] : []"
                  v-model="newEquipmentRegister.observation"
                  :label="
                    newEquipmentRegister.status === 'DISABLED'
                      ? 'Escreva uma observação *'
                      : 'Escreva uma observação'
                  "
                  :error="
                    newEquipmentRegister.status === 'DISABLED' && !newEquipmentRegister.observation
                  "
                  id="registerObservation"
                ></v-textarea>
              </v-flex>
              <v-flex xs12>
                <v-btn
                  color="primary"
                  :disabled="isDisabledCreateRegister"
                  style="float: right"
                  @click="saveEquipmentRegister()"
                  id="saveRegister"
                >
                  Adicionar
                </v-btn>
                <v-btn style="float: right" flat color="primary" @click="closeCardModal()">
                  Fechar
                </v-btn>
              </v-flex>
            </v-flex>
            <v-flex
              xs12
              v-else-if="selectedTab === 'createTransference'"
              id="transferencePlaceSelect"
            >
              <v-flex xs12>
                <placeSelect
                  v-model="idPlaceEditToTransference"
                  :rules="[validationRequired]"
                ></placeSelect>
              </v-flex>
              <v-flex xs12>
                <v-btn
                  style="float: right"
                  color="primary"
                  :disabled="!idPlaceEditToTransference"
                  @click="saveTransference()"
                  id="saveTransference"
                >
                  Transferir
                </v-btn>
                <v-btn style="float: right" flat color="primary" @click="closeCardModal()">
                  Fechar
                </v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
        </VTabs>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import datePicker from '@/components/date-time/DatePicker.vue';
import placeSelect from '@/components/selects/PlaceSelect.vue';
import DataTable from '@/components/utils/DataTable.vue';
import DataIterator from '@/components/utils/DataIterator.vue';
import VTabs from '@/components/utils/VTabs.vue';

import { formatDate, alertSnack, validationRequired } from '@/services/UtilsService';

import {
  saveEquipmentRegister,
  saveEquipmentTransference,
  getEquipmentAllRegisters,
} from '@/services/EquipmentService';

export default {
  data: () => ({
    windowWidth: window.innerWidth,
    validationRequired,
    selectedTab: 'registers',
    newEquipmentRegister: {
      status: 'WORKING',
      lastMaintenance: null,
      nextMaintenance: null,
      description: '',
      observation: '',
    },
    idPlaceEditToTransference: '',
    tabButtons: [
      { name: 'Registros', title: 'Registros deste equipamento', value: 'registers' },
      { name: 'Detalhes', title: 'Detalhes do equipamento', value: 'details' },
      {
        name: 'Adicionar registro',
        title: 'Adicionar registro à este equipamento',
        value: 'createRegister',
      },
      {
        name: 'Transferir equipamento',
        title: 'Transferir este equipamento para outro local',
        value: 'createTransference',
      },
    ],
    detailsContent: '',
    selectedEquipment: '',
    isLoading: false,
    statusList: [
      { name: 'Funcionando', value: 'WORKING' },
      { name: 'Inoperante', value: 'NOT_WORKING' },
      { name: 'Funcionando com defeito', value: 'WORKING_WITH_DEFECT' },
      { name: 'Funcionando com defeito crítico', value: 'WORKING_WITH_CRITICAL_DEFECT' },
      { name: 'Em manutenção', value: 'UNDER_MAINTENCE' },
      { name: 'Aguardando substituição', value: 'WAITING_FOR_REPLACEMENT' },
      { name: 'Desativado', value: 'DISABLED' },
    ],
    tableEquipments: {
      isLoading: true,
      headerList: [
        { text: 'Data atualização', align: 'left', value: 'dateTime' },
        { text: 'Descrição', align: 'center', value: 'description' },
        { text: 'Observação', align: 'center', value: 'observation' },
        { text: 'Status', align: 'center', value: 'status' },
        { text: 'Última manutenção', align: 'center', value: 'note' },
        { text: 'Próxima manutenção', align: 'center', value: 'status' },
      ],
      contentList: [],
      noResult: 'Não há registro deste equipamento.',
      itensPerPage: [-1],
      textPerPage: 'Registros por página',
      linkMovesAndNewRegister: true,
    },
  }),
  computed: {
    isDisabledCreateRegister() {
      const isNoEquipmentRegisterFieldSelected = Boolean(
        !this.newEquipmentRegister.observation &&
          !this.newEquipmentRegister.status &&
          !this.newEquipmentRegister.lastMaintenance &&
          !this.newEquipmentRegister.nextMaintenance &&
          !this.newEquipmentRegister.description,
      );

      return (
        (this.newEquipmentRegister.status === 'DISABLED' &&
          !this.newEquipmentRegister.observation) ||
        isNoEquipmentRegisterFieldSelected
      );
    },
    getTitle() {
      return this.tabButtons.find((button) => button.value === this.selectedTab).title;
    },
  },
  methods: {
    formatEquipmentStatus(statusValue) {
      const equipStatusList = {
        WORKING: 'Funcionando',
        WORKING_WITH_DEFECT: 'Funcionando com defeito',
        WORKING_WITH_CRITICAL_DEFECT: 'Funcionando com defeito crítico',
        UNDER_MAINTENCE: 'Em manutenção',
        WAITING_FOR_REPLACEMENT: 'Aguardando substituição',
        DISABLED: 'Desativado',
      };
      return equipStatusList[statusValue];
    },
    closeCardModal() {
      this.$emit('showModal', false);
      this.selectedTab = 'registers';
    },
    async saveEquipmentRegister() {
      this.isLoading = true;
      const variables = {
        equipmentId: this.selectedEquipmentId,
        observation: this.newEquipmentRegister.observation,
        status: this.newEquipmentRegister.status,
        lastMaintenance: this.newEquipmentRegister.lastMaintenance,
        nextMaintenance: this.newEquipmentRegister.nextMaintenance,
        dateTime: new Date().toISOString(),
        description: this.newEquipmentRegister.description,
      };
      this.newEquipmentRegister = this.$options.data().newEquipmentRegister;

      await saveEquipmentRegister(this.$apollo, variables)
        .then(() => {
          alertSnack('saveDefault');
          this.closeCardModal();
        })
        .catch(() => {
          alertSnack('errorDefault');
        });
      await this.updateRegistersList();
      this.isLoading = false;
    },
    async updateRegistersList() {
      const resp = await getEquipmentAllRegisters(this.$apollo, {
        id: this.selectedEquipmentId,
      });
      this.selectedEquipment.equipmentRegisters = resp.equipmentRegisters;
    },
    async getRegisters() {
      getEquipmentAllRegisters(this.$apollo, {
        id: this.selectedEquipmentId,
      }).then((resp) => {
        const title = 'Detalhes do equipamento';

        this.detailsContent = {
          title:
            resp.equipment && resp.equipment.codeIdentifier
              ? `${title} nº: ${resp.equipment.codeIdentifier}`
              : title,
          content: [
            { name: 'Pontencia', value: resp.equipment.details.power || '- -' },
            { name: 'Tensão', value: resp.equipment.details.tension || '- -' },
            { name: 'Corrente', value: resp.equipment.details.current || '- -' },
            { name: 'Diametro', value: resp.equipment.details.diameter || '- -' },
            { name: 'Area', value: resp.equipment.details.area || '- -' },
            { name: 'Carga', value: resp.equipment.details.load || '- -' },
          ],
        };

        this.selectedEquipment = {
          codeIdentifier: resp.equipment.codeIdentifier,
          details: resp.equipment.details,
          equipmentRegisters: resp.equipment.equipmentRegisters,
          isDisabled: resp.equipment.isDisabled,
        };

        this.tableEquipments.contentList = resp.equipment.equipmentRegisters.map((register) => ({
          dateTime: formatDate(register.dateTime),
          description: register.description,
          observation: register.observation,
          status: this.formatEquipmentStatus(register.status),
          lastMaintenance: formatDate(register.lastMaintenance),
          nextMaintenance: formatDate(register.nextMaintenance),
        }));
        this.tableEquipments.isLoading = false;
      });
    },
    async saveTransference() {
      this.isLoading = true;
      const variables = {
        day: new Date().toISOString(),
        to: this.idPlaceEditToTransference,
        from: this.idPlaceEdit,
        employeeId: localStorage.employeeId,
        equipmentId: this.selectedEquipmentId,
      };

      this.idPlaceEditToTransference = '';

      await saveEquipmentTransference(this.$apollo, variables)
        .then(() => {
          alertSnack('saveDefault');
          this.closeCardModal();
        })
        .catch(() => {
          alertSnack('errorDefault');
        });

      this.isLoading = false;
    },
  },
  components: {
    placeSelect,
    datePicker,
    DataTable,
    DataIterator,
    VTabs,
  },
  watch: {
    selectedEquipmentId(value) {
      this.selectedTab = 'registers';
      this.idPlaceEditToTransference = '';
      this.newEquipmentRegister = this.$options.data().newEquipmentRegister;
      if (!value) return;
      this.getRegisters();
    },
  },
  props: ['selectedEquipmentId', 'idPlaceEdit'],
};
</script>

<style scoped>
tr:hover {
  background: #e7e3e3;
}
</style>
