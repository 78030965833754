var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    attrs: {
      "menu-props": "auto",
      items: _vm.types,
      value: _vm.value,
      label: _vm.rules ? "Tipo de local *" : "Tipo de local",
      disabled: _vm.disabled,
      rules: _vm.rules,
      "item-text": "name",
      "item-value": "value"
    },
    on: {
      change: function($event) {
        return _vm.$emit("input", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }