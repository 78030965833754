import { query } from '@/services/GraphqlService';
import listClockIn from '@/graphql/check/listClockIn.gql';

export const getClockInList = (
    apollo,
    variables,
) => query(
    apollo,
    listClockIn,
    variables,
);
