<template>
  <v-container class="container">
    <div class="support-chat">
      <v-toolbar class="toolbar" color="#0d1824" dark flat>
        <v-toolbar-title class="toolbar-title">Suporte</v-toolbar-title>
        <v-spacer></v-spacer>
        <ShowAnalyzesButton :originId="originId" v-if="originId" />
      </v-toolbar>
      <div class="chat-container">
        <ChatSideBar @chat-click="onChatClick" />
        <div class="chat-content">
          <div class="messages-container" ref="chatContainer">
            <div v-for="message in messages" :key="message.id || message.sentIn" class="message">
              <Balloon
                v-bind:class="!message.isFromUser ? 'align-right' : ''"
                :fromMe="!message.isFromUser"
                :message="message.body"
                :time="message.sentIn | formatDate"
                :status="message.isRead ? 3 : 1"
              />
            </div>
          </div>
          <div class="message-input" v-if="originId">
            <textarea v-model="message" placeholder="Digite sua mensagem..." rows="4"> </textarea>
            <v-btn @click="sendMessage" color="#0d1824" dark>
              Enviar
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { getChatMessages, createChatMessage, markAsRead } from '@/services/ChatService';
import Balloon from '@/components/chat/Balloon.vue';
import ChatSideBar from '@/components/chat/ChatSideBar.vue';
import ShowAnalyzesButton from '@/components/chat/ShowAnalyzesButton.vue';

export default {
  name: 'SupportChat',
  components: {
    Balloon,
    ChatSideBar,
    ShowAnalyzesButton,
  },
  data() {
    return {
      originId: undefined,
      messages: [],
      message: '',
      isLoaded: false,
      updateInterval: undefined,
    };
  },
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    async queryChatMessages() {
      if (!this.originId) return;
      const lastMessageUpdatedAt = this.messages.length
        ? this.messages[this.messages.length - 1].updatedAt
        : undefined;
      const resp = await getChatMessages(this.$apollo, {
        where: {
          originId: this.originId,
        },
        messagesWhere: {
          updatedAt_gt: lastMessageUpdatedAt,
        },
      });
      this.messages = this.messages.map((message) => {
        const newMessage = resp.supportChat.messages.find(
          (supportMessage) => supportMessage.sentIn === message.sentIn,
        );
        if (newMessage) {
          message.isRead = newMessage.isRead;
        }
        return message;
      });
      const newMessages = resp.supportChat.messages.filter(
        (newMessage) => !this.messages.some((message) => message.sentIn === newMessage.sentIn),
      );
      if (newMessages.length) {
        this.markMessagesAsRead();
        newMessages.sort(
          (messageA, messageB) => new Date(messageA.sentIn) - new Date(messageB.sentIn),
        );
        this.messages.push(...newMessages);
        this.scrollToBottom();
      }
    },
    async sendMessage(event) {
      if (event) event.preventDefault();
      this.message = this.message.trim();
      if (!this.message) return;
      const message = {
        supportChat: { connect: { originId: this.originId } },
        body: this.message,
        sentIn: new Date().toISOString(),
      };
      createChatMessage(this.$apollo, {
        data: message,
      });
      this.messages.push(message);
      this.message = '';
      this.scrollToBottom();
      this.$emit('new-message', { message, originId: this.originId });
    },
    markMessagesAsRead() {
      markAsRead(this.$apollo, {
        where: {
          supportChat: { originId: this.originId },
          isRead: false,
          isFromUser: true,
        },
      });
    },
    onChatClick(originId) {
      this.originId = originId;
    },
    addOriginIdToUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('originId', this.originId);
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`);
    },
    setUpdateInterval() {
      this.updateInterval = setInterval(this.queryChatMessages, 5000);
    },
  },
  watch: {
    originId() {
      if (!this.originId) return;
      this.messages = [];
      this.addOriginIdToUrl();
      this.queryChatMessages();
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.originId = urlParams.get('originId');
    this.setUpdateInterval();

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        const isControlPressed = event.ctrlKey || event.metaKey;
        if (isControlPressed && this.message) {
          this.message += '\n';
        } else {
          event.stopPropagation();
          event.preventDefault();
          this.sendMessage();
        }
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
    document.removeEventListener('keydown', () => {});
  },
  filters: {
    formatDate(value) {
      const date = new Date(value);
      return date.toLocaleString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    },
  },
};
</script>

<style scoped>
.chat-container {
  display: flex;
  background-color: red;
  height: calc(100vh - 164px);
  box-shadow: 0 0 10px 0 rgb(0, 0, 0, 0.2);
}
.chat-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #e5ddd5;
  padding: 0;
}

.messages-container {
  overflow-y: scroll;
  padding: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.message {
  display: flex;
  flex-direction: column;
}
.support-chat {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.align-right {
  align-self: flex-end;
  width: fit-content;
}

.message-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #fff;
  box-shadow: 0 -1px 0 0 rgb(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
}

textarea {
  resize: none;
  width: 100%;
  max-height: 84px;
  outline: none;
  border: none;
}
</style>
