var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GmapMap",
    {
      ref: "mapRef",
      attrs: {
        gestureHandling: "greedy",
        center: _vm.currentMapCenter,
        zoom: _vm.currentZoom,
        "map-type-id": "satellite"
      },
      on: {
        click: event => {
          _vm.infoWindowIsOpened = false
          _vm.$emit("click", {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
          })
        },
        zoom_changed: function($event) {
          _vm.$refs.mapRef.$mapPromise.then(map => {
            _vm.currentZoom = map.getZoom()
          })
        }
      }
    },
    [
      _c(
        "GmapCluster",
        {
          on: {
            click: cluster => {
              _vm.lastMapCenter = cluster.markers_[0].position
              _vm.infoWindowIsOpened = _vm.currentZoom > 17
              _vm.infoWindowPosition = cluster.markers_[0].position
              _vm.infoWindowMessages = cluster.markers_.map(
                marker => marker.title
              )
              _vm.currentZoom = _vm.zoomIn(_vm.currentZoom)
            }
          }
        },
        [
          _c(
            "GmapInfoWindow",
            {
              attrs: {
                position: _vm.infoWindowPosition,
                opened: _vm.infoWindowIsOpened
              },
              on: { closeclick: () => (_vm.infoWindowIsOpened = false) }
            },
            [
              _c("h1", [_vm._v("Registros")]),
              _c("br"),
              _vm._l(_vm.infoWindowMessages, function(message, index) {
                return _c("p", { key: index }, [_vm._v(_vm._s(message))])
              })
            ],
            2
          ),
          _vm._l(_vm.notificationMarkers, function(marker, index) {
            return _c("GmapMarker", {
              key: index + "notification",
              attrs: {
                position: marker.position,
                title: marker.title,
                clickable: true,
                draggable: false,
                icon: "/assets/icons/notification.svg"
              },
              on: {
                click: function($event) {
                  _vm.lastMapCenter = marker.position
                  _vm.infoWindowMessages = [marker.title]
                  _vm.currentZoom = _vm.zoomIn(_vm.currentZoom)
                  _vm.infoWindowIsOpened = _vm.currentZoom > 17
                  _vm.infoWindowPosition = marker.position
                }
              }
            })
          }),
          _vm._l(_vm.analyzesMarkers, function(marker, index) {
            return _c("GmapMarker", {
              key: index + "analyzes",
              attrs: {
                position: marker.position,
                title: marker.title,
                clickable: true,
                draggable: false,
                icon: "/assets/icons/test-tube.svg"
              },
              on: {
                click: function($event) {
                  _vm.lastMapCenter = marker.position
                  _vm.infoWindowMessages = [marker.title]
                  _vm.currentZoom = _vm.zoomIn(_vm.currentZoom)
                  _vm.infoWindowIsOpened = _vm.currentZoom > 17
                  _vm.infoWindowPosition = marker.position
                }
              }
            })
          }),
          _vm._l(_vm.defaultMarkers, function(marker, index) {
            return _c("GmapMarker", {
              key: index + "default",
              attrs: {
                position: marker.position,
                clickable: true,
                title: marker.title,
                draggable: false
              },
              on: {
                click: function($event) {
                  _vm.lastMapCenter = marker.position
                  _vm.currentZoom = _vm.zoomIn(_vm.currentZoom)
                  _vm.infoWindowMessages = [marker.title]
                  _vm.infoWindowIsOpened = _vm.currentZoom > 17
                  _vm.infoWindowPosition = marker.position
                }
              }
            })
          })
        ],
        2
      ),
      _vm._l(_vm.localMarkers, function(marker, index) {
        return _c("GmapMarker", {
          key: index + "local",
          attrs: {
            position: marker.position,
            title: marker.title,
            clickable: true,
            draggable: false,
            icon: "/assets/icons/localIcon.svg"
          },
          on: {
            click: function($event) {
              _vm.lastMapCenter = marker.position
              _vm.infoWindowMessages = [marker.title]
              _vm.infoWindowIsOpened = _vm.currentZoom > 17
              _vm.infoWindowPosition = marker.position
              _vm.currentZoom = _vm.zoomIn(_vm.currentZoom)
            }
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }