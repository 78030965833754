var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "FilterCard",
        {
          attrs: { searchButton: true, resetButton: true },
          on: { clickOnReset: _vm.resetFilter, clickOnSearch: _vm.submitFilter }
        },
        [
          _c(
            "v-flex",
            {
              attrs: {
                "px-2": "",
                xs12: "",
                sm12: "",
                md4: "",
                id: "filterByNameEmailFranchisee"
              }
            },
            [
              _c("v-text-field", {
                attrs: { label: "Pesquise pelo nome / e-mail" },
                model: {
                  value: _vm.formFilter.string,
                  callback: function($$v) {
                    _vm.$set(_vm.formFilter, "string", $$v)
                  },
                  expression: "formFilter.string"
                }
              })
            ],
            1
          ),
          _vm.isAdmin || _vm.isCoordinador
            ? _c(
                "v-flex",
                { attrs: { "px-2": "", xs12: "", sm12: "", md4: "" } },
                [
                  _c("FranchiseeSelect", {
                    model: {
                      value: _vm.formFilter.franchiseeId,
                      callback: function($$v) {
                        _vm.$set(_vm.formFilter, "franchiseeId", $$v)
                      },
                      expression: "formFilter.franchiseeId"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            {
              attrs: {
                "px-2": "",
                xs12: "",
                sm12: "",
                md2: "",
                id: "filterByType"
              }
            },
            [
              _c("RolesSelect", {
                attrs: { includeOptionAll: true },
                model: {
                  value: _vm.formFilter.role,
                  callback: function($$v) {
                    _vm.$set(_vm.formFilter, "role", $$v)
                  },
                  expression: "formFilter.role"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            {
              attrs: {
                "px-2": "",
                xs12: "",
                sm12: "",
                md2: "",
                id: "filterByStatus"
              }
            },
            [
              _c("StatusSelect", {
                model: {
                  value: _vm.formFilter.status,
                  callback: function($$v) {
                    _vm.$set(_vm.formFilter, "status", $$v)
                  },
                  expression: "formFilter.status"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      this.listUsers.length
        ? _c("CardList", {
            attrs: {
              sortBy: "undefined",
              id: "dataUsers",
              contentList: _vm.formatedUsersList
            },
            on: { click: element => _vm.openModal(element.id) }
          })
        : _vm._e(),
      _vm.isLoading ? _c("LoadingCircle", { staticClass: "mt-3" }) : _vm._e(),
      _c(
        "CardModal",
        {
          attrs: { width: "65%", removeURLParams: true },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("UserCreation", {
            attrs: { idUserEdit: _vm.idUserEdit },
            on: {
              showModal: function($event) {
                _vm.showModal = $event
              }
            }
          })
        ],
        1
      ),
      _vm.isAdmin ||
      _vm.isCoordinador ||
      _vm.isFranchisee ||
      _vm.isAssociate ||
      _vm.isLimited
        ? _c(
            "v-btn",
            {
              attrs: {
                fab: "",
                bottom: "",
                right: "",
                color: "#A6CE39",
                dark: "",
                fixed: "",
                title: "Novo",
                id: "create"
              },
              on: {
                click: function($event) {
                  _vm.showModal = true
                }
              }
            },
            [_c("v-icon", [_vm._v("add")])],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }