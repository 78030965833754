<template>
  <v-container id="listReports">
    <FilterCard @clickOnReset="resetFilter()" :resetButton="true">
      <v-flex id="selectType" xs12 md6 xl3>
        <v-autocomplete
          autocomplete="off"
          class="mx-2"
          menu-props="auto"
          :items="isClient ? typesForClients : types"
          label="Selecione o tipo"
          v-model="type"
          item-text="name"
          item-value="value"
          @change="fileFormat = 'CSV'"
      /></v-flex>
      <v-flex v-if="type === 'ANALYZE'" xs12 md6 xl3>
        <v-autocomplete
          autocomplete="off"
          class="mx-2"
          menu-props="auto"
          :items="formats"
          label="Selecione formato"
          v-model="fileFormat"
          item-text="name"
          item-value="value"
      /></v-flex>
      <v-flex v-if="(isAdmin || isCoodinador) && fileFormat !== 'PDF'" xs12 md6 xl3>
        <franchiseeSelect
          class="mx-2"
          id="selectFranchisee"
          v-model="selectedFranchisee"
          :allDataOption="type !== 'DAILY_TRATAMENT' && type !== 'POOL_CONDITIONS'"
        ></franchiseeSelect>
      </v-flex>
      <v-flex v-if="type === 'DAILY_TRATAMENT' || type === 'POOL_CONDITIONS'" xs12 md6 xl3>
        <placeSelect
          :franchisee="selectedFranchisee"
          :client="selectedClient"
          class="mx-2"
          id="selectPlace"
          v-model="selectedPlace"
        ></placeSelect>
      </v-flex>
      <v-flex v-if="type === 'DAILY_TRATAMENT' || type === 'POOL_CONDITIONS'" xs12 md6 xl3>
        <poolSelect
          :localId="selectedPlace.id"
          class="mx-2"
          id="selectPool"
          v-model="selectedPool"
        ></poolSelect>
      </v-flex>
      <v-flex
        v-if="!isClient && (fileFormat === 'PDF' || type === 'ANALYZE_WITH_PHOTO')"
        xs12
        md6
        xl3
      >
        <clientSelect class="mx-2" v-model="selectedClient"> </clientSelect>
      </v-flex>
      <v-flex v-if="type !== 'DAILY_TRATAMENT' && type !== 'POOL_CONDITIONS'" xs12 md6 xl3>
        <datePicker class="datePicker mx-2" label="Data inicial" v-model="startDate"></datePicker>
      </v-flex>
      <v-flex v-if="type !== 'DAILY_TRATAMENT' && type !== 'POOL_CONDITIONS'" xs12 md6 xl3>
        <datePicker class="datePicker mx-2" label="Data final" v-model="endDate"></datePicker>
      </v-flex>
      <v-flex v-if="type === 'DAILY_TRATAMENT' || type === 'POOL_CONDITIONS'" xs12 md6 xl3>
        <datePicker
          type="month"
          class="datePicker mx-2"
          label="Mês e ano de referência"
          v-model="selectedMonth"
        ></datePicker>
      </v-flex>
      <v-flex xs12 id="toolbarButton">
        <v-btn
          outline
          class="mx-2"
          :disabled="!type"
          :loading="isLoading"
          @click="downloadReport()"
          id="generateReport"
          >Gerar relatório</v-btn
        >
      </v-flex>
    </FilterCard>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="this.color"
      :right="true"
      :timeout="timeout"
      id="snackbar"
    >
      {{ snackbarText }}
      <v-btn color="White" flat @click="snackbar = false"> Fechar </v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import * as moment from 'moment';
import FilterCard from '@/components/filters/FilterCard.vue';
import datePicker from '@/components/date-time/DatePicker.vue';
import franchiseeSelect from '@/components/selects/FranchiseeSelect.vue';
import placeSelect from '@/components/selects/PlaceSelect.vue';
import poolSelect from '@/components/selects/PoolSelect.vue';
import clientSelect from '@/components/selects/ClientSelect.vue';
import { generateReport } from '../../services/ReportService';
/* import { error } from 'util';
 */
export default {
  data: () => ({
    fileFormat: localStorage.userRole === 'CLIENT' ? 'PDF' : 'CSV',
    selectedFranchisee: '',
    selectedClient: localStorage.userRole === 'CLIENT' ? localStorage.userId : '',
    selectedPlace: '',
    selectedPool: '',
    snackbarText: '',
    color: '',
    snackbar: false,
    timeout: 6000,
    menu: '',
    isLoading: false,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    selectedMonth: new Date().toISOString(),
    type: localStorage.userRole === 'CLIENT' ? 'ANALYZE' : '',
    types: [
      { name: 'Tratamento diário', value: 'DAILY_TRATAMENT' },
      { name: 'Condições da Piscina', value: 'POOL_CONDITIONS' },
      { name: 'Análises', value: 'ANALYZE' },
      { name: 'Análises com foto', value: 'ANALYZE_WITH_PHOTO' },
      { name: 'Deslocamento', value: 'DISPLACEMENT' },
      { name: 'Movimentação de estoque', value: 'TRANSFERENCES' },
      { name: 'Ponto', value: 'CLOCK_IN' },
      { name: 'Problema', value: 'PROBLEMS' },
      { name: 'Estoque', value: 'STOCK' },
      { name: 'Visitas', value: 'VISITS' },
    ],
    typesForClients: [
      { name: 'Análises', value: 'ANALYZE' },
      { name: 'Análises com foto', value: 'ANALYZE_WITH_PHOTO' },
      { name: 'Tratamento diário', value: 'DAILY_TRATAMENT' },
    ],
    formats: [
      { name: 'CSV', value: 'CSV' },
      { name: 'PDF', value: 'PDF' },
    ],
  }),
  computed: {
    isClient() {
      return localStorage.userRole === 'CLIENT';
    },
    isAdmin() {
      return localStorage.userRole === 'ADMIN';
    },
    isCoodinador() {
      return localStorage.userRole === 'COORDINADOR';
    },
  },
  methods: {
    async downloadReport() {
      const isPDF = this.fileFormat === 'PDF' || this.type === 'ANALYZE_WITH_PHOTO';
      this.isLoading = true;
      if (this.type === 'DAILY_TRATAMENT' || this.type === 'POOL_CONDITIONS') {
        this.startDate = moment(this.selectedMonth).startOf('month').toISOString();
        this.endDate = moment(this.selectedMonth).endOf('month').toISOString();
      } else if (!isPDF && !this.isClient) {
        this.selectedClient = null;
      }
      const variables = {
        beginning: moment(this.startDate).format('YYYY-MM-DDT00:00:00'),
        end: moment(this.endDate).format('YYYY-MM-DDT23:59:59'),
        ...(this.selectedFranchisee ? { franchiseeId: this.selectedFranchisee || null } : {}),
        ...(this.selectedClient ? { client: this.selectedClient || null } : {}),
        ...(this.selectedPool ? { poolId: this.selectedPool.id || null } : {}),
      };
      await generateReport(
        variables,
        this.type,
        isPDF,
        this.$apollo,
        (this.snackbarText = 'Relatório gerado com sucesso'),
        (this.color = 'green'),
      )
        .catch((error) => {
          if (error.message === 'No data to report.') {
            this.snackbarText = 'Não há dados para os filtros selecionados';
            this.color = 'red';
            this.snackbar = true;
          } else if (error.message === 'Network error: Failed to fetch') {
            this.snackbarText = 'Verifique sua conexão com a internet';
            this.color = 'red';
            this.snackbar = true;
          } else {
            throw error;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
      this.snackbar = true;
    },
    resetFilter() {
      this.type = '';
      this.selectedFranchisee = '';
      this.startDate = new Date().toISOString();
      this.endDate = new Date().toISOString();
    },
  },
  components: {
    clientSelect,
    franchiseeSelect,
    datePicker,
    FilterCard,
    placeSelect,
    poolSelect,
  },
  filters: {},
};
</script>

<style>
@media screen and (max-width: 960px) {
  #generateReport {
    width: 50%;
    font-size: 55%;
  }
  #listReports #toolbarButton {
    text-align: center;
  }
  #listReports .datePicker {
    font: caption;
  }
  #listReports .toolbarFilter {
    height: 125px;
  }
  #listReports #filterLayout {
    padding-top: 17%;
  }
}
</style>
