var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    attrs: {
      autocomplete: "off",
      "menu-props": "auto",
      items: _vm.roles,
      name: "role",
      label: "Tipo de usuário",
      value: _vm.value,
      "item-text": "name",
      "item-value": "value",
      rules: _vm.rules,
      disabled: _vm.isDisabled
    },
    on: {
      change: function($event) {
        return _vm.$emit("input", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }