var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ballon-container", on: { click: _vm.onMessageClick } },
    [
      _c("div", { class: _vm.fromMe ? "green-balloon" : "white-balloon" }, [
        _vm.fromMe && _vm.time
          ? _c("div", { staticClass: "blank-space" })
          : _vm._e(),
        !_vm.fromMe
          ? _c("div", { staticClass: "triangle-topright" })
          : _vm._e(),
        _c(
          "div",
          {
            class:
              (_vm.fromMe ? "from-me" : "from-contact") + " whatsapp-message"
          },
          [
            !_vm.isFile
              ? _c("div", { staticClass: "whatsapp-message-text" }, [
                  _vm.forwardText
                    ? _c("div", { staticClass: "forward" }, [
                        _c("img", {
                          attrs: { src: "/assets/icons/forward.svg" }
                        }),
                        _vm._v(_vm._s(_vm.forwardText) + "\n        ")
                      ])
                    : _vm._e(),
                  _vm.id
                    ? _c("div", { staticClass: "forward" }, [
                        _c("b", [_vm._v("ID:")]),
                        _vm._v(" " + _vm._s(_vm.id))
                      ])
                    : _vm._e(),
                  _c("div", { class: _vm.noWrap ? "no-wrap" : "pre-wrap" }, [
                    _vm._v(_vm._s(_vm.message))
                  ])
                ])
              : _vm._e(),
            _vm.isImage
              ? _c("div", [
                  _c("img", {
                    staticClass: "attached-media",
                    attrs: { src: _vm.mediaUrl }
                  })
                ])
              : _vm._e(),
            _vm.isVideo
              ? _c("div", [
                  _c(
                    "video",
                    { staticClass: "attached-media", attrs: { controls: "" } },
                    [_c("source", { attrs: { src: _vm.mediaUrl } })]
                  )
                ])
              : _vm._e(),
            _vm.isAudio
              ? _c("div", [
                  _c(
                    "audio",
                    { staticClass: "attached-media", attrs: { controls: "" } },
                    [_c("source", { attrs: { src: _vm.mediaUrl } })]
                  )
                ])
              : _vm._e(),
            _vm.time
              ? _c("div", { staticClass: "whatsapp-message-info" }, [
                  _c("div", { staticClass: "whatsapp-message-time" }, [
                    _vm._v(_vm._s(_vm.time))
                  ]),
                  _vm.fromMe
                    ? _c("div", { staticClass: "whatsapp-message-status" }, [
                        _c("img", {
                          class:
                            _vm.status === _vm.statusDict.ACK_ERROR
                              ? "red-svg"
                              : _vm.status === _vm.statusDict.ACK_PENDING ||
                                _vm.status === _vm.statusDict.ACK_SERVER
                              ? "gray-svg"
                              : "blue-svg",
                          attrs: { src: "/assets/icons/delivered.svg" }
                        })
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ]
        ),
        _vm.fromMe ? _c("div", { staticClass: "triangle-topleft" }) : _vm._e(),
        !_vm.fromMe && _vm.time
          ? _c("div", { staticClass: "blank-space" })
          : _vm._e()
      ]),
      _c("div")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }