<template>
  <v-card :width="windowWidth < 960 ? `${windowWidth}px` : '100%'">
    <v-toolbar color="#0d1824" dark flat>
      <v-toolbar-title>Cadastro de Local</v-toolbar-title>
      <div class="flex-grow-1"></div>
    </v-toolbar>
    <v-tabs color="#0d1824" dark v-if="idPlaceEdit" v-model="activeTab">
      <v-tab v-for="tab in tabButtons" @click="selectedTab = tab.value" :key="tab.name">
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <component
      v-if="idPlaceEdit"
      :is="selectedTab"
      :idPlaceEdit="idPlaceEdit"
      @showModal="showModal = $event"
    />
    <CreationPlaceTab v-else @showModal="showModal = $event" />
  </v-card>
</template>

<script>
import { isRole } from '@/services/UtilsService';

import PlaceTab from '@/components/places/tabs/PlaceTab.vue';
import StockTab from '@/components/places/tabs/StockTab.vue';
import PoolTab from '@/components/places/tabs/PoolTab.vue';
import EquipmentsTab from '@/components/places/tabs/EquipmentsTab.vue';
import ChecklistTab from '@/components/places/tabs/ChecklistTab.vue';
import CommitmentsTab from '@/components/places/tabs/CommitmentsTab.vue';

import VTabs from '@/components/utils/VTabs.vue';
import TabUsers from '@/components/places/tabs/TabUsers.vue';

export default {
  data: () => ({
    windowWidth: window.innerWidth,
    showModal: true,
    isClient: isRole('CLIENT'),
    selectedTab: '',
    tabButtons: [],
    activeTab: 0,
  }),
  watch: {
    showModal(showModal) {
      this.$emit('showModal', showModal);
    },
    idPlaceEdit() {
      this.activeTab = 0;
      this.selectedTab = 'PlaceTab';
      this.addIdToURL();
    },
  },
  methods: {
    addIdToURL() {
      if (this.idPlaceEdit) {
        window.history.replaceState(null, null, `?id=${this.idPlaceEdit}`);
      }
    },
    setTabButtons() {
      this.tabButtons = [
        { name: 'Local', value: PlaceTab },
        { name: 'Piscinas', value: PoolTab },
      ];
      if (!this.isClient && !isRole('LIMITED')) {
        this.tabButtons = this.tabButtons.concat([
          { name: 'Estoques', value: StockTab },
          { name: 'Tarefas', value: ChecklistTab },
          { name: 'Compromissos', value: CommitmentsTab },
          { name: 'Clientes', value: TabUsers },
        ]);
      }
      if (isRole('ADMIN') || isRole('COORDINADOR')) {
        this.tabButtons.push({ name: 'Equipamentos', value: EquipmentsTab });
      }
    },
  },
  created() {
    this.selectedTab = 'PlaceTab';
    this.setTabButtons();
  },
  components: {
    CommitmentsTab,
    EquipmentsTab,
    ChecklistTab,
    PlaceTab,
    TabUsers,
    CreationPlaceTab: PlaceTab,
    StockTab,
    PoolTab,
    VTabs,
  },
  props: ['idPlaceEdit'],
};
</script>
