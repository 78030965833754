var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "FilterCard",
        {
          attrs: { resetButton: true, searchButton: true },
          on: {
            clickOnReset: function($event) {
              return _vm.resetFilter()
            },
            clickOnSearch: function($event) {
              return _vm.loadTransferences()
            }
          }
        },
        [
          _c(
            "v-flex",
            {
              staticClass: "spaceBetweenFields",
              attrs: { xs12: "", xl3: "", md6: "" }
            },
            [
              _c("v-text-field", {
                staticClass: "mx-2",
                attrs: {
                  label: "Pesquise pelo colaborador",
                  id: "searchEmployeeName"
                },
                model: {
                  value: _vm.filter.employeeName,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "employeeName", $$v)
                  },
                  expression: "filter.employeeName"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticClass: "spaceBetweenFields",
              attrs: { xs12: "", xl3: "", md6: "" }
            },
            [
              _c("v-text-field", {
                staticClass: "mx-2",
                attrs: { label: "Pesquise pelo local", id: "searchLocalName" },
                model: {
                  value: _vm.filter.localName,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "localName", $$v)
                  },
                  expression: "filter.localName"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticClass: "spaceBetweenFields",
              attrs: { xs12: "", xl2: "", md4: "", id: "searchByType" }
            },
            [
              _c("v-autocomplete", {
                staticClass: "mx-2",
                attrs: {
                  autocomplete: "off",
                  "menu-props": "auto",
                  items: _vm.types,
                  label: "Pesquise pelo tipo",
                  "item-text": "name",
                  "item-value": "value"
                },
                model: {
                  value: _vm.filter.type,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "type", $$v)
                  },
                  expression: "filter.type"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", xl2: "", md4: "" } },
            [
              _c("datePicker", {
                staticClass: "mx-2",
                attrs: { label: "Data inicial" },
                model: {
                  value: _vm.filter.startDate,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "startDate", $$v)
                  },
                  expression: "filter.startDate"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", xl2: "", md4: "" } },
            [
              _c("datePicker", {
                staticClass: "mx-2",
                attrs: { label: "Data final" },
                model: {
                  value: _vm.filter.endDate,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "endDate", $$v)
                  },
                  expression: "filter.endDate"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("CardList", {
        attrs: {
          isDescending: true,
          sortBy: "date",
          isLoading: _vm.isLoading,
          contentList: _vm.filteredTrasferenceTable
        },
        on: { click: transference => _vm.openDetailsModal(transference.id) }
      }),
      _c(
        "CardModal",
        {
          attrs: { width: "65%" },
          model: {
            value: _vm.showDetailsModal,
            callback: function($$v) {
              _vm.showDetailsModal = $$v
            },
            expression: "showDetailsModal"
          }
        },
        [
          _c("TransferenceDetails", {
            attrs: { transferenceId: _vm.selectedTransferenceId },
            on: {
              showModal: function($event) {
                _vm.showDetailsModal = $event
              }
            }
          })
        ],
        1
      ),
      _c(
        "CardModal",
        {
          attrs: { width: "65%" },
          model: {
            value: _vm.showCreateModal,
            callback: function($$v) {
              _vm.showCreateModal = $$v
            },
            expression: "showCreateModal"
          }
        },
        [
          _c("TransferenceCreation", {
            on: {
              showModal: function($event) {
                _vm.showCreateModal = $event
              }
            }
          })
        ],
        1
      ),
      !_vm.isClient
        ? _c(
            "v-btn",
            {
              attrs: {
                fab: "",
                bottom: "",
                right: "",
                color: "#A6CE39",
                dark: "",
                fixed: "",
                title: "Novo",
                id: "create"
              },
              on: {
                click: function($event) {
                  return _vm.openCreateModal()
                }
              }
            },
            [_c("v-icon", [_vm._v("add")])],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }