<template>
  <v-autocomplete
    autocomplete="off"
    menu-props="auto"
    :items="roles"
    name="role"
    label="Tipo de usuário"
    :value="value"
    @change="$emit('input', $event)"
    item-text="name"
    item-value="value"
    :rules="rules"
    :disabled="isDisabled"
  />
</template>
<script>
import { isRole } from '@/services/UtilsService';

export default {
  data: () => ({
    allRoles: [
      { name: 'Administrador', value: 'ADMIN', authorizationToCreate: ['ADMIN'] },
      {
        name: 'Coordenador',
        value: 'COORDINADOR',
        authorizationToCreate: ['ADMIN', 'COORDINADOR'],
      },
      { name: 'Franqueado', value: 'FRANCHISEE', authorizationToCreate: ['ADMIN', 'COORDINADOR'] },
      {
        name: 'Colaborador',
        value: 'EMPLOYEE',
        authorizationToCreate: ['ADMIN', 'COORDINADOR', 'FRANCHISEE'],
      },
      {
        name: 'Cliente',
        value: 'CLIENT',
        authorizationToCreate: ['ADMIN', 'COORDINADOR', 'FRANCHISEE', 'ASSOCIATE', 'LIMITED'],
      },
      { name: 'Associado', value: 'ASSOCIATE', authorizationToCreate: ['ADMIN', 'COORDINADOR'] },
      { name: 'Limitado', value: 'LIMITED', authorizationToCreate: ['ADMIN', 'COORDINADOR'] },
    ],
  }),
  computed: {
    roles() {
      const allRolesfilterIncludeAll = this.allRoles.filter(
        (role) => this.includeOptionAll || role.value !== 'ALL',
      );
      return allRolesfilterIncludeAll.filter((role) =>
        role.authorizationToCreate.find((authorizadRole) => isRole(authorizadRole)),
      );
    },
  },
  props: ['value', 'rules', 'isDisabled', 'includeOptionAll'],
};
</script>
