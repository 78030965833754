<template slot-scope="{ result: { loading, error, data } }">
  <v-container id="listCheck">
    <FilterCard
      @clickOnReset="resetFilter()"
      :searchButton="true"
      @clickOnSearch="queryAndSetData()"
      :resetButton="true"
    >
      <v-flex xs12 md6 xl4>
        <v-text-field
          class="mx-2"
          label="Pesquise pelo colaborador"
          v-model="employeSearchTerm"
          id="employeSearch"
        />
      </v-flex>
      <v-flex xs12 md6 xl3>
        <v-text-field
          class="mx-2"
          label="Pesquise pelo local"
          v-model="localSearchTerm"
          id="localSearch"
        />
      </v-flex>
      <v-flex xs12 md4 xl1 v-if="!isClient()" id="searchByType">
        <v-select
          class="mx-2"
          menu-props="auto"
          :items="types"
          v-model="type"
          item-value="value"
          item-text="name"
          :change="typeFilter(type)"
          label="Pesquise pelo tipo"
        />
      </v-flex>
      <v-flex xs12 md4 xl2>
        <datePicker class="datePicker mx-2" label="Data inicial" v-model="startDate"></datePicker>
      </v-flex>
      <v-flex xs12 md4 xl2>
        <datePicker class="datePicker mx-2" label="Data final" v-model="endDate"></datePicker>
      </v-flex>
    </FilterCard>
    <div class="text-xs-center">
      <v-progress-circular v-if="!data" indeterminate color="primary" class="loader ma-5" />
    </div>
    <v-layout row wrap pt-4 v-if="data">
      <v-flex order-xs12 lg7>
        <v-card>
          <!-- Cabeçalho tabela -->
          <v-toolbar>
            <v-layout row class="toolbar">
              <v-flex xs6 lg4>
                <v-toolbar-title>Nome</v-toolbar-title>
              </v-flex>
              <v-flex xs4 lg4 class="hideMobile">
                <v-toolbar-title>Local</v-toolbar-title>
              </v-flex>
              <v-flex xs3 lg1>
                <v-toolbar-title>Início</v-toolbar-title>
              </v-flex>
              <v-flex xs3 lg1>
                <v-toolbar-title>Fim</v-toolbar-title>
              </v-flex>
              <v-flex xs3 lg1>
                <v-toolbar-title>Atraso</v-toolbar-title>
              </v-flex>
              <v-flex xs2 lg2 class="hideMobile">
                <v-toolbar-title>Duração</v-toolbar-title>
              </v-flex>
            </v-layout>
          </v-toolbar>
          <v-list xs12 id="dataCheck">
            <clock-check
              v-for="(line, index) in data"
              :key="index"
              :header="line.header"
              :content="line.content"
            ></clock-check>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex xs12 order-lg12 lg5 pb-4>
        <v-card class="no-margin-mobile ml-4">
          <googleMaps
            :center="mapCenter"
            v-if="data"
            :defaultMarkers="markers"
            :localMarkers="markersPlaces"
            class="map"
          ></googleMaps>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar v-model="snackbar" :right="true" :timeout="timeout">
      {{ textSnackbar }}
      <v-btn color="blue" flat @click="snackbar = false">Fechar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import googleMaps from '@/components/maps/GoogleMaps.vue';
import * as moment from 'moment';

import listPlacesActive from '@/graphql/places/listPlacesActive.gql';
import FilterCard from '@/components/filters/FilterCard.vue';
import datePicker from '@/components/date-time/DatePicker.vue';
import { normalizeString } from '@/services/UtilsService';
import { getVisitListForClient, getVisitList } from '@/services/VisitService';
import { getClockInList } from '@/services/ClockInService';
import { getVisitCommitments } from '@/services/CommitmentsService';

export default {
  data: () => ({
    commitmentCalendar: [],
    mapCenter: { lat: -15.0, lng: -47.0 },
    snackbar: '',
    textSnackbar: '',
    timeout: 6000,
    data: '',
    markersDataList: [],
    dataPlaces: [],
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    employeSearchTerm: '',
    localSearchTerm: '',
    typeFilterSelected: '',
    type: 'ALL',
    types: [
      { name: 'Todas', value: 'ALL' },
      { name: 'Ponto', value: 'clockIn' },
      { name: 'Visita', value: 'visits' },
    ],
    patternDate: true,
  }),
  computed: {
    startTime() {
      return moment(this.startDate)
        .startOf('day')
        .toISOString();
    },
    endTime() {
      return moment(this.endDate)
        .endOf('day')
        .toISOString();
    },
    markers() {
      return this.markersDataList.reduce((markersList, clockIn) => {
        markersList.push({
          position: { lat: Number(clockIn.latStart), lng: Number(clockIn.lngStart) },
          title: this.getMarkerText(clockIn),
        });
        if (clockIn.ends) {
          markersList.push({
            position: { lat: Number(clockIn.latEnds), lng: Number(clockIn.lngEnds) },
            title: this.getMarkerText(clockIn, true),
          });
        }
        return markersList;
      }, []);
    },
    markersPlaces() {
      return this.dataPlaces.map((place) => ({
        position: { lat: Number(place.address.latitude), lng: Number(place.address.longitude) },
        title: place.localName,
      }));
    },
  },
  components: {
    googleMaps,
    FilterCard,
    datePicker,
  },
  methods: {
    getDates(startDate, stopDate) {
      const dateArray = [];
      let currentDate = moment(startDate);
      const stopDateMoment = moment(stopDate);
      while (currentDate <= stopDateMoment) {
        dateArray.push(currentDate.format('YYYY-MM-DD'));
        currentDate = moment(currentDate).add(1, 'days');
      }
      return dateArray;
    },
    async setCommitmentCalendar() {
      const dateList = this.getDates(this.startDate, this.endDate);
      const commitments = await getVisitCommitments(this.$apollo, {
        start: this.startTime,
        end: this.endTime,
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6]
          .filter((value) => dateList.some((date) => moment(date).day() === value))
          .map((value) => ({ value })),
      });
      this.commitmentCalendar = dateList
        .map((date) => ({
          commitments: commitments
            .filter((commitment) =>
              commitment.daysOfWeek.some((dayOfWeek) => dayOfWeek.value === moment(date).day()),
            )
            .sort((a, b) => a.startHour * 60 + a.startMinute - (b.startHour * 60 + b.startMinute)),
          date,
        }))
        .filter((day) => day.commitments.length);
    },
    isClient() {
      return localStorage.userRole === 'CLIENT';
    },
    alertSnack(text) {
      this.snackbar = true;
      this.textSnackbar = text;
    },
    async setDataToClient(start, ends) {
      const variables = {
        start,
        ends,
        id: localStorage.userId,
      };

      const visits = await getVisitListForClient(this.$apollo, variables);

      let listVisits = visits.user.places.flatMap((local) => local.visits);

      if (this.employeSearchTerm) {
        listVisits = listVisits.filter((visit) =>
          this.findStringPart(visit.employee.name, this.employeSearchTerm),
        );
      }
      if (this.localSearchTerm) {
        listVisits = listVisits.filter((visit) =>
          this.findStringPart(visit.local.localName, this.localSearchTerm),
        );
      }

      this.data = [{ content: listVisits, header: undefined }];
      this.markersDataList = listVisits;
    },
    async queryAndSetData() {
      this.data = '';
      const variables = {
        start: this.startTime,
        ends: this.endTime,
      };

      if (this.isClient()) {
        return this.setDataToClient(variables.start, variables.ends);
      }

      const calendarPromise = this.setCommitmentCalendar();
      const respVisits =
        this.typeFilterSelected === 'clockIn'
          ? { visits: [] }
          : await getVisitList(this.$apollo, variables);
      const respClockIns =
        this.typeFilterSelected === 'visits'
          ? { clockIns: [] }
          : await getClockInList(this.$apollo, variables);
      await calendarPromise;
      return this.setData(respVisits.visits, respClockIns.clockIns);
    },
    getMarkerText(clockIn, isEnds) {
      const actionText = isEnds ? 'finalizad' : 'iniciad';
      const text = `${
        clockIn.local
          ? `Visita ${actionText}a por ${clockIn.employee.name} em ${clockIn.local.localName} `
          : `Ponto ${actionText}o por ${clockIn.employee.name} `
      }`;
      const dateTimeText = `${(isEnds ? moment(clockIn.ends) : moment(clockIn.start)).format(
        'DD/MM/YYYY HH:mm',
      )}`;
      return text + dateTimeText;
    },
    resetFilter() {
      this.localSearchTerm = '';
      this.employeSearchTerm = '';
      this.type = '';
      this.startDate = this.isClient()
        ? moment(moment(moment.now()).subtract(1, 'months')).format('YYYY-MM-DD')
        : moment(moment.now()).format('YYYY-MM-DD');
      this.endDate = moment(moment.now()).format('YYYY-MM-DD');
    },
    typeFilter(type) {
      this.typeFilterSelected = type;
    },
    findStringPart(text, part) {
      return !part || normalizeString(text).indexOf(normalizeString(part)) >= 0;
    },
    organizeData(clockIns, visits) {
      return clockIns.map((clockIn, clockInIndex, clockInList) => {
        const visitsFromClockIn = visits
          .filter((visit) => clockIn.employee.id === visit.employee.id)
          .filter((visit) =>
            moment(visit.startInCell).isBetween(
              clockIn.startInCell,
              clockIn.endsInCell ||
                (clockInList[clockInIndex + 1] && clockInList[clockInIndex + 1].startInCell) ||
                moment.now(),
              null,
              '[]',
            ),
          );
        return { header: clockIn, content: visitsFromClockIn };
      });
    },
    findVisitsWithoutClockIn(visits, clockIns) {
      return visits.filter(
        (visit) =>
          !clockIns.some(
            (clockIn) =>
              clockIn.employee.id === visit.employee.id &&
              moment(visit.startInCell).isBetween(
                clockIn.startInCell,
                clockIn.endsInCell ? clockIn.endsInCell : moment.now(),
                null,
                '[]',
              ),
          ),
      );
    },
    filterByEmployeeName(visits, clockIns) {
      let filteredVisits = visits;
      let filteredClockIns = clockIns;
      if (this.employeSearchTerm) {
        this.mapCenter = null;
        filteredVisits = visits.filter((visit) =>
          this.findStringPart(visit.employee.name, this.employeSearchTerm),
        );
        filteredClockIns = clockIns.filter((clockIn) =>
          this.findStringPart(clockIn.employee.name, this.employeSearchTerm),
        );
      }
      return { filteredVisits, filteredClockIns };
    },
    filterByLocalName(visits, clockIns) {
      let filteredVisits = visits;
      let filteredClockIns = clockIns;
      if (this.localSearchTerm) {
        this.mapCenter = null;
        filteredVisits = visits.filter((visit) =>
          this.findStringPart(visit.local.localName, this.localSearchTerm),
        );
        filteredClockIns = clockIns.filter((clockIn) =>
          filteredVisits.find(
            (visit) =>
              visit.employee.id === clockIn.employee.id &&
              moment(visit.start).isBetween(
                clockIn.start,
                clockIn.ends ? clockIn.ends : moment.now(),
                null,
                '[]',
              ),
          ),
        );
      }
      return { filteredVisits, filteredClockIns };
    },
    setData(visits, clockIns) {
      visits = this.addDelayToVisits(visits);
      visits = this.addDelayToList(visits);
      const filteredByEmployeeName = this.filterByEmployeeName(visits, clockIns);
      const { filteredVisits, filteredClockIns } = this.filterByLocalName(
        filteredByEmployeeName.filteredVisits,
        filteredByEmployeeName.filteredClockIns,
      );

      if (this.typeFilterSelected === 'visits') {
        this.data = [{ content: filteredVisits, header: undefined }];
        this.markersDataList = filteredVisits;
        return true;
      }

      if (this.typeFilterSelected === 'clockIn') {
        this.data = [{ content: filteredClockIns, header: undefined }];
        this.markersDataList = filteredClockIns;
        return true;
      }

      this.markersDataList = filteredVisits.concat(filteredClockIns);

      let visitsWithoutClockIn = this.findVisitsWithoutClockIn(filteredVisits, filteredClockIns);
      visitsWithoutClockIn = visitsWithoutClockIn.map((visit) => ({
        header: null,
        content: [visit],
      }));

      this.data = this.organizeData(filteredClockIns, filteredVisits);
      this.data = this.data.concat(visitsWithoutClockIn);
      if (!this.data.length && (this.employeSearchTerm || this.localSearchTerm)) {
        this.alertSnack('Não há resultados para esta pesquisa.');
      }
      this.data = this.data.sort((a, b) => {
        const aDateTime = a.header ? a.header.start : a.content[0].start;
        const bDateTime = b.header ? b.header.start : b.content[0].start;
        return moment(aDateTime).diff(bDateTime);
      });

      return this.data;
    },
    addDelayToVisits(visits) {
      visits.map((visit) => {
        const visitDay = this.commitmentCalendar.find(
          (day) => day.date === moment(visit.start).format('YYYY-MM-DD'),
        );
        if (visitDay) {
          const commitmentIndex = visitDay.commitments.findIndex(
            (commitment) =>
              commitment.employee.id === visit.employee.id &&
              commitment.local.id === visit.local.id,
          );
          if (commitmentIndex > -1) {
            const commitment = visitDay.commitments.splice(commitmentIndex, 1)[0];
            visit.delay = this.calcDelay(commitment, visitDay.date, visit.start);
          }
        }
        return visit;
      });
      this.commitmentCalendar = this.commitmentCalendar.filter((day) => day.commitments.length);
      return visits;
    },
    calcDelay(commitment, visitDate, time) {
      let delay = moment(time).diff(
        `${visitDate} ${commitment.startHour}:${commitment.startMinute} +0000`,
      );
      if (delay > 0) {
        delay = moment.duration(delay);
        return delay.days() > 1
          ? `${delay.days()} dias`
          : `${delay.hours()}h:${`0${delay.minutes()}`.slice(-2)}m`;
      }
      return '';
    },
    addDelayToList(visits) {
      this.commitmentCalendar.forEach((day) =>
        day.commitments.forEach((commitment) => {
          const delay = this.calcDelay(commitment, day.date);
          if (delay) {
            visits.push({
              employee: commitment.employee,
              local: commitment.local,
              delay,
            });
          }
        }),
      );
      return visits;
    },
  },
  beforeMount() {
    this.queryAndSetData();
  },
  created() {
    this.$root.$apolloProvider.defaultClient
      .query({
        fetchPolicy: 'no-cache',
        query: listPlacesActive,
      })
      .then((resp) => {
        this.dataPlaces = resp.data.locals;
      });
  },
};
</script>

<style>
.alignment {
  text-align: center;
}
.headerList {
  font-size: large;
}
#listCheck .map {
  width: 100%;
  height: 700px;
}
#listCheck .toolbar {
  text-align: center;
}

@media screen and (max-width: 1263px) {
  #listCheck .map {
    width: 100%;
    height: 280px;
  }
  #listCheck .hideMobile {
    display: none;
  }
  #listCheck .no-margin-mobile {
    margin: 0px !important;
  }
  #listCheck .datePicker {
    font: caption;
  }
}
</style>
