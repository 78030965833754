var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _vm.isToolbarVisible
        ? _c(
            "v-toolbar",
            { attrs: { color: "#0d1824", dark: "", flat: "" } },
            [_c("v-toolbar-title", [_vm._v("Cadastro de Usuário")])],
            1
          )
        : _vm._e(),
      !_vm.isDataLoading
        ? _c(
            "v-layout",
            { attrs: { "mx-2": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-switch", {
                    staticStyle: { float: "right" },
                    attrs: {
                      label: _vm.formData.isEnabled
                        ? "Usuário ativo"
                        : "Usuário inativo"
                    },
                    model: {
                      value: _vm.formData.isEnabled,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "isEnabled", $$v)
                      },
                      expression: "formData.isEnabled"
                    }
                  }),
                  _vm.idUserEdit
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ma-3",
                          staticStyle: { float: "right" },
                          attrs: {
                            loading: _vm.isLoadingResetPassword,
                            color: "secundary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.resetPassword()
                            }
                          }
                        },
                        [_vm._v("Recuperar senha")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-container",
        { attrs: { "grid-list-xl": "" } },
        [
          !_vm.isDataLoading
            ? _c(
                "v-form",
                { ref: "form" },
                [
                  _c(
                    "v-layout",
                    [
                      _c("v-icon", [_vm._v("person")]),
                      _c("h3", { staticClass: "ml-2" }, [
                        _vm._v("Dados Pessoais")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { md6: "", sm12: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "nameUser",
                              placeholder: "Nome",
                              autocomplete: "off",
                              rules: [_vm.validationRequired]
                            },
                            model: {
                              value: _vm.formData.firstName,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "firstName", $$v)
                              },
                              expression: "formData.firstName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { md6: "", sm12: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "lastName",
                              placeholder: "Sobrenome",
                              rules: [_vm.validationRequired]
                            },
                            model: {
                              value: _vm.formData.lastName,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "lastName", $$v)
                              },
                              expression: "formData.lastName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { md6: "", sm12: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "emailUser",
                              "prepend-icon": "mail_outline",
                              placeholder: "E-mail",
                              type: "email",
                              rules: [_vm.validationEmail]
                            },
                            model: {
                              value: _vm.formData.email,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "email", $$v)
                              },
                              expression: "formData.email"
                            }
                          })
                        ],
                        1
                      ),
                      !_vm.idUserEdit || _vm.isAdmin
                        ? _c(
                            "v-flex",
                            { attrs: { md6: "", sm12: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  autocomplete: "new-password",
                                  "append-icon": _vm.showPassword
                                    ? "visibility_off"
                                    : "visibility",
                                  "prepend-icon": "lock_outline",
                                  id: "passwordField",
                                  placeholder: "Senha",
                                  type: _vm.showPassword ? "password" : "text",
                                  rules: [
                                    value => () => _vm.validationPassword(value)
                                  ]
                                },
                                on: {
                                  "click:append": function($event) {
                                    _vm.showPassword = !_vm.showPassword
                                  }
                                },
                                model: {
                                  value: _vm.formData.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "password", $$v)
                                  },
                                  expression: "formData.password"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isLimited
                        ? _c(
                            "v-flex",
                            { attrs: { md6: "", sm12: "" } },
                            [
                              _c("RolesSelect", {
                                attrs: { rules: [_vm.validationRequired] },
                                on: {
                                  input: value => {
                                    if (
                                      value === "FRANCHISEE" ||
                                      _vm.currentUser.selectedRole ===
                                        "FRANCHISEE"
                                    ) {
                                      _vm.formData.placesRemoved = _vm.formData.places.map(
                                        place => ({ id: place.id })
                                      )
                                      _vm.formData.places = []
                                    }
                                  }
                                },
                                model: {
                                  value: _vm.formData.selectedRole,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "selectedRole", $$v)
                                  },
                                  expression: "formData.selectedRole"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-flex",
                        { attrs: { md6: "", sm12: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "cpfCnpj",
                              placeholder: "CPF/CNPJ",
                              minlength: "11",
                              maxlength: "14",
                              type: "text",
                              mask: "###.###.###-##"
                            },
                            model: {
                              value: _vm.formData.cpfCnpj,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "cpfCnpj", $$v)
                              },
                              expression: "formData.cpfCnpj"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { "align-center": "" } },
                    [
                      _c("v-icon", [_vm._v("location_on")]),
                      _c("h3", { staticClass: "ml-2" }, [_vm._v("Locais")])
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _vm.formData.selectedRole !== "FRANCHISEE" &&
                      !_vm.isLimited
                        ? _c(
                            "v-flex",
                            { attrs: { md6: "", sm12: "" } },
                            [
                              _c("FranchiseeSelect", {
                                attrs: {
                                  disabled: !_vm.isAdmin && !_vm.isCoordinador
                                },
                                on: {
                                  input: function($event) {
                                    _vm.formData.places = []
                                  }
                                },
                                model: {
                                  value: _vm.formData.selectedFranchiseeId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "selectedFranchiseeId",
                                      $$v
                                    )
                                  },
                                  expression: "formData.selectedFranchiseeId"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.formData.selectedRole !== "FRANCHISEE"
                        ? _c(
                            "v-flex",
                            { attrs: { md6: "", sm12: "" } },
                            [
                              _c("PlaceSelect", {
                                attrs: {
                                  franchisee: _vm.formData.selectedFranchiseeId,
                                  id: "selectPlaces"
                                },
                                model: {
                                  value: _vm.formData.selectedPlace,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "selectedPlace", $$v)
                                  },
                                  expression: "formData.selectedPlace"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.formData.selectedRole !== "FRANCHISEE"
                        ? _c(
                            "v-flex",
                            { attrs: { md6: "", sm12: "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { width: "100%", margin: "0px" },
                                  attrs: {
                                    disabled: !_vm.formData.selectedPlace,
                                    color: "secundary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.addLocal()
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", [_vm._v("add")]),
                                  _vm._v(" Adicionar Local\n          ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-flex",
                        { attrs: { md6: "", sm12: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: { width: "100%", margin: "0px" },
                              attrs: { color: "secundary" },
                              on: {
                                click: function($event) {
                                  _vm.showLocalCreation = true
                                }
                              }
                            },
                            [
                              _c("v-icon", [_vm._v("add")]),
                              _vm._v(" Novo Local\n          ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.formData.places, function(place, index) {
                    return _c(
                      "v-layout",
                      {
                        key: "place" + index,
                        attrs: { "align-center": "", wrap: "" }
                      },
                      [
                        _c("v-flex", { attrs: { md6: "", sm10: "" } }, [
                          _c("h3", { staticClass: "d-inline" }, [
                            _vm._v(_vm._s(place.localName))
                          ])
                        ]),
                        _c(
                          "v-flex",
                          { attrs: { md6: "", sm2: "" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeLocal(index)
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("close")])],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _c("br"),
                  _c(
                    "v-layout",
                    { attrs: { "align-center": "" } },
                    [
                      _c("v-icon", [_vm._v("phone")]),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("h3", { staticClass: "d-inline ml-2" }, [
                          _vm._v("Contatos")
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { md6: "", sm12: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "contactName",
                              label: "Nome do contato",
                              type: "text"
                            },
                            model: {
                              value: _vm.formData.newPhone.contactName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formData.newPhone,
                                  "contactName",
                                  $$v
                                )
                              },
                              expression: "formData.newPhone.contactName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { md1: "", sm2: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "dddPhone",
                              label: "DDD",
                              minlength: "2",
                              maxlength: "2",
                              type: "text",
                              mask: "(##)"
                            },
                            model: {
                              value: _vm.formData.newPhone.ddd,
                              callback: function($$v) {
                                _vm.$set(_vm.formData.newPhone, "ddd", $$v)
                              },
                              expression: "formData.newPhone.ddd"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { md5: "", sm10: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "numberPhone",
                              label: "Número",
                              type: "text",
                              mask: "#####-####"
                            },
                            model: {
                              value: _vm.formData.newPhone.telephoneNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formData.newPhone,
                                  "telephoneNumber",
                                  $$v
                                )
                              },
                              expression: "formData.newPhone.telephoneNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { md12: "", sm12: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled:
                                  !_vm.formData.newPhone.contactName ||
                                  !_vm.formData.newPhone.ddd ||
                                  _vm.formData.newPhone.telephoneNumber.length <
                                    9,
                                id: "addPhone",
                                color: "secundary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addPhone()
                                }
                              }
                            },
                            [
                              _c("v-icon", [_vm._v("add")]),
                              _vm._v(" Adiconar Número\n          ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.formData.telephones, function(phone, index) {
                    return _c(
                      "v-layout",
                      {
                        key: "phone" + index,
                        attrs: { "align-center": "", wrap: "" }
                      },
                      [
                        _c("v-flex", { attrs: { md5: "", sm11: "" } }, [
                          _c("h3", { staticClass: "d-inline" }, [
                            _vm._v(_vm._s(phone.contactName))
                          ])
                        ]),
                        _c("v-flex", { attrs: { md3: "", sm9: "" } }, [
                          _c("h3", { staticClass: "d-inline" }, [
                            _vm._v(
                              "(" +
                                _vm._s(phone.ddd) +
                                ") " +
                                _vm._s(phone.telephoneNumber)
                            )
                          ])
                        ]),
                        _c(
                          "v-flex",
                          { attrs: { md2: "", sm2: "" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.removePhone(index)
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("close")])],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _vm.isAdmin || _vm.isCoordinador
                    ? _c(
                        "v-flex",
                        { attrs: { "ma-2": "", xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: "Receber mensagens por Whatsapp"
                                },
                                model: {
                                  value: _vm.formData.hasInstantMessageEnabled,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "hasInstantMessageEnabled",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formData.hasInstantMessageEnabled"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm.isCloseButtonVisible
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", flat: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeModal()
                                }
                              }
                            },
                            [_vm._v("Fechar")]
                          )
                        : _vm._e(),
                      (_vm.isCoordinador || _vm.isAdmin) && _vm.idUserEdit
                        ? _c(
                            "v-btn",
                            {
                              attrs: { outline: "", color: "primary" },
                              on: { click: _vm.openChat }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("support")
                              ]),
                              _vm._v("Suporte")
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "saveUser",
                            loading: _vm.isSubmitLoading,
                            color: "primary",
                            disabled: !_vm.isEnabledSubmit
                          },
                          on: {
                            click: function($event) {
                              return _vm.submit()
                            }
                          }
                        },
                        [_vm._v("\n          Salvar\n        ")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e(),
          _vm.isDataLoading
            ? _c("LoadingCircle", { staticClass: "mt-1" })
            : _vm._e()
        ],
        1
      ),
      _c(
        "CardModal",
        {
          attrs: { width: "45%" },
          model: {
            value: _vm.showLocalCreation,
            callback: function($$v) {
              _vm.showLocalCreation = $$v
            },
            expression: "showLocalCreation"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "#0d1824", dark: "", flat: "" } },
                [
                  _c("v-toolbar-title", [_vm._v("Cadastro de Local")]),
                  _c("div", { staticClass: "flex-grow-1" })
                ],
                1
              ),
              _c("PlaceTab", {
                attrs: { inUserCreation: true },
                on: {
                  showModal: function($event) {
                    _vm.showLocalCreation = $event
                  },
                  variablesLocalCreation: function($event) {
                    _vm.variablesLocalCreation = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }