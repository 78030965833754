<template>
  <v-card :width="windowWidth < 960 ? `${windowWidth}px` : '100%'">
    <v-toolbar color="#0d1824" dark flat
      ><v-toolbar-title>Cadastro de Equipamento</v-toolbar-title></v-toolbar
    >
    <v-container>
      <v-flex id="selectType">
        <v-autocomplete
          autocomplete="off"
          menu-props="auto"
          :items="types"
          v-model="selectedType"
          item-value="value"
          item-text="name"
          label="Selecione o tipo *"
        />
      </v-flex>
      <v-text-field
        v-if="selectedType === 'OUTRO'"
        :disabled="isLoading"
        label="Escreva o tipo *"
        v-model="textFieldType"
        id="textFieldType"
      />
      <v-text-field
        :disabled="isLoading"
        :label="'Marca'"
        v-model="equipmentDetails.brand"
        :id="'brandField'"
      />
      <v-text-field
        :disabled="isLoading"
        :label="'Modelo'"
        v-model="equipmentDetails.model"
        :id="'modelField'"
      />
      <v-text-field
        v-if="selectedType !== 'Filtro'"
        :disabled="isLoading"
        :label="'Pontência'"
        v-model="equipmentDetails.power"
        :id="'powerField'"
      />
      <v-flex id="tensionSelect">
        <v-autocomplete
          autocomplete="off"
          v-if="selectedType !== 'Filtro'"
          menu-props="auto"
          :items="tensions"
          v-model="equipmentDetails.tension"
          item-value="value"
          item-text="name"
          :label="'Tensão'"
        />
      </v-flex>
      <v-flex id="currentSelect">
        <v-autocomplete
          autocomplete="off"
          v-if="selectedType !== 'Filtro'"
          menu-props="auto"
          :items="currents"
          v-model="equipmentDetails.current"
          item-value="value"
          item-text="name"
          :label="'Corrente'"
        />
      </v-flex>
      <v-text-field
        v-if="selectedType !== 'Bomba'"
        :disabled="isLoading"
        :label="'Diâmetro (cm)'"
        v-model="equipmentDetails.diameter"
        :id="'diameterField'"
        type="number"
      />
      <v-text-field
        v-if="selectedType !== 'Bomba'"
        :disabled="isLoading"
        :label="'Área (m²)'"
        v-model="equipmentDetails.area"
        :id="'areaField'"
        type="number"
      />
      <v-text-field
        v-if="selectedType !== 'Bomba'"
        :disabled="isLoading"
        :label="'Carga (kg)'"
        v-model="equipmentDetails.load"
        :id="'loadField'"
        type="number"
      />
    </v-container>
    <v-card-actions>
      <v-btn
        @click="updateEquipmentStatus(!isDisabled)"
        flat
        v-if="equipmentId && !this.isLoading && !isFranchisee()"
        color="primary"
        id="disabledButton"
        >{{ isDisabled ? 'Ativar Equipamento' : 'Desativar Equipamento' }}</v-btn
      >
      <v-spacer />
      <v-btn
        flat
        color="primary"
        @click="closeModal()"
        :disabled="isLoading"
        v-on:keyup.enter="closeModal()"
        id="closeModal"
        >Fechar</v-btn
      >
      <v-btn
        id="submitEquipment"
        :disabled="isLoading || (!selectedType && !textFieldType)"
        :loading="isLoading"
        color="primary"
        @click="submit()"
        >Salvar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  saveEquipmentDetails,
  updateEquipmentDetailsStatus,
  updateEquipmentDetails,
} from '@/services/EquipmentService';

export default {
  data: () => ({
    windowWidth: window.innerWidth,
    types: [
      { name: 'Filtro', value: 'Filtro' },
      { name: 'Bomba', value: 'Bomba' },
      { name: 'Outro', value: 'OUTRO' },
    ],
    tensions: [
      { name: '110 V', value: '110V' },
      { name: '220 V', value: '220V' },
      { name: '380 V', value: '380V' },
      { name: '110 / 220 V', value: '110/220V' },
      { name: '220 / 380 V', value: '220V/380V' },
    ],
    currents: [
      { name: 'Monofásica', value: 'Monofásica' },
      { name: 'Trifásicas', value: 'Trifásicas' },
    ],
    isLoading: false,
    isDisabled: false,
    equipmentId: '',
    selectedType: '',
    textFieldType: '',
    equipmentDetails: {
      brand: '',
      model: '',
      power: '',
      tension: '',
      current: '',
      diameter: '',
      area: '',
      load: '',
    },
  }),
  computed: {},
  methods: {
    reset() {
      this.isLoading = false;
      this.isDisabled = false;
      this.equipmentId = '';
      this.selectedType = '';
      this.textFieldType = '';
      this.equipmentDetails = this.$options.data().equipmentDetails;
    },
    isFranchisee() {
      return localStorage.userRole === 'FRANCHISEE';
    },
    async updateEquipmentStatus(status) {
      this.isLoading = true;
      await updateEquipmentDetailsStatus(this.$apollo, {
        id: this.equipmentId,
        isDisabled: status,
      });
      this.closeModal();
    },
    closeModal() {
      this.$emit('showModal', false);
    },
    async submit() {
      const variables = {
        type: this.selectedType === 'OUTRO' ? this.textFieldType : this.selectedType,
        brand: this.equipmentDetails.brand,
        model: this.equipmentDetails.model,
        power: this.selectedType === 'Filtro' ? null : this.equipmentDetails.power,
        tension: this.selectedType === 'Filtro' ? null : this.equipmentDetails.tension,
        current: this.selectedType === 'Filtro' ? null : this.equipmentDetails.current,
        diameter: this.selectedType === 'Bomba' ? null : this.equipmentDetails.diameter,
        area: this.selectedType === 'Bomba' ? null : this.equipmentDetails.area,
        load: this.selectedType === 'Bomba' ? null : this.equipmentDetails.load,
      };
      this.isLoading = true;
      if (this.equipmentId) {
        variables.id = this.equipmentId;
        await updateEquipmentDetails(this.$apollo, variables);
      } else {
        await saveEquipmentDetails(this.$apollo, variables);
      }
      this.closeModal();
    },
  },
  created() {},
  filters: {},
  components: {},
  watch: {
    equipmentData(equipmentData) {
      this.reset();
      if (equipmentData) {
        if (equipmentData.type === 'Bomba' || equipmentData.type === 'Filtro') {
          this.selectedType = equipmentData.type;
        } else {
          this.selectedType = 'OUTRO';
          this.textFieldType = equipmentData.type;
        }
        this.equipmentId = equipmentData.id;
        this.isDisabled = equipmentData.isDisabled === 'block';
        Object.keys(this.equipmentDetails).forEach((equipmentDetailKey) => {
          this.equipmentDetails[equipmentDetailKey] = equipmentData[equipmentDetailKey];
        });
      }
    },
  },
  props: ['equipmentData'],
};
</script>

<style scoped>
</style>
