export const query = (apollo, graphqlQuery, variables = {}, options) =>
  apollo
    .query({
      fetchPolicy: 'no-cache',
      query: graphqlQuery,
      variables,
      ...options || {},
    })
    .then((resp) => resp.data);

export const mutation = (apollo, graphqlQuery, variables, options) =>
  apollo
    .mutate({
      mutation: graphqlQuery,
      variables,
      ...options || {},
    })
    .then((resp) => resp.data);
