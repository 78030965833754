var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "grid-list-xl": "", id: "containerTabEquipment" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", md7: "" } }, [
            _c("h2", [_vm._v("Equipamentos deste local")])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", md5: "" } },
            [
              !_vm.isClient
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ma-0",
                      staticStyle: { width: "100%" },
                      attrs: { large: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.showModalNewRegister = true
                        }
                      }
                    },
                    [_vm._v("\n        Cadastrar equipamento\n      ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("DataTable", {
                attrs: { tableContent: _vm.tableEquipments },
                on: {
                  selectedItemIndex: function($event) {
                    _vm.selectedEquipmentIndex = $event
                  }
                }
              })
            ],
            1
          ),
          _c(
            "CardModal",
            {
              attrs: { width: "50%" },
              model: {
                value: _vm.showModalRegister,
                callback: function($$v) {
                  _vm.showModalRegister = $$v
                },
                expression: "showModalRegister"
              }
            },
            [
              _c("RegisterEquipmentCardWithTabs", {
                attrs: {
                  selectedEquipmentId: _vm.selectedEquipmentId,
                  idPlaceEdit: _vm.idPlaceEdit
                },
                on: {
                  showModal: function($event) {
                    _vm.showModalRegister = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CardModal",
        {
          attrs: { width: "40%" },
          model: {
            value: _vm.showModalNewRegister,
            callback: function($$v) {
              _vm.showModalNewRegister = $$v
            },
            expression: "showModalNewRegister"
          }
        },
        [
          _c("RegisterEquipmentCard", {
            attrs: { idPlaceEdit: _vm.idPlaceEdit },
            on: {
              showModalNewRegister: function($event) {
                _vm.showModalNewRegister = $event
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              !_vm.isClient
                ? _c(
                    "v-btn",
                    {
                      attrs: { flat: "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("showModal", false)
                        }
                      }
                    },
                    [_vm._v("\n        fechar\n      ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }