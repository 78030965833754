import { query, mutation } from '@/services/GraphqlService';
import getCommitmentsByLocalQuery from '@/graphql/commitments/getCommitmentsByLocal.gql';
import getVisitCommitmentsQuery from '@/graphql/commitments/getVisitCommitments.gql';
import createCommitmentMutation from '@/graphql/commitments/createCommitment.gql';
import deleteCommitmentMutation from '@/graphql/commitments/deleteCommitment.gql';


export const getCommitmentsByLocal = (apollo, localId) =>
  query(apollo, getCommitmentsByLocalQuery, { id: localId })
    .then((resp) => resp.commitments);

export const createCommitment = (apollo, variables) =>
  mutation(apollo, createCommitmentMutation, variables);

export const deleteCommitment = (apollo, id) => mutation(apollo, deleteCommitmentMutation, { id });

export const getVisitCommitments = (apollo, variables) =>
  query(apollo, getVisitCommitmentsQuery, variables)
    .then((resp) => resp.commitments);
