var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "piscinapp-switch" }, [
    _c("div", { staticClass: "piscinapp-switch__container" }, [
      _c("input", {
        staticClass: "piscinapp-switch__input",
        attrs: { type: "checkbox" },
        domProps: { checked: _vm.value },
        on: { change: _vm.toggleValue }
      }),
      _c("div", { staticClass: "piscinapp-switch__track" }),
      _c("div", { staticClass: "piscinapp-switch__thumb" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }