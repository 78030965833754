<template>
  <v-data-iterator :items="[details.content]" hide-actions>
    <template v-slot:item="props">
      <v-flex xs12>
        <v-card>
          <v-list>
            <v-list-tile v-for="(item, index) in props.item" :key="index">
              <v-list-tile-content>{{ item.name }}</v-list-tile-content>
              <v-list-tile-content class="align-end">
                {{ item.value }}
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-flex>
    </template>
  </v-data-iterator>
</template>

<script>
export default {
  props: ['details'],
};
</script>
