import { query, mutation } from '@/services/GraphqlService';

import createPlaceMutation from '@/graphql/places/createPlaceMutation.gql';
import createMinimumQuantityMutation from '@/graphql/places/createMinimumQuantityMutation.gql';
import createPoolMutation from '@/graphql/places/createPoolMutation.gql';
import updatePlaceMutation from '@/graphql/places/updatePlaceMutation.gql';
import getSelectPlacesQuery from '@/graphql/places/getSelectPlacesQuery.gql';
import getPlacesToSelectByFranchiseeQuery from '@/graphql/places/getPlacesToSelectByFranchiseeQuery.gql';
import getPlacesToSelectByUserQuery from '@/graphql/places/getPlacesToSelectByUserQuery.gql';
import getPlacesQuery from '@/graphql/places/getPlacesQuery.gql';
import getPoolByLocalIdQuery from '@/graphql/places/poolByLocalIdQuery.gql';
import getPlacesForClientQuery from '@/graphql/places/getPlacesForClientQuery.gql';
import getPlaceQuery from '@/graphql/places/getPlaceQuery.gql';
import getPlaceForClientQuery from '@/graphql/places/getPlaceForClientQuery.gql';
import getPoolsByLocalIdQuery from '@/graphql/places/getPoolsByLocalIdQuery.gql';
import updatePoolMutation from '@/graphql/places/updatePoolMutation.gql';

export const createPlace = (apollo, variables) => mutation(apollo, createPlaceMutation, variables);

export const createMinimumQuantity = (apollo, variables) =>
  mutation(apollo, createMinimumQuantityMutation, variables);

export const createPool = (apollo, variables) => mutation(apollo, createPoolMutation, variables);

export const updatePool = (apollo, variables) => mutation(apollo, updatePoolMutation, variables);

export const updatePlace = (apollo, variables) => mutation(apollo, updatePlaceMutation, variables);

export const getPlacesToSelectByFranchisee = (apollo, variables) =>
  query(apollo, getPlacesToSelectByFranchiseeQuery, variables);

export const getPlacesToSelectByUser = (apollo, variables) =>
  query(apollo, getPlacesToSelectByUserQuery, variables);

export const getPlaces = (apollo, variables) => query(apollo, getPlacesQuery, variables);

export const getPlacesForClient = (apollo, variables) =>
  query(apollo, getPlacesForClientQuery, variables);

export const getPlace = (apollo, variables) => query(apollo, getPlaceQuery, variables);

export const getPoolByLocalId = (apollo, variables) =>
  query(apollo, getPoolByLocalIdQuery, variables, { client: 'visitorApolloClient' });

export const getPlaceForClient = (apollo, variables) =>
  query(apollo, getPlaceForClientQuery, variables);

export const getSelectPlaces = (apollo, variables) =>
  query(apollo, getSelectPlacesQuery, variables);

export const getPoolsByLocalId = (apollo, variables) =>
  query(apollo, getPoolsByLocalIdQuery, variables);

export const typeFilter = (value) => {
  const data = {
    CLIENT: 'Cliente',
    PROVIDER: 'Fornecedor',
    RESIDENTIAL: 'Residência',
    HEADQUARTERS: 'Sede',
    ALL: 'Todos',
  };
  return data[value];
};
