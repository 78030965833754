<template>
  <v-container>
    <div class="text-xs-center" v-if="isLoading">
      <v-progress-circular size="35" indeterminate color="primary"></v-progress-circular>
      <br />
    </div>
    <v-card v-else>
      <v-toolbar flat>
        <v-layout wrap>
          <v-flex xs12 md6>
            <v-btn
              class="mx-0 mb-2"
              color="primary"
              @click="isCreationModalShown = true"
              style="float: right; width:100%;"
            >
              <v-icon>add</v-icon>Adicionar compromisso
            </v-btn>
          </v-flex>
        </v-layout>
      </v-toolbar>
      <v-list subheader two-line style="overflow: auto; height: 300px;">
        <v-divider></v-divider>
        <v-list-tile v-for="(commitment, index) in commitments" :key="index">
          <v-list-tile-content>
            <v-list-tile-title>{{ commitment.employee.name }}</v-list-tile-title>
            <v-list-tile-sub-title
              >{{ commitment.daysOfWeek | commitmentDaysOfWeek }} -
              {{ commitment | commitmentDateTime }}
            </v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-layout>
              <v-flex mx-3>
                <v-btn @click="deleteCommitment(commitment.id)" icon>
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </v-card>
    <CardModal width="960" style="position: fixed;" v-model="isCreationModalShown">
      <v-card>
        <v-toolbar color="#0d1824" dark flat>
          <v-toolbar-title>Novo compromisso</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="padding-modal">
          <v-layout wrap>
            <v-flex md8 xs12>
              <EmployeeSelect v-model="formData.employeeId" class="px-2"></EmployeeSelect>
              <v-select
                full-width
                v-model="formData.daysOfWeek"
                :items="dayNames"
                attach
                chips
                label="Selecione os dias da semana"
                multiple
              ></v-select
            ></v-flex>
            <v-flex md4 xs12>
              <v-time-picker
                style="float: right;"
                format="24hr"
                v-model="formData.time"
              ></v-time-picker>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary darken-1" flat="flat" @click="isCreationModalShown = false">
            Fechar
          </v-btn>
          <v-btn :loading="isCommitmentCreationLoading" @click="createCommitment()" color="primary">
            Criar
          </v-btn>
        </v-card-actions>
      </v-card>
    </CardModal>
  </v-container>
</template>

<script>
import {
  getCommitmentsByLocal,
  createCommitment,
  deleteCommitment,
} from '@/services/CommitmentsService';
import CardModal from '@/components/modal/CardModal.vue';
import EmployeeSelect from '@/components/selects/EmployeeSelect.vue';

export default {
  data: () => ({
    isLoading: true,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    isCommitmentCreationLoading: false,
    isCreationModalShown: false,
    commitments: [],
    formData: {
      employeeId: '',
      time: '',
      daysOfWeek: [],
    },
  }),
  computed: {},
  methods: {
    async createCommitment() {
      this.isCommitmentCreationLoading = true;
      const splitedTime = this.formData.time.split(':');
      const brHour = Number(splitedTime[0]);
      const variables = {
        type: 'VISIT',
        hour: brHour < 21 ? brHour + 3 : brHour - 21,
        minute: splitedTime[1],
        employeeId: this.formData.employeeId,
        localId: this.idPlaceEdit,
        daysOfWeek: this.formData.daysOfWeek.map((dayOfWeekName) => ({
          value: this.dayNames.findIndex((name) => name === dayOfWeekName),
        })),
      };
      await createCommitment(this.$apollo, variables);
      await this.setCommitments();
      this.isCreationModalShown = false;
      this.isCommitmentCreationLoading = false;
      this.formData = this.$options.data().formData;
    },
    async deleteCommitment(id) {
      this.isLoading = true;
      await deleteCommitment(this.$apollo, id);
      await this.setCommitments();
      this.isLoading = false;
    },
    async setCommitments() {
      this.isLoading = true;
      this.commitments = await getCommitmentsByLocal(this.$apollo, this.idPlaceEdit);
      this.isLoading = false;
    },
  },
  created() {
    this.setCommitments();
  },
  filters: {
    commitmentDaysOfWeek(daysOfWeek) {
      const dayNames = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
      return daysOfWeek.reduce(
        (text, dayOfWeek) =>
          !text ? dayNames[dayOfWeek.value] : `${text}, ${dayNames[dayOfWeek.value]}`,
        '',
      );
    },
    commitmentDateTime(commitment) {
      const brHour =
        commitment.startHour > 2 ? commitment.startHour - 3 : commitment.startHour + 21;
      const pedStart = (number) => String(number).padStart(2, '0');
      return `${pedStart(brHour)}:${pedStart(commitment.startMinute)}`;
    },
  },
  components: {
    EmployeeSelect,
    CardModal,
  },
  props: ['idPlaceEdit'],
};
</script>

<style scoped>
.padding-modal {
  padding: 15px;
}
</style>
