var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-iterator", {
    attrs: { items: [_vm.details.content], "hide-actions": "" },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function(props) {
          return [
            _c(
              "v-flex",
              { attrs: { xs12: "" } },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-list",
                      _vm._l(props.item, function(item, index) {
                        return _c(
                          "v-list-tile",
                          { key: index },
                          [
                            _c("v-list-tile-content", [
                              _vm._v(_vm._s(item.name))
                            ]),
                            _c(
                              "v-list-tile-content",
                              { staticClass: "align-end" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.value) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }