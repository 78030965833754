import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/src/stylus/app.styl';
import pt from 'vuetify/es5/locale/pt';

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
    primary: '#1e88ca',
    secondary: '#a6ce39',
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
});
