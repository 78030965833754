import { mutation } from '@/services/GraphqlService';
import createLogMutation from '@/graphql/log/createLogMutation.gql';

export const log = (apollo, message, type) => {
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console[type || 'log'](message);
    } else {
        const logMessage = {
            platform: 'web',
            userId: localStorage.userId,
            data: message,
            type,
        };
        mutation(
            apollo,
            createLogMutation,
            { message: JSON.stringify(logMessage) },
        );
    }
};
