<template>
  <v-container id="containerPlace">
    <FilterCard
      :resetButton="true"
      @clickOnReset="resetFilter()"
      :searchButton="true"
      @clickOnSearch="submitFilter()"
    >
      <v-flex xs12 sm12 md6>
        <v-text-field
          class="mx-2"
          id="formFilter.searchString"
          label="Pesquise pelo nome / estado"
          v-model="formFilter.searchString"
        />
      </v-flex>
      <v-flex px-2 xs12 sm12 md4 v-if="isAdmin || isCoordinador">
        <FranchiseeSelect v-model="formFilter.franchiseeId" />
      </v-flex>
      <v-flex id="searchSelectStatus" xs12 sm12 md2>
        <StatusSelect class="mx-2" v-model="formFilter.status" />
      </v-flex>
    </FilterCard>
    <CardList
      v-if="listPlaces.length"
      sortBy="undefined"
      id="dataPlaces"
      @click="(element) => openModal(element.id)"
      :contentList="formatedPlacesList"
    />
    <LoadingCircle class="mt-3" v-if="isLoading" />
    <CardModal :removeURLParams="true" v-model="showModal" width="75%">
      <LocalCreation :idPlaceEdit="idPlaceEdit" @showModal="showModal = $event"></LocalCreation>
    </CardModal>
    <v-btn
      v-if="!isClient && !isLimited"
      fab
      bottom
      right
      color="#A6CE39"
      dark
      fixed
      @click="openModal()"
      title="Novo"
      id="create"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { getPlaces } from '@/services/PlacesService';
import { isRole } from '@/services/UtilsService';

import FilterCard from '@/components/filters/FilterCard.vue';
import CardList from '@/components/lists/CardList.vue';
import LoadingCircle from '@/components/animations/LoadingCircle.vue';
import CardModal from '@/components/modal/CardModal.vue';
import LocalCreation from '@/components/places/LocalCreation.vue';
import StatusSelect from '@/components/selects/StatusSelect.vue';
import FranchiseeSelect from '@/components/selects/FranchiseeSelect.vue';

export default {
  data: () => ({
    isAdmin: isRole('ADMIN'),
    isCoordinador: isRole('COORDINADOR'),
    isClient: isRole('CLIENT'),
    isLimited: isRole('LIMITED'),
    isLoading: false,
    idPlaceEdit: null,
    showModal: '',
    listPlaces: [],
    formFilter: {
      searchString: '',
      status: null,
      franchiseeId: '',
    },
    lastPlaceId: null,
    isLazyLoadEnabled: true,
  }),
  components: {
    FilterCard,
    CardList,
    CardModal,
    LocalCreation,
    StatusSelect,
    LoadingCircle,
    FranchiseeSelect,
  },
  methods: {
    resetFilter() {
      this.isLazyLoadEnabled = true;
      this.formFilter = this.$options.data().formFilter;
      this.listPlaces = [];
      this.getPlacesList();
    },
    async getPlacesList(variables) {
      this.isLoading = true;
      if (!variables) {
        variables = {
          first: 25,
          after: this.lastPlaceId,
          where: {
            isDisabled: false,
          },
        };
      }
      await getPlaces(this.$apollo, variables)
        .then((resp) => {
          if (resp.locals.length) {
            this.lastPlaceId = variables.first && resp.locals[resp.locals.length - 1].id;
            this.listPlaces = [...this.listPlaces, ...resp.locals];
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async submitFilter() {
      this.isLazyLoadEnabled = false;
      this.listPlaces = [];
      const variables = {
        where: {
          franchisee:
            this.formFilter.franchiseeId === '' ? undefined : { id: this.formFilter.franchiseeId },
          AND: [
            {
              OR: [
                { localName_contains: this.formFilter.searchString },
                { address: { state_contains: this.formFilter.searchString } },
              ],
            },
            {
              isDisabled: {
                ACTIVE: false,
                INACTIVE: true,
                ALL: undefined,
              }[this.formFilter.status],
            },
          ],
        },
      };

      await this.getPlacesList(variables);
    },
    openModal(elementId = '') {
      this.idPlaceEdit = elementId;
      this.showModal = true;
    },
  },
  computed: {
    formatedPlacesList() {
      return {
        columns: [
          { type: 'text', text: 'Local', value: 'local' },
          { type: 'text', text: 'Franquia', value: 'franchisee' },
          { type: 'icon', text: '', value: 'isDisabled' },
        ],
        data: this.listPlaces.map((place) => ({
          local: place.localName,
          franchisee:
            place.franchisee.id !== 'cjqs1fn49c2js0762hu91g5ti'
              ? place.franchisee.employee.name
              : 'Piscina Fácil',
          isDisabled: place.isDisabled ? 'block' : 'done',
          id: place.id,
        })),
      };
    },
  },
  watch: {
    showModal(showModal) {
      if (showModal) return;
      if (!this.idPlaceEdit) {
        this.getPlacesList();
        this.idPlaceEdit = null;
        this.listPlaces = [];
      }
    },
  },
  async created() {
    this.getPlacesList();
    window.onscroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        if (!this.isLoading && this.isLazyLoadEnabled) {
          this.getPlacesList();
        }
      }
    };
  },
  mounted() {
    if (window.location.href.includes('?id=')) {
      const localId = this.$router.app.$route.fullPath.split('?id=')[1];
      this.openModal(localId);
    }
  },
};
</script>
