var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", md4: "" } },
                [
                  _c("v-img", {
                    staticClass: "ma-2",
                    style: `cursor: pointer;`,
                    attrs: {
                      "min-width": "100px",
                      "lazy-src": _vm.analyze.urlPhotos[0],
                      src: _vm.analyze.urlPhotos[1] || _vm.analyze.urlPhotos[0]
                    },
                    on: {
                      click: () => {
                        if (!_vm.isMobile) {
                          _vm.clickedPhotos = _vm.analyze.urlPhotos
                          _vm.dialog = !_vm.dialog
                        }
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", md4: "" } },
                [
                  _vm.analyze.completionUrlPhotos.length
                    ? _c("v-img", {
                        staticClass: "ma-2",
                        style: `cursor: pointer;`,
                        attrs: {
                          "min-width": "100px",
                          "lazy-src": _vm.analyze.completionUrlPhotos[0],
                          src:
                            _vm.analyze.completionUrlPhotos[1] ||
                            _vm.analyze.completionUrlPhotos[0]
                        },
                        on: {
                          click: () => {
                            if (!_vm.isMobile) {
                              _vm.clickedPhotos =
                                _vm.analyze.completionUrlPhotos
                              _vm.dialog = !_vm.dialog
                            }
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", md4: "" } },
                [
                  _vm.analyze.urlTestStripPhotos.length
                    ? _c("v-img", {
                        staticClass: "ma-2",
                        style: `cursor: pointer;`,
                        attrs: {
                          "min-width": "100px",
                          "lazy-src": _vm.analyze.urlTestStripPhotos[0],
                          src: _vm.analyze.urlTestStripPhotos[0]
                        },
                        on: {
                          click: () => {
                            if (!_vm.isMobile) {
                              _vm.clickedPhotos = _vm.analyze.urlTestStripPhotos
                              _vm.dialog = !_vm.dialog
                            }
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { width: _vm.windowHeight * 0.8 } },
            [
              _vm.dialog
                ? _c("v-img", {
                    attrs: {
                      "lazy-src": _vm.clickedPhotos[1] || _vm.clickedPhotos[0],
                      src:
                        _vm.clickedPhotos[2] ||
                        _vm.clickedPhotos[1] ||
                        _vm.clickedPhotos[0],
                      id: "bigImage"
                    }
                  })
                : _vm._e(),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.dialog = !_vm.dialog
                        }
                      }
                    },
                    [_vm._v("\n          Voltar\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }