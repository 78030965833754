<template>
  <div
    id="clockCheck"
    style="
    margin-bottom: 8px;
    box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.40);
    margin-
    "
  >
    <v-list-tile
      v-bind:class="
        line.type === 'Ponto'
          ? line.duration.alert == true
            ? 'tileHeaderAlert'
            : 'tileHeader'
          : 'tileContent'
      "
      v-for="(line, index) in contentLines"
      v-on:click="openPhoto(line)"
      :key="index"
      avatar
    >
      <v-flex xs6 lg4>
        <v-list-tile-content>
          <v-list-tile-title v-text="line.employeeName" />
        </v-list-tile-content>
      </v-flex>
      <v-flex xs4 lg4 class="hideMobile">
        <v-list-tile-content>
          <v-list-tile-title v-text="line.local" />
        </v-list-tile-content>
      </v-flex>
      <v-flex xs3 lg1>
        <v-list-tile-content
          :title="formatDateTimeAndObeservationText(line.startInCell, line.observationStart)"
          class="alignment"
        >
          {{ line.start.time }}
          <br />
          {{ !index ? line.start.date : '' }}
        </v-list-tile-content>
      </v-flex>
      <v-flex xs3 lg1>
        <v-list-tile-content
          :title="formatDateTimeAndObeservationText(line.endsInCell, line.observationEnds)"
          class="alignment"
        >
          {{ line.ends.time }}
          <br />
          {{ !index ? line.ends.date : '' }}
        </v-list-tile-content>
      </v-flex>
      <v-flex xs3 lg1 class="hideMobile">
        <v-list-tile-content>
          <v-list-tile-title v-text="line.delay" class="alignment" />
        </v-list-tile-content>
      </v-flex>
      <v-flex xs2 lg2 class="hideMobile">
        <v-list-tile-content>
          <v-list-tile-title v-text="line.duration.string" class="alignment" />
        </v-list-tile-content>
      </v-flex>
    </v-list-tile>
  </div>
</template>

<script>
import * as moment from 'moment';

export default {
  data: () => ({}),
  computed: {
    headerLine() {
      return {
        id: this.header.id,
        duration: this.calcDuration(this.header.start, this.header.ends),
        employeeName: this.header.employee.name,
        type: this.header.local ? 'Visita' : 'Ponto',
        local: this.header.local ? this.header.local.localName : '',
        start: this.formatVert(this.header.start),
        ends: this.formatVert(this.header.ends),
        startInCell: this.header.startInCell
          ? moment(this.header.startInCell).format('DD/MM/YYYY HH:mm')
          : '',
        endsInCell: this.header.endsInCell
          ? moment(this.header.endsInCell).format('DD/MM/YYYY HH:mm')
          : '',
        observationStart: this.header.observationStart,
        observationEnds: this.header.observationEnds,
      };
    },
    contentLines() {
      return (this.header ? [this.headerLine] : []).concat(
        this.content.map((check) => ({
          id: check.id,
          duration: this.calcDuration(check.start, check.ends),
          employeeName: check.employee.name,
          type: check.local ? 'Visita' : 'Ponto',
          local: check.local ? check.local.localName : '',
          start: this.formatVert(check.start),
          ends: this.formatVert(check.ends),
          delay: check.delay,
          startInCell: check.startInCell
            ? moment(check.startInCell).format('DD/MM/YYYY HH:mm')
            : '',
          endsInCell: check.endsInCell ? moment(check.endsInCell).format('DD/MM/YYYY HH:mm') : '',
          observationStart: check.observationStart,
          observationEnds: check.observationEnds,
          comprovationSelfieUrl: check.comprovationSelfieUrl,
        })),
      );
    },
  },
  methods: {
    openPhoto(line) {
      if (line.comprovationSelfieUrl && line.comprovationSelfieUrl.length) {
        window
          .open(
            line.comprovationSelfieUrl[2] ||
              line.comprovationSelfieUrl[1] ||
              line.comprovationSelfieUrl[0],
            '_blank',
          )
          .focus();
      }
    },
    formatVert(dateTime) {
      return dateTime
        ? {
            time: moment(dateTime).format('HH:mm'),
            date: moment(dateTime).format('DD/MM'),
          }
        : {};
    },
    formatDateTimeAndObeservationText(dateTime, obervation) {
      return `Horário no telefone: ${dateTime || 'Não disponível'} || Observação: ${obervation ||
        'Não disponível'}`;
    },
    calcDuration(start, ends) {
      if (!ends || !start) {
        return { string: !start ? 'Não iniciado' : 'Não finalizado', alert: false };
      }
      const durationInSeconds = moment(ends, 'YYYY-MM-DD HH:mm').diff(
        moment(start, 'YYYY-MM-DD HH:mm'),
        'minutes',
      );
      const duration = moment.duration(durationInSeconds, 'minutes');
      let durationMinutes = String(duration.minutes());
      if (durationMinutes.length < 2) {
        durationMinutes = `0${durationMinutes}`;
      }
      let durationHours = duration.hours();
      if (duration.days()) {
        durationHours += duration.days() * 24;
      }
      return {
        string: `${durationHours}h${durationMinutes}m`,
        alert: durationHours >= 6,
      };
    },
  },
  props: ['header', 'content'],
  created() {},
};
</script>
<style scoped>
.tileHeader {
  background: rgb(225, 249, 255);
}
.tileContent {
  background: '' /* rgb(238, 255, 204) */;
}
.tileHeaderAlert {
  background: rgb(248, 156, 145);
}
@media screen and (max-width: 960px) {
  #clockCheck .hideMobile {
    display: none;
  }
  #clockCheck .alignment {
    align-items: center;
  }
}
</style>
