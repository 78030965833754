var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    attrs: {
      autocomplete: "off",
      items: _vm.pools,
      name: "locais",
      label: "Selecione uma piscina",
      "item-text": "name",
      "item-value": "id",
      value: _vm.value.id,
      "return-object": "",
      disabled: _vm.disabled || !_vm.pools.length,
      rules: _vm.rules
    },
    on: {
      change: function($event) {
        return _vm.$emit("input", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }