var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.places.length
    ? _c("v-autocomplete", {
        attrs: {
          autocomplete: "off",
          items: _vm.places,
          name: "locais",
          label: "Selecione o local",
          "item-text": "localName",
          "item-value": "id",
          value: _vm.value.id,
          "return-object": "",
          disabled: _vm.disabled,
          rules: _vm.rules
        },
        on: {
          change: function($event) {
            return _vm.$emit("input", $event)
          }
        }
      })
    : _c("p", [_vm._v("Não há outro local vinculado a esta franquia.")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }