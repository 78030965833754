<template>
  <v-autocomplete
    autocomplete="off"
    menu-props="auto"
    :disabled="disabled"
    :items="avalibleProduts"
    @change="
      (id) => {
        $emit('input', id);
        $emit(
          'selected',
          productDescriptionList.find((description) => description.value === id),
        );
      }
    "
    :value="value"
    label="Selecione o produto"
    item-text="name"
    item-value="value"
  />
</template>
<script>
import { getProductDescriptionToSelect } from '@/services/ProductsService';

export default {
  data: () => ({
    productDescriptionList: [],
  }),
  computed: {
    avalibleProduts() {
      if (this.availableProductsIdList && this.availableProductsIdList.length) {
        return this.productDescriptionList.filter((productDescription) =>
          this.availableProductsIdList.some((id) => id === productDescription.value),
        );
      }
      return this.productDescriptionList;
    },
  },
  methods: {
    async getProductDescriptions() {
      const productDescriptions = await getProductDescriptionToSelect(this.$apollo);
      this.productDescriptionList = productDescriptions.map((description) => ({
        value: description.id,
        name: description.name,
        unit: description.unit,
        chemicalPurity: description.chemicalPurity,
      }));
    },
  },
  created() {
    this.getProductDescriptions();
  },
  props: ['value', 'disabled', 'availableProductsIdList'],
};
</script>
